import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";
import { RootState } from '../app/store';

export interface productsState {
  fetchProductList: any;
  fetchAddProductStatus: string;
  fetchUpdateProductStatus: string;
  fetchProductListStatus: string;
  fetchDeleteProductStatus: string;
  fetchProductDetail: any;
  fetchProductDetailStatus: string;
  fetchPendingProductsStatus: string;
  pendingProducts: Array<any>;
}

const initialState: productsState = {
  fetchProductList: [],
  fetchAddProductStatus: SAGA_STATUS_INIT,
  fetchUpdateProductStatus: SAGA_STATUS_INIT,
  fetchProductListStatus: SAGA_STATUS_INIT,
  fetchDeleteProductStatus: SAGA_STATUS_INIT,
  fetchProductDetail: {},
  fetchProductDetailStatus: SAGA_STATUS_INIT,
  fetchPendingProductsStatus: SAGA_STATUS_INIT,
  pendingProducts: []
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductList(state) {
      state.fetchProductListStatus = SAGA_STATUS_PENDING;
    },
    fetchProductListSuccess(state, action: PayloadAction<any>) {
      state.fetchProductList = action.payload;
      state.fetchProductListStatus = SAGA_STATUS_SUCCESS;
    },
    fetchProductListFailed(state, action: PayloadAction<string>) {
      state.fetchProductListStatus = SAGA_STATUS_FAILED;
    },
    addProduct(state, action: PayloadAction<any>) {
      state.fetchAddProductStatus = SAGA_STATUS_PENDING;
    },
    addProductSuccess(state, action: PayloadAction<any>) {
      state.fetchProductList.push(...action.payload)
      state.fetchAddProductStatus = SAGA_STATUS_SUCCESS;
    },
    addProductFailed(state) {
      state.fetchAddProductStatus = SAGA_STATUS_FAILED;
    },
    updateProduct(state, action: PayloadAction<any>) {
      state.fetchUpdateProductStatus = SAGA_STATUS_PENDING;
    },
    updateProductSuccess(state, action: PayloadAction<any>) {
      action.payload.forEach((updatedObj: any) => {
        let productToUpdateIndex = state.fetchProductList.findIndex((p: any) => p._id = updatedObj._id);
        if(productToUpdateIndex > -1) {
          state.fetchProductList[productToUpdateIndex] = action.payload[0]
        }
      })
      state.fetchUpdateProductStatus = SAGA_STATUS_SUCCESS;
    },
    updateProductFailed(state, action: PayloadAction<string>) {
      state.fetchUpdateProductStatus = SAGA_STATUS_FAILED;
    },
    deleteProduct(state, action: PayloadAction<any>) {
      state.fetchDeleteProductStatus = SAGA_STATUS_INIT;
    },
    deleteProductSuccess(state, action: PayloadAction<any>) {
      state.fetchProductList = state.fetchProductList.filter((product: any) => product._id !== action.payload._id)
      state.fetchDeleteProductStatus = SAGA_STATUS_SUCCESS;
    },
    deleteProductFailed(state, action: PayloadAction<string>) {
      state.fetchDeleteProductStatus = SAGA_STATUS_FAILED;
    },
    fetchProductDetail(state, action: PayloadAction<any>){
      state.fetchProductDetailStatus = SAGA_STATUS_PENDING;
    },
    fetchProductDetailSuccess(state, action: PayloadAction<any>){
      state.fetchProductDetail = action.payload;
      state.fetchProductDetailStatus = SAGA_STATUS_SUCCESS;
    },
    fetchProductDetailFailed(state, action: PayloadAction<string>){
      state.fetchProductDetailStatus = SAGA_STATUS_FAILED;
    },
    fetchPendingProducts(state) {
      state.fetchPendingProductsStatus = SAGA_STATUS_PENDING;
    },
    fetchPendingProductsSuccess(state, action: PayloadAction<any>) {
      state.pendingProducts = action.payload
      state.fetchPendingProductsStatus = SAGA_STATUS_SUCCESS;
    },
    fetchPendingProductsFailure(state, action: PayloadAction<any>) {
      state.fetchPendingProductsStatus = SAGA_STATUS_FAILED;
    },
    resetProductStatus(state) {
      state.fetchAddProductStatus = SAGA_STATUS_INIT;
      state.fetchUpdateProductStatus = SAGA_STATUS_INIT;
      state.fetchDeleteProductStatus = SAGA_STATUS_INIT;
      state.fetchProductDetailStatus = SAGA_STATUS_INIT;
      state.fetchPendingProductsStatus = SAGA_STATUS_INIT;
    },
    reset(state) {
      state.fetchProductList = [];
      state.fetchAddProductStatus = SAGA_STATUS_INIT;
      state.fetchUpdateProductStatus = SAGA_STATUS_INIT;
      state.fetchProductListStatus = SAGA_STATUS_INIT;
      state.fetchDeleteProductStatus = SAGA_STATUS_INIT;
      state.fetchProductDetail = {};
      state.fetchProductDetailStatus = SAGA_STATUS_INIT;
      state.fetchPendingProductsStatus = SAGA_STATUS_INIT;
      state.pendingProducts = [];
    }   
  },
});

// Actions
export const productsActions = productsSlice.actions;

// Selectors
export const fetchProductList = (state: RootState) => state.products.fetchProductList;
export const fetchAddProductStatus = (state: RootState) => state.products.fetchAddProductStatus;
export const fetchDeleteProductStatus = (state: RootState) => state.products.fetchDeleteProductStatus;
export const fetchProductDetail = (state: RootState) => state.products.fetchProductDetail;
export const fetchUpdateProductStatus = (state: RootState) => state.products.fetchUpdateProductStatus;
export const getFetchPendingProductsStatus = (state: RootState) => state.products.fetchPendingProductsStatus;
export const fetchPendingProducts = (state: RootState) => state.products.pendingProducts;

// Reducer
const productsReducer = productsSlice.reducer;
export default productsReducer;
