import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";
import { store } from "../../app/store";

function* httpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };
  
  const state = store.getState();
  
  
  let token = state?.auth?.currentUser?.tempToken ?  state?.auth?.currentUser?.tempToken :  state?.auth?.currentUser?.accessToken
  let myError;
  if (token) {
    payloadData.headers['accesstoken'] = token
  }
  try {
    const { data: result, error, headers } = yield call(axiosInstance, payloadData);
    return {
      error,
      result,
      headers
    };
  } catch (error: any) {

    myError = error?.response?.status ? error.response.data : error;

    if (myError?.status) {
      if (myError.status === 401) {
        yield put(push({ pathname: "/" }));
        toast.error("Session Expired. Please login again.");
      } else {
        toast.error(myError.message);
      }
    }  
    // } else {
    //   toast.error(myError.message);
    // }
  }

  return {
    error: myError?.message ? myError : true,
    result: null,
  };
}

export default httpClient;
