import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { calendarActions } from './calendarSlice';
import httpClient from "../../api/bookingApi/httpClient";
import { toast } from "react-toastify";

function* handleFetchCalendarData(action: PayloadAction<any>) {
  
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/bookings/calendarData?${action.payload}`,
  });
  
  if (error) {
    yield put(calendarActions.fetchCalendarFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    
    yield put(calendarActions.fetchCalendarSuccess(result.payload))
  }
  return { error, result };
}


export function* calendarSaga() {
  yield takeLatest(calendarActions.fetchCalendarData.type, handleFetchCalendarData);
 
}
