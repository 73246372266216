import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { Payouts } from '../models/payouts';
import { MerchantBookedTeesheets } from '../models/merchantBookedTeesheets';

export interface payoutTransactionsState {
    fetchPayoutTransactionsStatus: string;
    fetchActivePayoutStatus: string;
    payoutTransactionsCount: number;
    payoutTransactions: Array<MerchantBookedTeesheets>;
    activePayout: Payouts;
}

const initialState: payoutTransactionsState = {
    fetchPayoutTransactionsStatus : "",
    fetchActivePayoutStatus: "",
    payoutTransactionsCount: 0,
    payoutTransactions: [],
    activePayout: {}
};

const payoutTransactionsSlice = createSlice({
  name: 'payoutTransactions',
  initialState,
  reducers: {
    payoutTransactionsList(state, action: PayloadAction<any>) {
        state.fetchPayoutTransactionsStatus = "pending";
    },
    payoutTransactionsListSuccess(state, action: PayloadAction<any>) {
        const { count = 0, result = [] } = (action?.payload || {});
        state.payoutTransactions = result;
        state.payoutTransactionsCount = count ? count : result.length;
        state.fetchPayoutTransactionsStatus = "success";
    },
    payoutTransactionsListFailed(state, action: PayloadAction<string>) {
        state.fetchPayoutTransactionsStatus = "failed";
    },
    fetchActivePayout(state, action: PayloadAction<any>) {
        state.fetchActivePayoutStatus = "pending";
    },
    fetchActivePayoutSuccess(state, action: PayloadAction<any>) {
        const { result = [{}] } = action.payload;
        state.activePayout = result[0];
        state.fetchActivePayoutStatus = "success";
    },
    fetchActivePayoutFailed(state, action: PayloadAction<any>) {
        state.fetchActivePayoutStatus = "failed";
    },
    resetPayoutTransactions(state) {
        state = initialState;
    }
  }
});

// Actions
export const payoutTransactionsActions = payoutTransactionsSlice.actions;

// Selectors
export const fetchMerchantPayoutTransactions = (state: RootState) => state.payoutTransactions.payoutTransactions;
export const fetchMerchantActivePayout = (state: RootState) => state.payoutTransactions.activePayout;

// Reducer
const payoutTransactionsReducer = payoutTransactionsSlice.reducer;
export default payoutTransactionsReducer;
