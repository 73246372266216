import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import "./common-header.scss";

import { useAppSelector } from "../../app/hooks";
import { currentUser } from "../../reducers/authSlice";
import { Rating } from "react-simple-star-rating";
interface CommonHeaderProps {
  sectionTitle?: any;
  hasExportButton?: boolean;
  hasAddProductButton?: boolean;
  hasRatingVisible?: boolean;
  disabledAddProductButton?: boolean;
  hasPendingProducts?: boolean
}

const CommonHeader: FC<CommonHeaderProps> = ({
  sectionTitle,
  hasExportButton,
  hasAddProductButton,
  hasRatingVisible,
  disabledAddProductButton,
  hasPendingProducts
}) => {

  const { firstname, lastname } = useAppSelector(currentUser);
  const navigate = useNavigate();

  return (
    <section className="commonHeader">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md">
            <h1>
              Welcome,{" "}
              <span>
                {firstname} {lastname}
              </span>
            </h1>
            <h6>{sectionTitle}</h6>
          </div>
          {sectionTitle == "Listings" && (
            <div className="col-12 col-md-auto">
              <button
                type="button"
                className="button button-primary button-rounded button-min-120 fw600"
                onClick={() => navigate("/register")}
              >
                + Add Course
              </button>
            </div>
          )}
          {hasAddProductButton && (
            <div className="col-12 col-md-auto">
              <button
                type="button"
                disabled={disabledAddProductButton || hasPendingProducts}
                title={
                  disabledAddProductButton
                    ? hasPendingProducts
                      ? "Please confirm or reject the existing products before adding new ones."
                      : "4 products can be added."
                    : ""
                }
                className="button button-primary button-rounded button-min-120 fw600"
                onClick={() => navigate("/product/add")}
              >
                + Add New
              </button>
            </div>
          )}
          {hasExportButton && (
            <div className="col-12 col-md-auto">
              <a href={void 0} className="color-s3 fw600 _linkButton">
                <em className="icon-export me-2"></em> Export
              </a>
            </div>
          )}
          {hasRatingVisible && (
            <div className="col-auto text-center _ratingColumn">
              <div className="ratingBlock mb-2">
                <Rating
                  initialValue={2}
                  showTooltip
                  size={17}
                  fillColor={"#FF8F28"}
                  tooltipArray={["1.0", "2.0", "3.0", "4.0", "5.0"]}
                  readonly
                />
              </div>
              <p className="mb-0 fontsize-10 fw500">4k customers ratings</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CommonHeader;
