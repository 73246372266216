import React, { FC } from "react";
import "./transition-detail-modal.scss";
import Modal from "react-bootstrap/Modal";
import { DateTime } from "luxon";

interface TransitionDetailModalProps {
  showModal: boolean;
  handleClose: (arg: any) => void;
  transaction: any
}

const TransitionDetailModal: FC<TransitionDetailModalProps> = ({
  showModal,
  handleClose,
  transaction
}) => {
  const close = () => {
    handleClose(false);
  };
  
  return (
    <>
      <Modal
        className="transitionDetailModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton">
            <em className="fa-solid fa-close" onClick={() => close()}></em>
          </a>
          <h4 className="fontsize-16 color-700 fw700 mb-4">
            Transaction Details
          </h4>
          <div className="formWrap">
            <div className="text-center mt-5 pt-4">
              {transaction?.transaction?.status == "SUCCESS" &&
                <img
                  src="../assets/images/icons/bill-check.svg"
                  alt=""
                  className="d-inline-block mb-4"
                />}
              {transaction?.transaction?.status != "SUCCESS" && <img
                src="../assets/images/icons/bill-cross.svg"
                alt=""
                className="d-inline-block mb-4"
              />}
              <h3 className="mb-3">${transaction?.totalAmount ? transaction?.totalAmount?.toFixed(2) : '$0.00'}</h3>
              <p className="mb-3">Transaction ID : {transaction?.transaction?.transactionId}</p>

              <label className={`badgeStatus ${transaction?.transaction?.status == 'SUCCESS' ? 'success' : 'pending'}`}>
                {transaction?.transaction?.status}
              </label>
            </div>

            <div className="_detailBlock mt-5">
              <div className="row gutters-5 py-1">
                <div className="col">
                  <label className="my-2 color-700">Booking Amount</label>
                </div>
                <div className="col-auto">
                  <h6 className="my-2 fw700">${transaction?.totalAmount ? transaction?.totalAmount?.toFixed(2) : '$0.00'}</h6>
                </div>
              </div>
              <div className="row gutters-5 py-1">
                <div className="col">
                  <label className="my-2 color-700">Total Amount</label>
                </div>
                <div className="col-auto">
                  <h6 className="my-2 fw700">${transaction?.totalAmount ? transaction?.totalAmount?.toFixed(2) : '$0.00'}</h6>
                </div>
              </div>
              <div className="row gutters-5 py-1">
                <div className="col">
                  <label className="my-2 color-700">Booked On</label>
                </div>
                <div className="col-auto">
                  <h6 className="my-2 fw700">{transaction?.booking?.createdAt ? (DateTime.fromMillis(transaction?.booking?.createdAt).toFormat('dd LLLL y, hh:mm a')) : "-"}</h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransitionDetailModal;
