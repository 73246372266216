import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { DateTime } from "luxon";
import "./weather-discount-modal.scss";
import {
  capitalizeString,
  disableNumWheelBehaviour,
} from "../../../../utils/common";

type PopUpProps = {
  show: boolean;
  setShow: Function;
  submitHandler: any;
  data: any;
  selectedDate: Date;
  selectedDiscount: any;
};
function WeatherDiscountPopup({
  show,
  setShow,
  data,
  submitHandler,
  selectedDate,
  selectedDiscount,
}: PopUpProps) {
  const handleClose = () => setShow(false);
  const [discount, setDiscount] = useState<number | null>();
  const [discountError, setDiscountError] = useState<string>("");
  const [date, setDate] = useState(selectedDate);

  useEffect(() => {
    if (selectedDiscount?.date) {
      setDate(new Date(`${selectedDiscount?.date}T00:00:00`));
      setDiscount(selectedDiscount.discount);
    }
  }, [selectedDiscount]);

  return (
    <>
      <Modal
        className="updateInfoModalWrapper3"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={handleClose}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            {
              <div className="formBlock">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!discount || discount <= 0)
                      setDiscountError("Enter valid discount");
                    else {
                      submitHandler({
                        _id: selectedDiscount?._id,
                        clubId: data.clubId,
                        courseId: data?.courseId,
                        date: DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"),
                        discount,
                      });
                    }
                  }}
                >
                  <div className="row gutters-10">
                    <div className="col-12 col-sm ">
                      <h4 className="mb-4 mt-1">
                        {selectedDiscount?._id ? "Edit" : "Add"} Bad Weather
                        Discount
                      </h4>

                      {/* <div style={{display:"flex",justifyContent: "center", flexDirection:"column", alignItems:"center"}}> */}

                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control control-lg"
                            value={DateTime.fromJSDate(date).toFormat(
                              "yyyy-MM-dd"
                            )}
                            disabled={true}
                            id="fromDate"
                            placeholder="Date"
                          />
                          <label htmlFor="fromDate">Date</label>
                        </div>
                      </div>

                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="number"
                            className="form-control control-lg"
                            placeholder="Discount"
                            value={discount ?? 0}
                            step={1}
                            max={100}
                            id="discount"
                            onWheel={disableNumWheelBehaviour}
                            onChange={(e) => {
                              setDiscount(
                                Number(e.target.value) > 0
                                  ? Math.trunc(Number(e.target.value))
                                  : null
                              );
                              discountError && setDiscountError("");
                            }}
                          />
                          <label htmlFor="discount">Discount (%)</label>
                        </div>
                        {discountError && (
                          <div className="isInvalidMessage text-danger">
                            {discountError}
                          </div>
                        )}
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className=" button button-primary button-rounded button-min-100 button-lm fontsize-16 fw700 mb-2"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WeatherDiscountPopup;
