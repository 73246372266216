import React, { FC, useEffect, useState, useLayoutEffect, useRef } from "react";
import "./add-product.scss";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { merchantFilesActions, fetchSelectedMerchantFiles } from "../../../../reducers/merchantFilesSlice";
import { MerchantFiles } from "../../../../models/merchantFiles";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { productsActions, fetchAddProductStatus, fetchProductDetail, fetchUpdateProductStatus } from "../../../../reducers/productSlice";
import { AddProductPayload } from "../../../../models/product";
import { isImageSizeValid, isValidImage } from "../../../../utils/common";
import debounce from 'lodash/debounce';

const AddProduct = () => {

  const dispatch: any = useAppDispatch();
  const [hasModalVisible, sethasModalVisible] = useState(false);
  const [showAddImageError, setShowAddImageError] = useState(false);
  const [searchParams] = useSearchParams();
  const addProductStatus: string = useAppSelector(fetchAddProductStatus);
  const updateProductStatus: string = useAppSelector(fetchUpdateProductStatus);
  const [inventoryMaxError, setInventoryMaxError] = useState(false);
  const [imagesDataCount, setImagesDataCount] = useState<any>(0);
  const [showMaxImageError, setShowMaxImageError] = useState(false);
  const [isInventoryNeeded, setIsInventoryNeeded] = useState(true);
  const productDetail = useAppSelector(fetchProductDetail);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<AddProductPayload>()
  const merchantImages: Array<MerchantFiles> = useAppSelector(
    fetchSelectedMerchantFiles
  );
  const navigate = useNavigate();
  const productId = searchParams.get("productId");
  const isApproved = searchParams.get("isApproved");
  const [partialFormData, setPartialFormData] = useState({
    price: "",
    maxCartQuantity: "",
    inventory: ""
  })

  useEffect(() => {
    if (!productId) {
      dispatch(merchantFilesActions.resetSelectedMerchantFile());
    }
    else {
      const query = {
        id: productId,
        ...(isApproved ? { isApproved } : {})
      };
      dispatch(productsActions.fetchProductDetail(query))
    }

  }, [])


  useEffect(() => {
    if (isInventoryNeeded === false) {
      setInventoryMaxError(false);
    }
  }, [isInventoryNeeded])


  useEffect(() => {
    if (productId && productDetail?.length > 0) {
      dispatch(merchantFilesActions.merchantFilesUpdate(productDetail[0].images));
      let defaultValues: any = {};
      defaultValues.title = productDetail[0].title.trim()
      defaultValues.description = productDetail[0].description.trim()
      defaultValues.price = Number(productDetail[0].price).toFixed(2)
      defaultValues.maxCartQuantity = productDetail[0].maxQtyForCart
      defaultValues.inventory = productDetail[0].inventory
      setIsInventoryNeeded(productDetail[0]?.isInventoryRequired !== undefined && productDetail[0]?.isInventoryRequired !== null
        ? productDetail[0]?.isInventoryRequired
        : true);
      setPartialFormData({ ...defaultValues })
      let files = productDetail[0]?.images.map((item: any) => {
        return { _id: item?._id, path: item?.path };
      });
      setImagesDataCount(files?.length);
      reset({ ...defaultValues, isInventoryNeeded: productDetail[0]?.isInventoryRequired });
    }
  }, [searchParams, productDetail]);

  const addOrUpdateProduct = (productId: string | null, obj: any) => {
    if (productId) {
      let updateObj: any = [{
        ...obj,
        id: productId
      }]
      dispatch(productsActions.updateProduct(updateObj))
    } else {
      dispatch(productsActions.addProduct(obj))
    }
  }
  /**use-ref to set internal timeouts to point to current function component */
  const addOrUpdateDebounced = useRef(debounce(addOrUpdateProduct, 500));

  const onSubmit: SubmitHandler<AddProductPayload> = data => {
    if (!merchantImages || merchantImages.length == 0) {
      setShowAddImageError(true)
      return
    }

    if (merchantImages && merchantImages.length > 1) {
      setShowMaxImageError(true)
      return;
    }

    if (isInventoryNeeded && partialFormData?.maxCartQuantity > partialFormData?.inventory) {
      setInventoryMaxError(true);
      return;
    }

    else {
      setShowAddImageError(false);
      setShowMaxImageError(false);
      setInventoryMaxError(false);
      let photos = merchantImages.map(({ _id }) => _id);

      let obj = {
        title: data?.title?.trim(),
        description: data?.description?.trim(),
        images: photos,
        price: Number(data.price).toFixed(2),
        maxQtyForCart: Number(data.maxCartQuantity),
        inventory: isInventoryNeeded === true ? Number(data.inventory) : 0,
        isInventoryRequired: isInventoryNeeded
      }

      const productId = searchParams.get("productId");
      addOrUpdateDebounced.current(productId, obj);
    }
  };

  const onRemoveMerchantImage = (imageId?: string) => {
    const updatedImages = merchantImages.filter(
      (imageObj) => imageObj._id !== imageId
    );
    if (updatedImages?.length === 0) {
      setShowMaxImageError(false);
    }
    setImagesDataCount(updatedImages?.length);
    dispatch(merchantFilesActions.merchantFilesUpdate(updatedImages));
  };

  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });

  useEffect(() => {
    if (addProductStatus == 'success' || updateProductStatus == 'success') {
      sethasModalVisible(true)
      setTimeout(() => {
        dispatch(productsActions.resetProductStatus())
        dispatch(merchantFilesActions.resetSelectedMerchantFile());
        navigate("/product/list")
      }, 1000);
    }
  }, [addProductStatus, updateProductStatus])


  const onDrop = (files: any) => {
    if ((imagesDataCount && imagesDataCount === 1)) {
      setShowMaxImageError(true)
      return;
    }

    if (files.length === 0) {
      setShowMaxImageError(true)
      return;
    } else if (files.length > 0 && files.length <= 1) {
      // let files = e.target.files
      let file = files[0];
      if (!isImageSizeValid(file)) {
        toast.error("Image size must not exceed 5MB");
        return;
      }

      // Validate file type
      if (!isValidImage(file)) {
        toast.error("Only PNG, JPEG, and JPG images are allowed to upload");
        return;
      }

      // If all validations pass, create FormData and append the file
      setShowAddImageError(false);
      setShowMaxImageError(false);
      const formData = new FormData();

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append("files", file);
      }
      setImagesDataCount((prevCount: any) => prevCount + files.length);

      dispatch(merchantFilesActions.merchantFilesUpload(formData));
    } else {
      return;
    }

  }
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ noDragEventsBubbling: true, maxFiles: 1, onDrop })

  const handlePartialFormOnChange = (e: any) => {
    let { name, value } = e.target;
    if (name === "price" && (Number(value) - Math.floor(Number(value))) !== 0) {
      value = Number((Math.floor(Number(value) * 100) / 100).toFixed(2))
    } else if (name === "maxCartQuantity" || name === "inventory") {
      value = value ? Math.trunc(Number(value)) : ""
    }

    if (partialFormData['inventory'] !== "" && name === "maxCartQuantity" && value <= Math.trunc(Number(partialFormData['inventory'])) && isInventoryNeeded) {
      setInventoryMaxError(false);
    }

    if (partialFormData['inventory'] !== "" && name === "maxCartQuantity" && value > Math.trunc(Number(partialFormData['inventory'])) && isInventoryNeeded) {
      setInventoryMaxError(true);
    }

    if (partialFormData['inventory'] !== "" && name === "inventory" && value >= Math.trunc(Number(partialFormData['maxCartQuantity'])) && isInventoryNeeded) {
      setInventoryMaxError(false);
    }

    setPartialFormData({ ...partialFormData, [name]: value })
  }

  const disableNumWheelBehaviour = (e: any) => {
    const target = e.target;
    target.blur();
    setTimeout(() => {
      target.focus();
    }, 0);
  }


  return (
    <>
      <div className="addProductWrapper">
        <section className="commonHeader">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-auto">
                <a className="button button-s3 button-border button-circle button-sm" onClick={() => navigate("/product/list")}>
                  <em className="fas fa-arrow-left"></em>
                </a>
              </div>
              <div className="col-12 col-md">
                <h2>Add product</h2>
              </div>
            </div>
          </div>
        </section>
        <div className="py-5 addProductInner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-xl-6">
                <h4 className="mb-4">Add your product details</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="Product Title"
                          {...register("title",
                            {
                              required: true,
                              validate: {
                                notEmpty: (value) => {
                                  if (value.trim() === "") return "Title cannot be empty";
                                  return;
                                }
                              }
                            }
                          )}
                        />
                        {errors.title?.type === 'required' && <div className="isInvalidMessage text-danger">Please enter product title</div>}
                        {errors.title?.message && <div className="isInvalidMessage text-danger">{errors.title?.message}</div>}
                        <label htmlFor="title">Product Title</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating mb-4">
                        <textarea
                          className="form-control"
                          id="description"
                          placeholder="Description"
                          style={{ height: "120px" }}
                          rows={8}
                          {...register("description", { required: true , validate: {
                            notEmpty: (value) => {
                              if (value.trim() === "") return "Description cannot be empty";
                              return true;
                            }
                          }})}
                        ></textarea>
                        {errors.description?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter product description</div>}
                        {errors.description?.message && <div className="isInvalidMessage text-danger">{errors.description?.message}</div>}
                        <label htmlFor="description">Description</label>
                      </div>
                    </div>
                    <div  {...getRootProps()} onClick={e => e.stopPropagation()}>
                      <h6 className="mb-3 fw700">Product Images</h6>
                      <div className={`uploadImageWrap mb-0 d-block`} onClick={open}>
                        <input {...getInputProps()} />
                        <div className="inner py-5">
                          <h5>Drag your photo here</h5>
                          <p className="mb-2">Only 1 image can be added</p>
                        </div>
                      </div>
                      {showMaxImageError && <div className="isInvalidMessage text-danger">Only 1 image can be added</div>}
                      {showAddImageError && <div className="isInvalidMessage text-danger">Please add product images</div>}
                      <div className="row gutters-7 mt-4">
                        {merchantImages?.map((image: MerchantFiles) => (
                          <div className="col-auto" key={image._id}>
                            <div className="uploadedImage">
                              <span>
                                <img
                                  src={image.path}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <a
                                href={void 0}
                                onClick={() =>
                                  onRemoveMerchantImage(image?._id)
                                }
                              >
                                <em className="fa-solid fa-close"></em>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <h6 className="mb-3 fw700">Pricing</h6>
                      <input
                        type="number"
                        className="form-control form-control-xl"
                        placeholder="$0.00"
                        step="0.01"
                        value={partialFormData.price}
                        onWheel={disableNumWheelBehaviour}
                        {...register("price", { required: true, min: 1, onChange: handlePartialFormOnChange })}
                      />
                      {errors.price?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter product price</div>}
                    </div>
                    <div className="col-12 mb-4">
                      <h6 className="mb-3 fw700">Max Quantity For Cart</h6>
                      <input
                        type="number"
                        className="form-control form-control-xl"
                        value={partialFormData.maxCartQuantity}
                        onWheel={disableNumWheelBehaviour}
                        placeholder="Add quantity"
                        {...register("maxCartQuantity", { required: true, min: 1, onChange: handlePartialFormOnChange })}
                      />
                      {(errors.maxCartQuantity?.type == 'required' || errors.maxCartQuantity?.type == 'min') && <div className="isInvalidMessage text-danger">Please enter min and max allowed cart quantity</div>}
                      {inventoryMaxError && <div className="isInvalidMessage text-danger">Max Quantity cannot be greater than Inventory</div>}
                    </div>
                    <div className="col-12 mb-4">
                      <div className="d-flex align-items-center mb-3">
                        <h6 className="fw700 mb-0">Inventory</h6>
                        <div className="form-check inventory-check-styles">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="inventoryNeeded"
                            checked={isInventoryNeeded}
                            onChange={(e) => setIsInventoryNeeded(e.target.checked)}
                          />
                        </div>
                      </div>
                      {isInventoryNeeded && (
                        <input
                          type="number"
                          value={partialFormData.inventory}
                          className={`form-control form-control-xl ${errors.inventory ? 'is-invalid' : ''}`}
                          onWheel={disableNumWheelBehaviour}
                          placeholder="Add Inventory"
                          {...register("inventory", {
                            required: isInventoryNeeded,
                            min: isInventoryNeeded ? 1 : undefined,
                            onChange: handlePartialFormOnChange
                          })}

                          step="1" // Prevents entering decimal values
                        />
                      )}
                      {errors.inventory?.type === 'required' && isInventoryNeeded && (
                        <div className="isInvalidMessage text-danger">Please enter inventory count</div>
                      )}
                      {errors.inventory?.type === "min" && isInventoryNeeded && (
                        <div className="isInvalidMessage text-danger">Entered value should not be less than 1</div>
                      )}

                    </div>
                    <div className="col-12 pt-4">
                      <button
                        type="submit"
                        className="button button-primary button-rounded button-min-120 fw700"
                        disabled={showAddImageError || inventoryMaxError ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="updateInfoModalWrapper"
        show={hasModalVisible}
        centered={true}
      >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={() => sethasModalVisible(false)}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            <div className="successConfirmationModalWrap text-center">
              <span className="markIcon success">
                <em className="fa-solid fa-circle-check"></em>
              </span>
              <h2 className="mb-2">All Done!</h2>
              <p className="color-gray fw500">Product added successfully.</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProduct;
