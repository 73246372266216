import { put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { userPayoutAccountsActions } from '../reducers/userPayoutAccountSlice';
import httpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';

function* handlefetchUserPayoutAccounts(action: PayloadAction<any>) {
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/payoutAccount`,
  });
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(userPayoutAccountsActions.fetchPayoutAccountsfailed(error.message));
    toast.error(error);
  } else {
    yield put(userPayoutAccountsActions.fetchPayoutAccountsSuccess(result.response));
    toast.success(result.message)
  }
  return { error, result };
}

function* handleAddPayoutAccountInfo(action: PayloadAction<any>) {
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/payoutAccount`,
  });
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(userPayoutAccountsActions.addNewPayoutAccountInfofailed(error.message));
    toast.error(error);
  } else {
    yield put(userPayoutAccountsActions.addNewPayoutAccountInfoSuccess(result.response));
    toast.success(result.message);
  }
  return { error, result };
}

function* handleUpdatePayoutAccountInfo(action: PayloadAction<any>) {
  console.log(action.payload);
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/payoutAccount`,
  });
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(userPayoutAccountsActions.updatePayoutAccountInfofailed(error.message));
    toast.error(error);
  } else {
    yield put(userPayoutAccountsActions.updatePayoutAccountInfoSuccess(result.response));
    toast.success(result.message)
  }
  return { error, result };
}

export function* userPayoutAccountsSaga() {
  yield takeLatest(userPayoutAccountsActions.fetchPayoutAccounts.type, handlefetchUserPayoutAccounts);
  yield takeLatest(userPayoutAccountsActions.addNewPayoutAccountInfo.type, handleAddPayoutAccountInfo);
  yield takeLatest(userPayoutAccountsActions.updatePayoutAccountInfo.type , handleUpdatePayoutAccountInfo);
}
  