import React, { FC, useState, useEffect } from "react";
import "./cancel-booking-modal.scss";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler } from "react-hook-form";

interface CancelBookingModalProps {
  showModal: boolean;
  handleClose: (arg: any) => void;
  cancelBooking: (arg: any) => void;
  bookingCancelStatus: string
}

const CancelBookingModal: FC<CancelBookingModalProps> = ({
  showModal,
  handleClose,
  cancelBooking,
  bookingCancelStatus
}) => {

  type CancelReasonInput = {
    reason: string,
    description: string,
    terms: boolean
  }

  const { register, handleSubmit, formState: { errors }, reset } = useForm<CancelReasonInput>()

  const onSubmit: SubmitHandler<CancelReasonInput> = data => {
    cancelBooking(data)
  }

  const [step, setStep] = useState(1);
  const next = (step: number) => {
    setStep(step);
  };
  const close = () => {
    handleClose(!showModal);
    setTimeout(() => {
      setStep(1);
    }, 300);
  };

  useEffect(() => {
    if (bookingCancelStatus == 'success') {
      console.log("booking cnacel status is ----", bookingCancelStatus)
      if (showModal) {
        handleClose(!showModal);
      }
    }
  }, [bookingCancelStatus])

  return (
    <>
      <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton">
            <em className="fa-solid fa-close" onClick={() => close()}></em>
          </a>
          <div className="formWrap">
            {step == 1 && (
              <div className="text-center">
                <img
                  src="../assets/images/pause.png"
                  alt="pause"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4 pb-3">
                  Are you sure, you want to cancel this booking?
                </h3>
                <button
                  type="button"
                  className="button button-primary button-rounded button-min-120 fontsize-12 fw600"
                  onClick={() => next(2)}
                >
                  Yes, Cancel Booking
                </button>
              </div>
            )}

            {step == 2 && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <h4 className="mb-4">Cancel Booking</h4>
                <div className="mb-4">
                  <div className="form-floating">
                    <select className="form-select" id="reason"  {...register("reason", { required: true })}>
                      <option value="" disabled hidden selected></option>
                      <option value="I made booking by accident">I made the booking by accident</option>
                      <option value="Other">Othere</option>
                    </select>
                    <label htmlFor="reason">Select a Reason</label>
                    {errors.reason?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter description why you want to cancel</div>}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="form-floating">
                    <textarea
                      rows={6}
                      className="form-control"
                      id="description"
                      style={{ height: "auto" }}
                      {...register("description", { required: true })}
                    ></textarea>
                    <label htmlFor="description">Description</label>
                    {errors.description?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter description why you want to cancel</div>}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="row gutters-5">
                    <div className="col-auto">
                      <label className="mb-0 ch-checkbox ch-checkbox-bordered">
                        <input type="checkbox" {...register('terms', { required: true })} />
                        <span>&nbsp;</span>
                      </label>
                    </div>
                    <div className="col">
                      <p className="mb-0 fw500">
                        I agree with <a href={void 0}>Terms & Conditions</a> and{" "}
                        <a href={void 0}>Privacy Policy</a>.
                      </p>
                      {errors.terms?.type == 'required' && <div className="isInvalidMessage text-danger">Please accept terms and conditions</div>}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="button button-danger button-rounded button-min-120 fw600 fontsize-16"
                >
                  Confirm
                </button>
              </form>
            )}

            {step == 3 && (
              <div className="successConfirmationModalWrap text-center">
                <span className="markIcon success">
                  <em className="fa-solid fa-circle-check"></em>
                </span>
                <h2 className="mb-3">Successfully cancelled.</h2>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CancelBookingModal;
