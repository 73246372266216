import React, { FC, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import "./header.scss";
import { authActions, currentUser, getLogoutStatus, getUserExistsinPortal, getUserExistsinPortalStatus, verifyOtpStatus } from "../../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SAGA_STATUS_FAILED, SAGA_STATUS_SUCCESS } from "../../utils/constants";
import RegisterModal from "../../shared/delete-modal/register-portal-modal";

interface HeaderProps {}

const roleToUrlMapper:any = {
  hotel:process.env.REACT_APP_ACCOMODATION_API_URL || '#',
  thirdPartyMerchant: process.env.REACT_APP_TOURISM_API_URL || '#'
}
const roleToPortalMapper:any = {
  hotel: 'Accommodations',
  thirdPartyMerchant: 'Tourism'
}

const Header: FC<HeaderProps> = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch()
  const location = useLocation();
  const userProfile: any = useAppSelector(currentUser);
  const userLogoutStatus: string = useAppSelector(getLogoutStatus);
  const userExists:{exists:boolean, role:string} = useAppSelector(getUserExistsinPortal);
  const userExistsStatus:string = useAppSelector(getUserExistsinPortalStatus);
  const verifyOtpStatusVal: string = useAppSelector(verifyOtpStatus);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [portalLinkAccess, setPortalLinkAccess] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const browserOrigin = window.location.origin;
  const profileDefaultImage = `${browserOrigin}/assets/images/default-user.png`;
  
  const routeChange = (path: any) => {
    navigate(path);
  };
  const getNavLinkClass = (e: any) => {
    return e.some((x: string) => x == location.pathname)
      ? "_actionButton active"
      : "_actionButton";
  };

  const handleToggle = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
  };


  const handleLogout = () => {
    dispatch(authActions.logout());
    localStorage.setItem("token", "");
    dispatch({payload: undefined, type: 'logout'});
    window.Intercom('shutdown');
    navigate("");
  };

  const handlePortalLinks=(portal:string) =>{
    let email = userProfile.email;
    let role = (portal === 'accommodation')?'hotel':'thirdPartyMerchant';
    dispatch(authActions.userPortalExists({email, role}));
    setPortalLinkAccess(true);
    setIsDropdownOpen(false); 
  }

  const handleRegisterLink=() =>{
    const portal = roleToPortalMapper?.[userExists?.role];
    dispatch(authActions.merchantRegister(
      {
        "name":`${userProfile.firstname} ${userProfile.lastname}`.trim(),
        "email":userProfile.email,
        "phoneNumber":userProfile.phone.number,
        "portal":portal?.toLowerCase(),
        "countryCode":userProfile.phone.countryCode,
    } 
    ));
    setShowRegisterModal(false);
    setPortalLinkAccess(false);
  }

  useEffect(()=>{
    /**fix - portalLinkAccess - avoid reloading of popup as redux is persisting state */
    if (SAGA_STATUS_SUCCESS === userExistsStatus && portalLinkAccess) {
      if(userExists.exists){
        window.open(roleToUrlMapper?.[userExists?.role] || '#',"_blank")
      }else{
        setShowRegisterModal(true)
      }
    }
  },[userExistsStatus])

  useEffect(() => {
    // if logout response was sent then restart intercom & navigate to home 
    if ([SAGA_STATUS_FAILED, SAGA_STATUS_SUCCESS].includes(userLogoutStatus)) {
      /** Start new instance of intercom as guest visitor */
      window.Intercom('boot', {
        api_base: `${process.env.REACT_APP_INTERCOM_API_URL}`,
        app_id: `${process.env.REACT_APP_INTERCOM_ID}`
      });
      dispatch(authActions.resetUserLogoutStatus());
      navigate("/"); 
    }
  }, [userLogoutStatus]);

  useEffect(() => {
    if (verifyOtpStatusVal === SAGA_STATUS_SUCCESS) {
      dispatch(authActions.fetchAppConfig());
    }
  }, [])

  return (
    <>
      <header className="headerWrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="headerWrapper__inner">
                <div className="row gutters-5 align-items-center">
                  <div className="col-auto">
                    <NavLink to={"/"} className="brand">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/brand/integolf.svg`}
                        alt="integolf"
                        className="img-fluid"
                      />
                    </NavLink>
                  </div>
                  <div className="col">
                    <nav className="headerWrapper__nav">
                      <ul>
                        <li>
                          <NavLink to={"/dashboard"}>Dashboard</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/calendar"}>Calendar</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/courses"}>Courses</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/bookings/upcoming"} className={getNavLinkClass([
                                "/bookings/upcoming",
                                "/bookings/all",
                                "/bookings/cancelled",
                                "/bookings/completed",
                              ])}>Bookings</NavLink>
                        </li>
                        <li>
                          <Dropdown className="menuDropdown">
                            <Dropdown.Toggle
                              className={getNavLinkClass([
                                "/transaction/payout",
                                "/transaction/history",
                              ])}
                            >
                              Payment History
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="fw600"
                                href="#"
                                onClick={() =>
                                  routeChange("/transaction/payout")
                                }
                              >
                                Payouts
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="fw600"
                                href="#"
                                onClick={() =>
                                  routeChange("/transaction/history")
                                }
                              >
                                Transactions
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                        <li>
                          <Dropdown className="menuDropdown">
                            <Dropdown.Toggle className="_actionButton">
                              More
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="fw600"
                                href="#"
                                onClick={() => routeChange("/product/list")}
                              >
                                My Products
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="fw600"
                                href="#"
                                onClick={() => routeChange("/marketing-material")}
                              >
                                Marketing Material
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="fw600"
                                href="#"
                                onClick={() => routeChange("/discount-requests")}
                              >
                                Discount Requests
                              </Dropdown.Item>
                              {/* <Dropdown.Item className="fw600" href="#">
                                Contact Us
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="col-auto d-lg-none">
                    <a href={void 0} className="headerWrapper__hamberger">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </a>
                  </div>
                  <div className="col-auto">
                    <div className="headerWrapper__actions">
                      {/* <a href="#!;" className="actionButton">
                        <em className="icon-search"></em>
                      </a>
                      <a href="#!;" className="actionButton">
                        <em className="icon-bell"></em>
                      </a> */}
                      <div className="_accountBlock">
                        <Dropdown align="end" show={isDropdownOpen} onToggle={handleToggle}>
                          <Dropdown.Toggle className="toggleButton">
                            <span className="_imgBlock">
                              <img
                                src={(userProfile?.profileImage?.path || profileDefaultImage)}
                                alt="default"
                                className="img-fluid"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                          <div className="dropdownUser">
                            <span className="imgBlock mb-2">
                              <img
                                src={userProfile?.profileImage?.path || profileDefaultImage}
                                alt="default"
                                className="img-fluid"
                              />
                            </span>
                            <div>
                              {userProfile.fullName && <h6 className="mb-0 fw700">{ userProfile.fullName}</h6>}
                              {userProfile?.phone?.number && <h6 className="mb-0 fw700">{`+${userProfile?.phone?.countryCode} ${userProfile?.phone?.number}`}</h6>}
                              <label className="mb-0 fw500 color-gray fontsize-12">
                                {userProfile?.email}
                              </label>
                            </div>
                          </div>
                          <NavLink className="dropdown-item active" to={'/'}>
                            <img className="_iconnew"
                                src={`${browserOrigin}/assets/images/icons/golf.png`}
                                alt="course"
                              />
                              <span className="_content">Course Panel</span>
                              <span className="_activeText" >
                              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="6" cy="6" r="5" fill="currentColor"></circle>
                              <path d="M4.25 6.25L5.25 7.25L7.75 4.75" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>Active</span>
                            </NavLink>
                            <a className="dropdown-item" onClick={()=>handlePortalLinks('thirdPartyMerchant')}>
                            <img
                                className="_iconnew"
                                src={`${browserOrigin}/assets/images/icons/tourism.png`}
                                alt="tourism"
                              />
                              <span className="_content">Tourism Panel</span>
                              
                            </a>
                            <a className="dropdown-item" onClick={()=>handlePortalLinks('accommodation')}>
                              <img
                                  className="_iconnew"
                                  src={`${browserOrigin}/assets/images/icons/hotel.png`}
                                  alt="hotel"
                                />
                                <span className="_content">Accomodation Panel</span>
                              </a>
                            <NavLink
                              to={`/account/profile`}
                              className="dropdown-item"
                              onClick={()=>setIsDropdownOpen(false)}
                            >
                              <img className="_iconnew" src={profileDefaultImage}/>My Accounts
                            </NavLink>
                            {/* <NavLink
                              to={`/account/integration`}
                              className="dropdown-item"
                            >
                              Integration
                            </NavLink> */}
                            <a href={void 0} onClick={()=> handleLogout()} className="dropdown-item _logout">
                              <em className="icon-logout me-2 p-t3"></em> Sign
                              Out
                            </a>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <RegisterModal 
        showModal={showRegisterModal} 
        portal={roleToPortalMapper?.[userExists?.role] || ''}  
        onCloseClick={()=>setShowRegisterModal(false)} 
        onLinkClick={handleRegisterLink} 
      />
    </>
  );
};

export default Header;
