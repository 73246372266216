import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";
import { store } from "../../app/store";
import { Navigate } from "react-router";

function* httpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };

  const state = store.getState();
  let token = state?.auth?.currentUser?.accessToken;

  if (token) {
    payloadData.headers['accesstoken'] = token
  }
  try {
    const { data: result, error } = yield call(axiosInstance, payloadData);
    return {
      error,
      result,
    };
  } catch (error: any) {
  
    let myError = error?.response?.status ? error.response.data : error;

    if (myError?.status || error?.response?.status===401) {
      if (myError.status === 401 || error?.response?.status===401) {
        toast.error("Session Expired. Please login again.");
        window.location.href = "/";
      } else {
        toast.error(myError.message);
      }
    } else {
      toast.error(myError.message);
    }
  }

  return {
    error: true,
    result: null,
  };
}

export default httpClient;
