import React, { FC, useEffect, useState } from "react";
import "./booking-detail.scss";
import CancelBookingModal from "../../../../shared/cancel-booking-modal/cancel-booking-modal";
import { useAppSelector } from "../../../../app/hooks";
import { useAppDispatch } from "../../../../app/hooks";
import {
  bookingActions,
  fetchBookingDetail,
  fetchBookingCancelStatus,
} from "../../bookingSlice";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { capitalizeString, mapTimeZone } from "../../../../utils/common";
import { useNavigate } from "react-router-dom";
import { capitalizeFullString } from "../../../../utils/common";

const BookingDetail = () => {
  const [showCancelBookModal, setshowCancelBookModal] = useState(false);
  const handleCloseModal = (e: any) => {
    setshowCancelBookModal(false);
  };
  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const booking: any = useAppSelector(fetchBookingDetail);
  const bookingCancelStatus: string = useAppSelector(fetchBookingCancelStatus);
  const { id }: any = useParams();

  useEffect(() => {
    if (id) {
      dispatch(bookingActions.bookingDetail(id))
      // dispatch(bookingActions.bookingDetail(id));
    }
  }, []);

  const cancelBooking = (data: any) => {
    let apiData = {
      reason: data.reason,
      description: data.description,
    };
    dispatch(bookingActions.cancelBooking({ bookingId: id, data: apiData }));
  };

  const getUserName = (user: any) => {
    let fullName = "";
    if (user.firstname && user.lastname) {
      fullName = capitalizeFullString(`${user.firstname} ${user.lastname}`)
    } else if(user.name && typeof user.name==='string' ){
      fullName = capitalizeFullString(`${user.name}`)
    }
    else {
      if (user.role == "user") {
        fullName = "Integolf User"
      } else {
        fullName = "Guest User"
      }
    }

    return fullName
  }

  const getClubAddress = (club: any) => {
    let name = [club.addressOne, club.addressTwo, club.city, club.countryName].filter(Boolean).join(", ")
    return capitalizeFullString(name)
  }

  const getTotalTeesheetPrice = (teesheets: any) =>{
    let totalPrice = teesheets.reduce((sum:any, currentItem:any) => {
      let price = (currentItem.price * currentItem.players);
      return sum + price;
    }, 0);

    return totalPrice.toFixed(2)
  }

  return (
    <>
    {(booking['_id'])? 
    <div className="bookingDetailWrapper">
    <section className="commonHeader">
      <div className="container">
        <div className="row gutters-10 align-items-center">
          <div className="col-auto">
            <a
              className="button button-s3 button-border button-circle button-sm"
              onClick={() => navigate(-1)}
            >
              <em className="fas fa-arrow-left"></em>
            </a>
          </div>
          <div className="col-12 col-lg">
            <ul className="navBreadCrumb">
              {/* <li>
                <a href="#!;">Upcomming</a>
              </li> */}
              <li>
                <span>Booking Details</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="bookingDetailWrap py-4">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            {booking?.user && <>
              <h1 className="mb-3">
                {getUserName(booking?.user)}
              </h1>
              <p className="color-700">
                {booking?.user?.email ? booking?.user?.email : "-"}
                {booking?.userd?.phone && (
                  <span className="color-400">
                    {" "}
                    &nbsp;|&nbsp;
                    {`+ ${booking?.user?.phone?.countryCode}${booking?.user?.phone?.number}`}
                  </span>
                )}
              </p>
            </>}

            {/* {booking?.user && <>
              <h1 className="mb-3">
              {getUserName(booking?.user)}
              </h1>
              <p className="color-700">
                {booking?.user?.email ? booking?.user?.email : "-"}
                {booking?.user?.phone && (
                  <span className="color-400">
                    {" "}
                    &nbsp;|&nbsp;
                    {`+ ${booking?.user?.phone}`}
                  </span>
                )}
              </p>
            </>} */}


            <hr />
            <div className="row">
              <div className="col">
                <h2 className="mb-3">
                  {capitalizeFullString(booking?.teesheets[0]?.club?.name)}
                </h2>
                <p className="color-700">
                  <em className="icon-location"></em>
                  {booking && booking.teesheets?.length > 0 && getClubAddress(booking.teesheets[0].club)}
                </p>
              </div>
              <div className="col-auto">
                <a
                  href={void 0}
                  onClick={() => {
                    navigate("/courses");
                  }}
                  className="color-active fw700 text-decoration-underline"
                >
                  View Course
                </a>
              </div>
            </div>
            <div className="row gutters-7 mt-2">
              <div className="col-12 col-sm _item">
                <label className="color-700 mb-2">Tee Time</label>
                {/* {booking.teesheets && booking.teesheets.length == 1 && <h5 className="color-800">
                  {booking?.teesheets && booking.teesheets.length > 0 && booking?.teesheets[0].teesheetId
                    ? DateTime.fromMillis(
                      booking?.teesheets[0]?.dateTime
                    ).toFormat("h:mm a, EEE dd MMM yyyy")
                    : "n/a"}
                </h5>} */}

                {booking.teesheets && booking.teesheets.length > 0 &&
                  booking.teesheets.map((teesheet: any, index: number) => (
                    <h5 className="color-800">
                      {DateTime.fromISO(teesheet?.teesheetObj?.dateTimeISO, {zone:mapTimeZone(teesheet?.club)}).toFormat("h:mm a, EEE dd MMM yyyy")}
                    </h5>))}
                {/* {booking.teesheets && booking.teesheets?.length > 1 && <h5 className="color-800">
                  {booking?.teesheets[0]?.teesheetId
                    ? DateTime.fromMillis(
                      booking?.teesheets[booking.teesheets.length - 1]?.dateTime
                    ).toFormat("dd MMM yyyy")
                    : "n/a"}
                  {", "}
                  {booking?.teesheets[0]?.teesheetId
                    ? DateTime.fromMillis(
                      booking?.teesheets[0]?.dateTime
                    ).toFormat("h:mm a")
                    : "n/a"} {"- "}
                  {booking?.teesheets[0]?.teesheetId
                    ? DateTime.fromMillis(
                      booking?.teesheets[booking.teesheets.length - 1]?.dateTime
                    ).toFormat("h:mm a")
                    : "n/a"}
                </h5>
                } */}
              </div>
              <div className="col-12 col-sm _item">
                <label className="color-700 mb-2">Number of Golfers</label>
                <h5 className="color-800">
                  {booking?.noOfGuest ? booking?.noOfGuest : booking?.teesheets[0]?.players} players
                  {/* , {booking?.holes} Holes */}
                </h5>
              </div>
              <div className="col-12 col-sm _item">
                <label className="color-700 mb-2">Number of Holes</label>
                <h5 className="color-800">
                {booking?.teesheets[0]?.teesheetObj?.holes} 
                  {/* , {booking?.holes} Holes */}
                </h5>
              </div>
            </div>
            {booking?.orders && booking.orders.products?.length > 0 &&
              <div className="row gutters-5 mt-3">
                {booking?.orders && booking.orders.products?.length > 0 &&
                  booking?.orders?.products.map((order: any, index: number) => (
                    <div className="col-auto">
                      {order?.quantity && <label className="badge mb-2">{order?.title}: {order?.quantity}</label>}
                    </div>))}
              </div>
            }

            <hr />
            <div className="row gutters-10 actionBlock">
              {/* <div className="col-auto">
                <button
                  type="button"
                  className="button button-primary button-rounded fw700"
                >
                  Scan Barcode
                </button>
              </div> */}
              {/* {booking?.status?.value == 'success' && <div className="col-auto">
                <button
                  type="button"
                  className="button button-danger button-rounded fw700 buttonCancel"
                  onClick={() => setshowCancelBookModal(true)}
                >
                  Cancel Booking
                </button>
              </div>} */}
              {(booking?.status?.value == "cancelled") && (
                <label className="_label active fontsize-14 fw700 my-2">
                  Booking Cancelled
                </label>
              )}
              {booking?.status?.value == "courseConfirmationPending" && (
                <label className="_label active fontsize-14 fw700 my-2">
                  Course Confirmation Pending
                </label>
              )}
              {booking?.status?.value == "failed" && (
                <label className="_label active fontsize-14 fw700 my-2">
                  Payment Failed
                </label>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="row gutters-5 mb-4 mt-4">
              <div className="col-12 col-sm">
                <p className="fontsize-12 fw600 color-600">
                  Booked on:{" "}
                  <span className="color-800">
                    {booking?.createdAt
                      ? DateTime.fromMillis(booking?.createdAt, {zone:mapTimeZone(booking?.teesheets?.[0]?.club)}).toFormat(
                        "ccc, LLL dd, yyyy h:mm a"
                      )
                      : "n/a"}
                  </span>
                </p>
              </div>
              <div className="col-12 col-sm-auto">
                <p className="fontsize-12 fw600 color-600">
                  Booking code: {booking?.bookingId}
                </p>
              </div>
            </div>

            <div className="_paySection mb-2">
              <h3 className="_paySection-title mb-3">Charges</h3>

              <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-0">
                    Golf Total
                    {/* {booking?.noOfGuest ? booking?.noOfGuest : booking?.teesheets[0]?.players} Golfers x ${booking?.price} */}
                  </label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">
                    $ {getTotalTeesheetPrice(booking?.teesheets)}
                    {/* $ {(booking?.price * booking?.players).toFixed(2)} */}
                  </h6>
                </div>
              </div>

              {booking?.orders && booking.orders.products?.length > 0 &&
                booking?.orders?.products?.map((order: any, index: number) => (
                  <>
                    {order?.price &&
                      <div className="row align-items-center gutters-5 _priceRow">
                        <div className="col">
                          <div className="row gutters-5 justify-content-between">
                            <div className="col">
                              <label className="fw500 mb-0"> {order?.title}</label>
                            </div>
                            <div className="col-auto">
                              <label className="fw500 mb-0">$ {order?.price} X {order?.quantity}</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <h6 className="mb-0 fw700">$ {((order?.price) * (order?.quantity)).toFixed(2)}</h6>
                        </div>
                      </div>
                    }
                  </>

                ))}



              {booking?.teesheets[0]?.discountPercent > 0 && (
                <div className="row align-items-center gutters-10 _priceRow">
                  <div className="col">
                    <label className="fw500 mb-0">
                      Discount ({booking?.teesheets[0]?.discountPercent}%)
                    </label>
                  </div>
                  <div className="col-auto">
                    <h6 className="mb-0 fw700">
                      -${" "}
                      {booking?.teesheets[0]?.discountAmount
                        ? (booking?.teesheets[0]?.discountAmount).toFixed(2)
                        : ""}
                    </h6>
                  </div>
                </div>
              )}

              <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-0">Booking Fee (5%)</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">
                    ${" "}
                    {booking?.bookingFees
                      ? booking?.bookingFees.toFixed(2)
                      : ""}
                  </h6>
                </div>
              </div>

              {<div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-0">Tax</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">$ {booking?.taxAmt}</h6>
                </div>
              </div> }

              {booking?.credits > 0 && <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-0">Credits ( {booking?.credits ?? 0} x ${(Number(booking?.creditAmt ?? 0) / Number(booking?.credits ?? 0)).toFixed(2)} )</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">-$ {booking?.creditAmt?.toFixed(2)}</h6>
                </div>
              </div> }
              {booking?.isLuckyBooking && <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-0">Lucky booking Promo</label>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">-$  {(booking?.totalPrice).toFixed(2)}</h6>
                </div>
              </div> }
              

              <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <h5 className="fw700 mb-0">Total</h5>
                </div>
                <div className="col-auto">
                  <h5 className="mb-0 fw700">
                    ${" "}
                    {booking?.totalPrice
                      ? booking?.isLuckyBooking ? "0.00":(booking?.totalPrice).toFixed(2)
                      : ""}
                  </h5>
                </div>
              </div>
            </div>
            {!booking?.isLuckyBooking && <div className="_paySection mb-2">
              <h3 className="_paySection-title mb-3">Payment</h3>

              <div className="row gutters-10 _priceRow">
                <div className="col">
                  <label className="fw500 mb-2">
                    {booking.paymentInfo?._id ? `Paid with **** **** ${booking?.paymentInfo?.last4}` : "Payment Failed"}
                  </label>
                  <p className="fontsize-12 color-600 fw500 mb-0">
                    {booking?.paymentInfo?.createdAt
                      ? DateTime.fromMillis(booking?.paymentInfo?.createdAt, {zone:mapTimeZone(booking?.teesheets?.[0]?.club)}).toFormat(
                        "ccc, LLL dd, yyyy h:mm a"
                      )
                      : "n/a"}
                  </p>
                </div>
                <div className="col-auto">
                  <h6 className="mb-0 fw700">
                    ${" "}
                    {booking?.totalPrice
                      ? (booking?.totalPrice).toFixed(2)
                      : ""}
                  </h6>
                </div>
              </div>

              <div className="row align-items-center gutters-10 _priceRow">
                <div className="col">
                  <h5 className="fw700 mb-0">Total Paid</h5>
                </div>
                <div className="col-auto">
                  <h5 className="mb-0 fw700">
                  ${" "}
                    {
                      booking?.paymentInfo?.last4
                        ? `${booking?.totalPrice
                          ? (booking?.totalPrice).toFixed(2)
                          : ""
                        }`
                        : "0.00"}
                  </h5>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>:<></>}
      
      <CancelBookingModal
        showModal={showCancelBookModal}
        handleClose={handleCloseModal}
        cancelBooking={cancelBooking}
        bookingCancelStatus={bookingCancelStatus}
      ></CancelBookingModal>
    </>
  );
};

export default BookingDetail;
