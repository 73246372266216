import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { DiscountRequestsEditActions } from '../reducers/discountEditSlice';
import httpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';

function* handleListDiscountRequestList(action: PayloadAction<any>) {
  const { headers, params } = action.payload
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/discountrequest/${params}`,
    headers: action.payload.headers
  });

  if (error) {
    yield put(DiscountRequestsEditActions.discountRequestListFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(DiscountRequestsEditActions.discountRequestListSuccess(result.response))
  }
  return { error, result };
}

function* handleProcessDiscountRequest(action: PayloadAction<any>) {

  const { headers, data } = action.payload
  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error } = yield call(httpClient, {
    data: data,
    method: "put",
    url: `/updateDiscountRequest`,
    headers
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.processDiscountRequestFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(DiscountRequestsEditActions.processDiscountRequestSuccess(result.response))
  }
  return { error, result };
}
function* processDiscountRequestExpiry(action: PayloadAction<any>) {
  const { headers, params } = action.payload
  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/discountrequest/verify/${params}`,
    headers: action.payload.headers
  });

  if (error) {
    yield put(DiscountRequestsEditActions.expiryDiscountRequestFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(DiscountRequestsEditActions.expiryDiscountRequestSuccess(result.response))
  }
  return { error, result };
}

function* fetchBadWeatherDiscount(action: PayloadAction<any>) {
  const { clubId } = action.payload;
  let url = `/badWeatherDiscount/${clubId}`;
  const { result, error } = yield call(httpClient, {
    method: "get",
    url,
  });
  if (error) {
    yield put(DiscountRequestsEditActions.getBadWeatherRequestFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(DiscountRequestsEditActions.getBadWeatherRequestSuccess(result.response))
  }
  return { error, result };
}

function* fetchCourseWeatherData(action: PayloadAction<any>) {
  const { courseId } = action.payload;
  let url = `/fetchCourseWeatherData/${courseId}`;
  const { result, error } = yield call(httpClient, {
    method: "get",
    url,
  });
  if (error) {
    yield put(DiscountRequestsEditActions.fetchCourseWeatherDataFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(DiscountRequestsEditActions.fetchCourseWeatherDataSuccess(result.response))
  }
  return { error, result };
}


function* addBadWeatherDiscount(action: PayloadAction<any>) {
  const data = action.payload
  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error, axiosError } = yield call(httpClient, {
    data: data,
    method: "post",
    url: `/addBadWeatherDiscount`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.addBadWeatherDiscountFailed(error.message)); // Dispatch action
    toast.error(error?.message ?? axiosError?.response?.data?.response ?? '');
  } else {
    toast.success("Discount added successfully")
    yield put(DiscountRequestsEditActions.addBadWeatherDiscountSuccess(result.response))
  }
  return { error, result };
}

function* editBadWeatherDiscount(action: PayloadAction<any>) {
  const data = action.payload
  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error, axiosError } = yield call(httpClient, {
    data: data,
    method: "put",
    url: `/editBadWeatherDiscount`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.editBadWeatherDiscountFailed(error.message)); // Dispatch action
    toast.error(error?.message ?? axiosError?.response?.data?.response ?? '');
  } else {
    toast.success("Discount updated successfully")
    yield put(DiscountRequestsEditActions.editBadWeatherDiscountSuccess(result.response))
  }
  return { error, result };
}

function* deleteBadWeatherDiscount(action: PayloadAction<any>) {
  const data = action.payload
  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error, axiosError } = yield call(httpClient, {
    method: "put",
    url: `/deleteBadWeatherDiscount/${data?.discountId}`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.deleteBadWeatherDiscountFailed(error.message)); // Dispatch action
    toast.error(error?.message ?? axiosError?.response?.data?.response ?? '');
  } else {
    toast.success("Discount deleted successfully")
    yield put(DiscountRequestsEditActions.deleteBadWeatherDiscountSuccess(result.response))
  }
  return { error, result };
}



function* processBadWeatherDiscountReq(action: PayloadAction<any>) {
  const { id, status } = action.payload
  const { result, error, axiosError } = yield call(httpClient, {
    method: "put",
    data: {
      status
    },
    url: `/badWeatherDiscount/${id}`,
  });
  if (error) {
    yield put(DiscountRequestsEditActions.processBadWeatherDiscountRequestFailed(error.message)); // Dispatch action
    toast.error(error?.message ?? axiosError?.response?.data?.response ?? '');
  } else {
    toast.success(`Discount ${status} successfully`)
    yield put(DiscountRequestsEditActions.processBadWeatherDiscountRequestSuccess(result.response))
  }
  return { error, result };
}

function* handleAddInstantDiscount(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/addInstantDiscount`
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.addInstantDiscountFailed(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(DiscountRequestsEditActions.addInstantDiscountSuccess(result.response))
    toast.success("Discount added successfully")
  }
  return { error, result };
}


function* handleListOtherDiscounts(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/fetchOtherDiscounts?courseId=${action.payload}`
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.fetchOtherDiscountsFailed(error.message)); // Dispatch action
  } else {
    yield put(DiscountRequestsEditActions.fetchOtherDiscountsSuccess(result.response))

  }
  return { error, result };
}

function* handleUpdateInstantDiscount(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/updateInstantDiscount`
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.updateInstantDiscountFailed(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(DiscountRequestsEditActions.updateInstantDiscountSuccess(result.response))
    toast.success("Discount updated successfully")
  }
  return { error, result };
}

function* handleDeleteInstantDiscount(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "delete",
    url: `/removeInstantDiscount`
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(DiscountRequestsEditActions.deleteInstantDiscountFailed(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(DiscountRequestsEditActions.deleteInstantDiscountSuccess(result.response))
    toast.success("Discount deleted successfully")
  }
  return { error, result };
}

function* processInstantDiscountReq(action: PayloadAction<any>) {
  yield put(accountStorageActions.showHideSpinner(true));
  const { id, status } = action.payload
  const { result, error, axiosError } = yield call(httpClient, {
    method: "put",
    data: {
      status
    },
    url: `/updateInstantDiscountRequest/${id}`,
  });
  yield put(accountStorageActions.showHideSpinner(false));

  if (error) {
    yield put(DiscountRequestsEditActions.processInstantDiscountRequestFailed(error.message)); // Dispatch action
    toast.error(error?.message ?? axiosError?.response?.data?.response ?? '');
  } else {
    toast.success("Discount status updated successfully")
    yield put(DiscountRequestsEditActions.processInstantDiscountRequestSuccess(result.response))
  }
  return { error, result };
}

function* fetchInstantDiscount(action: PayloadAction<any>) {
 let clubId=action.payload.clubId
  let url = `/fetchInstantDiscounts/${clubId}`;
  const { result, error } = yield call(httpClient, {
    method: "get",
    url,
  });
  if (error) {
    yield put(DiscountRequestsEditActions.getInstantDiscountRequestFailed(error.message)); // Dispatch action
  } else {
    yield put(DiscountRequestsEditActions.getInstantDiscountRequestSuccess(result.response))
  }
  return { error, result };
}


export function* discountRequestEditSaga() {
  yield takeLatest(DiscountRequestsEditActions.discountRequestsList.type, handleListDiscountRequestList);
  yield takeLatest(DiscountRequestsEditActions.processDiscountRequest.type, handleProcessDiscountRequest);
  yield takeLatest(DiscountRequestsEditActions.expiryDiscountRequest.type, processDiscountRequestExpiry);

  yield takeLatest(DiscountRequestsEditActions.getBadWeatherRequest.type, fetchBadWeatherDiscount);
  yield takeLatest(DiscountRequestsEditActions.processBadWeatherDiscountRequest.type, processBadWeatherDiscountReq);
  yield takeLatest(DiscountRequestsEditActions.addInstantDiscount.type, handleAddInstantDiscount);
  yield takeLatest(DiscountRequestsEditActions.fetchOtherDiscounts.type, handleListOtherDiscounts);
  yield takeLatest(DiscountRequestsEditActions.updateInstantDiscount.type, handleUpdateInstantDiscount);
  yield takeLatest(DiscountRequestsEditActions.deleteInstantDiscount.type, handleDeleteInstantDiscount);

  yield takeLatest(DiscountRequestsEditActions.fetchCourseWeatherData.type, fetchCourseWeatherData);
  yield takeLatest(DiscountRequestsEditActions.addBadWeatherDiscount.type, addBadWeatherDiscount);
  yield takeLatest(DiscountRequestsEditActions.deleteBadWeatherDiscount.type, deleteBadWeatherDiscount);
  yield takeLatest(DiscountRequestsEditActions.editBadWeatherDiscount.type, editBadWeatherDiscount);
  yield takeLatest(DiscountRequestsEditActions.getInstantDiscountRequest.type, fetchInstantDiscount);
  yield takeLatest(DiscountRequestsEditActions.processInstantDiscountRequest.type, processInstantDiscountReq);
}
