import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { DiscountRequestEditPayload } from "../models/discountEditRequest";
import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED, INSTANT_DISCOUNT , BAD_WEATHER_DISCOUNT} from "../utils/constants";

export interface discountRequestsEditState {
  fetchDiscountRequests: string;
  processDiscountRequestStatus: string;
  discountRequests: Array<DiscountRequestEditPayload>;
  expiryDiscountRequestsStatus: any,
  processInstantDiscountRequestStatus:any,
  badWeatherRequests: Array<any>;
  instantDiscountsRequests:Array<any>,
  fetchInstantDiscountStatus:any,
  fetchBadWeatherDiscountStatus: string,
  processBadWeatherDiscountRequestStatus: string
  addInstantDiscountStatus: string,
  instantDiscounts: Array<any>,
  badWeatherDiscounts: Array<any>,
  fetchOtherDiscountsStatus: string,
  updateInstantDiscountStatus: string,
  deleteInstantDiscountStatus: string,
  courseWeatherData:Array<any>,
  fetchCourseWeatherDataStatus:string,
  addBadWeatherDiscountStatus:string,
  deleteBadWeatherDiscountStatus:string,
  editBadWeatherDiscountStatus:string,

}

const initialState: discountRequestsEditState = {
  fetchDiscountRequests: SAGA_STATUS_INIT,
  processDiscountRequestStatus: SAGA_STATUS_INIT,
  expiryDiscountRequestsStatus: true,
  discountRequests: [],
  instantDiscountsRequests:[],
  badWeatherRequests: [],
  processInstantDiscountRequestStatus:SAGA_STATUS_INIT,
  fetchInstantDiscountStatus:SAGA_STATUS_INIT,
  fetchBadWeatherDiscountStatus: SAGA_STATUS_INIT,
  processBadWeatherDiscountRequestStatus: SAGA_STATUS_INIT,

  addInstantDiscountStatus: SAGA_STATUS_INIT,
  instantDiscounts: [],
  badWeatherDiscounts: [],
  fetchOtherDiscountsStatus: SAGA_STATUS_INIT,
  updateInstantDiscountStatus: SAGA_STATUS_INIT,
  deleteInstantDiscountStatus: SAGA_STATUS_INIT,

  courseWeatherData:[],
  fetchCourseWeatherDataStatus: SAGA_STATUS_INIT,
  addBadWeatherDiscountStatus:SAGA_STATUS_INIT,
  deleteBadWeatherDiscountStatus: SAGA_STATUS_INIT,
  editBadWeatherDiscountStatus:SAGA_STATUS_INIT

};

const discountRequestsEditSlice = createSlice({
  name: 'discountEditRequests',
  initialState,
  reducers: {
    discountRequestsList(state, action: PayloadAction<any>) {
      state.fetchDiscountRequests = SAGA_STATUS_PENDING;
    },
    discountRequestListSuccess(state, action: PayloadAction<any>) {
      state.discountRequests = action?.payload?.result;
      state.fetchDiscountRequests = SAGA_STATUS_SUCCESS;
    },
    discountRequestListFailed(state, action: PayloadAction<string>) {
      state.fetchDiscountRequests = SAGA_STATUS_FAILED;
    },
    processDiscountRequest(state, action: PayloadAction<any>) {
      state.processDiscountRequestStatus = SAGA_STATUS_PENDING;
    },
    processDiscountRequestSuccess(state, action: PayloadAction<any>) {
      state.discountRequests = action?.payload?.result;
      state.processDiscountRequestStatus = SAGA_STATUS_SUCCESS;
    },
    processDiscountRequestFailed(state, action: PayloadAction<string>) {
      state.processDiscountRequestStatus = SAGA_STATUS_FAILED;
    },
    resetProcessDiscountRequestStatus(state) {
      state.processDiscountRequestStatus = SAGA_STATUS_INIT;
    },
    resetdiscountRequests(state) {
      state.fetchDiscountRequests = SAGA_STATUS_INIT;
      state.processDiscountRequestStatus = SAGA_STATUS_INIT;
      state.expiryDiscountRequestsStatus = false
      state.discountRequests = [];
    },
    expiryDiscountRequest(state, action: PayloadAction<any>) {
      state.expiryDiscountRequestsStatus = SAGA_STATUS_PENDING;
    },
    expiryDiscountRequestSuccess(state, action: PayloadAction<any>) {
      state.expiryDiscountRequestsStatus = action?.payload?.expiry;
    },
    expiryDiscountRequestFailed(state, action: PayloadAction<any>) {
      state.expiryDiscountRequestsStatus = action?.payload?.expiry;
    },


    getBadWeatherRequest(state, action: PayloadAction<any>) {
      state.fetchBadWeatherDiscountStatus = SAGA_STATUS_PENDING;
    },
    getBadWeatherRequestSuccess(state, action: PayloadAction<any>) {
      state.badWeatherRequests = action?.payload;
      state.fetchBadWeatherDiscountStatus = SAGA_STATUS_SUCCESS;
    },
    getBadWeatherRequestFailed(state, action: PayloadAction<string>) {
      state.badWeatherRequests = [];
      state.fetchBadWeatherDiscountStatus = SAGA_STATUS_FAILED;
    },
    processBadWeatherDiscountRequest(state, action: PayloadAction<any>) {
      state.processBadWeatherDiscountRequestStatus = SAGA_STATUS_PENDING;
    },
    processBadWeatherDiscountRequestSuccess(state, action: PayloadAction<any>) {
      state.processBadWeatherDiscountRequestStatus = SAGA_STATUS_SUCCESS;
    },
    processBadWeatherDiscountRequestFailed(state, action: PayloadAction<string>) {
      state.processBadWeatherDiscountRequestStatus = SAGA_STATUS_FAILED;
    },
    addInstantDiscount(state, action: PayloadAction<any>) {
      state.addInstantDiscountStatus = SAGA_STATUS_PENDING
    },
    addInstantDiscountSuccess(state, action: PayloadAction<any>) {
      state.addInstantDiscountStatus = SAGA_STATUS_SUCCESS
    },
    addInstantDiscountFailed(state, action: PayloadAction<string>) {
      state.addInstantDiscountStatus = SAGA_STATUS_FAILED
    },
    fetchOtherDiscounts(state, action: PayloadAction<any>) {
      state.fetchOtherDiscountsStatus = SAGA_STATUS_PENDING
    },
    fetchOtherDiscountsSuccess(state, action: PayloadAction<any>) {

      if (action?.payload?.result?.length > 0) {
        let instantDiscounts = action?.payload?.result.filter((result: any) => result?.discountProfileId?.name == INSTANT_DISCOUNT && result?.discountStatus!=="rejected");
        let badWeatherDiscounts = action?.payload?.result.filter((result: any) => (result?.discountProfileId?.name == BAD_WEATHER_DISCOUNT && result?.discountStatus!=="rejected"));
        state.instantDiscounts = instantDiscounts;
        state.badWeatherDiscounts = badWeatherDiscounts;
      }else{
        state.instantDiscounts = [];
        state.badWeatherDiscounts = [];
      }
      state.fetchOtherDiscountsStatus = SAGA_STATUS_SUCCESS
    },
    fetchOtherDiscountsFailed(state, action: PayloadAction<string>) {
      state.fetchOtherDiscountsStatus = SAGA_STATUS_FAILED
    },
    resetInstantDiscountStatus(state) {
      state.addInstantDiscountStatus = SAGA_STATUS_INIT
      state.updateInstantDiscountStatus = SAGA_STATUS_INIT
      state.deleteInstantDiscountStatus = SAGA_STATUS_INIT
    },
    updateInstantDiscount(state, action: PayloadAction<any>) {
      state.updateInstantDiscountStatus = SAGA_STATUS_PENDING
    },
    updateInstantDiscountSuccess(state, action: PayloadAction<any>) {
      state.updateInstantDiscountStatus = SAGA_STATUS_SUCCESS
    },
    updateInstantDiscountFailed(state, action: PayloadAction<string>) {
      state.updateInstantDiscountStatus = SAGA_STATUS_FAILED
    },
    deleteInstantDiscount(state, action: PayloadAction<any>) {
      state.deleteInstantDiscountStatus = SAGA_STATUS_PENDING
    },
    deleteInstantDiscountSuccess(state, action: PayloadAction<any>) {
      state.deleteInstantDiscountStatus = SAGA_STATUS_SUCCESS
    },
    deleteInstantDiscountFailed(state, action: PayloadAction<string>) {
      state.deleteInstantDiscountStatus = SAGA_STATUS_FAILED
    },

    fetchCourseWeatherData(state, action: PayloadAction<any>){
      state.fetchCourseWeatherDataStatus = SAGA_STATUS_PENDING
    },
    fetchCourseWeatherDataSuccess(state, action: PayloadAction<any>){
      state.courseWeatherData = action?.payload ?? [];
      state.fetchCourseWeatherDataStatus = SAGA_STATUS_SUCCESS;
    },
    fetchCourseWeatherDataFailed(state, action: PayloadAction<any>){
      state.courseWeatherData=[];
      state.fetchCourseWeatherDataStatus = SAGA_STATUS_FAILED;
    },
    addBadWeatherDiscount(state, action: PayloadAction<any>) {
      state.addBadWeatherDiscountStatus = SAGA_STATUS_PENDING
    },
    addBadWeatherDiscountSuccess(state, action: PayloadAction<any>) {
      state.addBadWeatherDiscountStatus = SAGA_STATUS_SUCCESS
    },
    editBadWeatherDiscountFailed(state, action: PayloadAction<string>) {
      state.editBadWeatherDiscountStatus = SAGA_STATUS_FAILED
    },
    editBadWeatherDiscount(state, action: PayloadAction<any>) {
      state.editBadWeatherDiscountStatus = SAGA_STATUS_PENDING
    },
    editBadWeatherDiscountSuccess(state, action: PayloadAction<any>) {
      state.editBadWeatherDiscountStatus = SAGA_STATUS_SUCCESS
    },
    addBadWeatherDiscountFailed(state, action: PayloadAction<string>) {
      state.addBadWeatherDiscountStatus = SAGA_STATUS_FAILED
    },
    deleteBadWeatherDiscount(state, action: PayloadAction<any>) {
      state.deleteBadWeatherDiscountStatus = SAGA_STATUS_PENDING
    },
    deleteBadWeatherDiscountSuccess(state, action: PayloadAction<any>) {
      state.deleteBadWeatherDiscountStatus = SAGA_STATUS_SUCCESS
    },
    deleteBadWeatherDiscountFailed(state, action: PayloadAction<string>) {
      state.deleteBadWeatherDiscountStatus = SAGA_STATUS_FAILED
    },
    resetBadWeatherDiscountStatus(state) {
      state.deleteBadWeatherDiscountStatus = SAGA_STATUS_INIT
      state.addBadWeatherDiscountStatus = SAGA_STATUS_INIT
      state.editBadWeatherDiscountStatus = SAGA_STATUS_INIT
    },
    resetBadWeatherDiscountPage(state){
      state.badWeatherRequests = [];
      state.fetchBadWeatherDiscountStatus =  SAGA_STATUS_INIT;
    },
    
    getInstantDiscountRequest(state, action: PayloadAction<any>) {
      state.fetchInstantDiscountStatus = SAGA_STATUS_PENDING;
    },
    getInstantDiscountRequestSuccess(state, action: PayloadAction<any>) {
      state.instantDiscountsRequests = action?.payload;
      state.fetchInstantDiscountStatus = SAGA_STATUS_SUCCESS;
    },
    getInstantDiscountRequestFailed(state, action: PayloadAction<string>) {
      state.instantDiscountsRequests = [];
      state.fetchInstantDiscountStatus = SAGA_STATUS_FAILED;
    },
    resetInstantDiscountPage(state){
      state.instantDiscountsRequests = [];
      state.fetchInstantDiscountStatus =  SAGA_STATUS_INIT;
    },
    processInstantDiscountRequest(state, action: PayloadAction<any>) {
      
      state.processInstantDiscountRequestStatus = SAGA_STATUS_PENDING;
    },
    processInstantDiscountRequestSuccess(state, action: PayloadAction<any>) {
      state.processInstantDiscountRequestStatus = SAGA_STATUS_SUCCESS;
    },
    processInstantDiscountRequestFailed(state, action: PayloadAction<string>) {
      state.processInstantDiscountRequestStatus = SAGA_STATUS_FAILED;
    },

  }
});

// Actions
export const DiscountRequestsEditActions = discountRequestsEditSlice.actions;

// Selectors
export const fetchdiscountRequests = (state: RootState) => state.discountEditRequest.discountRequests;
export const fetchProcessDiscountRequestStatus = (state: RootState) => state.discountEditRequest.processDiscountRequestStatus;
export const expiryDiscountRequest = (state: RootState) => state.discountEditRequest.expiryDiscountRequestsStatus

export const fetchBadWeatherDiscountRequests = (state: RootState) => state.discountEditRequest.badWeatherRequests;
export const fetchBadWeatherDiscountRequestStatus = (state: RootState) => state.discountEditRequest.processBadWeatherDiscountRequestStatus;

export const instantDiscounts = (state: RootState) => state.discountEditRequest.instantDiscounts;
export const badWeatherDiscounts = (state: RootState) => state.discountEditRequest.badWeatherDiscounts;
export const addInstantDiscountStatus = (state: RootState) => state.discountEditRequest.addInstantDiscountStatus;
export const updateInstantDiscountStatus = (state: RootState) => state.discountEditRequest.updateInstantDiscountStatus;
export const deleteInstantDiscountStatus = (state: RootState) => state.discountEditRequest.deleteInstantDiscountStatus;

export const courseWeatherData = (state: RootState) => state.discountEditRequest.courseWeatherData;
export const courseWeatherDataStatus = (state: RootState) => state.discountEditRequest.fetchCourseWeatherDataStatus;
export const addBadWeatherDiscountStatus = (state: RootState) => state.discountEditRequest.addBadWeatherDiscountStatus;
export const deleteBadWeatherDiscountStatus = (state: RootState) => state.discountEditRequest.deleteBadWeatherDiscountStatus;
export const editBadWeatherDiscountStatus = (state: RootState) => state.discountEditRequest.editBadWeatherDiscountStatus;


export const fetchInstantDiscountRequests = (state: RootState) => state.discountEditRequest.instantDiscountsRequests;
export const fetchInstantDiscountRequestStatus = (state: RootState) => state.discountEditRequest.processInstantDiscountRequestStatus;



// Reducer
const discountRequestsEditReducer = discountRequestsEditSlice.reducer;
export default discountRequestsEditReducer;
