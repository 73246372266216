import React, { FC, useEffect, useState } from "react";
import "./instant-discount.scss";
import { DateTime } from "luxon";
import DiscountModel from "./modals/discount-model";
import { DiscountRequestsEditActions, fetchBadWeatherDiscountRequestStatus, editBadWeatherDiscountStatus, deleteBadWeatherDiscountStatus, addBadWeatherDiscountStatus, courseWeatherData, instantDiscounts, badWeatherDiscounts, addInstantDiscountStatus, updateInstantDiscountStatus, deleteInstantDiscountStatus, fetchInstantDiscountRequestStatus } from "../../reducers/discountEditSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useSearchParams } from "react-router-dom";
import CommonHeader from "../../shared/common-header/common-header";
import {
  BAD_WEATHER_DISCOUNT,
  SAGA_STATUS_FAILED,
  SAGA_STATUS_SUCCESS,
} from "../../utils/constants";
import {
  capitalizeFullString,
  convertTime24to12,
  mapWeatherCodeType,
} from "../../utils/common";
import WeatherDiscountPopup from "./modals/weather-discount-modal/weather-discount-modal";

function areWeatherDiscountedDatesSame(
  dateFromWeather: string,
  dateFromWeatherDiscount: string
) {
  return (
    DateTime.fromFormat(dateFromWeather, "yyyy-MM-dd").diff(
      DateTime.fromFormat(dateFromWeatherDiscount, "yyyy-MM-dd"),
      "days"
    )?.days === 0
  );
}

const InstantDiscounts: FC = () => {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();

  const clubId = searchParams.get("clubId");
  const courseId = searchParams.get("courseId");
  const [showDiscountModel, setShowDiscountModel] = useState<boolean>(false);
  const [modalType, setModalType] = useState("");
  const closeDiscountModel = () => {
    setShowDiscountModel(false);
  };

  const [selectedDiscount, setSelectedDiscount] = useState<any>({});
  const [courseDateTime,setCourseDateTime]=useState<any>("");
  const [showBadWeatherPopup, setShowBadWeatherPopup] =
    useState<boolean>(false);
  const [selectedBadWeatherDiscountDate, setSelectedBadWeatherDiscountDate] =
    useState<Date>(new Date());
  const instantDiscountList: any = useAppSelector(instantDiscounts);
  const badWeatherDiscountList: any = useAppSelector(badWeatherDiscounts);
  const addedInstantDiscountStatus = useAppSelector(addInstantDiscountStatus);
  const updatedInstantDiscountStatus = useAppSelector(updateInstantDiscountStatus);
  const processedInstantDiscountStatus = useAppSelector(fetchInstantDiscountRequestStatus)
  const deletedInstantDiscountStatus = useAppSelector(deleteInstantDiscountStatus);
  const courseWeather: any = useAppSelector(courseWeatherData);
  const addBadWeatherDiscStatus: string = useAppSelector(addBadWeatherDiscountStatus)
  const deletedBadWeatherDiscountStatus: string = useAppSelector(deleteBadWeatherDiscountStatus)
  const editedBadWeatherDiscountStatus: string = useAppSelector(editBadWeatherDiscountStatus)
  const badWeatherDiscountStatusUpdate: any = useAppSelector(fetchBadWeatherDiscountRequestStatus);

  /** Reste al status on page load */
  useEffect(() => {
    dispatch(DiscountRequestsEditActions.resetInstantDiscountStatus());
    dispatch(DiscountRequestsEditActions.resetBadWeatherDiscountStatus());
  }, []);



  useEffect(() => {
    if (
      addedInstantDiscountStatus == SAGA_STATUS_SUCCESS ||
      updatedInstantDiscountStatus == SAGA_STATUS_SUCCESS ||
      deletedInstantDiscountStatus == SAGA_STATUS_SUCCESS ||
      processedInstantDiscountStatus === SAGA_STATUS_SUCCESS ||
      processedInstantDiscountStatus === SAGA_STATUS_FAILED ||
      updatedInstantDiscountStatus === SAGA_STATUS_FAILED
    ) {
      closeDiscountModel();
      setSelectedDiscount({}); // Unset it so that previou values dont list in modal
      dispatch(DiscountRequestsEditActions.fetchOtherDiscounts(courseId));
      dispatch(
        DiscountRequestsEditActions.fetchCourseWeatherData({ courseId })
      );
    }
  }, [
    addedInstantDiscountStatus,
    updatedInstantDiscountStatus,
    deletedInstantDiscountStatus,
    processedInstantDiscountStatus
  ]);

    useEffect(()=>{
    if(courseWeather){
     let currentClubDateTime=  courseWeather?.currentClubDateTime ? DateTime.fromISO(courseWeather?.currentClubDateTime) : DateTime.now();
     setCourseDateTime(currentClubDateTime)
    }
    },[courseWeather])

  useEffect(() => {
    if (
      addBadWeatherDiscStatus == SAGA_STATUS_SUCCESS ||
      deletedBadWeatherDiscountStatus == SAGA_STATUS_SUCCESS ||
      editedBadWeatherDiscountStatus == SAGA_STATUS_SUCCESS ||
      badWeatherDiscountStatusUpdate == SAGA_STATUS_SUCCESS ||
      badWeatherDiscountStatusUpdate == SAGA_STATUS_FAILED 
    ) {
      closeDiscountModel();
      setSelectedDiscount({}); // Unset it so that previou values dont list in modal
      dispatch(DiscountRequestsEditActions.fetchOtherDiscounts(courseId));
      dispatch(
        DiscountRequestsEditActions.fetchCourseWeatherData({ courseId })
      );
    }
  }, [
    addBadWeatherDiscStatus,
    deletedBadWeatherDiscountStatus,
    editedBadWeatherDiscountStatus,
    badWeatherDiscountStatusUpdate,
    updatedInstantDiscountStatus
  ]);


  /**List all types of discounts like badWeather, instanytDiscount on page load */
  useEffect(() => {
    dispatch(DiscountRequestsEditActions.fetchOtherDiscounts(courseId));
    dispatch(DiscountRequestsEditActions.fetchCourseWeatherData({ courseId }));
  }, []);

  const onAddInstantDiscount = (data: any) => {
    let currentDate = DateTime.now().toFormat("yyyy-MM-dd");

    data.date = currentDate;
    if (!data.discountProfileId) {
      // clubId , courseId needed in case of add discount
      data.clubId = clubId;
      data.courseId = courseId;
      dispatch(DiscountRequestsEditActions.addInstantDiscount(data));
    } else {
      dispatch(DiscountRequestsEditActions.updateInstantDiscount(data));
    }
  };

  const getTimeFormat = (time: string) => {
    let splitTimeString = time.split(":");
    let convertedString = convertTime24to12(
      splitTimeString[0],
      splitTimeString[1]
    );
    return convertedString;
  };

  const onDiscountEdit = (discount: any) => {
    setModalType("");
    setSelectedDiscount(discount);
    setTimeout(() => {
      setShowDiscountModel(true);
    }, 300);
  };

  const onBadWeatherDiscountEdit = (discount: any) => {
    setSelectedDiscount(discount);
    setTimeout(() => {
      setShowBadWeatherPopup(true);
    }, 300);
  };

  const handleStatus = async (status: string, id: string) => {
    let payload = { status, id }
    dispatch(DiscountRequestsEditActions.processInstantDiscountRequest(payload))
  };

  const onDiscountDelete = (discount: any) => {
    setSelectedDiscount(discount);
    setModalType("delete");
    setTimeout(() => {
      setShowDiscountModel(true);
    }, 300);
  };

  const deleteDiscount = (data: any) => {
    if (selectedDiscount?.discountProfileId?.name === BAD_WEATHER_DISCOUNT) {
      dispatch(DiscountRequestsEditActions.deleteBadWeatherDiscount(data));
    } else {
      dispatch(DiscountRequestsEditActions.deleteInstantDiscount(data));
    }
  };

  const addUpdateBadWeather = (data: any) => {
    if (data?._id) {
      dispatch(DiscountRequestsEditActions.editBadWeatherDiscount(data));
    } else {
      dispatch(DiscountRequestsEditActions.addBadWeatherDiscount(data));
    }
    setSelectedDiscount({});
    setShowBadWeatherPopup(false);
  };

  const changeBadWeatherDiscountStatus = async (status: string, id: string) => {
    let payload = { status, id };
    dispatch(
      DiscountRequestsEditActions.processBadWeatherDiscountRequest(payload)
    );
  };
  const currentClubDateTimeLuxon = courseWeather?.currentClubDateTime ? DateTime.fromISO(courseWeather?.currentClubDateTime) : DateTime.now();
  const dateWiseWeatherData =
    courseWeather?.dateWiseWeather &&
      Object.keys(courseWeather?.dateWiseWeather ?? {})?.length > 0
      ? Object.entries(courseWeather?.dateWiseWeather)
        ?.filter(
          ([key]) =>
            DateTime.fromFormat(`${key} 23:59`, "yyyy-MM-dd HH:mm").diff(
              currentClubDateTimeLuxon,
              "days"
            )?.days >= 0
        )
        ?.slice(0, 3)
      : [];

  return (
    <div className="discountRequestsListWrapper">
      <CommonHeader sectionTitle={"Discount Requests"} />
      <div className="container">
        {/* <div className="row">
          <div className="col-12">
           
          </div>
        </div> */}
        <div className="addDiscountWrap p-3">
          <div className="formStepper">
            <div>
              <div className="row gutters-5 mb-4">
                <div className="col-12 col-lg mt-4">
                  <h5 className="mb-2 _h5">Add discounts on your tee times</h5>
                </div>
              </div>
              <div className="mb-4">
                <div className="row gutters-5 mb-3 align-items-center">
                  <div className="col-12 col-lg  d-flex justify-content-between align-items-center">
                    <h6 className="mb-0 fw700 fontsize-16 color-900">
                      Instant Discount
                    </h6>

                    {/* { */}

                    {/* {instantDiscountList && instantDiscountList.length == 0 && (
                      <div className="col-12 col-lg-auto order-2 gap-2 d-flex addDisBtn">
                        <img
                          src="./assets/images/icons/plusIconWithCircleVector.svg"
                          alt=""
                        />
                        <a
                          className="color-active text-decoration-none fw700 d-inline-block "
                          href={void 0}
                          onClick={() => {
                            setModalType("");
                            setShowDiscountModel(true);
                          }}
                        >
                          Add Instant Discount
                        </a>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className={`d-flex flex-column justify-content-center align-items-center gap-3 myExtraDiv  
                  ${(instantDiscountList && instantDiscountList?.length == 0) && "myExtraDivBack"}
                  ${(instantDiscountList && instantDiscountList.length > 0) && "justifyStartImp"}
                  `}
                >
                  {instantDiscountList && instantDiscountList?.length == 0 && (
                    <div className="col-12 col-lg-auto order-2 gap-2 d-flex addDisBtn">
                      <img
                        src="./assets/images/icons/plusIconWithCircleVector.svg"
                        alt=""
                      />
                      <a
                        className="color-active text-decoration-none fw700 d-inline-block "
                        href={void 0}
                        onClick={() => {
                          setModalType("");
                          setShowDiscountModel(true);
                        }}
                      >
                        Add Instant Discount
                      </a>
                    </div>
                  )}

                  {/* =================================== */}

                  <div className="table-responsive  order-1 myTableResClassDiv order-1 myTableResClassDiv">
                    {instantDiscountList && instantDiscountList.length > 0 && (
                      <table className="table  mb-2 myTableClass">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}># </th>
                            <th>
                              Date &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                            <th>
                              Start Time &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                            <th>
                              End Time &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                            <th>
                              Discount Given &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                            <th>
                              Teesheet sold &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                            <th>
                              Action &nbsp;{" "}
                              <span className="sortIcons">
                                <em className="fa fa-caret-up"></em>
                                <em className="fa fa-caret-down"></em>
                              </span>
                            </th>
                          </tr>
                        </thead>

                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++ */}

                        <tbody>
                          {instantDiscountList.map(
                            (discounts: any, index: number) => (

                              // ==================================

                              <>
                                {Array(1).fill(
                                  <tr key={index}>
                                    <td
                                      className="fw600 fontsize-14"
                                      style={{ width: "5%" }}
                                    >
                                      {index + 1}
                                    </td>
                                    <td>
                                      <h6 className="mb-0 fw600 fontsize-14">
                                        {discounts?.date}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 fw600 fontsize-14">
                                        {discounts?.startTime
                                          ? getTimeFormat(discounts?.startTime)
                                          : "n/a"}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 fw600 fontsize-14">
                                        {discounts?.endTime
                                          ? getTimeFormat(discounts?.endTime)
                                          : "n/a"}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 fw600 fontsize-14">
                                        {discounts?.discount}%
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="mb-0 fw600 fontsize-14">
                                        {discounts?.teesheetSold
                                          ? discounts?.teesheetSold
                                          : "n/a"}
                                      </h6>
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-start">
                                        {discounts?.discountStatus == "accepted" ?
                                          <>
                                            <a
                                              href={void 0}
                                              className="fontsize-18 color-custome"
                                              onClick={() =>
                                                onDiscountEdit(discounts)
                                              }
                                              title="Edit"
                                            >
                                              <em className="icon-pen"></em>
                                            </a>
                                            <a
                                              href={void 0}
                                              className="fontsize-18 color-custome color-danger ms-3"
                                              onClick={() =>
                                                onDiscountDelete(discounts)
                                              }
                                              title="Delete"
                                            >
                                              <em className="far fa-trash-can"></em>
                                            </a>
                                          </> : <>
                                            <a
                                              href={void 0}
                                              className="fontsize-18 color-custome color-sucess ms-3"
                                              onClick={() => handleStatus("accepted", discounts?._id)}
                                              title="Approve"
                                            >
                                              <em className="fa-solid fa-check"></em>
                                            </a>
                                            <a
                                              href={void 0}
                                              className="fontsize-18 color-custome color-danger ms-3"
                                              onClick={() => handleStatus("rejected", discounts?._id)}
                                              title="Disapprove"
                                            >
                                              <em className="fa-sharp fa-solid fa-xmark"></em>
                                            </a>
                                          </>}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                              // ==================================
                            )
                          )}
                        </tbody>
                      </table>
                    )}

                    {/* =================================== */}

                    {instantDiscountList?.length == 0 && (
                      <span className="mb-10 mt-10 noInstantDisText ">
                        No instant discount added yet
                      </span>
                    )}

                    {/* =================================== */}
                  </div>
                </div>
              </div>

              {/* ========================================================================== */}
              <div className="mb-4  mt-5">
                <div className="row gutters-5 mb-3">
                  <div className="col-12 col-lg">
                    <h6 className="mb-4 fw700 fontsize-16 color-900">
                      Bad Weather Discount
                    </h6>
                  </div>

                  <div className="weatherGrid mb-3 myWeatherGrid ">
                    {dateWiseWeatherData?.length <= 0 && (
                      <p>Weather data for course not available</p>
                    )}

                    {/* ========================================================= */}
                    <div className="allMyWeatherWrapper">
                      {dateWiseWeatherData?.length > 0 &&
                        dateWiseWeatherData.map(([key, value]: any) => (
                          <div className="weatherWrapper myWeatherWrapper">
                            <div className="myWeatherWrapperImgDiv">
                              <img
                                title={
                                  value?.weather?.[0]?.description
                                    ? capitalizeFullString(
                                      value?.weather?.[0]?.description
                                    )
                                    : "Temperature"
                                }
                                src={mapWeatherCodeType(
                                  value?.weather?.[0]?.icon
                                )}
                                alt="../../../assets/images/icons/weather/Clear_Day_Icon.png"
                                className="img-fluid"
                                width={"40px"}
                                height={"40px"}
                              />
                            </div>

                            <div className="weatherDayDeg">
                              <div>
                                {key &&
                                  DateTime.fromFormat(
                                    key,
                                    "yyyy-MM-dd"
                                  ).toFormat("EEE, LLL dd")}
                              </div>

                              <div>
                                <strong>
                                  {value?.temp_min
                                    ? `${value.temp_min.toFixed(0)}°C`
                                    : ""}
                                </strong>
                                &nbsp;{" "}
                                {value?.temp_max
                                  ? `${value.temp_max.toFixed(0)}°C`
                                  : ""}
                              </div>
                            </div>

                            {/* ====================================================== */}

                            {/* add icon should be visible only if no active discounts or request is present  */}
                            {/* ====================================================== */}
                            {(courseWeather?.badWeatherDiscounts || [])?.filter(
                              (obj: any) =>
                                areWeatherDiscountedDatesSame(key, obj.date) &&
                                obj.status !== "rejected"
                            )?.length <= 0 ? (
                              <a
                                href={void 0}
                                className="fontsize-18 color-custome myEditIcon"
                                onClick={() => {
                                  setSelectedBadWeatherDiscountDate(
                                    new Date(`${key}T00:00:00`)
                                  );
                                  setSelectedDiscount({});
                                  setShowBadWeatherPopup(true);
                                }}
                              >
                                <em className="icon-pen"></em>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>


                    {/* =============addDisBtnOnTopOfGrayStart===================== */}

                    {/* {badWeatherDiscountList && badWeatherDiscountList.length > 0 && (
                      <div
                        className="col-12 col-lg-auto order-2 gap-2 d-flex addDisBtn"
                        style={{ alignSelf: "end" }}
                      >
                        <img
                          src="./assets/images/icons/plusIconWithCircleVector.svg"
                          alt=""
                        />
                        <a
                          className="color-active text-decoration-none fw700 d-inline-block "
                          href={void 0}
                          onClick={() => {
                            setModalType("");
                            setShowDiscountModel(true);
                          }}
                        >
                          Add Weather Discount
                        </a>
                      </div>
                    )} */}
                  </div>

                  {/* ========================================================= */}
                </div>


                {/* =============badWeatherDisGrayPartStart===================== */}
                <div
                  style={{ maxWidth: "100%" }}
                  className={` weatherWrapperTableDivStart ${(badWeatherDiscountList &&
                    badWeatherDiscountList?.length == 0) && "myExtraDivBack"}`}
                >
                  <div className="d-flex flex-column justify-content-center align-items-center gap-3 myExtraDiv ">
                    

                    <div className="table-responsive  order-1 myTableResClassDiv">
                      {badWeatherDiscountList &&
                        badWeatherDiscountList.length > 0 && (
                          <table className="table  mb-2 myTableClass">
                            <thead>
                              <tr>
                                <th
                                  style={{ width: "5%" }}
                                  className="fw600 fontsize-14"
                                >
                                  #{" "}
                                </th>
                                <th
                                  style={{ width: "70%" }}
                                  className="fw600 fontsize-14"
                                >
                                  Date &nbsp;{" "}

                                  <span className="sortIcons">
                                    <em className="fa fa-caret-up"></em>
                                    <em className="fa fa-caret-down"></em>
                                  </span>
                                </th>
                                <th>
                                  Discount &nbsp;{" "}
                                  <span className="sortIcons">
                                    <em className="fa fa-caret-up"></em>
                                    <em className="fa fa-caret-down"></em>
                                  </span>
                                </th>
                                <th>
                                  Action &nbsp;{" "}
                                  <span className="sortIcons">
                                    <em className="fa fa-caret-up"></em>
                                    <em className="fa fa-caret-down"></em>
                                  </span>
                                </th>
                              </tr>
                            </thead>

                            {/* +++++++++++++++++++++++++++++++++++++++++++++++++++ */}

                            <tbody>
                              {badWeatherDiscountList.map(
                                (discounts: any, index: number) => (
                                  // ==================================
                                  <>
                                    {Array(1).fill(

                                      <tr
                                        key={index}
                                        className={
                                          discounts?.discountStatus ===
                                            "pending"
                                            ? "table-warning"
                                            : ""
                                        }
                                      >
                                        <td className="fw700 fontsize-16">
                                          {index + 1}
                                        </td>
                                        <td style={{ width: "70%" }}>
                                          <h6 className="mb-0 fw700 fontsize-16">
                                            {discounts?.date}
                                          </h6>
                                        </td>

                                        <td>
                                          <h6 className="mb-0 fw700 fontsize-16">
                                            {discounts?.discount}%
                                          </h6>
                                        </td>

                                        <td>
                                          <div className="d-flex align-items-center justify-content-start">
                                            {discounts?.discountStatus === "accepted" ? <>
                                              <a
                                                href={void 0}
                                                className="fontsize-18 color-custome"
                                                onClick={() =>
                                                  onBadWeatherDiscountEdit(discounts)
                                                }
                                                title="Edit"
                                              >
                                                <em className="icon-pen"></em>
                                              </a>
                                              <a
                                                href={void 0}
                                                className="fontsize-18 color-custome color-danger ms-3"
                                                onClick={() =>
                                                  onDiscountDelete(discounts)
                                                }
                                                title="Delete"
                                              >
                                                <em className="far fa-trash-can"></em>
                                              </a>
                                            </> :
                                              <>
                                                <a
                                                  href={void 0}
                                                  className="fontsize-18 color-custome color-sucess ms-3"
                                                  onClick={() =>
                                                    changeBadWeatherDiscountStatus("accepted", discounts?._id)
                                                  }
                                                  title="Approve"
                                                >
                                                  <em className="fa-solid fa-check"></em>
                                                </a>
                                                <a
                                                  href={void 0}
                                                  className="fontsize-18 color-custome color-danger ms-3"
                                                  onClick={() =>
                                                    changeBadWeatherDiscountStatus("rejected", discounts?._id)
                                                  }
                                                  title="Disapprove"
                                                >
                                                  <em className="fa-sharp fa-solid fa-xmark"></em>
                                                </a>
                                              </>
                                            }

                                          </div>
                                        </td>
                                      </tr>

                                    )}
                                  </>
                                  // ==================================
                                )
                              )}
                            </tbody>
                          </table>
                        )}

                      {badWeatherDiscountList?.length == 0 && (
                        <span className="mb-10 mt-10 noInstantDisText ">
                          No bad weather discount added
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* =============badWeatherDisGrayPartEnd===================== */}

              </div>

              <DiscountModel
                showModal={showDiscountModel}
                onCloseClick={closeDiscountModel}
                selectedDiscount={selectedDiscount}
                onAddInstantDiscount={onAddInstantDiscount}
                modalType={modalType}
                deleteDiscount={deleteDiscount}
                currentClubDateTime={courseDateTime}
              />
              {showBadWeatherPopup && (
                <WeatherDiscountPopup
                  show={showBadWeatherPopup}
                  selectedDiscount={selectedDiscount}
                  setShow={setShowBadWeatherPopup}
                  selectedDate={selectedBadWeatherDiscountDate}
                  data={{ clubId, courseId }}
                  submitHandler={addUpdateBadWeather}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantDiscounts;
