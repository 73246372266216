import React, { FC, useEffect, useState } from "react";
import "./bad-weather-discounts.scss";
import { useNavigate, useParams } from "react-router";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { fetchBadWeatherDiscountRequestStatus, fetchBadWeatherDiscountRequests, DiscountRequestsEditActions } from "../../reducers/discountEditSlice";
import { isUserLoggedIn } from "../../reducers/authSlice";
import { DiscountRequestEditPayload } from "../../models/discountEditRequest";
import { DateTime } from "luxon";

const BadWeatherDiscounts: FC = () => {
  const { id, clubId }: any = useParams();
  const isUser = useAppSelector(isUserLoggedIn);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const discountRequests: Array<any> = useAppSelector(fetchBadWeatherDiscountRequests);
  const discountProcessStatus:any = useAppSelector(fetchBadWeatherDiscountRequestStatus);

  const handleStatus = async (status: string, id:string) => {
    let payload = { status, id }
    dispatch(DiscountRequestsEditActions.processBadWeatherDiscountRequest(payload))
  };

  useEffect(()=>{
    dispatch(DiscountRequestsEditActions.resetBadWeatherDiscountPage())
  },[])

  useEffect(() => {
    const payload = { id, clubId }
    dispatch(DiscountRequestsEditActions.getBadWeatherRequest(payload));
  }, [discountProcessStatus]);

  const sourceToDashboard = () => {
    navigate("/dashboard");
  }

  const sourceToContact=()=>{
    window.open("https://www.integolf.com/contact","_blank")
  }

  const login = () => {
    navigate("/");
  };

  return (
    <div className="discountUpdateWrapper">
      <header className="">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <a href="void:0">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/brand/integolf-black.svg`}
                alt="integolf"
                className="img-fluid"
              />
            </a>
            {!isUser ? (
              <div className="login-wrapper">
                <button
                  className="button button-primary button-rounded button-sm fontsize-14"
                  onClick={login}
                >
                  Login
                </button>
              </div>
            ) : <div className="login-wrapper cursor-pointer dashboard-wrapper" onClick={() => sourceToDashboard()}>Go To Dashboard</div>}
          </div>
        </div>
      </header>

      <section className="discountHeroWrapper">
        <div className="container">
          <div className="_heroContent">
            <h4 className="mb-3">Bad Weather Discount Notification</h4>
            {( !discountRequests?.length )  ? <h1 className="mb-3">Your Settings Link Has Expired</h1> : <> <h1 className="mb-3">
              Attention Required: Review and Accept Bad Weather Discount
            </h1></>}

            {/* {expiryDiscountRequestsStatus ? <p>Request a new link for the discount settings, or go to your dashboard's discount settings for more options.</p> : <p>
              An administrator has updated the offer prices for your
              products/services. Please review the changes and accept each
              updated price individually to ensure they reflect your current
              strategy.
            </p>} */}
          </div>

          <div className="discounUpdateContent">
            {discountRequests?.length > 0 && <h5 className="text-center mb-4 pb-2">Bad Weather Discount</h5>}
            {discountRequests?.length > 0 && discountRequests?.map((discount: any) => (
              <div className="_box mb-3">
                <div className="row">
                  <div className="col">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <label className="mb-2 mb-md-3">Discount Given</label>
                        <h6><span>{discount.discount}%</span>
                        </h6>
                      </div>
                      <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                        <label className="mb-2 mb-md-3">Date</label>
                        <h6><span>{DateTime.fromFormat(discount.date, "yyyy-MM-dd").toFormat("EEE, dd LLL")}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto d-flex align-items-center justify-content-center">
                    {(discount?.status == "pending"  ) &&
                      <div>
                        <button
                          onClick={() => handleStatus("accepted", discount?._id)}
                          disabled={  (["accepted", "rejected"].includes(discount?.status))}
                          type="button"
                          className="button button-primary button-rounded fw800 me-2">
                          Approve
                        </button>
                        <button
                          disabled={ (["accepted", "rejected"].includes(discount?.status))}
                          type="button"
                          onClick={() => handleStatus("rejected", discount?._id)}
                          className="button button-danger button-rounded fw800">
                          Reject
                        </button>
                      </div>
                    }
                    {( discount?.status == "accepted") && <span className="styled-span">Approved</span>}
                    {( discount?.status == "rejected") && <span className="styled-span-reject">Rejected</span>}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="_sections">
            <div className="_sections_box">
              <div className="_sections_box_content">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/clock_circle.svg`}
                  alt=""
                  className="img-fluid mx-auto mb-3"
                />
                <h5>Why This Matters</h5>
                <p>
                  Maintaining accurate and competitive pricing is crucial for
                  your success on our platform. By keeping your offer prices
                  up-to-date and aligned with your business strategy, you can
                  attract more customers and maximize your sales potential.
                </p>
              </div>
            </div>
            <div className="_sections_box">
              <div className="_sections_box_content">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/question_circle.svg`}
                  alt=""
                  className="img-fluid mx-auto mb-3"
                />
                <h5>Review Deadline</h5>
                <p>
                  Please complete your review and acceptance by [specific date].
                  If no action is taken by this date, the updated prices will be
                  automatically rejected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="helpSection">
        <span className="imgTopRight">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/help_bg.png`}
            alt=""
            className="img-fluid"
          />
        </span>
        <span className="imgBottomLeft">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/help_bg.png`}
            alt=""
            className="img-fluid"
          />
        </span>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="_content text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/icons/gear.svg`}
                  alt=""
                  className="img-fluid mx-auto mb-3"
                />
                <h2 className="mb-3">Need Help?</h2>
                <p>
                  If you have any questions or need assistance, contact our
                  support team a{" "}
                  <a href="mailto:support@integolf.com" className="text-white">
                    support@integolf.com
                  </a>
                </p>

                <button
                  type="button" onClick={()=>sourceToContact()}
                  className="button button-primary button-rounded fw700 mt-3"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default BadWeatherDiscounts;
