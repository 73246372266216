import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { UserPayoutAccount } from '../models/userPayoutAccounts';
import { SAGA_STATUS_FAILED, SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS } from '../utils/constants';


export interface userPayoutAccountsState {
  addNewUserPayoutAccountStatus: string;
  updateUserPayoutAccountStatus: string;
  fetchUserPayoutAccountsStatus: string;
  totalUserPayoutAccountsCount: number,
  userPayoutAccounts: Array<UserPayoutAccount>;
}

const initialState: userPayoutAccountsState = {
  addNewUserPayoutAccountStatus: SAGA_STATUS_INIT,
  updateUserPayoutAccountStatus: SAGA_STATUS_INIT,
  fetchUserPayoutAccountsStatus: SAGA_STATUS_INIT,
  totalUserPayoutAccountsCount: 0,
  userPayoutAccounts: []
};

const userPayoutAccountsSlice = createSlice({
  name: 'userPayoutAccounts',
  initialState,
  reducers: {
    fetchPayoutAccounts(state, action: PayloadAction<any>) {
      state.fetchUserPayoutAccountsStatus = SAGA_STATUS_PENDING;
    },
    fetchPayoutAccountsfailed(state, action: PayloadAction<string>) {
      state.fetchUserPayoutAccountsStatus = SAGA_STATUS_FAILED;
    },
    fetchPayoutAccountsSuccess(state, action: PayloadAction<any>) {
      const { count = 0, result = [] } = action.payload;
      state.userPayoutAccounts = result;
      state.totalUserPayoutAccountsCount = count;
      state.fetchUserPayoutAccountsStatus = SAGA_STATUS_SUCCESS;
    },
    addNewPayoutAccountInfo(state, action: PayloadAction<any>) {
      state.addNewUserPayoutAccountStatus = SAGA_STATUS_PENDING;
    },
    addNewPayoutAccountInfofailed(state, action: PayloadAction<string>) {
      state.addNewUserPayoutAccountStatus = SAGA_STATUS_FAILED;
    },
    addNewPayoutAccountInfoSuccess(state, action: PayloadAction<any>) {
      state.addNewUserPayoutAccountStatus = SAGA_STATUS_SUCCESS;
    },
    updatePayoutAccountInfo(state, action: PayloadAction<any>) {
      state.updateUserPayoutAccountStatus = SAGA_STATUS_PENDING;
    },
    updatePayoutAccountInfofailed(state, action: PayloadAction<string>) {
      state.updateUserPayoutAccountStatus = SAGA_STATUS_FAILED;
    },
    updatePayoutAccountInfoSuccess(state, action: PayloadAction<any>) {
      state.updateUserPayoutAccountStatus = SAGA_STATUS_SUCCESS;
    },
    resetuserPayoutAccountsinfo(state) {
      state.fetchUserPayoutAccountsStatus = SAGA_STATUS_INIT;
      state.addNewUserPayoutAccountStatus = SAGA_STATUS_INIT;
      state.updateUserPayoutAccountStatus = SAGA_STATUS_INIT;
      state.totalUserPayoutAccountsCount = 0;
      state.userPayoutAccounts = [];
    },
    resetResetAddNewPayoutAccountStatus(state) {
      state.addNewUserPayoutAccountStatus = SAGA_STATUS_INIT;
    },
    resetResetupdateUserPayoutAccountStatus(state) {
      state.updateUserPayoutAccountStatus = SAGA_STATUS_INIT;
    }
  }
});

// Actions
export const userPayoutAccountsActions = userPayoutAccountsSlice.actions;

// Selectors
export const fetchUserPayoutAccounts = (state: RootState) => state.userPayoutAccounts.userPayoutAccounts;
export const fetchUserPayoutAccountsStatus = (state: RootState) => state.userPayoutAccounts.fetchUserPayoutAccountsStatus;
export const fetchAddNewUserPayoutAccountStatus = (state: RootState) => state.userPayoutAccounts.addNewUserPayoutAccountStatus;
export const fetchUpdateUserPayoutAccountStatus = (state: RootState) => state.userPayoutAccounts.updateUserPayoutAccountStatus;

// Reducer
const userPayoutAccountsReducer = userPayoutAccountsSlice.reducer;
export default userPayoutAccountsReducer;
