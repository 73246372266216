import React, { FC, useEffect, useState } from "react";
import "./product-list.scss";
import { useNavigate } from "react-router";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

import CommonHeader from "../../../../shared/common-header/common-header";
import { productsActions, fetchProductList, fetchDeleteProductStatus, fetchPendingProducts, fetchUpdateProductStatus } from "../../../../reducers/productSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { merchantFilesActions } from "../../../../reducers/merchantFilesSlice";
import { CLUB_ACTIVE_STATUS, SAGA_STATUS_SUCCESS } from "../../../../utils/constants";

interface ProductListProps { }

const ProductList: FC<ProductListProps> = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const productList: any = useAppSelector(fetchProductList);
  const deleteProductStatus: string = useAppSelector(fetchDeleteProductStatus);
  const updateProductStatus: string = useAppSelector(fetchUpdateProductStatus);
  const pendingProducts : Array<any> = useAppSelector(fetchPendingProducts);
  const [showModal, SetShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  const onCloseClick = () => {
    SetShowModal(false);
  };

  const onDeleteProduct = () => {
    dispatch(productsActions.deleteProduct({ id: selectedProduct }))
  }

  const onEdit = (productId: string) => {
    dispatch(merchantFilesActions.resetSelectedMerchantFile());
    navigate(`/product/add?productId=${productId}`)
  }

  const updateProduct = (updates: Array<any>) => {
    if (updates.length > 0) {
      dispatch(productsActions.updateProduct(updates))
    }
  }

  const onPendingProductApproveAll = () => {
    const payload = pendingProducts.map(obj => ({
      id: obj._id,
      isApproved: true
    }));
    updateProduct(payload);
  }

  const onPendingProductRejectAll = () => {
    const payload = pendingProducts.map(obj => ({
      id: obj._id,
      isDeleted: true
    }));
    updateProduct(payload);
  }

  const onPendingProductAccept = (productId: string) => {
    const payload = [{
      id: productId,
      isApproved: true 
    }];
    updateProduct(payload);
  }

  const onPendingProductReject = (productId: string) => {
    const payload = [{
      id: productId,
      isDeleted: true 
    }];
    updateProduct(payload);
  }

  useEffect(() => {
    if (deleteProductStatus == 'success') {
      setTimeout(() => {
        SetShowModal(false)
        dispatch(productsActions.resetProductStatus())
      }, 1000);
    }
  }, [deleteProductStatus])

  useEffect(()=> {
    if (updateProductStatus === SAGA_STATUS_SUCCESS) {
      dispatch(productsActions.fetchPendingProducts());
      dispatch(productsActions.fetchProductList());
      dispatch(productsActions.resetProductStatus());
    }
  }, [updateProductStatus])

  useEffect(() => {
    dispatch(productsActions.fetchPendingProducts());
    dispatch(productsActions.fetchProductList());
  }, [])

  return (
    <>
      <div className="productListWrapper">
        <CommonHeader
          sectionTitle={"Products"}
          hasAddProductButton={true}
          hasPendingProducts={pendingProducts?.length >= 4 ? true : false}
          disabledAddProductButton={productList && (productList?.length + pendingProducts?.length ) >= 4  ? true : false}
        ></CommonHeader>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                (pendingProducts && pendingProducts.length) > 0 && <div className="card tableCard">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-auto">
                        <h4 className="card-title mb-0">Products List(To be added)</h4>
                      </div>
                      <div className="col-auto">
                        <a href={void (0)} className="fw700" onClick={onPendingProductApproveAll}>Approve all</a>
                      </div>
                      <div className="col-auto">
                        <a href={void (0)} className="fw700 color-danger" onClick={onPendingProductRejectAll}>Reject all</a>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-border">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Inventory</th>
                          <th>Pricing</th>
                          <th>Max Quantity For Cart</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(pendingProducts && pendingProducts.length) > 0 && pendingProducts.map((product: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <div className="productInfo d-flex align-items-center">
                                <span className="productImage me-3">

                                  <img
                                    src={product?.images?.length > 0 ? product.images[0].path : 0}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </span>
                                <h5 className="mb-0 fw700">
                                  {product?.title ?? "n/a"}
                                </h5>
                              </div>
                            </td>
                            <td>{ ((product?.isInventoryRequired===true && product?.inventory)) ? `${product?.inventory} in stock.` : 0}</td>
                            <td>
                              <h6 className="fw700 mb-0">$ { product?.price ? Number(product?.price)?.toFixed(2) : '0.00'}</h6>
                            </td>
                            <td>{product?.maxQtyForCart ?? 0}</td>
                            <td>
                              <div className="row">
                                <div className="col-auto">
                                  <a href={void (0)} className="fw700" onClick={() => onPendingProductAccept(product._id)}>
                                    Approve
                                  </a>
                                </div>
                                <div className="col-auto">
                                  <a href={void (0)} className="fw700 color-danger" onClick={() => onPendingProductReject(product._id)}>
                                    Reject
                                  </a>
                                </div>
                                <div className="col-auto">
                                  <a href={void (0)} className="fw700 color-700" onClick={() => onEdit(product._id)}>
                                    Edit
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>))}
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
              }
              {
                (productList && productList.length > 0) && <div className="card tableCard">
                <div className="card-header">
                  <h4 className="card-title mb-0">Products List</h4>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-border">
                      <thead>
                        <tr>
                          <th>Name</th>
                          {/* <th>Sizes</th> */}
                          <th>Status</th>
                          <th>Inventory</th>
                          <th>Pricing</th>
                          <th>Max Quantity For Cart</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.length > 0 && productList.map((product: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <div className="productInfo d-flex align-items-center">
                                <span className="productImage me-3">

                                  <img
                                    src={product?.images?.length > 0 ? product.images[0].path : 0}
                                    alt=""
                                    className="img-fluid"
                                  />

                                </span>
                                <h5 className="mb-0 fw700">
                                  {product?.title ?? 'n/a'}
                                </h5>
                              </div>
                            </td>
                            <td>
                              <label className="badgeStatus active mb-0">
                                { (product?.isInventoryRequired===true)? (product?.inventory > 0 )  ? 'Active' : 'Out of stock' : "Active" }
                              </label>
                            </td>
                            <td>{ ( product?.inventory && product?.isInventoryRequired ) ? `${product?.inventory} in stock.`: 0 }</td>
                            <td>
                              <h6 className="fw700 mb-0">$ { product?.price ? Number(product?.price)?.toFixed(2) : '0.00'}</h6>
                            </td>
                            <td>{product?.maxQtyForCart ?? 0}</td>
                            <td>
                              <div className="listActionDropdownBlock ms-3">
                                <Dropdown align="end">
                                  <Dropdown.Toggle className="toggleButton">
                                    <em className="icon-vertical-elipse"></em>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <a
                                      href={void (0)}
                                      className="dropdown-item fw700"
                                      onClick={() => onEdit(product._id)}
                                    >
                                      Edit
                                    </a>
                                    <a
                                      href={void (0)}
                                      className="dropdown-item fw700 color-danger"
                                      onClick={() => {
                                        setSelectedProduct(product._id);
                                        SetShowModal(true);
                                      }}
                                    >
                                      Delete
                                    </a>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>))}
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
              }
              <Modal
                className={`discountModalWrapper modalLayoutWrapper `}
                show={showModal}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Body>
                  <a href={void 0} className="closeButton" onClick={onCloseClick}>
                    <em className="fa-solid fa-close"></em>
                  </a>
                  {(deleteProductStatus != "success" || !deleteProductStatus) && <div className="formWrap">
                    {/* Delete product modal  */}
                    <div className="text-center px-md-5">
                      <img
                        src="../assets/images/info-circle.png"
                        alt="deactivate"
                        className="mb-4 d-inline-block"
                      />
                      <h3 className="mb-4">
                        Are you sure, you want to delete this product?
                      </h3>
                      <a
                        href={void 0}
                        className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                        onClick={() => onDeleteProduct()}
                      >
                        Yes, Delete
                      </a>
                    </div>

                  </div>}

                  {deleteProductStatus == "success" && (
                    <div className="successConfirmationModalWrap text-center">
                      <span className="markIcon success">
                        <em className="fa-solid fa-circle-check"></em>
                      </span>
                      <h2 className="mb-3">Successfully updated.</h2>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </div>

          {productList.length == 0 && pendingProducts.length == 0 && (
            <div className="productWelcomePage d-flex align-items-center justify-content-center">
              <div className="text-center">
                <h2 className="mb-4">First up:what are you selling?</h2>
                <h5 className="fontsize-16 color-700 mb-4 pb-3">
                  Let's add your products.
                </h5>
                <button
                  type="button"
                  className="button button-primary button-rounded button-min-180 fw700"
                  onClick={() => navigate("/product/add")}
                >
                  + Add Your Products
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductList;
