import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { Payouts } from '../models/payouts';

export interface payoutsState {
    fetchPayoutsStatus: string;
    fetchLastPaidPayoutStatus: string;
    fetchPendingPayoutStatus: string;
    payoutsCount: number;
    payouts: Array<Payouts>;
    lastPaidPayout: Payouts;
    pendingPayout: Payouts;
}

const initialState: payoutsState = {
    fetchPayoutsStatus : "",
    fetchLastPaidPayoutStatus: "",
    fetchPendingPayoutStatus: "",
    payoutsCount: 0,
    payouts: [],
    lastPaidPayout: {},
    pendingPayout: {}
};

const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    payoutsList(state) {
        state.fetchPayoutsStatus = "pending";
    },
    payoutsListSuccess(state, action: PayloadAction<any>) {
        const { result = [], count = 0 } = action.payload;
        state.payouts = result;
        state.payoutsCount = count;
        state.fetchPayoutsStatus = "success";
    },
    payoutsListFailed(state, action: PayloadAction<string>) {
        state.fetchPayoutsStatus = "failed";
    },
    fetchLastPaidPayout(state) {
        state.fetchLastPaidPayoutStatus = "pending";
    },
    fetchLastPaidPayoutSuccess(state, action: PayloadAction<any>) {
        const { result = [{}] } = action.payload;
        state.lastPaidPayout = result[0];
        state.fetchLastPaidPayoutStatus = "success";
    },
    fetchLastPaidPayoutFailed(state, action: PayloadAction<any>) {
        state.fetchLastPaidPayoutStatus = "failed";
    },
    fetchPendingPayout(state) {
        state.fetchPendingPayoutStatus = "pending";
    },
    fetchPendingPayoutSuccess(state, action: PayloadAction<any>) {
        const { result = [{}] } = action.payload;
        state.pendingPayout = result[0];
        state.fetchPendingPayoutStatus = "success";
    },
    fetchPendingPayoutFailed(state, action: PayloadAction<any>) {
        state.fetchPendingPayoutStatus = "failed";
    },
    resetPayouts(state) {
        state = initialState;
    }
  }
});

// Actions
export const payoutsActions = payoutsSlice.actions;

// Selectors
export const fetchMerchantPayouts = (state: RootState) => state.payouts.payouts;
export const fetchMerchantPaidPayout = (state: RootState) => state.payouts.lastPaidPayout;
export const fetchMerchantPendingPayout = (state: RootState) => state.payouts.pendingPayout;

// Reducer
const payoutsReducer = payoutsSlice.reducer;
export default payoutsReducer;
