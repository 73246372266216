import React, { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

import './image-cropper-modal.scss';

interface ImageCropperUploadModalProps {
  isModalVisible: boolean;
  handleClose: () => void;
  onError : Function;
  imageRef?: string;
  onSubmit: any;
  popUpMessage ?: string;
  popUpHeader ?: string;
  imagePlaceHolderMessage ?: string;
  minCropBoxHeight ?: number;
  minCropBoxWidth ?: number;
  aspectRatio ?: number;
}

const ImageCropperUploadModal: FC<ImageCropperUploadModalProps> = ({
    isModalVisible, 
    handleClose,
    onError,
    onSubmit,
    popUpMessage,
    popUpHeader,
    minCropBoxHeight = 10,
    minCropBoxWidth = 10,
    aspectRatio = 1
}) => {

    const cropperRef = useRef<ReactCropperElement>(null);
    const [ imageSrc, setImageSrc ] = useState("");
    const [ fileName, setFileName ] = useState("");

    const onHandleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if ( e.target.files && e.target.files.length == 1) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as any);
            };
            reader.readAsDataURL(e.target.files[0]);
            if (e.target.files[0] && e.target.files[0].name) {
                setFileName(e.target.files[0].name);
            }
        } else {
            onError("Select image to upload");
        }
    }

    const onCropSubmit = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blobData) => {
                const formData = new FormData();
                formData.append("files", blobData as Blob, fileName);
                onSubmit(formData);
            });
        } else {
            throw new Error("Cropper object not found");
        }
        onClose();
    }

    const onClose = () => {
        setFileName("");
        setImageSrc("");
        handleClose();
    }

    return (
        <>
        <Modal
            className="updateInfoModalWrapper"
            show={isModalVisible}
            centered={true}
        >
            <Modal.Body>
                <a href={void 0} className="closeButton" onClick={onClose}>
                    <em className="fa-solid fa-close"></em>
                </a>
                <div className="formWrap">
                    <div className="formBlock">
                        { popUpHeader && <h4 className="mb-3">{popUpHeader}</h4> }
                        { popUpMessage && <p>{popUpMessage}</p> }
                        {
                            !imageSrc ? <>
                                <label className="uploadImageWrap mb-0 d-block" >
                                    <input type="file" accept="image/*" onChange={onHandleImageSelect}/>
                                    <div className="inner">
                                        <img
                                        src="../assets/images/icons/gallery.svg"
                                        alt="gallery"
                                        />
                                        <h5>Click here to upload your photo</h5>
                                        {/* <label className="_link mb-0 mt-5">
                                            Upload from your device
                                        </label> */}
                                    </div>
                                </label>
                            </> : <>
                            <div className="mb-1">
                                <Cropper
                                    ref={cropperRef}
                                    style={{ height: 400, width: "100%" }}
                                    aspectRatio={aspectRatio}
                                    preview=".img-preview"
                                    src={imageSrc}
                                    viewMode={1}
                                    minCropBoxHeight={minCropBoxHeight}
                                    minCropBoxWidth={minCropBoxWidth}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    guides={true}
                                />
                            </div>
                            <button
                                type="button"
                                className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                                onClick={onCropSubmit}
                            >
                                Submit
                            </button>                                
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default ImageCropperUploadModal;
