import React, { FC } from "react";
import "./product.scss";
import { Outlet } from "react-router";

interface ProductProps {}

const Product: FC<ProductProps> = () => {
  return (
    <div className="productPage">
      <Outlet></Outlet>
    </div>
  );
};

export default Product;
