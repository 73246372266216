import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import "./link-tee-time-component.scss";

import { INTEGOLF_CLUB_TYPE_CHRONOGOLF, INTEGOLF_CLUB_TYPE_TEEON, INTEGOLF_CLUB_TYPE_INTEGOLF, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED, LINK_TEE_TIME_SYSTEM_STEP_FIRST, LINK_TEE_TIME_SYSTEM_STEP_SECOND, LINK_TEE_TIME_SYSTEM_STEP_THIRD, LINK_TEE_TIME_SYSTEM_STEP_FOURTH, CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP, SAGA_STATUS_INIT, COURSE_SYNC_STATUS_SUCCESS_NAME } from "../../../../utils/constants";
import { CoursesPayload } from "../../../../models/courses";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { coursesActions, fetchUpdateCourseDataStatus, fetchTestCourseTeesheetFetchStatus, fetchChronogolfCoursesRates, getFetchChronogolfCoursesRatesStatus } from "../../../../reducers/coursesSlice";
import { clubsActions, fetchUpdateClubStatus } from "../../../../reducers/clubsSlice";
import InfoModal from "../../../../shared/info-modal/info-model";
import { ClubPayload, IChronogolfCourseRates } from "../../../../models/clubs";
import LinkTeeTimeTeeonFormComponent from "./link-tee-time-teeon-form-component";

const chronoGolfWebsiteUrl = "https://www.chronogolf.com/";
const chronoGolfSyncTeeSheetStepMessage = 'Once you get the acknowledgement from chronogolf after performing the steps mentioned in the window (It may take 1-2 business days). Click on "Sync Tee Sheets" button down below to allow integolf to fetch your tee times.';
const teeonSyncTeeSheetStepMessage = 'Once your application is approved by teeon you can click on "Sync Tee Sheets" button below to allow integolf to fetch your tee times.';
const getSyncErrorModalMessage = (clubType: string) => `Looks like course tee sheet sync is not yet available for your account. Please contact ${clubType} support to check your status to verify.`;
const syncSuccessModalMessage = "Your course tee sheet is configured successfuly. Update the pending details and publish the course to start receiving new bookings.";
const DISCOUNTED_PORTAL_KEY = 'discountedPortal';
const TOURISM_PORTAL_KEY = 'tourismPortal';


interface ILinkTeeTimeComponentProps {
    clubObject: ClubPayload
    courseInfo: CoursesPayload;
    onNextClick: Function;
}

interface ISyncTeeSheetStep {
    disabled: boolean;
}

const LinkTeeTimeComponent: FC<ILinkTeeTimeComponentProps> = ({
    clubObject,
    courseInfo,
    onNextClick = () => console.log('On next clicked')
}) => {
    
    const [searchParams] = useSearchParams();
    // if edit step is passed then hide next button
    const editStep = searchParams.get("step");

    const { type, teeSheetSystemStep = LINK_TEE_TIME_SYSTEM_STEP_FIRST, syncStatus } = courseInfo;
    const { name, discountedPortalRate, tourismPortalRate } = clubObject;

    const disableStep2 = teeSheetSystemStep < LINK_TEE_TIME_SYSTEM_STEP_SECOND;
    const disableStep3 = teeSheetSystemStep < LINK_TEE_TIME_SYSTEM_STEP_THIRD;
    const disableStep4 = teeSheetSystemStep < LINK_TEE_TIME_SYSTEM_STEP_FOURTH;

    const dispatch = useAppDispatch();
    const [ discountedPortalRateCode, setDiscountedPortalRateCode ] = useState("");
    const [ tourismPortalRateCode, setTourismPortalRateCode ] = useState("");
    const [ rateErrorMessage, setRateErrorMessage ] = useState("");

    const courseUpdateStatus: string = useAppSelector(fetchUpdateCourseDataStatus);
    const clubObjectUpdateStatus: string = useAppSelector(fetchUpdateClubStatus);
    const testCourseTeesheetFetchStatus: string = useAppSelector(fetchTestCourseTeesheetFetchStatus);
    const chronogolfCoursesRates: Array<IChronogolfCourseRates> = useAppSelector(fetchChronogolfCoursesRates);
    const fetchChronogolfCoursesRatesStatus: string = useAppSelector(getFetchChronogolfCoursesRatesStatus);

    const onSyncClick = () => {

        const syncStart = parseInt(moment().startOf('day').format('x')); // check tee time fetch for 1 day to test

        const syncCourseData = { 
            courseId: courseInfo._id,
            clubId: clubObject._id,
            from: syncStart,
            to: syncStart 
        };
        dispatch(coursesActions.testCourseTeesheetFetch(syncCourseData));
    }
    
    const onCourseUpdate = (courseUpdatePayload: any) => {

        // update club active step 
        if (clubObject && clubObject?._id) {
            const updatePayload: any = {
                _id: clubObject._id,
                clubEditStep: CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP
            };
            dispatch(clubsActions.updateClubData(updatePayload));
        }

        courseUpdatePayload["_id"] = courseInfo._id;
        dispatch(coursesActions.updateCourseData(courseUpdatePayload));
    }

    const onTeeTimeSystemSelect = (e: any) => {
        if (e?.target?.value) {
            onCourseUpdate({type: e.target.value, teeSheetSystemStep: LINK_TEE_TIME_SYSTEM_STEP_SECOND});
        }   
    }

    const onPortalRateChange = (e: any, portalKey: string) => {

        const rateVal = e?.target?.value || "";
        setRateErrorMessage("");
        if (portalKey === DISCOUNTED_PORTAL_KEY) {
            setDiscountedPortalRateCode(rateVal);
        } else {
            setTourismPortalRateCode(rateVal);
        }
        
    }

    const onRateSubmit = () => {

        if (!!discountedPortalRateCode && !!tourismPortalRateCode) {
            
            // reset error message
            setRateErrorMessage("");

            const discountedPortalRateObject = chronogolfCoursesRates.find(({ rateCode }) => rateCode === discountedPortalRateCode);
            const tourismPortalRateObject = chronogolfCoursesRates.find(({ rateCode }) => rateCode === tourismPortalRateCode);

            const clubPayloadObject = {
                _id: clubObject._id,
                ...(discountedPortalRateObject ? {
                    discountedPortalRate: {
                        rateCode : discountedPortalRateObject.rateCode,
                        rateName : discountedPortalRateObject.rateName
                    }     
                }: {}),
                ...(tourismPortalRateObject ? {
                    tourismPortalRate: {
                        rateCode : tourismPortalRateObject.rateCode,
                        rateName : tourismPortalRateObject.rateName
                    }
                }: {})
            };
            dispatch(clubsActions.updateClubData(clubPayloadObject));
            onCourseUpdate({teeSheetSystemStep: LINK_TEE_TIME_SYSTEM_STEP_FOURTH});
        } else {
            const missingVal = [];

            if (discountedPortalRateCode.length === 0) {
                missingVal.push('Discounted Portal Rate');
            }

            if (tourismPortalRateCode.length === 0) {
                missingVal.push('Tourism Portal Rate');
            }

            const errorMessage = `Please select ${missingVal.join(' & ')} values before confirming the rate(s).`;
            setRateErrorMessage(errorMessage);
        }

    }

    const onChronogolfLinkClick = () => {
        // set tee sheet system step first to set to 4 so that teesheet sync is allowed alongside rates
        // once sync is done then allow user to set the rate in step 3 for choronogolf
        // check ticket IN-484 for description
        onCourseUpdate({teeSheetSystemStep: LINK_TEE_TIME_SYSTEM_STEP_FOURTH});
        window.open(chronoGolfWebsiteUrl, '_blank');
    }

    const resetTestCourseTeesheetFetchStatus = () => dispatch(coursesActions.resetTestCourseTeesheetFetchStatus());
    const onCourseSyncSuccess = () => {
        resetTestCourseTeesheetFetchStatus();
        onNextClick();
    }
    
    const onTeeonFormCompletion = (formData: any) => {
        // when tee on form submission is completed update the step
        onCourseUpdate({teeSheetSystemStep: LINK_TEE_TIME_SYSTEM_STEP_THIRD});
    }

    useEffect(() => {
        if (courseUpdateStatus == SAGA_STATUS_SUCCESS) {
            dispatch(coursesActions.resetUpdateCourseDataStatus());
        }
    }, [courseUpdateStatus]);

    useEffect(() => {
        if (fetchChronogolfCoursesRatesStatus == SAGA_STATUS_FAILED) {
            setRateErrorMessage("Rates will be fetched once the course is published and the sync process is completed successfully.");
        } else {
            setRateErrorMessage("");
        }
    }, [fetchChronogolfCoursesRatesStatus])

    useEffect(() => {
        if (
            teeSheetSystemStep >= LINK_TEE_TIME_SYSTEM_STEP_THIRD && 
            type === INTEGOLF_CLUB_TYPE_CHRONOGOLF
        ) {
            dispatch(coursesActions.fetchChronogolfCoursesRates({ clubObjectId: clubObject._id }));   
        }
    }, [teeSheetSystemStep, type]);

    useEffect(() => {

        if (clubObject && clubObject?.discountedPortalRate?.rateCode) {
            setDiscountedPortalRateCode(clubObject?.discountedPortalRate?.rateCode);   
        }

        if (clubObject && clubObject?.tourismPortalRate?.rateCode) {
            setTourismPortalRateCode(clubObject?.tourismPortalRate?.rateCode);
        }

        return () => {
            dispatch(coursesActions.resetFetchChronogolfCoursesRates());
        };
    }, [])

    const SyncTeeSheetStepView: FC<ISyncTeeSheetStep> = ({
        disabled
    }) => <>
        <h4>Start Tee sheet sync</h4>
        {
            type === INTEGOLF_CLUB_TYPE_CHRONOGOLF && <p>{chronoGolfSyncTeeSheetStepMessage}</p>
        }
        {
            type === INTEGOLF_CLUB_TYPE_TEEON && <p>{teeonSyncTeeSheetStepMessage}</p>
        }
        <div className="row mt-4">
            <div className="col-4">
                <button
                    type="button"
                    className="button button-primary button-rounded fw700 px-4 fontsize-14"
                    disabled={disabled}
                    onClick={() => onSyncClick()}
                >
                Sync Tee Sheets
                </button>
            </div>
            {
                !!!editStep && <div className="col-4">
                    <button
                        className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                        onClick={() => onNextClick()}
                    >
                        Next
                    </button>
                </div>
            }
        </div>
    </>;

    return (
        <>
            <div className="authSetupWrapper profileWrapper">
                <div className="container">
                    <div className="authSetupContainer">
                        <h3 className="profileWrapper__title mb-4">Authenticate Setups</h3>
                        <div className="stepWrap">
                            <div className="stepBlock">
                                <span className="_stepCheck _stepCheckGreen">&nbsp;</span>
                                <h6>Step 1</h6>
                                <h4>Select Tee Time booking system</h4>
                                <p>
                                Your authentication is currently pending for this step. Please select the tee time booking system of your choice by using the button below and follow the subsequent steps to complete the authentication process.
                                </p>
                                <div className="row justify-content-between mt-4">
                                    <div className="col-auto">
                                        <div className="formSelect">
                                            <select className="form-select" onChange={onTeeTimeSystemSelect} defaultValue={type}>
                                                <option disabled hidden selected>Select Tee Time System</option>
                                                <option value={`${INTEGOLF_CLUB_TYPE_CHRONOGOLF}`} >Chronogolf</option>
                                                <option value={`${INTEGOLF_CLUB_TYPE_TEEON}`}>TeeOn</option>
                                                <option value={`${INTEGOLF_CLUB_TYPE_INTEGOLF}`} disabled>Integolf</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                teeSheetSystemStep >= LINK_TEE_TIME_SYSTEM_STEP_SECOND && <div className={`stepBlock ${disableStep2 ? 'faded': ''}`}>
                                    <span className={`_stepCheck ${disableStep2 ?'_stepCheckGray': '_stepCheckGreen'}`}>&nbsp;</span>
                                    <h6>Step 2</h6>
                                    {
                                        type === INTEGOLF_CLUB_TYPE_CHRONOGOLF ? <>
                                            <h4>Enable Chronogolf Access</h4>
                                            <p>
                                                Your authentication is currently pending for this step. Go to { disableStep2 ? 'Chronogolf' : <a onClick={onChronogolfLinkClick} href="#">Chronogolf</a> } web page and follow the steps mentioned in the tutorial video to enable integolf to get your tee times. 
                                            </p>
                                            <div className="row mt-4">
                                                <div className="col-12">
                                                    <video width="500" height="250" controls>
                                                        <source src="./assets/video/chronogolf-how-to-video.mp4" type="video/mp4"/>
                                                    </video>
                                                </div>
                                            </div>
                                        </> : <>
                                            {
                                                type === INTEGOLF_CLUB_TYPE_TEEON ? <LinkTeeTimeTeeonFormComponent 
                                                    clubObject={clubObject}
                                                    courseInfo={courseInfo}
                                                    onFormCompletion={onTeeonFormCompletion}
                                                /> : <></>
                                            }
                                        </>
                                    }
                                </div> 
                            }
                            {
                                teeSheetSystemStep >= LINK_TEE_TIME_SYSTEM_STEP_THIRD && <div className={`stepBlock ${ disableStep3 ? 'faded' :''}`} >
                                    <span className={`_stepCheck ${ disableStep3 ?'_stepCheckGray': '_stepCheckGreen'}`}>&nbsp;</span>
                                    <h6>Step 3</h6>
                                    {
                                        type === INTEGOLF_CLUB_TYPE_CHRONOGOLF ? <>
                                            <h4>Choose Rates</h4>
                                            <p>
                                                Select the rates you want us to use for tourism & discounted booking portals. Once your golf course is synced with Chronogolf you will be able to view and set the specific rates you wish to offer.
                                            </p>
                                            <>
                                                    <div className="row justify-content-between mt-2 pt-2 pb-2">
                                                        <h5>Discounted Portal</h5>
                                                        <div className="col-8">
                                                            <div className="formSelect" style={{width: '100%'}}>
                                                                <select className="form-select" disabled={(disableStep3 || chronogolfCoursesRates?.length === 0)} defaultValue="" value={discountedPortalRateCode} onChange={e => onPortalRateChange(e, DISCOUNTED_PORTAL_KEY)}>
                                                                    <option value="" disabled>Select discounted portal rate</option>
                                                                    { chronogolfCoursesRates?.map(({ rateCode, rateName, _id }) => (
                                                                        <option key={_id} value={rateCode}>
                                                                            {rateName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>    
                                                    </div>
                                                    <div className="row justify-content-between mt-2 pt-2 pb-2">
                                                        <div className="col-8">
                                                            <h5>Tour Portal</h5>
                                                            <div className="formSelect" style={{width: '100%'}}>
                                                                <select className="form-select" disabled={(disableStep3|| chronogolfCoursesRates?.length === 0)} defaultValue="" value={tourismPortalRateCode} onChange={e => onPortalRateChange(e, TOURISM_PORTAL_KEY)}>
                                                                    <option value="" disabled>Select tour portal rate</option>
                                                                    { chronogolfCoursesRates?.map(({ rateCode, rateName, _id }) => (
                                                                        <option key={_id} value={rateCode}>
                                                                            {rateName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        (rateErrorMessage.length > 1) && <div className="isInvalidMessage text-danger mt-2">
                                                            {rateErrorMessage}
                                                        </div>
                                                    }
                                                    <button
                                                        className="button button-primary button-rounded button-min-120 fw600 fontsize-16 mt-2"
                                                        disabled={(disableStep3 || chronogolfCoursesRates?.length === 0)}
                                                        onClick={onRateSubmit}
                                                    >
                                                        Confirm Rates
                                                    </button>
                                                </>
                                        </> : <SyncTeeSheetStepView disabled={disableStep3} />
                                    }
                                </div>
                            }
                            {
                                type === INTEGOLF_CLUB_TYPE_CHRONOGOLF && teeSheetSystemStep >= LINK_TEE_TIME_SYSTEM_STEP_FOURTH && <div className={`stepBlock ${ disableStep4 ? 'faded' :''}`} >
                                    <span className={`_stepCheck ${ disableStep4 ?'_stepCheckGray': '_stepCheckGreen'}`}>&nbsp;</span>
                                    <h6>Step 4</h6>
                                    <SyncTeeSheetStepView disabled={disableStep4}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*Use delete modal to print sync status error message*/}
            <InfoModal 
                showModal={testCourseTeesheetFetchStatus === SAGA_STATUS_FAILED}
                modalImagePath="../assets/images/spedometer-low.png"
                message={getSyncErrorModalMessage(type||'')}
                buttonText="Close"
                onCloseClick={resetTestCourseTeesheetFetchStatus}
                onModalButtonClick={resetTestCourseTeesheetFetchStatus}
            />
            <InfoModal 
                showModal={testCourseTeesheetFetchStatus === SAGA_STATUS_SUCCESS}
                modalImagePath="../assets/images/info-circle.png"
                message={syncSuccessModalMessage}
                buttonText="Okay"
                onCloseClick={resetTestCourseTeesheetFetchStatus}
                onModalButtonClick={onCourseSyncSuccess}
            />
        </>
    );
};

export default LinkTeeTimeComponent;
