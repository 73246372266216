import React from "react";
import { Link } from "react-router-dom";

const LinkExpired = () => {
  return (
    <div className="page404Wrapper">
      <div className="page404Content text-center">
        <em
            style={{fontSize: '7.5em'}}
            className="fa-solid fa-link-slash mx-auto mb-5"
        />
        <h3 className="mb-4">Oops! The link expired</h3>
        <Link to="/" className="fontsize-16 fw700 color-active">Back To Home</Link>
      </div>
    </div>
  );
};

export default LinkExpired;
