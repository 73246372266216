import React, { FC, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { useGoogleLogin } from '@react-oauth/google';

import "./profile.scss";
import { useAppSelector } from "../../../../app/hooks";
import { currentUser } from "../../../../reducers/authSlice";
import ProfileImageCropperUploadModal from "../profile-image-cropper-modal/profile-image-cropper-modal";
import UserProfileDetails from "../user-profile-details/user-profile-details";
import { PayoutAccountDetails } from "../payout-account-details/payout-account-details";

const profileDefaultImage = "../assets/images/default-user.png";
const TAB_NAME_PROFILE_SETTINGS = "profileSettings";
const TAB_NAME_PAYOUT_SETTINGS = "payoutSettings";

interface ProfileProps {}

const Profile: FC<ProfileProps> = () => {
  //
  const navigate = useNavigate();
  const { hash } = useLocation();
  let [ _, defaultTab ] = hash.split('#');
  if (![TAB_NAME_PROFILE_SETTINGS,TAB_NAME_PAYOUT_SETTINGS].includes(defaultTab)) {
    defaultTab = TAB_NAME_PROFILE_SETTINGS;
  }

  const [showImageCropperUploadModal, setShowImageCropperUploadModal ] = useState(false);

  const userProfile: any = useAppSelector(currentUser);
  
  return (
    <>
      <div className="profileWrapper">
        <section className="commonHeader">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-auto">
                <a className="button button-s3 button-border button-circle button-sm" onClick={() => navigate(-1)}>
                  <em className="fas fa-arrow-left"></em>
                </a>
              </div>
              <div className="col-12 col-md">
                <h2>My Profile</h2>
              </div>
            </div>
          </div>
        </section>
        <div className="py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row">
                  <div className="col-12 col-md-3 col-xl-4">
                    <div className="profileWrapper__meta">
                      <span className="imgBlock mb-3">
                        <img
                          src={(userProfile?.profileImage?.path || profileDefaultImage)}
                          alt=""
                          className="img-fluid"
                        />
                      </span>
                      <h2 className="mb-3">{userProfile.firstname} {(userProfile.lastname||"")} </h2>
                      <h6 className="mb-4">{userProfile.email} </h6>
                      <button
                        type="button"
                        className="button button-s3 button-pill button-small button-border px-3 fontsize-12 fw600"
                        onClick={() => setShowImageCropperUploadModal(true)}
                      >
                        Upload Image
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-9 col-xl-8">
                  <Tabs defaultActiveKey={defaultTab} id="justify-tab-example" className="mb-3" justify>
                    <Tab eventKey={TAB_NAME_PROFILE_SETTINGS} title="Basic Details">
                      <UserProfileDetails/>
                    </Tab>
                    <Tab eventKey={TAB_NAME_PAYOUT_SETTINGS} title="Account Details">
                      <PayoutAccountDetails/>
                    </Tab>
                  </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileImageCropperUploadModal 
          isModalVisible={showImageCropperUploadModal}
          handleClose= {()=> setShowImageCropperUploadModal(false)}
      />
    </>
  );
};

export default Profile;
