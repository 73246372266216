import { RootState } from './../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ClubsState {
  fetchCalendarDataStatus: string,
  calendarData: any
}

const initialState: ClubsState = {
    fetchCalendarDataStatus: "",
    calendarData: []
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    fetchCalendarData(state, action: PayloadAction<any>) {
      state.fetchCalendarDataStatus = "pending";
    },
    fetchCalendarSuccess(state, action: PayloadAction<any>) {
      state.calendarData = action.payload;
      state.fetchCalendarDataStatus = "success";
    },
    fetchCalendarFailed(state, action: PayloadAction<string>) {
      state.fetchCalendarDataStatus = "failed";
    }
  },
});

// Actions
export const calendarActions = calendarSlice.actions;

// Selectors
export const fetchCalendarData = (state: RootState) => state.calendar.calendarData;


// Reducer
const calendarReducer = calendarSlice.reducer;
export default calendarReducer;
