import React, { FC } from "react";
import "./integolf-loader.scss";

interface IntegolfLoaderProps {
  show: boolean;
}

const IntegolfLoader: FC<IntegolfLoaderProps> = ({ show = false }) => {
  return show ? (
    <>
      <div id="fullPageLoader" style={{ display: "block" }}>
        <div className="loaderInnder">
          <img src={`${process.env.PUBLIC_URL}/assets/images/Loader.png`} alt="loading.." />
          {/* <span>Loading..</span> */}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default IntegolfLoader;
