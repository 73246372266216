import { RootState } from '../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface accountStorageState {
  showSpinner: boolean;
  showCourseAddPopup: boolean;
}

const initialState: accountStorageState = {
    showSpinner: false,
    showCourseAddPopup: true,
};

const accountStorageSlice = createSlice({
  name: 'accountStorage',
  initialState,
  reducers: {
    showHideSpinner(state, action: PayloadAction<any>) {
      state.showSpinner = action.payload;
    },
    showHideCourseAddPopup(state, action: PayloadAction<any>) {
      state.showCourseAddPopup = action.payload;
    },
    resetAccountStorageinfo(state) {
      state.showSpinner = false;
      state.showCourseAddPopup = true;
    }
  }
});

// Actions
export const accountStorageActions = accountStorageSlice.actions;

// Selectors
export const fetchAccountStorageSpinnerState = (state: RootState) => state.accountStorage.showSpinner;
export const fetchShowCourseAddPopUp = (state: RootState) => state.accountStorage.showCourseAddPopup;

// Reducer
const accountStorageReducer = accountStorageSlice.reducer;
export default accountStorageReducer;
