import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { INTEGOLF_MERCHANT_ALLOWED_PAYOUT_INTERVALS, PAYOUT_INTERVAL_TO_LABEL_MAPPING } from "../../../../utils/constants";
import UpdateInfoModal from "../update-info-modal/update-info-modal";
import UpdatePhoneModal from "../change-phone-modal/update-phone-modal";
import DeleteModal from "../../../../shared/delete-modal/delete-modal";
import { authActions, currentUser, getDeleteUserProfileStatus } from "../../../../reducers/authSlice";

interface UserProfileDetailsProps {}

const UserProfileDetails: FC<UserProfileDetailsProps> = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userProfile: any = useAppSelector(currentUser);
  
    const userProfileDeleteStatus = useAppSelector(getDeleteUserProfileStatus);

    const [showUpdateInfoPopup, setShowUpdateInfoPopup] = useState(false);
    const [showUpdatePhonePopup, setShowUpdatePhonePopup] = useState(false);
    const [showDeleteUserPopup, setShowDeleteUserPopup] = useState(false);
    const [ userFirstName = "", setUserFirstName ] = useState<string|null>("");
    const [ userLastName = "", setUserLastName ] = useState<string|null>("");
    const [ userSecondaryEmail = "", setUserSecondaryEmail ] = useState<string|null>("");
    const [ updateEmailPayloadKey, setUpdatedEmailPayloadKey ] = useState("");

    const onProfileFirstNameBlur = () => {
        if ( userFirstName != userProfile.firstname) {
            const userDataUpdatePayload = { firstname: userFirstName };
            updateUserProfileData(userDataUpdatePayload);
        }
    };
    
    const onProfileLastNameBlur = () => {
        if (userLastName != userProfile.lastname) {
            const userDataUpdatePayload = { lastname: userLastName };
            updateUserProfileData(userDataUpdatePayload);
        }
    }
    
    const showPhoneUpdateModal = (_: any) => {
        dispatch(authActions.resetUserUpdateStatus());
        dispatch(authActions.resetUserVerifyOtpStatus());
        setShowUpdatePhonePopup(true);
    }
    
    const onAddUpdateEmail = (updatePayloadKey:string) => {
        setUpdatedEmailPayloadKey(updatePayloadKey);
        dispatch(authActions.resetUserUpdateStatus());
        dispatch(authActions.resetUserVerifyOtpStatus());
        setShowUpdateInfoPopup(true);
    }
    
    const onUserDelete = () => {
        setShowDeleteUserPopup(false);
        dispatch(authActions.userProfileDelete());
    }

    const closePersonalInfoPopup = (value: any) => { 
        setShowUpdateInfoPopup(value);
        setUpdatedEmailPayloadKey("");
    };

    const updateUserProfileData = (updatePayload: any) => {
        updatePayload["_id"] = userProfile._id;
        dispatch(authActions.updateProfileData(updatePayload));
        dispatch(authActions.resetUserUpdateStatus());
    }

    useEffect(() => {
        const { firstname, lastname, secondaryEmail } = userProfile;
        setUserFirstName(firstname);
        setUserLastName(lastname);
        setUserSecondaryEmail(secondaryEmail);
    },[userProfile]);

    useEffect(() => {
        if (userProfileDeleteStatus == "success") {
            dispatch(authActions.logoutSuccess());
            navigate("/");
        }
    }, [userProfileDeleteStatus])

    return <>
        <div className="row">
            <div className="profileWrapper__section">
                <h3 className="profileWrapper__title mb-4">
                    Basic Details
                </h3>
                <div className="row gutters-10 pb-4">
                    <div className="col-12 col-sm mb-4">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="First Name"
                                value={(userFirstName||"")}
                                onChange={(e) => setUserFirstName(e.target.value)}
                                onBlur={onProfileFirstNameBlur}
                            />
                            <label htmlFor="firstName">First Name</label>
                        </div>
                    </div>
                    <div className="col-12 col-sm mb-4">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Last Name"
                                value={(userLastName||"")}
                                onChange={(e) => setUserLastName(e.target.value)}
                                onBlur={onProfileLastNameBlur}
                            />
                            <label htmlFor="lastName">Last Name</label>
                        </div>
                    </div>
                </div>
                {
                    (userProfile?.email || userProfile?.changeEmail) ? <div className="formField mb-0">
                        <label className="_label">Email Address</label>
                        <div className="row gutters-10">
                            <div className="col">
                                <h6>{ userProfile?.changeEmail ? userProfile.changeEmail : userProfile?.email}</h6>
                            </div>
                            <div className="col-auto">
                            {
                                userProfile?.changeEmail ? <span className="verified text-danger">
                                    Unverified
                                </span> : <span className="verified fw700">
                                <img
                                    src="../assets/images/icons/verify.svg"
                                    height={18}
                                    width={18}
                                    alt=""
                                />{" "}
                                    Verified
                                </span>
                            }
                            </div>
                            <div className="col-auto">
                                <a
                                    href={void 0}
                                    className="color-active fw700 text-decoration-underline"
                                    onClick={() => onAddUpdateEmail('changeEmail')}
                                >
                                    Update
                                </a>
                            </div>
                        </div>
                    </div> : <div className="profileWrapper__section">
                        <div className="row align-items-center">
                            <div className="col-12 col-md">
                                <h3 className="profileWrapper__title mb-3">
                                    Email
                                </h3>
                                <p className="color-600 fw500 mb-0">
                                    This email can be used to as a login identification.
                                    Notifications are also sent to this
                                    email.
                                </p>
                            </div>
                            <div className="col-12 col-md-auto">
                                <button
                                    type="button"
                                    className="button button-secondary button-rounded button-border button-min-160 fw700 fontsize-12 my-2"
                                    onClick={() => onAddUpdateEmail('changeEmail')}
                                >
                                    Add Email
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                <div className="profileWrapper__section">
                    <div className="row align-items-center">
                        <div className="col-12 col-md">
                            <h3 className="profileWrapper__title mb-3">
                                Phone
                            </h3>
                            {   userProfile?.phone?.number ? <p className="color-600 fw500 mb-0">
                                    +{userProfile?.phone?.countryCode}-{userProfile?.phone?.number}
                                </p> : <p className="color-600 fw500 mb-0">
                                    This phone number can be used to as a login identification.
                                    Notifications are also sent to this
                                    phone via sms.
                                </p>
                            }
                        </div>
                        <div className="col-12 col-md-auto">
                            <button
                                type="button"
                                className="button button-secondary button-rounded button-border button-min-160 fw700 fontsize-12 my-2"
                                onClick={showPhoneUpdateModal}
                            >
                                {userProfile?.phone?.number ? "Update Phone" : "Add Phone"}
                            </button>
                        </div>
                    </div>
                </div>
            }
            {/* <div className="profileWrapper__section">
                <h3 className="profileWrapper__title mb-3">
                    Login Services
                </h3>
                <p className="color-600 fw500">
                    Connect an external login services to quickly and
                    securely login.
                </p>
                <div className="row">
                    <div className="col-auto">
                        <button
                            type="button"
                            className="button button-block button-border button-rounded mt-3 button-social"
                            onClick={() => googleLogin()}
                            disabled={!!userProfile.googleId}
                        >
                            <span className="icon">
                            <img
                                src="../assets/images/icons/google.svg"
                                alt="facebook"
                                height={18}
                            />
                            </span>{" "}
                            Continue with Google
                        </button>
                    </div>
                </div>
            </div> 
            <div className="profileWrapper__section">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h3 className="profileWrapper__title mb-3">
                            Login Services
                        </h3>
                        <p className="color-600 fw500 mb-0">
                            Last password changed 45 days ago.
                        </p>
                    </div>
                    <div className="col-12 col-md-auto">
                        <button
                            type="button"
                            className="button button-secondary button-rounded button-border button-min-160 fw700 fontsize-12 my-2"
                            onClick={() => setShowUpdatePhonePopup(true)}
                        >
                            Change Password
                        </button>
                    </div>
                </div>
            </div>*/}
            {   
                userProfile?.email && <div className="profileWrapper__section">
                    <div className="row align-items-center">
                        <div className="col-12 col-md">
                            <h3 className="profileWrapper__title mb-3">
                                Secondary Email
                            </h3>
                            {
                                userProfile?.secondaryEmail ? <p className="color-600 fw500 mb-0">
                                    {userProfile?.secondaryEmail}
                                </p> : <p className="color-600 fw500 mb-0">
                                    A secondary email can be used to restore access to
                                    your account. Notifications are also sent to this
                                    email.
                                </p>
                            }
                        </div>
                        <div className="col-12 col-md-auto">
                            <button
                                type="button"
                                className="button button-secondary button-rounded button-border button-min-160 fw700 fontsize-12 my-2"
                                onClick={() => onAddUpdateEmail('secondaryEmail')}
                            >
                                { userProfile?.secondaryEmail ? "Update Email" : "Add Email"}
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div className="profileWrapper__section">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                    <h3 className="profileWrapper__title mb-3">
                        Remove Account
                    </h3>
                    <p className="color-600 fw500 mb-0">
                        Your account will be removed with all the active course and clubs
                        will be de-activated.
                    </p>
                    </div>
                    <div className="col-12 col-md-auto">
                    <button
                        type="button"
                        className="button bg-danger text-white button-rounded button-border button-min-160 fw700 fontsize-12 my-2"
                        onClick={() => setShowDeleteUserPopup(true)}
                    >
                        Delete
                    </button>
                    </div>
                </div>
            </div>
        </div>
        <UpdateInfoModal
            hasModalVisible={showUpdateInfoPopup}
            handleClose={closePersonalInfoPopup}
            emailPayloadKey={updateEmailPayloadKey}
        />
        <UpdatePhoneModal 
            isModalVisible={showUpdatePhonePopup} 
            handleClose= {()=>setShowUpdatePhonePopup(false)}
            userProfile = {userProfile}
        />
        <DeleteModal
            message="Are you sure ,You want to delete your account?"
            buttonText="Yes, Delete my account."
            showModal={showDeleteUserPopup}
            onCloseClick={() => setShowDeleteUserPopup(false)}
            onDeleteClick={onUserDelete}
        />
    </>;
}

export default UserProfileDetails;