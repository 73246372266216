import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { discountActions } from '../reducers/discountsSlice';
import { accountStorageActions } from '../reducers/accountStorageSlice';
import httpClient from "../api/merchantApi/httpClient";

function* handleFetchClubDiscountProfile(action: PayloadAction<string>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "get",
    url: `/discountProfile?courseId=${action.payload}&includeDiscounts=true`,
  });

  if (error) {
    yield put(discountActions.fetchDiscountProfileFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(discountActions.fetchDiscountProfileSuccess(result.response))
  }
  return { error, result };
}

function* handleAddDiscountProfile(action: PayloadAction<any>) {
  
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/discountProfile`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(discountActions.addDiscountProfileFailed(error.message)); // Dispatch action
    toast.error(error?.message || error);
  } else {
    yield put(discountActions.addDiscountProfileSuccess(result.response))
  }
  return { error, result };
}

function* handleUpdateDiscountProfile(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/discountProfile`,
  });

  if (error) {
    yield put(discountActions.updateDiscountProfileFailed(error.message)); // Dispatch action
    toast.error(error?.message || error);
  } else {
    yield put(discountActions.updateDiscountLevelSuccess(result.response))
  }
  return { error, result };
}

function* handleDeletedDiscountProfile(action: PayloadAction<string>) {
  const { result, error, headers } = yield call(httpClient, {
    data: {_id: action.payload},
    method: "delete",
    url: `/discountProfile`,
  });

  if (error) {
    yield put(discountActions.deleteDiscountProfileFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(discountActions.deleteDiscountProfileSuccess(result.response))
  }
  return { error, result };
}

function* handleAddDiscountLevel(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/discount`,
  });

  if (error) {
    yield put(discountActions.addDiscountLevelFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    result.response.discountProfileId = action.payload.discountProfileId
    yield put(discountActions.addDiscountLevelSuccess(result.response))
  }
  return { error, result };
}

function* handleUpdateDiscountLevel(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/discount`,
  });

  if (error) {
    yield put(discountActions.updateDiscountLevelFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(discountActions.updateDiscountLevelSuccess(result.response))
  }
  return { error, result };
}

function* handleAddRecommendations(action: PayloadAction<any>) {
  
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: {discountProfileId: action.payload},
    method: "post",
    url: `/discountRecommended`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(discountActions.addRecommendationsFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(discountActions.addRecommendationsSuccess(result.response))
  }
  return { error, result };
}

function* handleDeleteDiscountLevel(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    data: { _id: action.payload },
    method: "delete",
    url: `/discount`,
  });

  if (error) {
    yield put(discountActions.deleteDiscountLevelFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(discountActions.deleteDiscountLevelSuccess(result.response))
  }
  return { error, result };
}


export function* discountSaga() {
  yield takeLatest(discountActions.fetchDiscountProfile.type, handleFetchClubDiscountProfile);
  yield takeLatest(discountActions.addDiscountProfile.type, handleAddDiscountProfile);
  yield takeLatest(discountActions.deleteDiscountProfile.type, handleDeletedDiscountProfile);
  yield takeLatest(discountActions.addDiscountLevel.type, handleAddDiscountLevel);
  yield takeLatest(discountActions.addRecommendations.type, handleAddRecommendations);
  yield takeLatest(discountActions.deleteDiscountLevel.type, handleDeleteDiscountLevel);
  yield takeLatest(discountActions.updateDiscountLevel.type, handleUpdateDiscountLevel);
  yield takeLatest(discountActions.updateDiscountProfile.type, handleUpdateDiscountProfile)
};

