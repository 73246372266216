import React, { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface DeleteModalProps {
  message ?: string,
  buttonText ?: string,
  showModal: boolean;
  onCloseClick : (arg: any) => void;
  onDeleteClick: (arg: any) => void;
}

const DeleteModal: FC<DeleteModalProps> = ({
    message = "Are you sure, you want to delete?",
    buttonText = "Yes, please delete.",
    showModal = false,
    onCloseClick,
    onDeleteClick
}) => {
    return <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
    >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={onCloseClick}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            <div className="text-center">
              <img
                src="../assets/images/spedometer-low.png"
                alt="deactivate"
                className="mb-4 d-inline-block"
              />
              <h3 className="mb-4">
                {message}
              </h3>
              <a
                href={void 0}
                className="text-decoration-underline fw600 color-danger"
                onClick={onDeleteClick}
              >
                {buttonText}
              </a>
            </div>
          </div>  
      </Modal.Body>
  </Modal>;
};

export default DeleteModal;
