import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import httpClient from "../../api/bookingApi/httpClient";
import { toast } from "react-toastify";
import { bookingActions } from './bookingSlice';

function* handleListBookings(action: PayloadAction<any>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/bookings${action.payload}`,
  });

  if (error) {
    console.log('erroe is', error)
    yield put(bookingActions.bookingListFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(bookingActions.bookingListSuccess(result.payload))
  }
  return { error, result };
}

function* handleBookingDetail(action: PayloadAction<string>) {

  const { result, error } = yield call(httpClient, {
    method: "get",
    url: `/bookings/${action.payload}`
  });

  if (error) {
    yield put(bookingActions.bookingDetailFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(bookingActions.bookingDetailSuccess(result.payload))
  }
  return { error, result };
}

function* handleCancelBooking(action: PayloadAction<any>) {

  const { result, error } = yield call(httpClient, {
    method: "put",
    url: `/bookings/cancel/${action.payload.bookingId}`,
    data: action.payload.data
  });

  if (error) {
    yield put(bookingActions.cancelBookingFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(bookingActions.cancelBookingSuccess(result.payload))
    toast.success(result.message)
  }
  return { error, result };
}


export function* bookingSaga() {
  yield takeLatest(bookingActions.bookingList.type, handleListBookings);
  yield takeLatest(bookingActions.bookingDetail.type, handleBookingDetail);
  yield takeLatest(bookingActions.cancelBooking.type, handleCancelBooking)
}
