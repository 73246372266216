import React from "react";
import { Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import routes from "./Routes";
import NotFound from "../features/auth/pages/notFound";
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import RedirectionComponentLayout from "../layouts/RedirectionComponent";
import { isUserLoggedIn } from "../reducers/authSlice";
import { fetchAccountStorageSpinnerState } from "../reducers/accountStorageSlice";
import { useAppSelector } from "../app/hooks";
import IntegolfLoader from '../shared/integolf-loader/integolf-loader';
import DiscountRequestAdmin from "../features/discount-request-admin/discount-request-admin";
import BadWeatherDiscounts from "../features/bad-weather-discounts/bad-weather-discounts";

import LinkExpired from "../features/link-expired/link-expired";
import InstantDiscountsRequest from "../features/instant-discounts-request/instant-discounts-request";

const Navigation = () => {
  const isUser: boolean = useAppSelector(isUserLoggedIn);
  const showSpinner = useAppSelector(fetchAccountStorageSpinnerState);
  const publicComponents=[DiscountRequestAdmin, BadWeatherDiscounts,InstantDiscountsRequest]
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/landing.html" />
          {routes.map(({ component: Component, path, type, to, title, children }) => (
            <React.Fragment key={Date.now()}>

              {type === "public" && publicComponents.includes(Component) && (
                <Route
                  path={path}
                  key={Date.now()}
                  element={<PublicLayout component={Component}></PublicLayout>}
                ></Route>
              )}

              {type === "public" && !isUser && (
                <Route
                  path={path}
                  key={Date.now()}
                  element={<PublicLayout component={Component}></PublicLayout>}
                ></Route>
              )}

  
              {type === "private" && isUser && (
                <Route
                  path={path}
                  key={Date.now()}
                  element={<PrivateLayout component={Component}></PrivateLayout>}
                >
                  {children && children.length && children.map((e) => (
                    <Route path={e.path} key={Date.now()} element={<e.component />} />
                  ))}
                </Route>
              )}
              
              {((type === "public" && isUser) || (type === "private" && !isUser)) && <Route path={path} element={<RedirectionComponentLayout to={to} />}>
            
              </Route>}
              
            </React.Fragment>
          ))}
          <Route path="*" element={<NotFound />} />
          <Route path="/linkExpired" element={<LinkExpired />} />
      </>
    )
  );
  
  return <>
    <RouterProvider router={router}/>
    <IntegolfLoader show={showSpinner}/>
  </>

};

export default Navigation;
