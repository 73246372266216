import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import {MerchantFiles} from "../models/merchantFiles";

export interface merchantFilesState {
  fetchMerchantFilesStatus: string;
  saveMerchantFilesStatus: string;
  totalMerchantFilesCount: number;
  selectedMerchantFiles: Array<MerchantFiles>;
  merchantFiles: Array<MerchantFiles>;
}

const initialState: merchantFilesState = {
  fetchMerchantFilesStatus: "",
  saveMerchantFilesStatus: "",
  merchantFiles: [],
  selectedMerchantFiles: [],
  totalMerchantFilesCount: 0
};

const merchantFilesSlice = createSlice({
  name: 'merchantFiles',
  initialState,
  reducers: {
    merchantFilesList(state, action: PayloadAction<any>) {
        state.fetchMerchantFilesStatus = "pending";
    },
    merchantFilesListSuccess(state, action: PayloadAction<any>) {
        state.merchantFiles = action.payload;
        state.totalMerchantFilesCount = Array.isArray(action.payload)? action.payload.length: 0;
        state.fetchMerchantFilesStatus = "success";
    },
    merchantFilesListFailed(state, action: PayloadAction<string>) {
        state.fetchMerchantFilesStatus = "failed";
    },
    merchantFilesUpload(state, action: PayloadAction<FormData>) {
        state.saveMerchantFilesStatus = "pending";
    },
    merchantFilesUploadSuccess(state, action: PayloadAction<Array<any>>) {

      const filesPresentId = state.selectedMerchantFiles.map(({_id}) => _id);
      const filesToAdd = action.payload.filter(({_id}) => !filesPresentId.includes(_id))
      if (filesToAdd.length) {
        state.selectedMerchantFiles = [...state.selectedMerchantFiles, ...filesToAdd];
        state.saveMerchantFilesStatus = "success";
      }
    },
    merchantFilesUploadFailed(state, action: PayloadAction<string>) {
        state.saveMerchantFilesStatus = "failed";
    },
    merchantFilesUpdate(state, action: PayloadAction<any>) {
      state.selectedMerchantFiles = action.payload;
    },
    resetSelectedMerchantFile(state) {
        state.saveMerchantFilesStatus = "";
        state.selectedMerchantFiles = [];
    },
    resetmerchantFiles(state) {
        state.selectedMerchantFiles = [];
        state.merchantFiles = [];
        state.totalMerchantFilesCount = 0;
        state.fetchMerchantFilesStatus = "";
        state.saveMerchantFilesStatus = "";
    }
  },
});

// Actions
export const merchantFilesActions = merchantFilesSlice.actions;

// Selectors
export const fetchMerchantFilesStatus = (state: RootState) => state.merchantFiles.fetchMerchantFilesStatus;
export const fetchMerchantFiles = (state: RootState) => state.merchantFiles.merchantFiles;
export const fetchMerchantFilesCount = (state: RootState) => state.merchantFiles.totalMerchantFilesCount;
export const fetchSelectedMerchantFiles = (state: RootState) => state.merchantFiles.selectedMerchantFiles;
export const fetchMerchantFilesUploadStatus = (state: RootState) => state.merchantFiles.saveMerchantFilesStatus;

// Reducer
const merchantFilesReducer = merchantFilesSlice.reducer;
export default merchantFilesReducer;
