import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="page404Wrapper">
      <div className="page404Content text-center">
        <img
          src="../../../assets/images/404.png"
          alt=""
          className="mx-auto d-block img-fluid mb-5"
        />
        <h1>404</h1>
        <p className="mb-4">Oops! You are lost</p>
        <Link to="/" className="fontsize-16 fw700 color-active">Back To Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
