import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { reviewsActions } from '../reducers/reviewsSlice';
import httpClient from "../api/merchantApi/httpClient";

function* handleListCourseReviewList(action: PayloadAction<any>) {

    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/reviews`
    });

    if (error) {
        yield put(reviewsActions.reviewListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(reviewsActions.reviewListSuccess(result.response))
    }
    return { error, result };
}

export function* reviewSaga() {
    yield takeLatest(reviewsActions.reviewList.type, handleListCourseReviewList);
}
  