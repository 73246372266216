import React, { FC } from "react";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  subDays,
  daysToWeeks,
  eachHourOfInterval,
  eachDayOfInterval,
} from "date-fns";
import "./week-view-calendar.scss";
import { Navigate, useNavigate } from "react-router";

interface WeekViewCalendarProps {
  monthValue: any;
  calendarData: any;
}

const WeekViewCalendar: FC<WeekViewCalendarProps> = ({ monthValue, calendarData }) => {
  const now = new Date();
  const navigate = useNavigate()
  const arr = eachDayOfInterval({
    start: startOfWeek(now, { weekStartsOn: 1 }),
    end: endOfWeek(now, { weekStartsOn: 1 }),
  });

  const arrOfDays: any = [];
  arr.map((a) => arrOfDays.push(format(a, "EEEE")));

  const dateOrder = [1, 2, 3, 4, 5, 6, 7];
  const renderDays = () => {
    const dateFormat = "EEEE";
    const days: any = [];
    let startDate = startOfWeek(monthValue);
    dateOrder.forEach((ele) => {
      days.push(
        <th key={ele}>
          <span className="number d-block text-center">
            {format(addDays(startDate, ele), "d")}
          </span>
          <span className="dayName d-block text-center">
            {format(addDays(startDate, ele), dateFormat)}
          </span>
        </th>
      );
    });
    return (
      <thead>
        <tr>
          <th>&nbsp;</th>
          {days}
        </tr>
      </thead>
    );
  };

  const renderTimeLine: any = () => {
    const timeLine: any = [];
    // console.log(format(now, "p"));
    const result = eachHourOfInterval({
      start: new Date(2023, 1, 1, 7),
      end: new Date(2023, 1, 1, 19),
    });
    // console.log("result==", result);
    result.forEach((ele: any) => {
      timeLine.push(format(ele, "p"));
    });
    return { timeLine };
  };

 // const timeLine: any = ["08:00", "09:00", "10:00", "11:00", "12:00", "01:00"]

  const renderCells = () => {
    // debugger;
    const timeLine: any = [];
    const timeLineResult = eachHourOfInterval({
      start: new Date(2023, 1, 1, 7),
      end: new Date(2023, 1, 1, 19),
    });

    timeLineResult.forEach((ele: any) => {
      timeLine.push(ele);
    });

    const weekStart = startOfWeek(monthValue);
    const weekEnd = endOfWeek(weekStart);
    const startDate = addDays(startOfWeek(weekStart), 1);
    const endDate = addDays(weekEnd, 1);

    // console.log(weekStart, weekEnd);
    // console.log(startDate, endDate);

    const dateFormat = "d";

    const rows: any = [];
   
    let day: any = startDate;
    let formattedDate = "";

    for (let k = 0; k < timeLine.length; k++) {
     
      const subRow: any = [];
      let days: any = [];
      while (day <= endDate) {
        
        for (let i = 0; i < 7; i++) {
          const timeInHours = format(new Date(timeLine[k]), "H")
          formattedDate = format(day, "yyyy-MM-d");
          
          let dateOfDate = format(day, "d")
          let filteredData
          if(calendarData && calendarData.length >0){
            filteredData = calendarData?.find((d: any) =>( d?._id?.day.toString()== dateOfDate.toString()) && (d._id.hour.toString()==timeInHours.toString()) && (d._id.status =="success" || d._id.status =="cancelled" || d._id.status =="courseConfirmationPending" ))
          }
          
          days.push(
            <td
              className={`cell 
            ${day.getMonth() + 1 !== monthValue.getMonth() + 1 ? "hidden" : ""}
            ${
              format(now, "yyyy-MM-d") == format(day, "yyyy-MM-d")
                ? "selected"
                : ""
            }
            `}
              key={day}
            >
              <div className="timeCell">
                {filteredData &&  (
                  <div className="slots d-inline-flex flex-column" >
                  {filteredData && filteredData?._id?.status == 'cancelled' && <label className="badgeStatus danger my-1 clickable" onClick={()=> navigate('/bookings/cancelled')}>
                      {filteredData?.numberofbookings} Cancelled
                    </label>}
                   {filteredData && (filteredData?._id?.status == 'success' || filteredData?._id?.status == 'courseConfirmationPending') && <label className="badgeStatus primary my-1 clickable" onClick={()=> navigate('/bookings/upcoming')}>
                   {filteredData?.numberofbookings} Upcoming
                    </label>}
                  </div>
                )}
              </div>
            </td>
          );
          day = addDays(day, 1);
        }

        subRow.push(days);
        days = [];
      }
      if (day > endDate) {
        day = startDate
      }

      rows.push(
        <tr key={k}>
          <td className="cell timeCell">
            <span>{format(new Date(timeLine[k]), "hh:mm")}</span>
            {/* <span>{timeLine[k]}</span> */}
          </td>
          {subRow}
        </tr>
      );
    }

    // console.log("day <= endDate", timeLine.length);

    return <tbody>{rows}</tbody>;
  };

  return (
    <div className="weekViewCalendar">
      <div className="contentBlock">
        <table className="table mb-0">
          {renderDays()}
          {renderCells()}
        </table>
      </div>

      {/* {renderTimeLine()} */}
    </div>
  );
};

export default WeekViewCalendar;
