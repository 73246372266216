import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";
import "chart.js/auto";
import { Bar } from 'react-chartjs-2';

import "./dashboard.scss";
import CommonHeader from "../../shared/common-header/common-header";
import { useAppDispatch } from "../../app/hooks";
import { useAppSelector } from "../../app/hooks";
import { bookingActions, fetchBookings } from "../bookings/bookingSlice";
import {
  transactionActions,
  fetchTransactions,
  fetchMerchantTransactionRevenue,
  fetchMerchantRevenewGrowthRate,
  fetchMerchanPayoutBalance,
  fetchMerchantRevenueMonthlyClassification
} from "../transaction/transactionSlice";
import { capitalizeFullString, mapTimeZone } from "../../utils/common";

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });

  useEffect(() => {
    dispatch(bookingActions.bookingList('?skip=0&limit=2&type=upcoming'))
    dispatch(transactionActions.fetchTransactions(`skip=0&limit=2`))
    dispatch(transactionActions.fetchDashboardStats())
  }, [])

  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const bookings: any = useAppSelector(fetchBookings);
  const transactions: any = useAppSelector(fetchTransactions);
  const merchantTransactionRevenue: number = useAppSelector(fetchMerchantTransactionRevenue);
  const merchantRevenewGrowthRate: number = useAppSelector(fetchMerchantRevenewGrowthRate);
  const merchanPayoutBalance: number = useAppSelector(fetchMerchanPayoutBalance);
  const merchantRevenueMonthlyClassification: Array<number> = useAppSelector(fetchMerchantRevenueMonthlyClassification);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Revenue',
      },
    },
  };

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Monthly',
        data: merchantRevenueMonthlyClassification?.length > 0 ? [...merchantRevenueMonthlyClassification] : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],  // cannot directly pass the value since charts package deletes some values which is allowed only in [] not Array()
        backgroundColor: 'rgb(0,209,159)',
      }
    ],
  };

  return (
    <div className="dashboardWrapper">
      <CommonHeader sectionTitle={"Dashboard"} hasRatingVisible={false}></CommonHeader>
      <div className="dashboardInner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row gutters-10">
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="detailSection">
                        <div className="_section">
                          <label className="_total fontsize-16 mb-1">
                            Total Revenue
                          </label>
                          <h2 className="d-flex align-items-center flex-wrap">
                            <span className="me-2">{merchantTransactionRevenue ? `$` + merchantTransactionRevenue.toLocaleString() : '$0.00'}</span>{" "}
                          </h2>

                        </div>
                        <div className="_section mt-4">
                          <label className="fontsize-12 mb-2">
                            Payout Balance
                          </label>
                          <h5 className="mb-4"> {merchanPayoutBalance ? `$` + merchanPayoutBalance.toLocaleString() : '$0.00'} </h5>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <p className="mb-0 fontsize-12">
                                {/* Next Payout: July 31, 2023 */}
                              </p>
                            </div>
                            {/* <div className="col-auto">
                            <a href={void 0} className="fw700 color-active">
                              View All
                            </a>
                          </div> */}
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                      <Bar options={options} height={250} data={data} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="card mb-3">
                <div className="card-header">
                  <div className="row gutters-10">
                    <div className="col">
                      <h4 className="card-title mb-0">Upcoming Bookings</h4>
                    </div>
                    <div className="col-auto">
                      <a href={void 0} onClick={() => { navigate("/bookings/upcoming") }} className="fw700 color-active">
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-border">
                      <thead>
                        <tr>
                          <th>Name/Email</th>
                          <th>Tee Time</th>
                          <th>Number of Golfers</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookings && bookings.length > 0 && bookings.map((booking: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <div className="userInfo d-flex align-items-center">
                                <span className="userImage me-2">
                                  {!(booking?.user?.profileImage) && <img
                                    src="./assets/images/default-user.png"
                                    alt=""
                                    className="img-fluid"
                                  />}
                                  {booking?.user?.profileImage?.path && <img
                                    src={booking?.user?.profileImage?.path}
                                    alt=""
                                    className="img-fluid"
                                  />}
                                </span>
                                {<h6 className="fw700 mb-0">
                                  {booking?.user?.firstname ? `${booking?.user?.firstname} ${booking?.user?.lastname}` : (booking?.user?.name || 'Guest user')}</h6>}
                              </div>
                            </td>
                            <td>{booking?.teesheets && booking?.teesheets?.[0]?.teesheetIdDetail?.dateTimeISO ? (DateTime.fromISO(booking?.teesheets?.[0]?.teesheetIdDetail?.dateTimeISO, {
                              zone: mapTimeZone(booking.teesheets?.[0]?.club)
                            }).toFormat('hh:mm a, ccc, LLL dd, y')) : '-'}</td>
                            <td>{booking?.teesheets && booking?.teesheets?.[0]?.dateTime ? booking?.teesheets?.[0]?.players : 0} players, {booking?.teesheets?.[0]?.teesheetIdDetail?.holes} Holes</td>
                          </tr>))}
                        {
                          bookings.length == 0 && <div className="text-center"> No upcoming reservations</div>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card mb-3">
                <div className="card-header">
                  <div className="row gutters-10">
                    <div className="col">
                      <h4 className="card-title mb-0">Recent Transactions</h4>
                    </div>
                    <div className="col-auto">
                      <a href={void 0} onClick={() => { navigate("/transaction/history") }} className="fw700 color-active">
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-border">
                      <thead>
                        <tr>
                          <th>Paid By</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Amount Sent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions && transactions.length > 0 && transactions.map((t: any) => (
                          <tr key={t._id}>
                            <td>
                              <div className="userInfo">
                                <h6 className="fw700 mb-0">{t.firstname ? capitalizeFullString(`${t.firstname} ${t?.lastname}`) : 'Guest User'}</h6>
                              </div>
                            </td>
                            <td>{t?.booking?.createdAt ? (DateTime.fromMillis(t?.booking?.createdAt, {zone: mapTimeZone(t)}).toFormat('dd LLLL y')) : "-"}</td>
                            <td>
                              <label className="badgeStatus warning mb-0 fontsize-12">
                                {t?.transaction?.status}
                              </label>
                            </td>
                            <td>${t?.totalAmount ? t?.totalAmount.toFixed(2) : ''}</td>
                          </tr>))}
                        {
                          transactions.length == 0 && <div className="text-center"> No transactions found</div>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
