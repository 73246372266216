import React, { FC, useRef, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  CLUB_ACCOUNT_NAME_STEP,
  CLUB_LOCATION_MAP_STEP,
  CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP,
  CLUB_PICTURES_STEP,
  CLUB_DESCRIPTION_STEP,
  COURSE_BASICS_STEP,
  CLUB_FACILITIES_STEP,
  COURSE_TIME_AND_DAYS_STEP,
  COURSE_DISCOUNT_STEP,
  CLUB_PREVIEW_STEP,
  CLUB_ACTIVE_STATUS,  
} from "../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clubsActions, fetchClubData } from "../../reducers/clubsSlice";
import { merchantFilesActions } from "../../reducers/merchantFilesSlice";
import { coursesActions, fetchCourseData } from "../../reducers/coursesSlice";
import "./manage-golf-course.scss";
import AddGolfCourse from "./components/add-golf-course/add-golf-course";
import { ClubPayload } from "../../models/clubs";
import { CoursesPayload } from "../../models/courses";

const clubDataRequired = [CLUB_ACCOUNT_NAME_STEP, CLUB_LOCATION_MAP_STEP, CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP, CLUB_PICTURES_STEP, COURSE_TIME_AND_DAYS_STEP];
const clubSteps = [ CLUB_ACCOUNT_NAME_STEP, CLUB_LOCATION_MAP_STEP, CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP, CLUB_PICTURES_STEP, CLUB_DESCRIPTION_STEP, COURSE_BASICS_STEP, CLUB_FACILITIES_STEP, COURSE_TIME_AND_DAYS_STEP, COURSE_DISCOUNT_STEP, CLUB_PREVIEW_STEP, CLUB_ACTIVE_STATUS ];

interface ManageGolfCourseProps {}

const ManageGolfCourse: FC<ManageGolfCourseProps> = () => {

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const courseId = searchParams.get("courseId");
  const clubId = searchParams.get("clubId");
  const activeStep = searchParams.get("step");

  const initialStep = CLUB_ACCOUNT_NAME_STEP;
  const isEditScreen = (!!courseId && !!clubId);

  const club: ClubPayload = useAppSelector(fetchClubData);
  const course: CoursesPayload = useAppSelector(fetchCourseData);
  
  const [formStep, setStep] = useState(initialStep);
  
  const navigate = useNavigate();

  const navigateToPath = (path: string) => {
    dispatch(clubsActions.resetSelectedClub());
    dispatch(coursesActions.resetSelectedCourse());
    dispatch(merchantFilesActions.resetmerchantFiles());
    navigate(path);
  };

  const geHeaderNamefromStep = (step: number) => {
    let headerName = "";
  
    switch (step) {
      case CLUB_ACCOUNT_NAME_STEP:
        headerName = "Your Course Name";
        break;
      case CLUB_LOCATION_MAP_STEP:
        headerName = !!activeStep ? "Edit Course Location" : "Your Course Location";
        break;
      case CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP:
        headerName = !!activeStep ? "Edit/View Course Teesheets System" : "Course Teesheets System";
        break; 
      case CLUB_DESCRIPTION_STEP:
        headerName = !!activeStep ? "Edit Course Description" : "Create Course Description";
        break;
      case COURSE_BASICS_STEP:
        headerName = !!activeStep ? "Edit Course Basics" : "Basics of your golf course";
        break;
      case CLUB_FACILITIES_STEP:
        headerName = !!activeStep ? "Edit Course Facilities" : "Tell guests what your place has to offer";
        break;
      case CLUB_PICTURES_STEP:
        headerName = !!activeStep ? "Update Course Images" : "Add photos of your golf course";
        break;
      case COURSE_TIME_AND_DAYS_STEP:
        headerName = !!activeStep ? "Edit Course Bookings Timings" : "Set booking times";
        break;
      case COURSE_DISCOUNT_STEP:
        headerName = !!activeStep ? "Add/Edit Course Discounts" : "Add Discounts";
        break;  
      case CLUB_PREVIEW_STEP:
        headerName = "Course preview";
        break;
      default:
        break;
    }
  
    return headerName;
  };
 
  const [stepTitle, setStepTitle] = useState(geHeaderNamefromStep(initialStep));
  
  const changeStep = (stepNumber: number) => {
    setStepTitle(geHeaderNamefromStep(stepNumber));
    setStep(stepNumber);
  }

  const onSaveAndExit = () => {
    navigateToPath("/courses");
  }

  const onBack = () => {
    if (!!activeStep) { // if course detail edit is required then return to previous view
      navigate(-1);
    } else if (formStep <= initialStep) { // if the form step is also on first step then return to previous view
      navigate(-1);
    } else {    // else move back to previous step
      changeStep(formStep-1);
    }
  }

  const onPublishClick = () => {

    if (!course) {
      throw new Error("Course object not present");
    }
    const updatePayload = { _id: course._id, status: CLUB_ACTIVE_STATUS };
    dispatch(coursesActions.coursePublish(updatePayload));
  };

  useEffect(() => {
    
    if (courseId) {
      dispatch(coursesActions.fetchCourseData({id: courseId}));
    } else {
      dispatch(coursesActions.resetSelectedCourse());
    }

    if (clubId) {
      dispatch(clubsActions.fetchSelectedClubData({id: clubId}));
    } else {
      dispatch(clubsActions.resetSelectedClub())
    }

    // if active step is passed then move to the specific step
    if (activeStep && clubSteps.includes(parseInt(activeStep))) {
      changeStep(parseInt(activeStep));
    }
    
    // on unmount reset selected club and course
    return () => {
      dispatch(clubsActions.resetSelectedClub())
      dispatch(coursesActions.resetSelectedCourse());
      dispatch(clubsActions.resetUpdateClubDataStatus());
      dispatch(coursesActions.resetUpdateCourseDataStatus());
    };
  }, []);

  return (
    <div className="manageGolfCourse">
      <section className="commonHeader">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg">
                <h2 className="d-flex align-items-center">
                  <a className="button button-s3 button-border button-circle button-sm  me-3" onClick={onBack}>
                    <em className="fas fa-arrow-left"></em>
                  </a>
                  {stepTitle}
                </h2>
              </div>
              <div className="col-lg-auto">
                {
                  (!clubDataRequired.includes(formStep)) && !activeStep && <div className="row gutters-5">
                    <div className="col-auto">
                      {
                        (formStep != CLUB_PREVIEW_STEP) && <button
                          type="button"
                          className="button button-s3 button-border button-pill button-sm buttonSkipNext px-3"
                          onClick={() => changeStep(formStep+1)}
                        >
                          Skip
                        </button>
                      } 
                    </div>
                    <div className="col-auto">
                      <button
                        type="button"
                        className="button button-s3 button-border button-pill button-sm buttonSkipNext px-3"
                        onClick={() => onSaveAndExit()}
                      >
                        Save & Exit
                      </button>
                    </div>
                    {
                      formStep == CLUB_PREVIEW_STEP && course?._id && <div className="col-auto">
                        <button
                          type="button"
                          className="button button-s3 button-border button-pill button-sm buttonSkipNext px-3"
                          onClick={() => onPublishClick()}
                        >
                          Publish Course
                        </button>
                      </div> 
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <AddGolfCourse
              handleTileChange={changeStep} 
              setStep={setStep} 
              activeStep={formStep} 
              navigateToPath={navigateToPath}
              editClubCourseData={isEditScreen}
              club={club}
              course={course}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageGolfCourse;
