import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";
import httpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';
import { marketingMaterialActions } from '../reducers/marketingMaterialSlice';

function* handleProcessMarketingMaterialRequest(action: PayloadAction<any>) {

    const { result, error } = yield call(httpClient, {
        data: action.payload,
        method: "post",
        url: `/processMarketingRequest`
    });

    if (error) {
        yield put(marketingMaterialActions.processProcessMarketingMaterialFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(marketingMaterialActions.processProcessMarketingMaterialSuccess(result.response))
    }
    return { error, result };
}

function* handleActiveMarketingMaterialList(action: PayloadAction<any>) {
    yield put(accountStorageActions.showHideSpinner(true));

    const params = action?.payload || {}; 
    const { result, error } = yield call(httpClient, {
        method: "get",
        url: "/activeMarketingMaterial",
        params
    });
    yield put(accountStorageActions.showHideSpinner(false));
    if (error) {
        yield put(marketingMaterialActions.fetchActiveMarketingMaterialListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(marketingMaterialActions.fetchActiveMarketingMaterialListSuccess(result.response))
    }
    return { error, result };
}

function* handleDownloadMarketingMaterial(action: PayloadAction<any>) {
    yield put(accountStorageActions.showHideSpinner(true));
    const { result, error } = yield call(httpClient, {
        method: "post",
        url: "/downloadMarketingMaterial",
        data: action.payload,
    });
    yield put(accountStorageActions.showHideSpinner(false));
    if (error) {
        yield put(marketingMaterialActions.downloadedMarketingMaterialFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(marketingMaterialActions.downloadedMarketingMaterialSuccess(result))
    }
    return { error, result };
}

export function* marketingMaterialSaga() {
    yield takeLatest(marketingMaterialActions.fetchActiveMarketingMaterialList.type , handleActiveMarketingMaterialList);
    yield takeLatest(marketingMaterialActions.processMarketingMaterial.type, handleProcessMarketingMaterialRequest);
    yield takeLatest(marketingMaterialActions.processDownloadedMarketingMaterial.type, handleDownloadMarketingMaterial);
}
  