import { Action, combineReducers, configureStore, ThunkAction, AnyAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer from '../reducers/authSlice';
import bookingReducer from '../features/bookings/bookingSlice';
import coursesReducer from '../reducers/coursesSlice';
import clubsReducer from '../reducers/clubsSlice';
import externalClubsReducer from '../reducers/externalClubsSlice';
import calendarReducer from '../features/calendar/calendarSlice';
import transactionReducer from '../features/transaction/transactionSlice';
import merchantFilesReducer from '../reducers/merchantFilesSlice';
import accountStorageReducer from '../reducers/accountStorageSlice';
import reviewsReducer from '../reducers/reviewsSlice';
import discountReducer from '../reducers/discountsSlice';
import payoutsReducer from '../reducers/payoutsSlice';
import payoutTransactionsReducer from '../reducers/payoutTransactionsSlice';
import productsReducer from '../reducers/productSlice';
import discountRequestsReducer from '../reducers/discountRequestSlice';
import createSagaMiddleware from 'redux-saga';
import discountRequestsEditReducer from '../reducers/discountEditSlice';
import userPayoutAccountsReducer from '../reducers/userPayoutAccountSlice';
import rootSaga from './rootSaga';
import { persistReducer,persistStore,FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { history } from '../utils';
import marketingMaterialReducer from '../reducers/marketingMaterialSlice';

const combinedReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  booking: bookingReducer,
  courses: coursesReducer,
  clubs: clubsReducer,
  externalClubs: externalClubsReducer,
  calendar: calendarReducer,
  transaction: transactionReducer,
  merchantFiles: merchantFilesReducer,
  accountStorage: accountStorageReducer,
  reviews: reviewsReducer,
  discounts: discountReducer,
  payouts: payoutsReducer,
  payoutTransactions: payoutTransactionsReducer,
  products: productsReducer,
  discountRequest: discountRequestsReducer,
  discountEditRequest:discountRequestsEditReducer,
  marketingMaterial:marketingMaterialReducer,
  userPayoutAccounts: userPayoutAccountsReducer
})

const persistConfig = {
  key: 'merchant',
  storage,
  blacklist: ['calendar', 'accountStorage']
};

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type == 'logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware()


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'merchantFiles/merchantFilesUpload']
      },
    }).concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;