import React, { FC } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { decodeJwt } from "../../utils/common";

interface RedirectionComponentProps {
    to : string;
}

const RedirectionComponent: FC<RedirectionComponentProps> = ({ to }) => {

    // auto redirection logic
    const [searchParams] = useSearchParams();
    const target = searchParams.get("target");

    if (target) {
        try {
            const { target: targetPath } = decodeJwt(target);
            to = targetPath;
        } catch (err) {
            console.error(err);
            to = "/LinkExpired";
        }
    }

    return <Navigate replace to={to} />;
}

export default RedirectionComponent;