import React, { FC, useState } from "react";
import "./pagination.scss";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  totalCount: number;
  setSkip: any;
  pageCount: number;
}

const Pagination: FC<PaginationProps> = ({
  totalCount,
  setSkip,
  pageCount
}) => {
  
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 10) % totalCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setSkip(newOffset);
  };
  return (
    <>
      <div className="reactPaginateBlock mb-4">
        <ReactPaginate
          breakLabel="..."
          nextLabel={<em className="fas fa-angle-right"></em>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<em className="fas fa-angle-left"></em>}
          renderOnZeroPageCount={null}
          className="react-paginate"
        />
      </div>
    </>
  );
};

export default Pagination;
