import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { payoutsActions } from '../reducers/payoutsSlice';
import httpClient from "../api/merchantApi/httpClient";
import { INTEGOLF_MERCHANT_PAYOUT_STATUS_PENDING, INTEGOLF_MERCHANT_PAYOUT_STATUS_APPROVED } from '../utils/constants';

function* handleListPayouts(action: PayloadAction<any>) {

    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/payouts`
    });

    if (error) {
        yield put(payoutsActions.payoutsListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(payoutsActions.payoutsListSuccess(result.response))
    }
    return { error, result };
}

function* handleFetchLastPaidPayout() {

    const { result, error, headers } = yield call(httpClient, {
        params: { status: INTEGOLF_MERCHANT_PAYOUT_STATUS_APPROVED },
        method: "get",
        url: `/payouts`
    });

    if (error) {
        yield put(payoutsActions.fetchLastPaidPayoutFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(payoutsActions.fetchLastPaidPayoutSuccess(result.response))
    }
    return { error, result };
}

function* handleFetchPendingPayout() {

    const { result, error, headers } = yield call(httpClient, {
        params: { status: INTEGOLF_MERCHANT_PAYOUT_STATUS_PENDING },
        method: "get",
        url: `/payouts`
    });

    if (error) {
        yield put(payoutsActions.fetchPendingPayoutFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(payoutsActions.fetchPendingPayoutSuccess(result.response))
    }
    return { error, result };
}

export function* payoutsSaga() {
    yield takeLatest(payoutsActions.payoutsList.type, handleListPayouts);
    yield takeLatest(payoutsActions.fetchLastPaidPayout.type, handleFetchLastPaidPayout);
    yield takeLatest(payoutsActions.fetchPendingPayout.type, handleFetchPendingPayout);
}
  