import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";
import { store } from "../../app/store";

function* httpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };

  try {
    const { data: result, error, headers } = yield call(axiosInstance, payloadData);
    return {
      error,
      result,
      headers
    };
  } catch (error: any) {
    
    let myError = error?.response?.status ? error.response.data : error;
    if ((myError?.status === 401 || error?.response?.status === 401 ) && !error?.request?.responseURL?.includes("/verifySession")) {
      yield put(push({ pathname: "/" }));
      window.location.href = "/";
      toast.error("Session Expired. Please login again.");
    } 
    else if (error?.response?.status && error.response.status == 422 && error?.response?.data?.response?.message) {
      toast.error(error.response.data.response.message);
    } else {
    // Supressing toast message for verify session error
    if(!error?.request?.responseURL?.includes("/verifySession")) {
      toast.error(myError.message);
    }
  }
  }

  return {
    error: true,
    result: null,
  };
}

export default httpClient;
