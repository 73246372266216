import { RootState } from '../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface TransactionState {
  fetchTransactionStatus: string;
  fetchDashboardStatsStatus: string;
  fetchTransactions: any;
  totalTransactionsCount: number;
  totalMerchantRevenue: number;
  merchantRevenueGrowthRate: number;
  merchantPayoutBalance: number;
  merchantRevenueMonthlyClassification: Array<number>;
}

const initialState: TransactionState = {
    fetchTransactionStatus: "",
    fetchDashboardStatsStatus: "",
    fetchTransactions: [],
    totalTransactionsCount: 0,
    totalMerchantRevenue : 0,
    merchantRevenueGrowthRate: 0,
    merchantPayoutBalance: 0,
    merchantRevenueMonthlyClassification : (new Array(12)).fill(0)
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    fetchTransactions(state, action: PayloadAction<any>) {
      state.fetchTransactionStatus = "pending";
    },
    fetchTransactionsSuccess(state, action: PayloadAction<any>) {
      state.fetchTransactions = action.payload.response.result;
      state.totalTransactionsCount = action.payload?.response?.count;
      state.fetchTransactionStatus = "success";
    },
    fetchTransactionsFailed(state, action: PayloadAction<string>) {
      state.fetchTransactionStatus = "failed";
    },
    fetchDashboardStats(state) {
      state.fetchDashboardStatsStatus = "pending";
    },
    fetchDashboardStatsSuccess(state, action: PayloadAction<any>) {
      
      const { 
        merchantTransactionRevenue = initialState.totalMerchantRevenue, 
        payoutBalance = initialState.merchantPayoutBalance, 
        revenueGrowthRate = initialState.merchantRevenueGrowthRate, 
        revenueMonthlyClassification = initialState.merchantRevenueMonthlyClassification 
      } = action.payload;
      
      state.totalMerchantRevenue = merchantTransactionRevenue;
      state.merchantRevenueGrowthRate = revenueGrowthRate;
      state.merchantPayoutBalance = payoutBalance;
      state.merchantRevenueMonthlyClassification = revenueMonthlyClassification;
      state.fetchDashboardStatsStatus = "success";

    },
    fetchDashboardStatsFailed(state, action: PayloadAction<string>) {
      state.fetchDashboardStatsStatus = "failed";
    },
  },
});

// Actions
export const transactionActions = transactionSlice.actions;

// Selectors
export const fetchTransactionStatus = (state: RootState) => state.transaction.fetchTransactionStatus;
export const fetchTransactions = (state: RootState) => state.transaction.fetchTransactions;
export const fetchTransactionsTotalCount = (state: RootState) => state.transaction.totalTransactionsCount;
export const fetchMerchantTransactionRevenue = (state: RootState) => state.transaction.totalMerchantRevenue;
export const fetchMerchantRevenewGrowthRate = (state: RootState) => state.transaction.merchantRevenueGrowthRate;
export const fetchMerchanPayoutBalance = (state: RootState) => state.transaction.merchantPayoutBalance;
export const fetchMerchantRevenueMonthlyClassification = (state: RootState) => state.transaction.merchantRevenueMonthlyClassification;

// Reducer
const transactionReducer = transactionSlice.reducer;
export default transactionReducer;
