import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { discountRequestsActions, fetchdiscountRequests } from '../reducers/discountRequestSlice';
import httpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';

function* handleListDiscountRequestList(action: PayloadAction<any>) {

    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "post",
        url: `/fetchThirdPartyDiscountRequest`
    });

    if (error) {
        yield put(discountRequestsActions.discountRequestListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(discountRequestsActions.discountRequestListSuccess(result.response))
    }
    return { error, result };
}

function* handleProcessDiscountRequest(action: PayloadAction<any>) {

    yield put(accountStorageActions.showHideSpinner(true));

    const { result, error } = yield call(httpClient, {
        data: action.payload,
        method: "put",
        url: `/respondThirdPartyDiscountRequest`
    });

    yield put(accountStorageActions.showHideSpinner(false));
    if (error) {
        yield put(discountRequestsActions.processDiscountRequestFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(discountRequestsActions.processDiscountRequestSuccess(result.response))
    }
    return { error, result };
}

export function* discountRequestSaga() {
    yield takeLatest(discountRequestsActions.discountRequestList.type , handleListDiscountRequestList);
    yield takeLatest(discountRequestsActions.processDiscountRequest.type, handleProcessDiscountRequest);
}
  