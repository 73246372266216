import React, { FC, useEffect, useState } from "react";
import "./transition-history.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, Outlet } from "react-router-dom";
import CommonHeader from "../../../../shared/common-header/common-header";
import TransitionDetailModal from "../../../../shared/transition-detail-modal/transition-detail-modal";
import { useAppDispatch } from "../../../../app/hooks";
import { useAppSelector } from "../../../../app/hooks";
import { transactionActions, fetchTransactions, fetchTransactionsTotalCount } from "../../transactionSlice";
import { DateTime } from "luxon";
import { capitalizeFullString, mapTimeZone } from "../../../../utils/common";
import Pagination from "../../../../shared/pagination/pagination";

const TransitionHistory = () => {
  const [showTransitionDetailModal, setShowTransitionDetailModal] = useState(false);
  const handleCloseModal = (e: any) => {
    setShowTransitionDetailModal(false);
  };
  const dispatch = useAppDispatch()
  const transactions: any = useAppSelector(fetchTransactions);
  const totalTransactionCount: number = useAppSelector(fetchTransactionsTotalCount);
  const [currentTransaction, setCurrentTransaction] = useState("")

  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);


  useEffect(() => {
    dispatch(transactionActions.fetchTransactions(`skip=${skip}&limit=10`))
  }, [skip])

  useEffect(() => {
    setPageCount(Math.ceil(totalTransactionCount / 10));
  }, [transactions]);

  return (
    <>
      <div className="transition-history">
        <CommonHeader
          sectionTitle={"Transaction"}
          hasExportButton={false}
        ></CommonHeader>
        <div className="transitionWrapper__body">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-border">
                    <thead>
                      <tr>
                        <th>Paid By</th>
                        <th>Booking Id</th>
                        <th>Transaction ID</th>
                        <th>
                          Date{" "}
                          {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                        </th>
                        <th>
                          Status{" "}
                          {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                        </th>
                        <th>Total Amount</th>
                        {/* <th>Transaction Fee</th> */}
                        {/* <th>
                          Total Amount
                          
                        </th> */}
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.length > 0 && transactions.map((transaction: any, j: number) => (
                        <React.Fragment key={j}>
                          <tr>
                            <td>
                              <h6 className="mb-0 fw700">{transaction.firstname ? capitalizeFullString(`${transaction?.firstname} ${transaction?.lastname}`) : 'Guest User'}</h6>
                            </td>
                            <td>
                              <h6 className="mb-0 fw700">{transaction?.booking?.bookingId}</h6>
                            </td>
                            <td>
                              <span className="color-600 fw400">{transaction?.transaction?.transactionId ? transaction?.transaction?.transactionId : '-'}</span>
                            </td>
                            <td>{transaction?.booking?.createdAt ? (DateTime.fromMillis(transaction?.booking?.createdAt, {zone: mapTimeZone(transaction)}).toFormat('dd LLLL y')) : "-"}</td>
                            <td>

                              <label className={`mb-0 badge badgeStatus ${transaction?.transaction?.status == 'SUCCESS' ? 'completed' : 'pending'}`}>
                                {transaction?.transaction?.status ? transaction?.transaction?.status : '-'}
                              </label>
                            </td>
                            <td>
                              <h6 className="mb-0 fw700">{transaction?.totalAmount ? (`$ ${transaction?.totalAmount}`) : '$ 0.00'}</h6>
                            </td>
                            {/* <td>
                              <span className="color-600 fw500">$0.00</span>
                            </td> */}
                            {/* <td>
                              <h6 className="mb-0 fw700">${transaction?.totalPrice ? transaction?.totalPrice.toFixed(2) : ''}</h6>
                            </td> */}
                            <td>
                              <div className="listActionDropdownBlock">
                                <Dropdown align="end">
                                  <Dropdown.Toggle className="toggleButton">
                                    <em className="icon-vertical-elipse"></em>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setCurrentTransaction(transaction); setShowTransitionDetailModal(true);
                                      }

                                      }
                                      className="fw600"
                                    >
                                      View Detail
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {(!transactions || transactions.length == 0) &&
              <div className="noBookingBlock text-center mt-4 py-5">
                <img
                  src="../assets/images/no-booking.png"
                  alt="no-booking"
                  className="img-fluid mx-auto d-block"
                />
                <h5 className="mt-4 pt-3 fontsize-16 fw600">
                  You have no transactions
                </h5>
              </div>}

            {transactions && transactions.length > 0 && totalTransactionCount > 10 && (
              <div className="reactPaginateBlock mb-4">
                <Pagination
                  totalCount={totalTransactionCount}
                  setSkip={setSkip}
                  pageCount={pageCount}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <TransitionDetailModal
        showModal={showTransitionDetailModal}
        handleClose={handleCloseModal}
        transaction={currentTransaction}
      ></TransitionDetailModal>
    </>
  );
};

export default TransitionHistory;
