import React, { useEffect } from 'react'
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import Navigation from "./navigation";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';
import { store } from './app/store';

let clientId = "548961827810-h18s37ib016de3efr9vapqku0udqqet4.apps.googleusercontent.com"
let persistor = persistStore(store);
declare global { interface Window { Intercom: any; } }
const App = () => {

  useEffect(() => {

    const state = store.getState();

    let isUserLoggedIn = state?.auth?.isLoggedIn;
    if (isUserLoggedIn) {
     
      let userDetail = state?.auth?.currentUser;
      window.Intercom('boot', {
        api_base: `${process.env.REACT_APP_INTERCOM_API_URL}`,
        app_id: `${process.env.REACT_APP_INTERCOM_ID}`,
        email: userDetail.email,
        user_hash: userDetail.hmacToken,
        phone: `${userDetail?.phone?.countryCode}${userDetail?.phone?.number}`
      });
    } else {
      window.Intercom('boot', {
        api_base: `${process.env.REACT_APP_INTERCOM_API_URL}`,
        app_id: `${process.env.REACT_APP_INTERCOM_ID}`
      });
    }
  }, [])


  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Navigation></Navigation>
          <ToastContainer autoClose={2000} />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  )

}

export default App