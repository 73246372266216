import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../../app/store";
import { authActions } from "../../reducers/authSlice";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_MERCHANT_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true,
});

// Interceptors
axiosClient.interceptors.request.use(function (config: AxiosRequestConfig) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosClient.interceptors.response.use(function (response: AxiosResponse) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error?.response?.status == 403) {
    store.dispatch(authActions.logout());
    localStorage.clear();
    store.dispatch({ payload: undefined, type: 'logout' });
  }
  return Promise.reject(error);
});

export default axiosClient;