import React, {FC} from "react";
import ReactRouterPrompt from "react-router-prompt";

import DeleteModal from "../delete-modal/delete-modal";

interface PromptProps {
    show: boolean;
    message: string;
}

const Prompt: FC<PromptProps> = ({show, message}) => {
    return <ReactRouterPrompt when={show}>
        {({ isActive, onConfirm, onCancel }) => (
            <DeleteModal
                message={message}
                buttonText={"Yes"}
                showModal={show}
                onCloseClick={onCancel}
                onDeleteClick={onConfirm}
            />
        )}
  </ReactRouterPrompt>
}

export default Prompt;