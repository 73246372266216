import React, { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface RegisterModalProps {
  portal: string,
  buttonText?: string,
  showModal: boolean;
  onCloseClick : (arg: any) => void;
  onLinkClick: (arg: any) => void;
}

const RegisterModal: FC<RegisterModalProps> = ({
    portal,
    buttonText = "Yes, register",
    showModal = false,
    onCloseClick,
    onLinkClick
}) => {
    return <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
    >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={onCloseClick}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            <div className="text-center">
              <img
                src="../assets/images/spedometer-low.png"
                alt="deactivate"
                className="mb-4 d-inline-block"
              />
              <h3 className="mb-4">
                You are not registered for '{portal}' portal. Do you want to register?
              </h3>
              <a
                href={void 0}
                className="text-decoration-underline fw600 color-success"
                onClick={onLinkClick}
              >
                {buttonText}
              </a>
            </div>
          </div>  
      </Modal.Body>
  </Modal>;
};

export default RegisterModal;
