import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./footer.scss";
import { authActions, getLogoutStatus } from "../../reducers/authSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { SAGA_STATUS_PENDING } from "../../utils/constants";

const FooterLayout = () => {

  const location = useLocation();
  const dispatch = useAppDispatch();

  const logoutStatus: string = useAppSelector(getLogoutStatus);

  useEffect(() => {
    // if there us no current logout going on then verify session on location change
    if(logoutStatus !== SAGA_STATUS_PENDING){
      dispatch(authActions.verifySession({"role":"merchant"}))
    }
  }, [location]);

  return (
    <footer id="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <p className="mb-0">© 2024 Integolf</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLayout;
