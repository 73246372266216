import * as CryptoJS from "crypto-js";
import { v4 as uuidv4 } from 'uuid';
import { isExpired, decodeToken } from "react-jwt";
import moment from "moment";

import { COUNTRY_CODE_PHONE, COURSE_TEE_HOURS, DAY_SORTER, ENCRYPT_KEY, MOMENT_ATLANTIC_BERMUDA_UTC_OFFSET } from "./constants";

export const capitalizeString = (str: string): string => {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const capitalizeFullString = (str: string): string => {
  if (!str) return '';
  str = str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())
  return str
}

export const convertTime24to12 = (time24h: string|undefined, minutes: string = "00") => {
  let result = time24h;
  if (time24h && time24h.trim().length) {
    result = new Date('1970-01-01T' + `${time24h}:${minutes}:00` + 'Z')
      .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
  }
  return result;
 
};

export const encryptStringAES = (stringToEncrypt: string, key: string = ENCRYPT_KEY) => {
  
  if (!stringToEncrypt || !stringToEncrypt.length) {
      throw new Error("String to encrypt is not passed");
  }

  return CryptoJS.AES.encrypt(stringToEncrypt, key).toString();
}

export const getUniqueString = uuidv4;

export const getAtlanticMomentObj = () => moment().utcOffset(MOMENT_ATLANTIC_BERMUDA_UTC_OFFSET);

// Function to extract country code and contact number from a combined phone number 
export const extractPhoneAndCode = (phoneNumber: string) => {
  
  const [ countryObj ] = COUNTRY_CODE_PHONE.filter(({dial_code}) => phoneNumber.startsWith(dial_code));

  if (countryObj && countryObj.dial_code) {
    
    const result = phoneNumber.substring(countryObj.dial_code.length);
    
    return [ countryObj.dial_code, result];
  }

  return ['', phoneNumber];
  
}

// initialize with all checked values abd tee times
export const getValuesForAllTeeTimes = () => {
  const clubTimings: Array<any> = [];
  Object.keys(DAY_SORTER)
    .forEach((day: string, index: number) => clubTimings[index] = { 
      startHours: COURSE_TEE_HOURS[0], 
      endHours: COURSE_TEE_HOURS[COURSE_TEE_HOURS.length - 1],
      day 
    });
  return clubTimings;
}

const MAX_FILE_SIZE_MB = 5;

// Function to check if file size is within the limit
export const isImageSizeValid = (file: File): boolean => {
  const fileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes
  return file.size <= fileSizeInBytes;
};

// Function to check if file type is valid
export const isValidImage = (file: File): boolean => {
  const validExtensions = ['png', 'jpeg', 'jpg'];
  const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
  return validExtensions.includes(fileExtension);
};


export const decodeJwt = (jwtString: string|null): any => {
  if (jwtString) {
    if (isExpired(jwtString)) {
      throw new Error("Redirect link expired");
    }
    return decodeToken(jwtString);
  }
}

export const mapTimeZone = (zoneObject:{timezone:string|null})=>{
  let timeZone = "Atlantic/Bermuda";
  if(zoneObject && zoneObject.timezone && typeof zoneObject.timezone === "string"){
    timeZone = zoneObject.timezone;
  }
  return timeZone;

}

export const aesEncryptData = (stringToEncrypt: string, aesSecretKey: string): string => {
    
  if (!stringToEncrypt || !stringToEncrypt.trim().length) {
      throw new Error('Invalid string value passed to encrypt');
  }

  if (!aesSecretKey || !aesSecretKey.trim().length) {
      throw new Error('Invalid string value passed to AES Secret key');
  }

  const encrypted = CryptoJS.AES.encrypt(stringToEncrypt, aesSecretKey).toString();
  
  return encrypted
}

export const aesDecryptData = (stringToEncrypt: string, aesSecretKey: string): any => {
  
  if (!stringToEncrypt || !stringToEncrypt.trim().length) {
      throw new Error('Invalid string value passed to encrypt');
  }

  if (!aesSecretKey || !aesSecretKey.trim().length) {
      throw new Error('Invalid string value passed to AES Secret key');
  }

  const bytes = CryptoJS.AES.decrypt(stringToEncrypt, aesSecretKey);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}

export const disableNumWheelBehaviour = (e: any) => {
  const target = e.target;
  target.blur();
  setTimeout(() => {
    target.focus();
  }, 0);
}

export const mapWeatherCodeType = (weatherCode: string) => {

  switch (weatherCode) {
    case "01d": // Clear Sky Day
      return "../../../assets/images/icons/weather/Clear_Day_Icon.png"
    case "01n": // Clear Sky NIght
      return "../../../assets/images/icons/weather/Clear_Day_Night_Icon.png"
    case "02n": // Few clouds night
    case "02d": // Few clouds day
      return "../../../assets/images/icons/weather/Few_Clouds_Day.png"
    case "03n": // Scatterd clouds night
    case "03d": // Scatterd clouds
      return "../../../assets/images/icons/weather/Scattered_Clouds.png"
    case "04d": // Broken clouds Day
    case "04n": // Broken clouds Night
      return "../../../assets/images/icons/weather/Broken_Clouds.png"
    case "09d": // Shower rain day
    case "09n": // Shower rain night
      return "../../../assets/images/icons/weather/Shower_Rain.png"
    case "10d": // Rain Day
     return "../../../assets/images/icons/weather/Rain_Day.png"
    case "10n": // Rain Night
      return "../../../assets/images/icons/weather/Rain_Night.png"
    case "11d": // Thunderstorm Day
    case "11n": // Thunderstorm Night 
      return "../../../assets/images/icons/weather/Thunderstorm.png"
    case "13d": // Snow Day
    case "13n": // Snow Night
      return "../../../assets/images/icons/weather/Snow.png"
    case "50d": // Mist Day
    case "50n": // Mist Night
      return "../../../assets/images/icons/weather/Mist.png"
    default:
      return "../../../assets/images/icons/weather/Clear_Day_Icon.png"
  }
}

export const trimString = (data: string) => data.trim();