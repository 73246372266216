import React, { FC, useEffect } from "react";
import "./account.scss";
import { Outlet } from "react-router";

interface AccountProps {}

const Account: FC<AccountProps> = () => {
  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });
  return (
    <div className="accountWrapper">
      <Outlet></Outlet>
    </div>
  );
};

export default Account;
