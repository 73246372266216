import React, { Fragment, FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import PropTypes from "prop-types";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";

import './update-phone-modal.scss';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { authActions, getUserUpdateStatus, getUserVerifyOtpStatus } from "../../../../reducers/authSlice";

interface ChangePhonePayload {
  phone : string;
  emailPayloadKey : string; 
}

interface ChangePhoneOtpVerificationPayload {
  otp : string;
  emailPayloadKey: string
}

interface UpdatePhoneModalProps {
  isModalVisible: boolean;
  handleClose: (arg: any) => void;
  userProfile: any;
}

const CountrySelect = ({ value, onChange, labels, ...rest }: {
  value: any;
  onChange: any;
  labels: any;
}) => (
  <select
    className="form-select"
    id="region"
    {...rest}
    defaultValue={"US"}
    value={value}
    onChange={event => onChange(event.target.value || undefined)}
  >
    {getCountries().map((country) => (
      <option key={country} value={country}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

const UpdatePhoneModal: FC<UpdatePhoneModalProps> = ({
  isModalVisible, 
  handleClose,
  userProfile
}) => {
  
  const dispatch = useAppDispatch();
  const userProfileUpdateStatus : string = useAppSelector(getUserUpdateStatus);
  const userVerifyOtpStatus: string = useAppSelector(getUserVerifyOtpStatus);

  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showConfirmationStep, setShowConfirmationStep] = useState(false);
  const [OTP, setOTP] = useState("");
  const [showTimer, setShowTimer] = useState(true);
  const [time, setTime] = useState(180);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState(time % 60);
  const [country, setCountry] = useState<any>("US");

  const userPhoneChange: any = userProfile.changePhone || userProfile.phone;

  const {
    register: changePhoneRegister,
    handleSubmit: handChangePhoneSubmit,
    formState: { errors: errorsChangePhoneErrors },
    getValues,
    reset: resetPasswordForm
  } = useForm<ChangePhonePayload>();
  const changePhoneSubmit: SubmitHandler<ChangePhonePayload> = (data: any) => onSubmit(data);

  const {
    register: verifyOtpRegister,
    handleSubmit: handVerifyOtpSubmit,
    formState: { errors: errorsVerifyOtpErrors },
    reset: verifyOtpForm,
    control: verifyOtpControl,
  } = useForm<ChangePhoneOtpVerificationPayload>();
  const verifyOtpFormSubmit: SubmitHandler<ChangePhoneOtpVerificationPayload> = () => verifyOTPEvent();

  const closePopup = () => {
    handleClose(!isModalVisible);
    setShowConfirmationStep(false);
    setShowVerifyOTP(false);
    setOTP("")
  };

  const verifyOTPEvent = () => {
    const validateOtpPayload = { verificationCode: OTP, verificationType:  userProfile.changePhone ? 'changePhone': 'phone' };
    dispatch(authActions.updateUserOtpVerification(validateOtpPayload));
  };

  function padLeadingZeros(num: any, size: any) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
      setMinutes(Math.floor(time / 60));
      setSeconds(time % 60);
    }, 1000);
    if (time < 0) {
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [time, minutes, seconds]);

  // move to next step when there is a successfull update
  useEffect(() => {
    
    if (isModalVisible && userProfileUpdateStatus == "success" && !showVerifyOTP && !showConfirmationStep) {
      setShowConfirmationStep(false);
      setShowVerifyOTP(true);
      dispatch(authActions.resetUserUpdateStatus());
    } else if (isModalVisible && userVerifyOtpStatus == "success" && showVerifyOTP && !showConfirmationStep) {
      setShowConfirmationStep(true);
      setShowVerifyOTP(false);
      dispatch(authActions.resetUserVerifyOtpStatus());
    }

  }, [isModalVisible, userProfileUpdateStatus, userVerifyOtpStatus , showVerifyOTP, showConfirmationStep])

  const onSubmit = (data: any) => {
    const updatePayload = { 
      phoneCountryCode: getCountryCallingCode(country),
      phoneNumber: data.phone
    };
    dispatch(authActions.updateProfileData(updatePayload));
  }

  const resend = () => {
    /**
     * fix - IN-300
     */
    const updatePayload = { 
      phoneCountryCode: getCountryCallingCode(country),
      phoneNumber: getValues("phone")
    };
    dispatch(authActions.updateProfileData(updatePayload));

    setTime(180);
    setMinutes(Math.floor(180 / 60));
    setSeconds(180 % 60);
    setShowTimer(true);
  }

  return (
    <>
      <Modal
        className="updateInfoModalWrapper"
        show={isModalVisible}
        centered={true}
      >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={closePopup}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            {!showVerifyOTP && !showConfirmationStep && (
              <form className="formBlock" onSubmit={handChangePhoneSubmit(changePhoneSubmit)}>
                <h4 className="mb-3">Update Phone</h4>
                <Fragment>
                  <div className="mb-4">
                    <div className="form-floating" >
                      <CountrySelect
                        labels={en}
                        value={country}
                        onChange={setCountry}
                      />
                      <label htmlFor="region">Country/Region</label>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-floating" >
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        {...changePhoneRegister("phone", { required: true, pattern: /^[0-9]*$/i })}
                      />
                      {errorsChangePhoneErrors.phone?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter phone number</div>}
                      {errorsChangePhoneErrors.phone?.type == 'pattern' && <div className="isInvalidMessage text-danger">Please enter valid phone number</div>}
                      <label htmlFor="phoneNumber">Phone Number</label>
                    </div>
                  </div>
                </Fragment>
                <button
                  type="submit"
                  className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </form>
            )}
            {showVerifyOTP && !showConfirmationStep && (
              <form className="formOtpVerification" onSubmit={handVerifyOtpSubmit(verifyOtpFormSubmit)}>
                <div className="formBlock pb-0">
                  <h4 className="mb-3">Verify Phone number</h4>
                  <p>Enter the code we've sent on your provided phone: +{userPhoneChange.countryCode}-{userPhoneChange.number}</p>
                  <div className="mb-4">
                    <Controller 
                      control={verifyOtpControl}
                      {...verifyOtpRegister("otp", { required: true, minLength: 4 })} 
                      render={({ field: { onChange } }) => <>
                        <OtpInput
                          value={OTP}
                          shouldAutoFocus={true}
                          numInputs={4}
                          containerStyle={"optInputWrap justify-content-between"}
                          inputStyle={"optInput"}
                          focusStyle={"optInputFocus"}
                          isInputNum={true}
                          placeholder=""
                          onChange={(value: string) => {
                            onChange(value);
                            setOTP(value);
                          }}
                        />
                      </>} 
                    />
                  </div>
                  {
                    errorsVerifyOtpErrors.otp?.type == "required" && (
                      <span className="isInvalidMessage text-danger">
                        Please enter validation otp.
                      </span>
                    )
                  }
                  {
                    errorsVerifyOtpErrors.otp?.type == "minLength" && (
                      <span className="isInvalidMessage text-danger">
                        Enter correct otp value.
                      </span>
                    )
                  }
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-3">
                    {showTimer && <p className="mb-0 fw600">Time remaining {padLeadingZeros(minutes, 2)} : {padLeadingZeros(seconds, 2)} </p>}
                    {!showTimer && <p className="mb-0 fw600">Time remaining 00:00 </p>}
                      <button
                        type="button"
                        className="resendLink fw600 color-gray"
                        onClick={resend}
                        disabled={(minutes+seconds > 0)}
                      >
                        Resend
                      </button>
                  </div>
                  <button
                    type="submit"
                    className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                  >
                    Verify
                  </button>
                </div>
              </form>
            )}
            {!showVerifyOTP && showConfirmationStep && (
              <div className="successConfirmationModalWrap text-center">
                <span className="markIcon success">
                  <em className="fa-solid fa-circle-check"></em>
                </span>
                <h2 className="mb-2">All Done!</h2>
                <p className="color-gray fw500">Phone Updated successfully.</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatePhoneModal;
