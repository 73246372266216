import React, { FC, useEffect, useRef, useState } from "react";
import "./calendar.scss";
import MonthViewCalendar from "./components/month-view-calendar/month-view-calendar";
import CommonHeader from "../../shared/common-header/common-header";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  subDays,
  subWeeks,
  addWeeks,
} from "date-fns";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../app/hooks";
import { calendarActions, fetchCalendarData } from "./calendarSlice";
import { useAppSelector } from "../../app/hooks";
import WeekViewCalendar from "./components/week-view-calendar/week-view-calendar";

interface CalendarProps { }

const Calendar: FC<CalendarProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });

  // const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [currentWeek, setCurrentWeek] = useState(new Date());
  // const [currentMonthValue, setCurrentMonthValue] = useState("");
  // const [currentWeekValue, setCurrentWeekValue] = useState("");
  const [calendarType, setCalendarType] = useState("monthly");
  const calendarData: any = useAppSelector(fetchCalendarData);

  const [currentMonthOrWeek, setCurrentMonthOrWeek] = useState(new Date());
  const [currentMonthOrWeekName, setCurrentMonthOrWeekName] = useState("");

  useEffect(() => {
    console.log("isnide use effect");
    setMonthWeekName()
    getCalendarData()
  }, [currentMonthOrWeek, calendarType]);

  const prevMonthOrWeek = () => {
    if (calendarType == 'monthly') {
      const newMonth = subMonths(currentMonthOrWeek, 1);
      setCurrentMonthOrWeek(newMonth);
    } else if (calendarType == 'weekly') {
      const newWeek = subWeeks(currentMonthOrWeek, 1);
      setCurrentMonthOrWeek(newWeek);
    }

  };
  const nextMonthOrWeek = () => {
    if (calendarType == 'monthly') {
      const newMonth = addMonths(currentMonthOrWeek, 1);
      setCurrentMonthOrWeek(newMonth);
    } else if (calendarType == 'weekly') {
      const newWeek = addWeeks(currentMonthOrWeek, 1);
      setCurrentMonthOrWeek(newWeek);
    }
  };

  const setMonthWeekName = () => {
    if (calendarType == 'monthly') {
      let monthName = DateTime.fromJSDate(currentMonthOrWeek).toFormat("LLLL y");
      setCurrentMonthOrWeekName(monthName)
    } else if (calendarType == 'weekly') {
      const weekStart = startOfWeek(currentMonthOrWeek);
      const weekEnd = endOfWeek(weekStart);
      const startDate = addDays(startOfWeek(weekStart), 1);
      const endDate = addDays(endOfWeek(weekEnd), 1);
      setCurrentMonthOrWeekName(format(startDate, 'MMMM dd') + ' - ' + format(endDate, 'MMMM dd'))
    }
  }

  // const prevWeek = () => {
  //   const newWeek = subWeeks(currentWeek, 1);
  //   setCurrentWeek(newWeek);
  // };
  // const nextWeek = () => {
  //   const newWeek = addWeeks(currentWeek, 1);
  //   setCurrentWeek(newWeek);
  // };

  // useEffect(() => {
  //   let monthdata = DateTime.fromJSDate(currentWeek).toFormat("LLLL y");
  // //  setCurrentMonthValue(monthdata);
  //   const weekStart = startOfWeek(currentWeek);
  //   const weekEnd = endOfWeek(weekStart);
  //   const startDate = addDays(startOfWeek(weekStart), 1);
  //   const endDate = addDays(endOfWeek(weekEnd), 1);
  //   setCurrentWeekValue(format(startDate, 'MMMM dd') + ' - ' + format(endDate, 'MMMM dd'))
  // }, [currentWeek]);

  const changeCalendarType = (type: string) => {
    console.log("inside changeCalendarType", type);
    setCalendarType(type);
    setMonthWeekName()

  };


  const getCalendarData = () => {
    if (calendarType == "monthly") {
      console.log('currect date ts--', new Date(currentMonthOrWeek).getTime())
      let time = new Date(currentMonthOrWeek).getTime()

      dispatch(
        calendarActions.fetchCalendarData(
          `startDate=${time}&type=monthly`
        )
      );
    } else if (calendarType == "weekly") {
      console.log('currect date ts--', new Date(currentMonthOrWeek).getTime())
      let time = new Date(currentMonthOrWeek).getTime()

      dispatch(
        calendarActions.fetchCalendarData(
          `startDate=${time}&type=weekly`
        )
      )
    }
  }

  return (
    <div className="calendarWrapper">
      <CommonHeader sectionTitle={"Calendar"}></CommonHeader>
      <section className="commonHeader pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <nav className="navTabs">
                <a
                  onClick={() => changeCalendarType("weekly")}
                  className={`nav-link ${calendarType == "weekly" ? " active" : ""
                    }`}
                >
                  Weekly
                </a>
                <a
                  onClick={() => changeCalendarType("monthly")}
                  className={`nav-link ${calendarType == "monthly" ? " active" : ""
                    }`}
                >
                  Monthly
                </a>
              </nav>
            </div>
            <div className="col-auto">
              {calendarType == "monthly" && (
                <div className="d-flex align-items-center controlAction">
                  <a
                    className="button button-s3 button-border button-circle button-sm"
                    onClick={() => prevMonthOrWeek()}
                  >
                    <em className="fas fa-arrow-left"></em>
                  </a>
                  <span className="px-2">{currentMonthOrWeekName}</span>
                  <a
                    className="button button-s3 button-border button-circle button-sm"
                    onClick={() => nextMonthOrWeek()}
                  >
                    <em className="fas fa-arrow-right"></em>
                  </a>
                </div>
              )}
              {calendarType == "weekly" && (
                <div className="d-flex align-items-center controlAction">
                  <a
                    className="button button-s3 button-border button-circle button-sm"
                    onClick={() => prevMonthOrWeek()}
                  >
                    <em className="fas fa-arrow-left"></em>
                  </a>
                  <span className="px-2">{currentMonthOrWeekName}</span>
                  <a
                    className="button button-s3 button-border button-circle button-sm"
                    onClick={() => nextMonthOrWeek()}
                  >
                    <em className="fas fa-arrow-right"></em>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {calendarType == "monthly" && (
        <MonthViewCalendar
          monthValue={currentMonthOrWeek}
          calendarData={calendarData}
        ></MonthViewCalendar>
      )}

      {calendarType == "weekly" && (
        <WeekViewCalendar monthValue={currentMonthOrWeek}  calendarData={calendarData}></WeekViewCalendar>
      )}
    </div>
  );
};

export default Calendar;
