import React, { FC, useEffect, useState } from "react";
import "../pages/bookings-upcoming/bookings-upcoming.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import { capitalizeString, mapTimeZone } from "../../../utils/common";
import { DateTime } from "luxon";
import { Rating } from "react-simple-star-rating";
import { capitalizeFullString } from "../../../utils/common";
import Pagination from "../../../shared/pagination/pagination";

interface BookingListProps {
  bookings: any;
  totalCount: number;
  setSkip: any;
  type: string;
}

const BookingList: FC<BookingListProps> = ({
  bookings,
  totalCount,
  setSkip,
  type,
}) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / 10));
  }, [bookings]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 10) % totalCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setSkip(newOffset);
  };
  return (
    <>
      <div className="bookingsListWrapper">
        {bookings &&
          bookings.length > 0 &&
          bookings.map((booking: any, index: number) => (
            <div className="bookingDataBlock mb-2" key={index}>
              <div className="row gutters-10">
                <div className="col-auto">
                  <span className="userImage">
                    {!booking?.user?.profileImage?.path && (
                      <img
                        src="../../assets/images/default-user.png"
                        alt="user"
                        className="img-fluid"
                      />
                    )}
                    {booking?.user?.profileImage?.path && (
                      <img
                        src={booking?.user?.profileImage?.path}
                        alt="user"
                        className="img-fluid"
                      />
                    )}
                  </span>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col-12 col-lg-5">
                      {(booking?.user)? <>
                        <h2 className="mb-2">
                          {booking.user?.firstname?.length > 1
                            ? capitalizeString(`${booking?.user?.firstname} ${booking?.user?.lastname || ''}`)
                            : capitalizeString(`${booking?.user?.name || 'Guest User'}`)}
                        </h2>
                        <p className="color-700 mb-3 fw500">
                          {(typeof booking.user?.phone ==='object')? (
                            <>{`+ ${booking?.user?.phone?.countryCode}${booking?.user?.phone?.number}`} {" "} 
                            <span className="color-400">&nbsp; | &nbsp;</span>{" "}
                            </>
                          ):(
                            <>{`${booking?.user?.phone || 'n/a'}`} {" "} 
                            <span className="color-400">&nbsp; | &nbsp;</span>{" "}
                            </>
                          )
                          }           
                          {booking?.user?.email}
                        </p>
                      </>:<h2 className="mb-2">Guest User</h2>}

                      {/* {booking?.user && <>
                        <h2 className="mb-2">
                          {booking?.user?.name
                            ? capitalizeString(`${booking?.user.name}`)
                            : "Guest User"}
                        </h2>
                        <p className="color-700 mb-3 fw500">
                          {booking?.user.phone && (
                            <>{`+ ${booking?.user?.phone}`}</>
                          )}{" "}
                          <span className="color-400">&nbsp; | &nbsp;</span>{" "}
                          {booking?.user?.email}
                        </p>
                      </>} */}


                      <p className="color-600 mb-2 fw600">
                        Booked on:{" "}
                        <span className="color-800">
                          {booking?.createdAt
                            ? DateTime.fromMillis(booking?.createdAt, {zone:mapTimeZone(booking?.teesheets?.[0]?.club)}).toFormat(
                              "h:mm a, EEE dd MMM yyyy"
                            )
                            : "n/a"}
                        </span>
                      </p>
                      <p className="color-600 mb-2 fw600">
                        Total Payment:{" "}
                        <span className="color-800 fw700">
                          $
                          {booking?.totalPrice
                            ? (booking.isLuckyBooking)?"0.00":booking?.totalPrice.toFixed(2)
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 col-lg-7 colSeprator">
                      <h3 className="mb-2">
                        {capitalizeFullString(booking?.teesheets[0]?.club?.name)}
                      </h3>
                      <p className="color-700 mb-3 fw600">
                        <em className="icon-location me-2"></em>
                        {booking?.teesheets[0]?.club?.addressOne},{" "}
                        {booking?.teesheets[0]?.club?.city
                          ? booking?.teesheets[0]?.club?.city + ", "
                          : ""}
                        {booking?.teesheets[0]?.club?.countryName}
                      </p>
                      <div className="row gutters-7">
                        <div className="col-12 col-sm _item">

                          <label className="color-600 mb-2">Tee Time</label>
                          {booking.teesheets && booking.teesheets.length > 0 &&
                            booking.teesheets.map((teesheet: any, index: number) => (
                              <h5 className="color-800">
                                {DateTime.fromISO(teesheet?.teesheetIdDetail?.dateTimeISO, {zone:mapTimeZone(teesheet?.club)}).toFormat("h:mm a, EEE dd MMM yyyy")}
                              </h5>))}
                          {/* {booking.teesheets && booking.teesheets.length == 1 && <h5 className="color-800">
                            {booking.teesheets && booking.teesheets.length == 1 && booking?.teesheets[0]?.teesheetId
                              ? DateTime.fromMillis(
                                booking?.teesheets[0]?.dateTime
                              ).toFormat("h:mm a, EEE dd MMM yyyy")
                              : "n/a"}
                          </h5>} */}

                          {/* {booking.teesheets && booking.teesheets?.length >1 && <h5 className="color-800">
                          {booking?.teesheets[0]?.teesheetId
                              ? DateTime.fromMillis(
                                booking?.teesheets[booking.teesheets.length -1]?.dateTime
                              ).toFormat("dd MMM yyyy")
                              : "n/a"}
                              {", "}
                            { booking?.teesheets[0]?.teesheetId
                              ? DateTime.fromMillis(
                                booking?.teesheets[0]?.dateTime
                              ).toFormat("h:mm a")
                              : "n/a"} {"- "}
                              { booking?.teesheets[0]?.teesheetId
                              ? DateTime.fromMillis(
                                booking?.teesheets[booking.teesheets.length -1]?.dateTime
                              ).toFormat("h:mm a")
                              : "n/a"} 
                          </h5>} */}
                        </div>
                        <div className="col-12 col-sm _item">
                          <label className="color-600 mb-2">
                            Number of Golfers
                          </label>
                          <h5 className="color-800">
                            {booking?.noOfGuest ? booking?.noOfGuest: booking?.teesheets[0]?.players} players, {booking?.teesheets[0]?.teesheetIdDetail?.holes} Holes
                          </h5>
                        </div>
                      </div>
                      {booking?.orders && booking.orders.length > 0 && <div className="row gutters-5 mt-3">

                        {booking?.orders && booking.orders.products?.length > 0 &&
                          booking?.orders?.products.map((order: any, index: number) => (
                            <div className="col-auto">
                              {order?.quantity && <label className="badge mb-2">{order?.title}: {order?.quantity}</label>}
                            </div>))}

                      </div>}


                    </div>
                  </div>
                </div>
                <div className="col-auto align-self-center">
                  <div className="d-flex align-items-center justify-content-end actions">
                    {(booking?.status?.value == "cancelled" ||
                      booking?.status?.value == "refunded") && (
                        <label className="_label active fontsize-14 fw700 my-2">
                          {booking?.status?.value == "cancelled"
                            ? "Cancelled"
                            : "Refunded"}
                        </label>
                      )}

                    {booking?.status?.value == "courseConfirmationPending" && (
                      <label className="_label active fontsize-14 fw700 my-2">
                        Course Confirmation Pending
                      </label>
                    )}

                    {booking?.status?.value == "failed" && (
                      <label className="_label active fontsize-14 fw700 my-2">
                        Payment Failed
                      </label>
                    )}

                    {booking?.status?.value == "completed" && (
                      <div className="_rate my-2">
                        <Rating
                          initialValue={booking?.rating ? booking.rating : 0}
                          showTooltip={false}
                          onClick={function noRefCheck() { }}
                          size={20}
                          fillColor={"#18C233"}
                          tooltipArray={["1.0", "2.0", "3.0", "4.0", "5.0"]}
                          readonly
                        />
                      </div>
                    )}
                    {/* <button
                  type="button"
                  className="button button-primary button-rounded button-sm px-3 fw600"
                >
                  Scan Barcode
                </button> */}
                    <div className="listActionDropdownBlock ms-3">
                      <Dropdown align="end">
                        <Dropdown.Toggle className="toggleButton">
                          <em className="icon-vertical-elipse"></em>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <NavLink
                            to={`/booking-detail/${booking._id}`}
                            className="dropdown-item"
                          >
                            View Detail
                          </NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {bookings && bookings.length == 0 && (
          <div className="noBookingBlock text-center mt-4 py-5">
            <img
              src="../assets/images/no-booking.png"
              alt="no-booking"
              className="img-fluid mx-auto d-block"
            />
            <h5 className="mt-4 pt-3 fontsize-16 fw600">
              You have no {type} bookings
            </h5>
          </div>
        )}
      </div>
      {bookings && bookings.length > 0 && totalCount > 10 && (
        <div className="reactPaginateBlock mb-4">
          <Pagination
            totalCount={totalCount}
            setSkip={setSkip}
            pageCount={pageCount}
          />
        </div>
      )}
    </>
  );
};

export default BookingList;
