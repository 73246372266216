import { authSaga } from '../sagas/authSaga';
import { bookingSaga } from '../features/bookings/bookingSaga';
import { coursesSaga } from "../sagas/coursesSaga";
import { clubsSaga } from '../sagas/clubsSaga';
import { externalClubsSaga } from '../sagas//externalClubsSaga';
import { merchantFilesSaga } from '../sagas//merchantFilesSaga';
import { reviewSaga } from '../sagas/reviewsSaga';
import { discountSaga } from '../sagas/discountSaga';
import { payoutsSaga } from '../sagas/payoutsSaga';
import { payoutTransactionsSaga } from '../sagas/payoutTransactionsSaga';
import { productSaga } from '../sagas/productsSaga';
import { discountRequestSaga } from "../sagas/discountRequestSaga";
import { marketingMaterialSaga } from '../sagas/marketingMaterialSaga';
import { discountRequestEditSaga } from '../sagas/discountEditRequestSaga';
import { userPayoutAccountsSaga } from "../sagas/userPayoutAccountSaga";
import { calendarSaga } from '../features/calendar/calendarSaga';
import { transactionSaga } from '../features/transaction/transactionSaga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    authSaga(),
    bookingSaga(),
    coursesSaga(),
    clubsSaga(),
    calendarSaga(),
    transactionSaga(),
    externalClubsSaga(),
    merchantFilesSaga(),
    reviewSaga(),
    discountSaga(),
    payoutsSaga(),
    payoutTransactionsSaga(),
    productSaga(),
    discountRequestSaga(),
    marketingMaterialSaga(),
    discountRequestEditSaga(),
    userPayoutAccountsSaga()
  ]);
}
