import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { merchantFilesActions } from '../reducers/merchantFilesSlice';
import { accountStorageActions } from '../reducers/accountStorageSlice';
import httpClient from "../api/merchantApi/httpClient";

function* handleUploadMerchantFile(action: PayloadAction<FormData>) {

    // show loader on start
    yield put(accountStorageActions.showHideSpinner(true)); 
    const { result, error, headers } = yield call(httpClient, {
        data: action.payload,
        method: "post",
        url: `/file`
    });

    // hide loader on request end
    yield put(accountStorageActions.showHideSpinner(false));
    if (error) {
        yield put(merchantFilesActions.merchantFilesUploadFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(merchantFilesActions.merchantFilesUploadSuccess(result.response))
    }
    return { error, result };
}

export function* merchantFilesSaga() {
    yield takeLatest(merchantFilesActions.merchantFilesUpload.type, handleUploadMerchantFile);
}
  