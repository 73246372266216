import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import {MarketingMaterialRequestPayload} from "../models/marketingMaterial"
import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";

export interface MarketingMaterialState {
    processMarketingMarketingStatus: any;
    fetchActiveMarketingMaterialStatus:any;
    activeMarketingMaterial: Array<MarketingMaterialRequestPayload>;
    downloadedMarketingMaterial:any;
}

const initialState: MarketingMaterialState = {
    processMarketingMarketingStatus: SAGA_STATUS_INIT,
    fetchActiveMarketingMaterialStatus:SAGA_STATUS_INIT,
    activeMarketingMaterial: [],
    downloadedMarketingMaterial:SAGA_STATUS_INIT

};

const marketingMaterialSlice = createSlice({
  name: 'marketingmaterial',
  initialState,
  reducers: {
    fetchActiveMarketingMaterialList(state,action: PayloadAction<any> ) {
        state.fetchActiveMarketingMaterialStatus = SAGA_STATUS_PENDING;
    },
    fetchActiveMarketingMaterialListSuccess(state, action: PayloadAction<any>) {
        const activeMarketingMaterial = action?.payload[0]?.templatesInfo
        state.activeMarketingMaterial = activeMarketingMaterial;
    },
    fetchActiveMarketingMaterialListFailed(state, action: PayloadAction<string>) {
        state.fetchActiveMarketingMaterialStatus = SAGA_STATUS_FAILED;
    },
    resetctiveMarketingMaterialList(state) {
        state.processMarketingMarketingStatus = [];
    },
    processMarketingMaterial(state, action: PayloadAction<any>) {
        state.processMarketingMarketingStatus = SAGA_STATUS_PENDING;
    },
    processProcessMarketingMaterialSuccess(state, action: PayloadAction<any>) {
        state.processMarketingMarketingStatus = action?.payload[0]?.templatesInfo;
    },
    processProcessMarketingMaterialFailed(state, action: PayloadAction<string>) {
        state.processMarketingMarketingStatus = SAGA_STATUS_FAILED;
    },
    resetProcessMarketingMaterialStatus(state) {
        state.processMarketingMarketingStatus = SAGA_STATUS_INIT;
    },
    processDownloadedMarketingMaterial(state,action: PayloadAction<any>){
        state.downloadedMarketingMaterial = SAGA_STATUS_PENDING
    },
    resetDownloadedMarketingMaterial(state){
       state.downloadedMarketingMaterial=SAGA_STATUS_INIT
    },
    downloadedMarketingMaterialSuccess(state, action: PayloadAction<any>) {
        state.downloadedMarketingMaterial = action?.payload;
    },
    downloadedMarketingMaterialFailed(state, action: PayloadAction<any>) {
        state.downloadedMarketingMaterial = SAGA_STATUS_FAILED;
    }
  }
});

// Actions
export const marketingMaterialActions = marketingMaterialSlice.actions;

// Selectors
export const fetchActiveMarketingMaterial = (state: RootState) => state.marketingMaterial.activeMarketingMaterial;
export const fetchProcessMarketingMaterialStatus = (state: RootState) => state.marketingMaterial.processMarketingMarketingStatus;
export const handleDownloadMarketingRequest=(state:RootState)=>state.marketingMaterial.downloadedMarketingMaterial;
// Reducer
const marketingMaterialReducer = marketingMaterialSlice.reducer;
export default marketingMaterialReducer;
