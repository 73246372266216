import { RootState } from '../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SignupPayload, VerifyOtpPayload, UpdateProfilePayload, ChangeEmailPhonePayload } from '../models/auth'
import { SAGA_STATUS_FAILED, SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS } from '../utils/constants';
import { decodeJwt } from '../utils/common';

export interface AuthState {
  signupLoginStatus: string;
  verifyOtpStatus: string;
  resendOtpStatus: string;
  updateProfileDataStatus: string;
  verifyUpdateOtpStatus: string;
  isLoggedIn: boolean;
  currentUser: any;
  socialSignupLoginStatus: string;
  userProfileDeleteStatus: string;
  logoutStatus: string;
  userExists:{exists:boolean, role:string};
  userExistsStatus:string;
  merchantRegisterStatus:string;
  merchantRegister:any;
  fetchAppConfigStatus: string;
  appConfig: any;
}

const initialState: AuthState = {
  signupLoginStatus: SAGA_STATUS_INIT,
  verifyOtpStatus: SAGA_STATUS_INIT,
  resendOtpStatus: SAGA_STATUS_INIT,
  updateProfileDataStatus: SAGA_STATUS_INIT,
  verifyUpdateOtpStatus: SAGA_STATUS_INIT,
  isLoggedIn: false,
  currentUser: undefined,
  socialSignupLoginStatus: SAGA_STATUS_INIT,
  userProfileDeleteStatus: SAGA_STATUS_INIT,
  logoutStatus: SAGA_STATUS_INIT,
  userExists:{exists:false, role:""},
  userExistsStatus:SAGA_STATUS_INIT,
  merchantRegisterStatus:SAGA_STATUS_INIT,
  merchantRegister:null,
  fetchAppConfigStatus: SAGA_STATUS_INIT,
  appConfig: undefined
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signupLogin(state, action: PayloadAction<SignupPayload>) {
      state.signupLoginStatus = SAGA_STATUS_PENDING;
    },
    signupLoginSuccess(state, action: PayloadAction<any>) {
      state.currentUser = action.payload
      state.signupLoginStatus = SAGA_STATUS_SUCCESS;
    },
    signupLoginFailed(state, action: PayloadAction<string>) {
      state.signupLoginStatus = SAGA_STATUS_FAILED;
    },
    resetSignUpLoginStatus(state) {
      state.signupLoginStatus = SAGA_STATUS_INIT;
    },
    socialSignupLogin(state, action: PayloadAction<SignupPayload>) {
      state.socialSignupLoginStatus = SAGA_STATUS_PENDING;
    },
    socialSignupLoginSuccess(state, action: PayloadAction<any>) {
      state.socialSignupLoginStatus = SAGA_STATUS_SUCCESS;
      state.isLoggedIn = true
      state.currentUser = action.payload
    },
    socialSignupLoginFailed(state, action: PayloadAction<string>) {
      state.socialSignupLoginStatus = SAGA_STATUS_FAILED;
    },
    verifyOtp(state, action: PayloadAction<VerifyOtpPayload>) {
      state.verifyOtpStatus = SAGA_STATUS_PENDING;
      state.isLoggedIn = false;
    },
    verifyOtpSuccess(state, action: PayloadAction<any>) {
      state.verifyOtpStatus = SAGA_STATUS_SUCCESS;
      state.isLoggedIn = true
      state.currentUser = action.payload
    },
    verifyOtpFailed(state, action: PayloadAction<string>) {
      state.verifyOtpStatus = SAGA_STATUS_FAILED;
      state.isLoggedIn = false;
    },
    verifySession(state, action: PayloadAction<{role:string}>) {
      state.verifyOtpStatus = SAGA_STATUS_PENDING;
    },
    verifySessionSuccess(state, action: PayloadAction<any>) {
      state.verifyOtpStatus = SAGA_STATUS_SUCCESS;
      state.currentUser = action.payload;
      state.isLoggedIn = true
    },
    verifySessionFailed(state, action: PayloadAction<string>) {
      state.verifyOtpStatus = SAGA_STATUS_FAILED;
      state.isLoggedIn = false;
    },
    resendOtp(state, action: PayloadAction<VerifyOtpPayload>) {
      state.resendOtpStatus = SAGA_STATUS_PENDING;
    },
    resendOtpSuccess(state, action: PayloadAction<string>) {
      state.resendOtpStatus = SAGA_STATUS_SUCCESS;
    },
    resendOtpFailed(state, action: PayloadAction<string>) {
      state.resendOtpStatus = SAGA_STATUS_FAILED;
    },
    updateProfileData(state, action: PayloadAction<any>) {
      state.updateProfileDataStatus = SAGA_STATUS_PENDING;
    },
    updateProfileDataSuccess(state, action: PayloadAction<any>) {
      state.updateProfileDataStatus = SAGA_STATUS_SUCCESS;
      state.currentUser = action.payload;
    },
    updateProfileDataFailed(state, action: PayloadAction<any>) {
      state.updateProfileDataStatus = SAGA_STATUS_FAILED;
    },
    socialLoginSuccess(state, action: PayloadAction<any>) {
      state.isLoggedIn = true;
      state.currentUser = action.payload;
    },
    logout(state) { 
      state.logoutStatus = SAGA_STATUS_PENDING;
    },
    logoutFailed(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      state.logoutStatus = SAGA_STATUS_FAILED;
    },
    logoutSuccess(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      state.logoutStatus = SAGA_STATUS_SUCCESS;
    },
    updateUserOtpVerification(state, action: PayloadAction<any>) {
      state.verifyUpdateOtpStatus = SAGA_STATUS_PENDING;
    },
    updateUserEmailOtpVerificationSuccess(state, action: PayloadAction<any>) {
      state.verifyUpdateOtpStatus = SAGA_STATUS_SUCCESS;
      state.currentUser = action.payload
    },
    updateUserEmailOtpVerificationFailed(state, action: PayloadAction<string>) {
      state.verifyUpdateOtpStatus = SAGA_STATUS_FAILED;
    },
    resetUserUpdateStatus(state) {
      state.updateProfileDataStatus = SAGA_STATUS_INIT;
    },
    resetUserVerifyOtpStatus(state) {
      state.verifyUpdateOtpStatus = SAGA_STATUS_INIT;
    },
    resetUserLogoutStatus(state) {
      state.logoutStatus = SAGA_STATUS_INIT;
    },
    userProfileDelete(state) {
      state.userProfileDeleteStatus = SAGA_STATUS_PENDING;
    },
    userProfileDeleteSuccess(state) {
      state.userProfileDeleteStatus = SAGA_STATUS_SUCCESS;
    },
    userProfileDeleteFailed(state) {
      state.userProfileDeleteStatus = SAGA_STATUS_FAILED;
    },
    userPortalExists(state, action: PayloadAction<any>) {
      state.userExistsStatus = SAGA_STATUS_PENDING;
    },
    userPortalExistsSuccess(state, action: PayloadAction<{exists:boolean, role:string}>) {
      state.userExistsStatus = SAGA_STATUS_SUCCESS;
      state.userExists = action.payload;
    },
    userPortalExistsFailed(state, action: PayloadAction<any>) {
      state.userExistsStatus = SAGA_STATUS_FAILED;
    },
    merchantRegister(state, action: PayloadAction<any>) {
      state.merchantRegisterStatus = SAGA_STATUS_PENDING;
    },
    merchantRegisterSuccess(state, action: PayloadAction<{exists:boolean, role:string}>) {
      state.merchantRegisterStatus = SAGA_STATUS_SUCCESS;
      state.merchantRegister = action.payload;
    },
    merchantRegisterFailed(state, action: PayloadAction<any>) {
      state.merchantRegisterStatus = SAGA_STATUS_FAILED;
    },
    fetchAppConfig(state) {
      state.fetchAppConfigStatus = SAGA_STATUS_PENDING;
    },
    fetchAppConfigfailed(state, action: PayloadAction<string>) {
      state.fetchAppConfigStatus = SAGA_STATUS_FAILED;
    },
    fetchAppConfigSuccess(state, action: PayloadAction<any>) {
      state.appConfig = decodeJwt(action.payload);
      state.fetchAppConfigStatus = SAGA_STATUS_SUCCESS;
    },
    reset: state => initialState
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const signupLoginStatus = (state: RootState) => state.auth.signupLoginStatus;
export const verifyOtpStatus = (state: RootState) => state.auth.verifyOtpStatus;
export const isUserLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const currentUser = (state: RootState) => state.auth.currentUser;
export const socialSignupLoginStatus = (state: RootState) => state.auth.socialSignupLoginStatus;
export const getUserUpdateStatus = (state: RootState) => state.auth.updateProfileDataStatus;
export const getUserVerifyOtpStatus = (state: RootState) => state.auth.verifyUpdateOtpStatus;
export const getDeleteUserProfileStatus = (state: RootState) => state.auth.userProfileDeleteStatus;
export const getLogoutStatus = (state:RootState)=>state.auth.logoutStatus;
export const getUserExistsinPortal = (state:RootState)=>state.auth.userExists;
export const getUserExistsinPortalStatus = (state:RootState)=>state.auth.userExistsStatus;
export const getMerchantRegisterStatus = (state:RootState)=>state.auth.merchantRegisterStatus;
export const getMerchantRegister = (state:RootState)=>state.auth.merchantRegister;
export const fetchAppConfig = (state: RootState) => state.auth.appConfig;



// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
