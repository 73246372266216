import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { DiscountRequestPayload } from '../models/discountRequest';
import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";

export interface discountRequestsState {
    fetchDiscountRequestsStatus: string;
    processDiscountRequestStatus: string;
    discountRequestsCount: number;
    discountRequests: Array<DiscountRequestPayload>;
}

const initialState: discountRequestsState = {
    fetchDiscountRequestsStatus : SAGA_STATUS_INIT,
    processDiscountRequestStatus: SAGA_STATUS_INIT,
    discountRequestsCount: 0,
    discountRequests: []
};

const discountRequestsSlice = createSlice({
  name: 'discountRequests',
  initialState,
  reducers: {
    discountRequestList(state) {
        state.fetchDiscountRequestsStatus = SAGA_STATUS_PENDING;
    },
    discountRequestListSuccess(state, action: PayloadAction<any>) {
        state.discountRequests = action.payload.result;
        state.discountRequestsCount = Array.isArray(action.payload.result)? action.payload.result.length: 0;
        state.fetchDiscountRequestsStatus = SAGA_STATUS_SUCCESS;
    },
    discountRequestListFailed(state, action: PayloadAction<string>) {
        state.fetchDiscountRequestsStatus = SAGA_STATUS_FAILED;
    },
    processDiscountRequest(state, action: PayloadAction<any>) {
        state.processDiscountRequestStatus = SAGA_STATUS_PENDING;
    },
    processDiscountRequestSuccess(state, action: PayloadAction<any>) {
        state.processDiscountRequestStatus = SAGA_STATUS_SUCCESS;
    },
    processDiscountRequestFailed(state, action: PayloadAction<string>) {
        state.processDiscountRequestStatus = SAGA_STATUS_FAILED;
    },
    resetProcessDiscountRequestStatus(state) {
        state.processDiscountRequestStatus = SAGA_STATUS_INIT;
    },
    resetdiscountRequests(state) {
        state.fetchDiscountRequestsStatus = SAGA_STATUS_INIT;
        state.processDiscountRequestStatus = SAGA_STATUS_INIT;
        state.discountRequestsCount = 0;
        state.discountRequests = [];
    }
  }
});

// Actions
export const discountRequestsActions = discountRequestsSlice.actions;

// Selectors
export const fetchdiscountRequests = (state: RootState) => state.discountRequest.discountRequests;
export const fetchDiscountRequestsCount =  (state: RootState) => state.discountRequest.discountRequestsCount;
export const fetchProcessDiscountRequestStatus = (state: RootState) => state.discountRequest.processDiscountRequestStatus;

// Reducer
const discountRequestsReducer = discountRequestsSlice.reducer;
export default discountRequestsReducer;
