import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler } from "react-hook-form";

import "./add-discount.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  discountActions,
  fetchAddDiscountProfileStatus,
  fetchDeleteDiscountProfileStatus,
  fetchAddDiscountLevelStatus,
  fetchAddRecommendationStatus,
  fetchDeleteDiscountLevelStatus,
  fetchUpdateDiscountLevelStatus,
  fetchUpdateDiscountProfileStatus,
  fetchDiscountProfiles,
  fetchDiscountProfilesStatus,
} from "../../../../reducers/discountsSlice";
import {
  INTEGOLF_DISCOUNT_PROFILE_TYPE_DEFAULT,
  INTEGOLF_DISCOUNT_TYPE_DEFAULT,
  INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY,
  CLUB_ACTIVE_STATUS,
  CLUB_INACTIVE_STATUS,
  SAGA_STATUS_SUCCESS,
  SAGA_STATUS_INIT,
  DISCOUNT_NAME_INTEGOLF,
} from "../../../../utils/constants";
import InfoModal from "../../../../shared/info-modal/info-model";

const activateDiscountProfileStepName = "activateDiscountProfile";
const deActivateDiscountProfileStepName = "deActivateDiscountProfile";
const discountProfileModalText =
  "There are no discount profiles present for this course. You can add recomended discounts and can edit them later on. Do you wish to add them?";

interface AddDiscountProps {
  courseId?: string;
  onContinue: (param: any) => void;
  editClubCourseData: boolean;
}

const AddDiscount: FC<AddDiscountProps> = ({
  courseId,
  onContinue,
  editClubCourseData = false,
}) => {
  const [searchParams] = useSearchParams();

  // if edit step is passed then change button text from next to save
  const editStep = searchParams.get("step");

  const [showModal, setShowModal] = useState(false);
  const [showDiscountProfileModal, setShowDiscountProfileModal] =
    useState(false);
  const [levelType, SetLevelType] = useState("profile");
  const [selectedDiscountProfile, setSelectedDiscountProfile] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const dispatch = useAppDispatch();

  const addDiscountProfileStatus: string = useAppSelector(
    fetchAddDiscountProfileStatus
  );
  const updateDiscountProfilestatus: string = useAppSelector(
    fetchUpdateDiscountProfileStatus
  );
  const deletedDiscountProfileStatus: string = useAppSelector(
    fetchDeleteDiscountProfileStatus
  );
  const addDiscountLevelStatus: string = useAppSelector(
    fetchAddDiscountLevelStatus
  );
  const updateDiscountLevelStatus: string = useAppSelector(
    fetchUpdateDiscountLevelStatus
  );
  const addRecommendedDiscountsStatus: string = useAppSelector(
    fetchAddRecommendationStatus
  );
  const deleteDiscountLevelStatus: string = useAppSelector(
    fetchDeleteDiscountLevelStatus
  );
  const discountProfiles: any = useAppSelector(fetchDiscountProfiles);
  const listDiscountProfilesStatus: string = useAppSelector(
    fetchDiscountProfilesStatus
  );

  /** Call Api to delete discount profile */
  const deleteDiscountProfile = (data: any) => {
    dispatch(discountActions.deleteDiscountProfile(data));
  };

  /** Call Api to add dicount level under a discount profiel */
  const addDiscountLevel = (data: any) => {
    dispatch(discountActions.addDiscountLevel(data));
  };

  const editDiscountLevel = (data: any) => {
    dispatch(discountActions.updateDiscountLevel(data));
  };

  /** Call Api to add recommneded discounts under a discount profiel */
  const addRecommendedDiscounts = (discountProfileId: any) => {
    dispatch(discountActions.addRecommendations(discountProfileId));
  };

  /** Call Api to delete discount level added inside discount profile */
  const deleteDiscountLevel = (discountId: any) => {
    dispatch(discountActions.deleteDiscountLevel(discountId));
  };

  const discountProfileActivate = (
    discountProfileId: any,
    activateDiscountProfile: boolean = true
  ) => {
    const discountProfileStatus = activateDiscountProfile
      ? CLUB_ACTIVE_STATUS
      : CLUB_INACTIVE_STATUS;
    const payload = { _id: discountProfileId, status: discountProfileStatus };
    dispatch(discountActions.updateDiscountProfile(payload));
  };

  type discountProfileInput = {
    profileName: string;
  };

  type discountLevelInput = {
    days: number;
    discount: number;
  };

  const {
    register: addDiscountProfileRegister,
    handleSubmit: handleAddDiscountProfileSubmit,
    formState: { errors: addDiscountProfileErrors },
    reset: resetAddDiscountProfile,
  } = useForm<discountProfileInput>();

  const {
    register: addDiscountLevelRegister,
    handleSubmit: handleAddDiscountLevelSubmit,
    formState: { errors: addDiscountLevelErrors },
    reset: resetAddDiscountLevel,
  } = useForm<discountLevelInput>();

  const onAddDiscountProfileSubmit: SubmitHandler<discountProfileInput> = (
    data
  ) => {
    // if a discount profile is selected then it is an edit
    if (selectedDiscountProfile) {
      let updateProfileObj = {
        _id: selectedDiscountProfile,
        name: data.profileName?.trim(),
      };
      dispatch(discountActions.updateDiscountProfile(updateProfileObj));
    } else {
      // Else a new Discount is asked to be added
      let addProfileObj = {
        courseId: courseId,
        name: data.profileName?.trim(),
      };
      dispatch(discountActions.addDiscountProfile(addProfileObj));
    }
  };

  const onAddDiscountLevelSubmit: SubmitHandler<discountLevelInput> = (
    data
  ) => {
    // if a discount is selected then it is an edit
    if (selectedDiscount) {
      const updateDiscountPayload = {
        _id: selectedDiscount,
        days: Number(data.days),
        discount: Number(data.discount),
      };
      editDiscountLevel(updateDiscountPayload);
    } else {
      // Else a new Discount is asked to be added
      let addDiscountLevelObj = {
        discountProfileId: selectedDiscountProfile,
        days: Number(data.days),
        discount: Number(data.discount),
      };
      addDiscountLevel(addDiscountLevelObj);
    }
  };

  useEffect(() => {
    if (addDiscountProfileStatus == SAGA_STATUS_SUCCESS) {
      dispatch(discountActions.fetchDiscountProfile(courseId));
      setShowModal(false);
      dispatch(discountActions.resetAllDiscountStatus());
    }
  }, [addDiscountProfileStatus]);

  useEffect(() => {
    if (updateDiscountProfilestatus == SAGA_STATUS_SUCCESS) {
      SetLevelType("success");
      resetAddDiscountProfile({ profileName: undefined });
      setSelectedDiscountProfile("");
      dispatch(discountActions.resetAllDiscountStatus());
      dispatch(discountActions.fetchDiscountProfile(courseId));
    }
  }, [updateDiscountProfilestatus]);

  const onCloseClick = () => {
    setShowModal(false);
    resetAddDiscountProfile({ profileName: undefined });
    resetAddDiscountLevel({ days: undefined, discount: undefined });
    setSelectedDiscount("");
    setSelectedDiscountProfile("");
    setTimeout(() => {
      SetLevelType("profile");
    }, 500);
  };

  const openAddDiscountProfileModal = (discountProfileId?: string) => {
    if (discountProfileId) {
      let selectedDiscountProfileObject: any;

      discountProfiles.forEach((discountProfile: any) => {
        if (discountProfile._id == discountProfileId) {
          selectedDiscountProfileObject = discountProfile;
        }
      });

      // reset form with default values
      if (selectedDiscountProfileObject) {
        resetAddDiscountProfile({
          profileName: selectedDiscountProfileObject.name,
        });
      }
    } else {
      resetAddDiscountProfile({ profileName: undefined });
    }

    setShowModal(true);
    SetLevelType("profile");
  };

  const openAddDiscountLevelModal = (discountId?: string) => {
    if (discountId) {
      let selectedDiscountObject: any;

      discountProfiles.forEach((discountProfile: any) => {
        discountProfile.discounts.forEach((discount: any) => {
          if (discount._id == discountId) {
            selectedDiscountObject = discount;
          }
        });
      });

      // reset form with default values
      if (selectedDiscountObject) {
        resetAddDiscountLevel({
          days: selectedDiscountObject.days,
          discount: selectedDiscountObject.discount,
        });
      }
    } else {
      resetAddDiscountLevel({ days: undefined, discount: undefined });
    }

    setShowModal(true);
    SetLevelType("discountLevel");
  };

  const openDeleteProfileModal = () => {
    setShowModal(true);
    SetLevelType("deleteProfile");
  };
  const openActivateDiscountProfileModal = (
    activateDiscountProfile: boolean
  ) => {
    setShowModal(true);
    const levelName = activateDiscountProfile
      ? activateDiscountProfileStepName
      : deActivateDiscountProfileStepName;
    SetLevelType(levelName);
  };

  const openDeleteProfileLevelModal = () => {
    setShowModal(true);
    SetLevelType("deleteProfileLevel");
  };

  const onDeletedDiscountProfile = () => {
    deleteDiscountProfile(selectedDiscountProfile);
  };

  const onDiscountProfileStatusUpdate = (activateDiscountProfile: boolean) => {
    discountProfileActivate(selectedDiscountProfile, activateDiscountProfile);
  };

  /** Call Api to add recommended discount levels */
  const onAddingRecommendedDiscounts = (id: string) => {
    addRecommendedDiscounts(id);
  };

  const onDeleteDiscountLevel = () => {
    deleteDiscountLevel(selectedDiscount);
  };

  // when add recomended is added create 'default' discount profiles and add recomended dicounts on that profile
  const onAddRecommendedDisounts = () => {
    const discountProfilePayload = {
      courseId,
      name: DISCOUNT_NAME_INTEGOLF,
      type: INTEGOLF_DISCOUNT_TYPE_DEFAULT,
    };

    dispatch(discountActions.addDiscountProfile(discountProfilePayload));
    setShowDiscountProfileModal(false);
  };

  /** When discount profile is deleted sucessfuly, close delete popup and show success popup */
  useEffect(() => {
    if (deletedDiscountProfileStatus == SAGA_STATUS_SUCCESS) {
      setShowModal(false);
      SetLevelType("success");
      setSelectedDiscountProfile("");
      // when status is reset to init then 'listDiscountProfilesStatus' use effect will be triggered
      dispatch(discountActions.resetAllDiscountStatus());
    }
  }, [deletedDiscountProfileStatus]);

  /** When discount levels are added successfuly close the popup */
  useEffect(() => {
    if (addDiscountLevelStatus == SAGA_STATUS_SUCCESS) {
      setShowModal(false);
      SetLevelType("success");
      resetAddDiscountLevel({ days: undefined, discount: undefined });
      setSelectedDiscountProfile("");
    }
  }, [addDiscountLevelStatus]);

  useEffect(() => {
    if (updateDiscountLevelStatus == SAGA_STATUS_SUCCESS) {
      SetLevelType("success");
      resetAddDiscountLevel({ days: undefined, discount: undefined });
      setSelectedDiscount("");
      // when status is reset to init then 'listDiscountProfilesStatus' use effect will be triggered
      dispatch(discountActions.resetAllDiscountStatus());
    }
  }, [updateDiscountLevelStatus]);

  /** Ftech profiels again, once recommedtions are added */
  useEffect(() => {
    if (addRecommendedDiscountsStatus == SAGA_STATUS_SUCCESS) {
      dispatch(discountActions.fetchDiscountProfile(courseId));
    }
  }, [addRecommendedDiscountsStatus]);

  /** When discount level is deleted, show success message and recall get discount apu */
  useEffect(() => {
    if (deleteDiscountLevelStatus == SAGA_STATUS_SUCCESS) {
      setShowModal(false);
      SetLevelType("success");
      dispatch(discountActions.fetchDiscountProfile(courseId));
      setSelectedDiscount("");
    }
  }, [deleteDiscountLevelStatus]);

  useEffect(() => {
    if (listDiscountProfilesStatus === SAGA_STATUS_INIT) {
      dispatch(discountActions.fetchDiscountProfile(courseId));
    } else if (listDiscountProfilesStatus === SAGA_STATUS_SUCCESS) {
      // if there are
      if (discountProfiles.length === 0) {
        setShowDiscountProfileModal(true);
      }
    }
  }, [listDiscountProfilesStatus]);

  useEffect(() => {
    dispatch(discountActions.resetAllDiscountStatus());
  }, []);

  return (
    <>
      <div className="addDiscountWrap">
        <div className="formStepper">
          <div>
            <div className="row gutters-5 mb-4">
              <div className="col-12 col-lg">
                <h5 className="mb-2 _h5">Add discounts on your tee times</h5>
              </div>
              <div className="col-12 col-lg-auto">
                {editClubCourseData && (
                  <a
                    className="color-active text-decoration-underline fw700 d-inline-block mb-2"
                    href={void 0}
                    onClick={() => openAddDiscountProfileModal()}
                  >
                    + Add Discount Profile
                  </a>
                )}
              </div>
            </div>

            {discountProfiles &&
              discountProfiles.length > 0 &&
              discountProfiles.map((profile: any, index: number) => (
                <div className="mb-4" key={index}>
                  <div className="row gutters-5 mb-3 align-items-center">
                    <div className="col-12 col-lg">
                      <h6 className="mb-0 fw700 fontsize-16 color-900">
                        {profile.name}{" "}
                        {profile?.status === CLUB_ACTIVE_STATUS ? (
                          <>
                            <span className="badge active">Active</span>
                          </>
                        ) : (
                          <>
                            <span className="badge badgeStatus danger">
                              In-Active
                            </span>
                          </>
                        )}
                      </h6>
                    </div>
                    <div className="col-12 col-lg-auto">
                      <div className="listActionDropdownBlock">
                        <Dropdown align="end">
                          <Dropdown.Toggle className="toggleButton">
                            <em className="icon-vertical-elipse"></em>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <a
                              href={void 0}
                              className="dropdown-item fw700"
                              onClick={() => {
                                setSelectedDiscountProfile(profile._id);
                                openAddDiscountProfileModal(profile._id);
                              }}
                            >
                              Edit
                            </a>
                            {profile?.status !== CLUB_ACTIVE_STATUS ? (
                              <a
                                href={void 0}
                                className="dropdown-item fw700"
                                onClick={() => {
                                  setSelectedDiscountProfile(profile._id);
                                  openActivateDiscountProfileModal(true);
                                }}
                              >
                                Activate Discount Profile
                              </a>
                            ) : (
                              <a
                                href={void 0}
                                className="dropdown-item fw700"
                                onClick={() => {
                                  setSelectedDiscountProfile(profile._id);
                                  openActivateDiscountProfileModal(false);
                                }}
                              >
                                De-Activate Discount Profile
                              </a>
                            )}
                            {
                              <a
                                href={void 0}
                                className="dropdown-item fw700 color-danger"
                                onClick={() => {
                                  setSelectedDiscountProfile(profile._id);
                                  openDeleteProfileModal();
                                }}
                              >
                                Delete
                              </a>
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-border mb-2">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Days to Expire</th>
                          <th>Discount Given</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {profile.discounts &&
                          profile.discounts.length > 0 &&
                          profile.discounts.map(
                            (discounts: any, index: number) => (
                              <tr key={index}>
                                <td className="fw700 fontsize-16">
                                  {index + 1}
                                </td>
                                <td>
                                  <h6 className="mb-0 fw700 fontsize-16">
                                    {discounts.type ==
                                    INTEGOLF_DISCOUNT_TYPE_DEFAULT
                                      ? "Default"
                                      : discounts.type ==
                                        INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY
                                      ? "3rd Party"
                                      : discounts.days == 0
                                      ? "0 (Same Day)"
                                      : `${discounts.days} Or Less`}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="mb-0 fw700 fontsize-16">
                                    {discounts?.discount}%
                                  </h6>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <a
                                      href={void 0}
                                      className="fontsize-18 color-custome"
                                      onClick={() => {
                                        setSelectedDiscount(discounts._id);
                                        openAddDiscountLevelModal(
                                          discounts._id
                                        );
                                      }}
                                    >
                                      <em className="icon-pen"></em>
                                    </a>
                                    {discounts?.type &&
                                      discounts.type !=
                                        INTEGOLF_DISCOUNT_TYPE_DEFAULT && (
                                        <a
                                          href={void 0}
                                          className="fontsize-18 color-custome color-danger ms-3"
                                          onClick={() => {
                                            setSelectedDiscount(discounts._id);
                                            openDeleteProfileLevelModal();
                                          }}
                                        >
                                          <em className="far fa-trash-can"></em>
                                        </a>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}

                        <tr>
                          <td colSpan={4}>
                            <div className="row">
                              <div className="col-auto">
                                <a
                                  href={void 0}
                                  className="color-active text-decoration-underline fw700 d-inline-block"
                                  onClick={() => {
                                    setSelectedDiscountProfile(profile._id);
                                    openAddDiscountLevelModal();
                                  }}
                                  // onClick={openAddDiscountLevelModal}
                                >
                                  +Add Discount Level
                                </a>
                              </div>
                              {profile.discounts && (
                                <div className="col-auto">
                                  <a
                                    href={void 0}
                                    className="color-theme text-decoration-underline fw700 d-inline-block"
                                    onClick={() => {
                                      onAddingRecommendedDiscounts(profile._id);
                                    }}
                                  >
                                    +Add Recommended
                                  </a>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            {(!discountProfiles || discountProfiles.length == 0) && (
              <div>No discount profiles found</div>
            )}
            <div className="pt-4 mt-3">
              <button
                className="button button-primary button-rounded button-min-150 fw700"
                type="button"
                onClick={onContinue}
                disabled={!discountProfiles || discountProfiles.length == 0}
              >
                {!!editStep ? "Save" : "Continue"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Discount profile modal  */}
      <Modal
        className={`discountModalWrapper modalLayoutWrapper `}
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={onCloseClick}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            {levelType == "profile" && (
              <form
                onSubmit={handleAddDiscountProfileSubmit(
                  onAddDiscountProfileSubmit
                )}
              >
                <h4 className="mb-4">Add Discount Profile</h4>
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="profileName"
                      placeholder="Add Name"
                      {...addDiscountProfileRegister("profileName", {
                        required: true,
                        validate: (value) => value.trim() !== "",
                      })}
                    />
                    {addDiscountProfileErrors.profileName?.type && (
                      <div className="isInvalidMessage text-danger">
                        Please enter discount profile name
                      </div>
                    )}
                    <label htmlFor="profileName">Add Name</label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                  // onClick={() => SetLevelType("level")}
                >
                  Save
                </button>
              </form>
            )}

            {/* Add Discount level modal  */}
            {levelType == "discountLevel" && (
              <form
                onSubmit={handleAddDiscountLevelSubmit(
                  onAddDiscountLevelSubmit
                )}
              >
                <h4 className="mb-4">Discount Level Screen</h4>
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="expDate"
                      placeholder="Days to Expire"
                      {...addDiscountLevelRegister("days", {
                        required: true,
                        min: 0,
                      })}
                    />
                    {addDiscountLevelErrors.days?.type == "required" && (
                      <div className="isInvalidMessage text-danger">
                        Please enter no of days
                      </div>
                    )}
                    {addDiscountLevelErrors.days?.type == "min" && (
                      <div className="isInvalidMessage text-danger">
                        Days to expire should be more than 0.
                      </div>
                    )}
                    <label htmlFor="expDate">Days to Expire</label>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="discountOffer"
                      placeholder="Discount Offered"
                      {...addDiscountLevelRegister("discount", {
                        required: true,
                        min: 1,
                        max: 99,
                      })}
                    />
                    {addDiscountLevelErrors.discount?.type == "required" && (
                      <div className="isInvalidMessage text-danger">
                        Please enter discount amount
                      </div>
                    )}
                    {addDiscountLevelErrors.discount?.type == "min" && (
                      <div className="isInvalidMessage text-danger">
                        Discount offered should be more than 1%.
                      </div>
                    )}
                    {addDiscountLevelErrors.discount?.type == "max" && (
                      <div className="isInvalidMessage text-danger">
                        Discount offered should not be more than 99%.
                      </div>
                    )}
                    <label htmlFor="discountOffer">Discount Offered</label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                >
                  Save
                </button>
              </form>
            )}

            {/* Delete Discount profile modal  */}
            {levelType == "deleteProfile" && (
              <div className="text-center px-md-5">
                <img
                  src="./assets/images/info-circle.png"
                  alt="deactivate"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4">
                  Are you sure, you want to delete this discount profile?
                </h3>
                <p className="mb-4 fontsize-16">
                  By deleting this profile your all discount level will be
                  deleted.
                </p>
                <a
                  href={void 0}
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                  onClick={() => onDeletedDiscountProfile()}
                >
                  Yes, Delete
                </a>
              </div>
            )}

            {/** Model of deleting discount level */}
            {levelType == "deleteProfileLevel" && (
              <div className="text-center px-md-5">
                <img
                  src="./assets/images/info-circle.png"
                  alt="deactivate"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4">
                  Are you sure, you want to delete this discount levels?
                </h3>
                <a
                  href={void 0}
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                  // onClick={openSuccessModal}
                  onClick={() => onDeleteDiscountLevel()}
                >
                  Yes, Delete
                </a>
              </div>
            )}

            {levelType == activateDiscountProfileStepName && (
              <div className="text-center px-md-5">
                <img
                  src="./assets/images/info-circle.png"
                  alt="deactivate"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4">Activate this discount profile?</h3>
                <a
                  href={void 0}
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                  // onClick={openSuccessModal}
                  onClick={() => onDiscountProfileStatusUpdate(true)}
                >
                  Yes
                </a>
              </div>
            )}

            {levelType == deActivateDiscountProfileStepName && (
              <div className="text-center px-md-5">
                <img
                  src="./assets/images/info-circle.png"
                  alt="deactivate"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4">De-Activate this discount profile?</h3>
                <a
                  href={void 0}
                  className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                  // onClick={openSuccessModal}
                  onClick={() => onDiscountProfileStatusUpdate(false)}
                >
                  Yes
                </a>
              </div>
            )}

            {levelType == "success" && (
              <div className="successConfirmationModalWrap text-center">
                <span className="markIcon success">
                  <em className="fa-solid fa-circle-check"></em>
                </span>
                <h2 className="mb-3">Successfully updated.</h2>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <InfoModal
        showModal={showDiscountProfileModal}
        message={discountProfileModalText}
        modalImagePath="../assets/images/info-circle.png"
        buttonText="Add recommended"
        onModalButtonClick={onAddRecommendedDisounts}
        onCloseClick={() => setShowDiscountProfileModal(false)}
      />
    </>
  );
};

export default AddDiscount;
