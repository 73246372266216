import login from "../features/auth/pages/login";
import signup from "../features/auth/pages/signup";
import verify from "../features/auth/pages/verify"
import ManageGolfCourse from "../features/manage-golf-course/manage-golf-course";
import AddGolfCourse from "../features/manage-golf-course/components/add-golf-course/add-golf-course";
import Bookings from "../features/bookings/components/bookings/bookings";
import BookingsUpcoming from "../features/bookings/pages/bookings-upcoming/bookings-upcoming";
import BookingsCompleted from "../features/bookings/pages/bookings-completed/bookings-completed";
import BookingsCancelled from "../features/bookings/pages/bookings-cancelled/bookings-cancelled";
import BookingsAll from "../features/bookings/pages/bookings-all/bookings-all";
import BookingDetail from "../features/bookings/pages/booking-detail/booking-detail";
import Courses from "../features/course/components/courses/courses";
import CoursesDetail from "../features/course/components/courses-detail/courses-detail";
import Transition from "../features/transaction/transition";
import TransitionHistory from "../features/transaction/components/transition-history/transition-history";
import TransitionPayoutDetail from "../features/transaction/components/transition-payout-detail/transition-payout-detail";
import PayoutList from "../features/transaction/components/payout-list/payout-list";
import Product from "../features/product/product";
import ProductList from "../features/product/components/product-list/product-list";
import Calendar from "../features/calendar/calendar";
import Dashboard from "../features/dashboard/dashboard";
import Account from "../features/account/account";
import Profile from "../features/account/components/profile/profile";
import Integration from "../features/account/components/integration/integration";
import AddProduct from "../features/product/components/add-product/add-product";
import DiscountRequest from "../features/discount-request/discount-request";
import DiscountRequestAdmin from "../features/discount-request-admin/discount-request-admin";
import MarketingMaterial from "../features/marketingmaterial/marketingmaterial";
import BadWeatherDiscounts from "../features/bad-weather-discounts/bad-weather-discounts";
import InstantDiscounts from "../features/instant-discount/instant-discount";
import InstantDiscountsRequest from "../features/instant-discounts-request/instant-discounts-request";


const Routes = [
  {
    component: signup,
    path: "/",
    title: "Register",
    to: "/dashboard",
    type: "public",
  },
  {
    component: DiscountRequestAdmin,
    path: "discount-requests-admin/:id/:merchantId",
    title: "Discount Requests Admin",
    to: "/",
    type: "public",
  },
  {
    component: BadWeatherDiscounts,
    path: "bad-weather-discount-requests/:clubId",
    title: "Bad Weather Discounts",
    to: "/",
    type: "public",
  },
  {
    component: InstantDiscountsRequest,
    path: "instant-discount-requests/:clubId",
    title: "Instant Discounts",
    to: "/",
    type: "public",
  },
  {
    component: MarketingMaterial,
    path: "marketing-material",
    title: "Marketing Material",
    to: "/",
    type: "private",
  },
  {
    component: ManageGolfCourse,
    path: "register",
    title: "Golf Course | Register Account",
    to: "/",
    type: "private",
    children: [
      // {
      //   component: AddGolfCourse,
      //   path: "golf-course",
      //   title: "Golf Course | Register Account",
      //   to: "/",
      //   type: "private",
      // },
    ]
  },
  {
    component: Bookings,
    path: "bookings",
    title: "Merchant | Bookings",
    to: "/",
    type: "private",
    children: [
      {
        component: BookingsAll,
        path: "all",
        title: "Merchant | All Bookings",
        to: "/all",
        type: "private",
      },
      {
        component: BookingsUpcoming,
        path: "upcoming",
        title: "Merchant | Upcoming Bookings",
        to: "/upcoming",
        type: "private",
      },
      {
        component: BookingsCompleted,
        path: "completed",
        title: "Merchant | Completed Bookings",
        to: "/completed",
        type: "private",
      },
      {
        component: BookingsCancelled,
        path: "cancelled",
        title: "Merchant | Cancelled Bookings",
        to: "/cancelled",
        type: "private",
      },
    ]
  },
  {
    component: BookingDetail,
    path: "booking-detail/:id",
    title: "Merchant | Bookings",
    to: "/",
    type: "private",
  },
  {
    component: Courses,
    path: "courses",
    title: "Merchant | Courses",
    to: "/",
    type: "private",
  },
  {
    component: Dashboard,
    path: "dashboard",
    title: "Merchant | Dashboard",
    to: "/",
    type: "private"
  },
  {
    component: CoursesDetail,
    path: "course",
    title: "Merchant | Courses",
    to: "/",
    type: "private",
  },
  {
    component: Transition,
    path: "transaction",
    title: "Merchant | Courses",
    to: "/",
    type: "private",
    children: [
      {
        component: PayoutList,
        path: "payout",
        title: "Golf Course | Register Account",
        to: "/payout",
        type: "private",
      },
      {
        component: TransitionHistory,
        path: "history",
        title: "Golf Course | Register Account",
        to: "/history",
        type: "private",
      },
      {
        component: TransitionPayoutDetail,
        path: "history/:id",
        title: "Golf Course | Register Account",
        to: "/",
        type: "private",
      },
    ]
  },
  {
    component: Product,
    path: "product",
    title: "Merchant | product",
    to: "/",
    type: "private",
    children: [
      {
        component: ProductList,
        path: "list",
        title: "Merchant | Product | Listing",
        to: "/list",
        type: "private",
      },
      
      {
        component: AddProduct,
        path: "add",
        title: "Merchant | Product | Add Product",
        to: "/add",
        type: "private",
      }
    ]
  },
  {
    component: Calendar,
    path: "calendar",
    title: "Merchant | Calendar",
    to: "/",
    type: "private",
  },
  {
    component: Account,
    path: "account",
    title: "Merchant | My Account",
    to: "/",
    type: "private",
    children: [
      {
        component: Profile,
        path: "profile",
        title: "Merchant | Profile",
        to: "/profile",
        type: "private",
      },
      {
        component: Integration,
        path: "integration",
        title: "Merchant | Integration",
        to: "/profile",
        type: "private",
      }
    ]
  },
  {
    component: DiscountRequest,
    path: "discount-requests",
    title: "Merchant | Discount Requests",
    to: "/",
    type: "private",
  },
  {
    component: InstantDiscounts,
    path: "discounts",
    title: "Merchant | Discounts",
    to: "/",
    type: "private",
  }

];

export default Routes;
