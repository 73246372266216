import React,{FC, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { UserPayoutAccount } from "../../../../models/userPayoutAccounts";
import { 
    CLUB_ACTIVE_STATUS, 
    CLUB_INACTIVE_STATUS, 
    INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_TRANSFER, 
    INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID 
} from "../../../../utils/constants";
import { trimString } from "../../../../utils/common";

interface IPayoutAccountDetails {
    accountNumber : string;
    confirmAccountNumber : string;
    accountHolderName ?: string; 
    bicSwiftCode ?: string;
    status ?: number;
    maskedAccountNumber ?: string;
    interactId ?: string;
    type?: string;
}

interface IAddPayoutAccountDetailsPopUp {
    showModal: boolean;
    payoutAccountInfo ?: UserPayoutAccount;
    onCloseClick : () => void;
    onSubmitClick: (arg: any) => void;
}

const AddPayoutAccountDetailsPopUp : FC<IAddPayoutAccountDetailsPopUp> = ({
    showModal = false,
    payoutAccountInfo,
    onCloseClick,
    onSubmitClick
}) => {

    const { 
        register, 
        unregister,
        handleSubmit, 
        formState: { errors, dirtyFields }, 
        reset, 
        getValues, 
        setError, 
        clearErrors, 
        setValue,
        control: payoutAccountInfoController 
    } = useForm<IPayoutAccountDetails>();

    const payoutAccountInfoType = getValues('type');

    const confirmAccountNumberValidationStep = (isValid: boolean) => {
        if (isValid) {
            clearErrors("confirmAccountNumber");
        } else {
            setError('confirmAccountNumber', {type: 'manual'});
        }
    }

    const validateConfirmAccountNumber = () : boolean => {
        let confirmAccNumberisValid = true;
        if (getValues("confirmAccountNumber") && getValues('accountNumber')) {
            if(getValues("confirmAccountNumber") !== getValues('accountNumber')){
                confirmAccNumberisValid = false;
            }
        }
        return confirmAccNumberisValid;
    }

    const onConfirmAccNumberChange = () => {
        confirmAccountNumberValidationStep(validateConfirmAccountNumber());
    }

    const onSubmit: SubmitHandler<IPayoutAccountDetails> = (data: any) => {

        // validate confirm account number on re render manual errors gets cleared automatically
        if (validateConfirmAccountNumber()) {
            confirmAccountNumberValidationStep(true);
            if (payoutAccountInfo && payoutAccountInfo._id) {
                const { accountHolderName, bicSwiftCode, status, type, interactId } = data;
                
                const updates: any = {
                    ...(dirtyFields["accountHolderName"] ? {accountHolderName}: {}),
                    ...(dirtyFields["bicSwiftCode"] ? { bicSwiftCode }: {}),
                    ...(dirtyFields["status"]? {status}: {}),
                    ...(dirtyFields["type"] ? { type }: {}),
                    ...(dirtyFields["interactId"] ? { interactId }: {})
                };
    
                if (Object.keys(updates).length) {
                    updates["id"] = payoutAccountInfo._id;
                    onSubmitClick(updates);
                }
    
            } else {
                onSubmitClick(data);
            }
        } else {
            confirmAccountNumberValidationStep(false);
        }

    }

    useEffect(()=> {
        if (payoutAccountInfo && payoutAccountInfo._id) {
            // set pop up values
            setValue("accountHolderName",payoutAccountInfo.accountHolderName);
            setValue("maskedAccountNumber", payoutAccountInfo.maskedAccountNumber);
            setValue("bicSwiftCode", payoutAccountInfo.bicSwiftCode);
            setValue("status", payoutAccountInfo?.status);
            setValue("interactId", payoutAccountInfo?.interactId);
            setValue("type", payoutAccountInfo?.type)

            // validation rule still persists after the field is hidden so explicitly unregistering the field
            unregister("accountNumber");
            unregister("confirmAccountNumber")
        }
    }, [payoutAccountInfo]);

    useEffect(() => {
        if (!showModal) {
            reset({type: ''});
        }
    }, [showModal]);

    return <Modal
        className="modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        size="xl"
        keyboard={false}
        centered
    >
        <Modal.Body>
            <div className="container">
                <a href={void 0} className="closeButton" onClick={() => onCloseClick()}>
                    <em className="fa-solid fa-close"></em>
                </a>
                <div className="row justify-content-center">
                    <h4 className="mb-4 text-center">Add your Payout Account details</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="gutters-10 pb-4">
                            <div className="form-floating">
                                <Controller
                                    control={payoutAccountInfoController}
                                    name="type"
                                    render={({field}) => <>
                                        <select
                                            className="form-select border rounded-4 border-dark fw700 fontsize-12 my-2"
                                            id="type"
                                            disabled={!!(payoutAccountInfo && payoutAccountInfo?._id)}
                                            {...field}
                                            {...register("type", {required: true})}
                                            onSelect={(e: any) => field.onChange(e?.target?.value)}
                                        >
                                            <option disabled selected value="">Please select payout account info type</option>
                                            <option value={INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_TRANSFER}>Bank Transfer</option>
                                            <option value={INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID}>Interac ID</option>
                                        </select>
                                    </>}
                                />
                                <label htmlFor="type">Account info type</label>
                                {errors.type?.type == 'required' && <div className="isInvalidMessage text-danger">Account number is required.</div>}
                            </div>
                        </div>

                        {
                            (payoutAccountInfoType == INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_TRANSFER) ? <>
                                {
                                    (payoutAccountInfo && payoutAccountInfo._id) ? <div className="gutters-10 pb-4">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                id="maskaccnumber"
                                                disabled={true}
                                                placeholder="Add Here"
                                                className="form-control"
                                                {...register("maskedAccountNumber")}
                                            />
                                            <label htmlFor="maskaccnumber">Account Number</label>
                                        </div>
                                    </div> : <div className="gutters-10 pb-4">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                id="accnumber"
                                                placeholder="Add Here"
                                                className="form-control"
                                                {...register("accountNumber", { required: true, pattern: /^\d{7,12}$/, onChange: onConfirmAccNumberChange })}
                                            />
                                            <label htmlFor="accnumber">Canadian Account Number</label>
                                        </div>
                                        {errors.accountNumber?.type == 'required' && <div className="isInvalidMessage text-danger">Account number is required.</div>}
                                        {errors.accountNumber?.type == 'pattern' && <div className="isInvalidMessage text-danger">Please enter a valid Account number.</div>}
                                    </div>
                                }    
                                {
                                    !(payoutAccountInfo && payoutAccountInfo._id) && <div className="gutters-10 pb-4">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                id="confirmaccnumber"
                                                placeholder="Add Here"
                                                className="form-control"
                                                {...register("confirmAccountNumber", { required: true, pattern: /^\d{7,12}$/, onChange: validateConfirmAccountNumber})}
                                            />
                                            <label htmlFor="confirmaccnumber">Confirm Account Number</label>
                                        </div>
                                        {errors.confirmAccountNumber?.type == 'required' && <div className="isInvalidMessage text-danger">Confirm Account number is required.</div>}
                                        {errors.confirmAccountNumber?.type == 'pattern' && <div className="isInvalidMessage text-danger">Please enter a valid Account number.</div>}
                                        {errors.confirmAccountNumber?.type == 'manual' && <div className="isInvalidMessage text-danger">Value should match the account number.</div>}
                                    </div> 
                                }
                                <div className="gutters-10 pb-4">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            placeholder="Add Here"
                                            className="form-control"
                                            id="accountHolderName"
                                            {...register("accountHolderName", { 
                                                required: true,
                                                pattern: /^[a-zA-Z\s\-']{2,50}$/,
                                                setValueAs: trimString 
                                            })}
                                        />
                                        <label htmlFor="accountHolderName">Account Holder Name</label>
                                    </div>
                                    {errors.accountHolderName?.type == 'required' && <div className="isInvalidMessage text-danger">Account Holder name is required.</div>}
                                    {errors.accountHolderName?.type == 'pattern' && <div className="isInvalidMessage text-danger">Please enter a valid Account holder name.</div>}
                                </div>
                                <div className="gutters-10 pb-4">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            placeholder="Add Here"
                                            className="form-control"
                                            id="bicSwiftCode"
                                            {...register("bicSwiftCode", { 
                                                required: true,
                                                pattern: /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
                                                setValueAs: trimString 
                                            })}
                                        />
                                        <label htmlFor="bicSwiftCode">BIC/SWIFT Code</label>
                                    </div>
                                    {errors.bicSwiftCode?.type == "required"&& <div className="isInvalidMessage text-danger">BIC/SWIFT code is required.</div>}
                                    {errors.bicSwiftCode?.type == 'pattern' && <div className="isInvalidMessage text-danger">Please enter a valid BIC/SWIFT code.</div>}
                                </div>
                            </> : <div/> 
                        }
                        {
                            (payoutAccountInfoType == INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID) ? <div className="gutters-10 pb-4">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        placeholder="Add Here"
                                        className="form-control"
                                        id="interactId"
                                        {...register("interactId", { 
                                            required: true, 
                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            setValueAs: trimString  
                                        })}
                                    />
                                    <label htmlFor="interactId">Interac Id</label>
                                    {errors.interactId?.type == 'required' && <div className="isInvalidMessage text-danger">Interac ID is required.</div>}
                                    {errors.interactId?.type == 'pattern' && <div className="isInvalidMessage text-danger">Interac ID is should be a valid email.</div>}
                                </div>
                            </div> : <div/>
                        }
                        {
                            (payoutAccountInfo?.status) && <div className="gutters-10 pb-4">
                                <div className="form-floating">
                                    <Controller
                                        control={payoutAccountInfoController}
                                        name="status"
                                        render={({field}) => <>
                                            <select
                                                className="form-select border rounded-4 border-dark fw700 fontsize-12 my-2"
                                                id="status"
                                                disabled={payoutAccountInfo.status == CLUB_ACTIVE_STATUS}
                                                {...field}
                                                onSelect={(e: any) => field.onChange(parseInt(e?.target?.value+''))}
                                            >
                                                <option value={CLUB_ACTIVE_STATUS+''}>Active</option>
                                                <option value={CLUB_INACTIVE_STATUS+''}>Inactive</option>
                                            </select>
                                        </>}
                                    />
                                    <label htmlFor="status">Status (Only Inactive accounts are updated)</label>
                                </div>
                            </div>
                        }
                        <div className="d-grid gap-2">
                            <button
                                type="submit"
                                className="button button-primary button-rounded button-lg fw700"
                            >
                                <span>Save</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div> 
        </Modal.Body>
    </Modal>;
}

export default AddPayoutAccountDetailsPopUp;