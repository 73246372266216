import React, { FC, useEffect, useImperativeHandle, useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  subDays,
} from "date-fns";
import "./month-view-calendar.scss";
import { Navigate, useNavigate } from "react-router";

interface MonthViewCalendarProps {
  monthValue: any;
  calendarData: any;
}

const MonthViewCalendar: FC<MonthViewCalendarProps> = ({
  monthValue,
  calendarData,
}) => {
  const dateOrder = [1, 2, 3, 4, 5, 6, 0];
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate()

  const renderDays = () => {
    const dateFormat = "EEEE";

    const days: any = [];

    let startDate = startOfWeek(monthValue);

    dateOrder.forEach((ele) => {
      days.push(
        <th className="colHeader text-center" key={ele}>
          {format(addDays(startDate, ele), dateFormat)}
        </th>
      );
    });

    return <thead className="colThead">{days}</thead>;
  };


  const renderCells = () => {
    const monthStart = startOfMonth(monthValue);
    const monthEnd = endOfMonth(monthStart);
    const startDate = addDays(startOfWeek(monthStart), 1);
    const endDate = subDays(endOfWeek(monthEnd), 1);

    const dateFormat = "d";
    const rows: Array<JSX.Element> = [];
    let days: any = [];
    let day: any = startDate;
    let formatdate = "";
    let formattedDate = "";
    let formattedDateOfMonth = "";
    let selectedDay = format(new Date(), "dd-MM-yyyy");

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        formatdate = format(day, "yyyy-MM-d");
        formattedDateOfMonth = format(day, "dd-MM-yyyy");
        const cloneDay = day;
        let filterData;
        if (calendarData && calendarData.length > 0) {
          filterData = calendarData.find(
            (d: any) => d?.date?.toString() == formatdate?.toString()
          );
        }

        days.push(
          <td
            className={`cell ${!isSameMonth(day, monthStart) ? "disabled" : ""} 
              ${
                day.getMonth() + 1 !== monthValue.getMonth() + 1 ? "hidden" : ""
              }
              ${formattedDateOfMonth == selectedDay ? "selected" : ""}
              `}
            key={day}
          >
            <div className="dayCell">
              <span className="number">{formattedDate}</span>
              {/* {formatdate} */}
              {/* <span className="bg">{formattedDate}</span> */}
              {filterData && (
                <div className="slots d-inline-flex flex-column" >
                  {filterData?.cancelledBookings > 0 && (
                    <label className="badgeStatus danger my-1 clickable" onClick={()=> navigate('/bookings/cancelled')}>
                      {filterData.cancelledBookings} Cancelled
                    </label>
                  )}
                  {filterData?.upcomingBookings > 0 && (
                    <label className="badgeStatus primary my-1 clickable" onClick={()=> navigate('/bookings/upcoming')}>
                      {filterData.upcomingBookings} Upcoming
                    </label>
                  )}
                </div>
              )}
            </div>
          </td>
        );
        day = addDays(day, 1);
      }
      rows.push(<tr key={day}>{days}</tr>);
      days = [];
    }
    return (
      <table className="tableMonth table mb-0">
        {renderDays()}
        <tbody>{rows}</tbody>
      </table>
    );
  };

  const nextMonth = () => {
    const newMonth = addMonths(currentMonth, 1);
    setCurrentMonth(newMonth);

    console.log(newMonth);
    // onMonthChange(newMonth.getMonth() + 1);
  };

  // useEffect(() => {}, [higlight]);
  return (
    <div className="monthViewCalendar">
      {/* {renderDays()} */}
      <div className="contentBlock">{renderCells()}</div>
    </div>
  );
};

export default MonthViewCalendar;
