import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { payoutTransactionsActions } from '../reducers/payoutTransactionsSlice';
import httpClient from "../api/merchantApi/httpClient";

function* handleListPayoutTransactionsList(action: PayloadAction<any>) {

    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/bookedTeesheets`
    });

    if (error) {
        yield put(payoutTransactionsActions.payoutTransactionsListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(payoutTransactionsActions.payoutTransactionsListSuccess(result.response))
    }
    return { error, result };
}

function* handleFetchActivePayout(action: PayloadAction<any>) {

    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/payouts`
    });

    if (error) {
        yield put(payoutTransactionsActions.fetchActivePayoutFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(payoutTransactionsActions.fetchActivePayoutSuccess(result.response))
    }
    return { error, result };
}

export function* payoutTransactionsSaga() {
    yield takeLatest(payoutTransactionsActions.payoutTransactionsList.type, handleListPayoutTransactionsList);
    yield takeLatest(payoutTransactionsActions.fetchActivePayout.type, handleFetchActivePayout);
}
  