import React, { FC } from "react";
import "./integration.scss";

interface IntegrationProps {}

const Integration: FC<IntegrationProps> = () => {
  return (
    <div className="integrationWrapper profileWrapper">
      <section className="commonHeader">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-auto">
              <a className="button button-s3 button-border button-circle button-sm">
                <em className="fas fa-arrow-left"></em>
              </a>
            </div>
            <div className="col-12 col-md">
              <h2>Integration</h2>
            </div>
          </div>
        </div>
      </section>
      <div className="py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="profileWrapper__section p-0">
                <h3 className="profileWrapper__title mb-3">Integration</h3>
                <p className="color-600 fw500">
                  Integrated tools with integolf
                </p>

                <div className="integrationRow">
                  <div className="logoBlock logoTee">
                    <img
                      src="../assets/images/logo-tee-on.png"
                      alt=""
                      className="img-fluid"
                      width={"65px"}
                    />
                  </div>
                  <div className="field">
                    <label className="_label mb-1">Email Address</label>
                    <h6>alex.jularty245@gmail.com </h6>
                  </div>
                  <div className="statusBlock">
                    <span className="connected fw700 d-flex algin-items-center">
                      <img
                        src="../assets/images/icons/verify.svg"
                        height={18}
                        width={18}
                        alt=""
                      />{" "}
                      Connected
                    </span>
                    {/* <span className="disconnected fw700 d-flex algin-items-center">
                      <img
                        src="../assets/images/icons/warning-circle.svg"
                        height={18}
                        width={18}
                        alt=""
                      />{" "}
                      Discontented
                    </span> */}
                  </div>
                  <div className="actionBlock text-end">
                    <a
                      href={void 0}
                      className="color-active fw700 text-decoration-underline"
                    >
                      Disconnect
                    </a>
                  </div>
                </div>

                <div className="integrationRow">
                  <div className="logoBlock">
                    <img
                      src="../assets/images/logo-chronogolf.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="field">
                    <label className="_label mb-1">Email Address</label>
                    <h6>alex.jularty245@gmail.com </h6>
                  </div>
                  <div className="statusBlock">
                    {/* <span className="connected fw700 d-flex algin-items-center">
                      <img
                        src="../assets/images/icons/verify.svg"
                        height={18}
                        width={18}
                        alt=""
                      />{" "}
                      Connected
                    </span> */}
                    <span className="disconnected fw700 d-flex algin-items-center">
                      <img
                        src="../assets/images/icons/warning-circle.svg"
                        height={18}
                        width={18}
                        alt=""
                      />{" "}
                      Discontented
                    </span>
                  </div>
                  <div className="actionBlock text-end">
                    <a
                      href={void 0}
                      className="color-active fw700 text-decoration-underline"
                    >
                      Connect Again
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
