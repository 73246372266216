import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";
import { RootState } from '../app/store';

export interface discountState {
  fetchDiscountProfileStatus: string;
  discountProfiles: any;
  addDiscountProfileStatus: string;
  updateDiscountProfileStatus: string;
  deleteDiscountProfileStatus: string;
  addDiscountLevelStatus: string;
  updateDiscountLevelStatus: string;
  addRecommendationsStatus: string;
  deleteDiscountLevelStatus: string
}

const initialState: discountState = {
  fetchDiscountProfileStatus: SAGA_STATUS_INIT,
  discountProfiles: [],
  addDiscountProfileStatus: SAGA_STATUS_INIT,
  updateDiscountProfileStatus: SAGA_STATUS_INIT,
  deleteDiscountProfileStatus: SAGA_STATUS_INIT,
  addDiscountLevelStatus: SAGA_STATUS_INIT,
  updateDiscountLevelStatus: SAGA_STATUS_INIT,
  addRecommendationsStatus: SAGA_STATUS_INIT,
  deleteDiscountLevelStatus: SAGA_STATUS_INIT
};

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    fetchDiscountProfile(state, action: PayloadAction<any>) {
      state.fetchDiscountProfileStatus = SAGA_STATUS_PENDING;
    },
    fetchDiscountProfileSuccess(state, action: PayloadAction<any>) {
      state.discountProfiles = action.payload;
      state.fetchDiscountProfileStatus = SAGA_STATUS_SUCCESS
    },
    fetchDiscountProfileFailed(state, action: PayloadAction<string>) {
      state.fetchDiscountProfileStatus = SAGA_STATUS_FAILED;
    },
    addDiscountProfile(state, action: PayloadAction<any>) {
      state.addDiscountProfileStatus = SAGA_STATUS_PENDING;
    },
    addDiscountProfileSuccess(state, action: PayloadAction<any>) {
      // state.discountProfiles = [...state.discountProfiles, action.payload];
      state.addDiscountProfileStatus = SAGA_STATUS_SUCCESS
    },
    addDiscountProfileFailed(state, action: PayloadAction<string>) {
      state.addDiscountProfileStatus = SAGA_STATUS_FAILED;
    },
    updateDiscountProfile(state, action: PayloadAction<any>) {
      state.updateDiscountProfileStatus = SAGA_STATUS_PENDING;
    },
    updateDiscountProfileSuccess(state, action: PayloadAction<any>) {
      state.updateDiscountProfileStatus = SAGA_STATUS_SUCCESS;
    },
    updateDiscountProfileFailed(state, action: PayloadAction<any>) {
      state.updateDiscountProfileStatus = SAGA_STATUS_FAILED;
    },
    resetAllDiscountStatus(state) {
      state.fetchDiscountProfileStatus = SAGA_STATUS_INIT;
      state.addDiscountProfileStatus = SAGA_STATUS_INIT;
      state.updateDiscountProfileStatus = SAGA_STATUS_INIT;
      state.addDiscountLevelStatus = SAGA_STATUS_INIT;
      state.deleteDiscountProfileStatus = SAGA_STATUS_INIT;
      state.addRecommendationsStatus = SAGA_STATUS_INIT;
      state.deleteDiscountLevelStatus = SAGA_STATUS_INIT;
      state.deleteDiscountLevelStatus = SAGA_STATUS_INIT;
      state.updateDiscountLevelStatus = SAGA_STATUS_INIT;
    },
    deleteDiscountProfile(state, action: PayloadAction<any>) {
      state.deleteDiscountProfileStatus = SAGA_STATUS_PENDING;
    },
    deleteDiscountProfileSuccess(state, action: PayloadAction<any>) {
      let filteredData = state.discountProfiles.filter((profile: any) => profile._id != action.payload._id);
      state.discountProfiles = filteredData;
      state.deleteDiscountProfileStatus = SAGA_STATUS_SUCCESS;
    },
    deleteDiscountProfileFailed(state, action: PayloadAction<string>) {
      state.deleteDiscountProfileStatus = SAGA_STATUS_FAILED;
    },
    addDiscountLevel(state, action: PayloadAction<any>) {
      state.addDiscountLevelStatus = SAGA_STATUS_PENDING;
    },
    addDiscountLevelSuccess(state, action: PayloadAction<any>) {
      let discountProfileIndex = state.discountProfiles.findIndex((profile: any) => profile._id == action.payload.discountProfileId)
      if (discountProfileIndex > -1) {
        let existingDiscounts = state.discountProfiles[discountProfileIndex].discounts
        state.discountProfiles[discountProfileIndex].discounts =  existingDiscounts ? [...existingDiscounts, action.payload]: action.payload
      }
      state.addDiscountLevelStatus = SAGA_STATUS_SUCCESS;
    },
    addDiscountLevelFailed(state, action: PayloadAction<string>) {
      state.addDiscountLevelStatus = SAGA_STATUS_FAILED;
    },
    updateDiscountLevel(state, action: PayloadAction<any>) {
      state.updateDiscountLevelStatus = SAGA_STATUS_PENDING;
    },
    updateDiscountLevelSuccess(state, action: PayloadAction<any>) {
      state.updateDiscountLevelStatus = SAGA_STATUS_SUCCESS;
    },
    updateDiscountLevelFailed(state, action: PayloadAction<any>) {
      state.updateDiscountLevelStatus = SAGA_STATUS_FAILED;
    },
    addRecommendations(state, action: PayloadAction<string>) {
      state.addRecommendationsStatus = SAGA_STATUS_PENDING;
    },
    addRecommendationsSuccess(state, action: PayloadAction<any>) {
      state.addRecommendationsStatus = SAGA_STATUS_SUCCESS;
    },
    addRecommendationsFailed(state, action: PayloadAction<string>) {
      state.addRecommendationsStatus = SAGA_STATUS_FAILED;
    },
    deleteDiscountLevel(state, action: PayloadAction<any>){
      state.deleteDiscountLevelStatus = SAGA_STATUS_PENDING
    },
    deleteDiscountLevelFailed(state, action: PayloadAction<string>){
      state.deleteDiscountLevelStatus = SAGA_STATUS_FAILED
    },
    deleteDiscountLevelSuccess(state, action: PayloadAction<any>){
      state.deleteDiscountLevelStatus = SAGA_STATUS_SUCCESS
    },

  },
});

// Actions
export const discountActions = discountSlice.actions;

// Selectors
export const fetchDiscountProfiles = (state: RootState) => state.discounts.discountProfiles;
export const fetchAddDiscountProfileStatus = (state: RootState) => state.discounts.addDiscountProfileStatus;
export const fetchDeleteDiscountProfileStatus = (state: RootState) => state.discounts.deleteDiscountProfileStatus;
export const fetchAddDiscountLevelStatus = (state: RootState) => state.discounts.addDiscountLevelStatus;
export const fetchAddRecommendationStatus = (state: RootState) => state.discounts.addRecommendationsStatus;
export const fetchDeleteDiscountLevelStatus = (state: RootState) => state.discounts.deleteDiscountLevelStatus;
export const fetchUpdateDiscountLevelStatus = (state: RootState) => state.discounts.updateDiscountLevelStatus;
export const fetchUpdateDiscountProfileStatus = (state: RootState) => state.discounts.updateDiscountProfileStatus;
export const fetchDiscountProfilesStatus = (state: RootState) => state.discounts.fetchDiscountProfileStatus;

// Reducer
const discountReducer = discountSlice.reducer;
export default discountReducer;
