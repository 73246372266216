import React, { useState, useEffect } from 'react';

import "./payout-account-details.scss"

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchUserPayoutAccounts, fetchAddNewUserPayoutAccountStatus, fetchUpdateUserPayoutAccountStatus, userPayoutAccountsActions } from '../../../../reducers/userPayoutAccountSlice';
import { authActions, currentUser, fetchAppConfig } from '../../../../reducers/authSlice';
import { CLUB_ACTIVE_STATUS, CLUB_INACTIVE_STATUS, INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID, PAYOUT_INTERVAL_TO_LABEL_MAPPING, SAGA_STATUS_SUCCESS } from '../../../../utils/constants';
import { UserPayoutAccount } from '../../../../models/userPayoutAccounts';
import DeleteModal from '../../../../shared/delete-modal/delete-modal';
import AddPayoutAccountDetailsPopUp from './add-payout-account-details-pop-up';
import { aesEncryptData } from '../../../../utils/common';


export function PayoutAccountDetails() {

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [payoutAccountInfoId, setPayoutAccountInfoId] = useState('');
  
    const handleOpen = () => setOpenEditDialog(!openEditDialog);

    const dispatch = useAppDispatch();

    const userSavedPayoutAccounts: Array<UserPayoutAccount> = useAppSelector(fetchUserPayoutAccounts);
    const appConfig: any = useAppSelector(fetchAppConfig);
    const userProfile: any = useAppSelector(currentUser);

    const addNewPayoutAccountStatus: string = useAppSelector(fetchAddNewUserPayoutAccountStatus);
    const updatePayoutAccountStatus: string = useAppSelector(fetchUpdateUserPayoutAccountStatus);

    
    const onEditClick = (payoutAccountInfo: UserPayoutAccount) => {
        setPayoutAccountInfoId(payoutAccountInfo?._id);
        setOpenEditDialog(true);
    }

    const onRemoveClick = (payoutAccountInfo: UserPayoutAccount) => {
        // only remove if the payout account info is not active
        if (payoutAccountInfo.status == CLUB_INACTIVE_STATUS) {
            setPayoutAccountInfoId(payoutAccountInfo._id);
            setOpenDeleteDialog(true);
        }
    }

    const onPayoutAccountSubmit = (data: any) => {
        const encryptedData = aesEncryptData(JSON.stringify(data), appConfig?.aesApiSecretKey);
        console.log(data);
        if (data.id) {
            dispatch(userPayoutAccountsActions.updatePayoutAccountInfo({data: encryptedData}));    
        } else {
            dispatch(userPayoutAccountsActions.addNewPayoutAccountInfo({data: encryptedData}));
        }
    }

    const deletePayoutAccountInfo = () => { 
        const onDeletePayload = {
            "id": payoutAccountInfoId,
            "isDeleted": true
        };
        const encryptedData = aesEncryptData(JSON.stringify(onDeletePayload), appConfig?.aesApiSecretKey);
        dispatch(userPayoutAccountsActions.updatePayoutAccountInfo({data: encryptedData}));
        
        setOpenDeleteDialog(false);
        setPayoutAccountInfoId('');
    }

    const onEditPopUpClose = () => {
        setOpenEditDialog(false);
        setPayoutAccountInfoId('');
    }

    const onDeletePopUpClose = () => {
        setOpenDeleteDialog(false);
        setPayoutAccountInfoId('');
    }

    const getPayoutAccountDetail = (payoutAccountId ?: string) => {
        if (payoutAccountId && userSavedPayoutAccounts && userSavedPayoutAccounts.length) {
            const payoutAccount = userSavedPayoutAccounts.find((payoutObj:UserPayoutAccount) => payoutObj._id == payoutAccountId);
            return payoutAccount;
        }
    }

    const onPayoutIntervalChange = (e: any) => {
        const value = e?.target?.value;
        if (userProfile?.payoutInterval !== value) {
            const userDataUpdatePayload = {
                _id: userProfile._id,
                payoutInterval: value 
            };
            dispatch(authActions.updateProfileData(userDataUpdatePayload));
            dispatch(authActions.resetUserUpdateStatus());
        }
    }

    useEffect(() => {

        // reset status
        if (addNewPayoutAccountStatus === SAGA_STATUS_SUCCESS) {
            dispatch(userPayoutAccountsActions.fetchPayoutAccounts({}));
            dispatch(userPayoutAccountsActions.resetResetAddNewPayoutAccountStatus());
        }

        if (updatePayoutAccountStatus === SAGA_STATUS_SUCCESS) {
            dispatch(userPayoutAccountsActions.fetchPayoutAccounts({}));
            dispatch(userPayoutAccountsActions.resetResetupdateUserPayoutAccountStatus());   
        }

        onEditPopUpClose();

    }, [addNewPayoutAccountStatus, updatePayoutAccountStatus]);

    // on load fetch all listed payout accounts
    useEffect(() => {
        dispatch(userPayoutAccountsActions.fetchPayoutAccounts({}));
    }, [])
    
    return <>

        <div className="profileWrapper__section">
            <div className="py-4">
                <h3 className="profileWrapper__title mb-3">
                    Payout Account Details
                </h3>
                <p className="color-600 fw500 mb-0">
                    Add/Save your payout account details which will be used to receive your payouts. You can add mulitple accounts related and can switch between them by activating the account. You can not delete your active payout account only the in active ones are allowed to be deleted.
                </p>
            </div>
            <div className="pt-2">
                <div className="payout_info_card gutters-10">
                    <div className="row">
                        <div className="col-1">
                            <span className="col-2 p-1 align-items-center text-center">
                                <em className="fa fa-university fa-xl text-primary" />
                            </span>
                        </div>
                        <div className="col-8">
                            <span className="col-6 align-items-center">
                                Add new payout option.
                            </span>
                        </div>
                        <div className="col-3 text-center">
                            <span className="clickable font-bold text-primary" onClick={handleOpen}>
                                + Add
                            </span>
                        </div>
                    </div>
                </div>
                {
                    userSavedPayoutAccounts && userSavedPayoutAccounts.map(userPayoutAccount => (
                        <div className="payout_info_card gutters-10" key={userPayoutAccount?._id}>
                            <div className="row">
                                <div className="col-1">
                                    <span className="col-2 p-1 align-items-center text-center">
                                        <em className="fa fa-university fa-xl text-primary" />
                                    </span>
                                </div>
                                <div className="col-8">
                                    <div className='row'>
                                        {
                                            userPayoutAccount?.accountHolderName && <div className='col-auto'>{(userPayoutAccount?.accountHolderName)}</div>
                                        }
                                        <div className='col-auto'>
                                            <p className="text-sm">
                                                {
                                                    userPayoutAccount?.type === INTEGOLF_BANK_ACCOUNT_INFO_TYPE_BANK_INTERACT_ID ?
                                                    userPayoutAccount?.interactId :
                                                    userPayoutAccount?.maskedAccountNumber  
                                                }
                                            </p>
                                        </div>
                                        <div className='col-auto'>
                                            {
                                                userPayoutAccount?.status === CLUB_ACTIVE_STATUS && <a href={void 0} className="col-auto font-medium align-top">
                                                    <em className="fa fa-check-circle" color="#00D18C"/>
                                                </a> 
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3 align-items-center text-center'>
                                    <a href={void 0} className="clickable text-primary " onClick={() => onEditClick(userPayoutAccount)}>
                                        Edit
                                    </a>
                                    {
                                        (userPayoutAccount.status == CLUB_INACTIVE_STATUS) && <>
                                            &nbsp;/&nbsp;
                                            <a href={void 0} className="clickable text-danger ml-6 font-bold" onClick={() => onRemoveClick(userPayoutAccount)}>
                                                Remove
                                            </a>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="profileWrapper__section">
            <div className="row align-items-center">
                <div className="col-12 col-md">
                    <h3 className="profileWrapper__title mb-3">
                        Payout Cadence
                    </h3>
                    <p className="color-600 fw500 mb-0">
                        The interval in which amounts all the cancelled/completed bookings are accumulated under an invoice and is sent to marchant. <br/><br/> 1. <b>Weekly</b>: The payout will be ready on upcoming Monday. <br/> 2. <b>Twice a month</b>: The payout will be ready on either the 15th of the month or the end of the month which ever is upcoming. <br/> 3. <b>Monthly</b>: The payout will be ready on the end of the month. <br/><br/> <b>Note:</b> Updating payout cadence will take effect after any upcoming payouts are processed.
                    </p>
                </div>
                <div className="col-12 col-md-auto">
                    <select
                        className="form-select border rounded-4 border-dark py-3 fw700 fontsize-12 my-2"
                        onChange={(e:any) => onPayoutIntervalChange(e)}
                    >
                        <option disabled hidden selected>
                            Select Payout Interval
                        </option>
                        {
                        PAYOUT_INTERVAL_TO_LABEL_MAPPING.map((obj, index) => <option
                            value={obj.code}
                            key={index}
                            className="my-4"
                            selected={obj.code == userProfile?.payoutInterval}
                        >
                            {obj.label}
                        </option>)
                        }
                    </select>  
                </div>
            </div>
        </div>

        {/* Modal popup*/}
        <AddPayoutAccountDetailsPopUp 
            showModal={openEditDialog} 
            onCloseClick={onEditPopUpClose}
            onSubmitClick={onPayoutAccountSubmit}
            payoutAccountInfo={getPayoutAccountDetail(payoutAccountInfoId)}
        />
        
        {/* Modal popup*/}
        <DeleteModal
            message="Are you sure you want to delete the payout account info? Once the info is deleted it is not retrievable.?"
            buttonText="Yes, please delete."
            showModal={openDeleteDialog}
            onCloseClick={onDeletePopUpClose}
            onDeleteClick={deletePayoutAccountInfo}
        />
    </>;
} 