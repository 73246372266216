import React, { FC, useEffect, useState } from "react";
import "./courses-detail.scss";
import { Rating } from "react-simple-star-rating";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ClubPayload } from "../../../../models/clubs";
import { CoursesPayload } from "../../../../models/courses";
import { fetchClubData } from "../../../../reducers/clubsSlice";
import { fetchCourseData, fetchCourseDeleteStatus, coursesActions } from "../../../../reducers/coursesSlice";
import { clubsActions } from "../../../../reducers/clubsSlice";
import { reviewsActions } from "../../../../reducers/reviewsSlice";
import { CLUB_ACTIVE_STATUS, CLUB_DESCRIPTION_STEP, CLUB_FACILITIES_STEP, CLUB_INACTIVE_STATUS, CLUB_LOCATION_MAP_STEP, CLUB_PICTURES_STEP, COURSE_BASICS_STEP, COURSE_DISCOUNT_STEP, COURSE_TIME_AND_DAYS_STEP, INTEGOLF_DISCOUNT_TYPE_DEFAULT, INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY } from "../../../../utils/constants";
import DeactivateModal from "../../../../shared/deactivate-modal/deactivate-modal";
import DeleteCourseModal from "../../../../shared/delete-course-modal/delete-course-modal";
import { ReviewsPayload } from "../../../../models/reviews";
import { fetchAverageRatings, fetchReviews, fetchTotalReviewsCount } from "../../../../reducers/reviewsSlice";
import { convertTime24to12 } from "../../../../utils/common";
import { fetchDiscountProfiles, discountActions } from "../../../../reducers/discountsSlice";

const DEFAULT_USER_IMAGE_PATH = "../assets/images/default-user.png";

interface CourseDiscountsProps {
  discounts: Array<any>;
  status: number;
  name: string;
  type: string;
  _id: string;
}

const CourseDiscounts: FC<CourseDiscountsProps> = ({
  discounts = [],
  status = CLUB_ACTIVE_STATUS,
  name = '',
  _id = '',
}) => (
  <div className="row mt-4" key={_id}>
    <div className="col-12">
      <h6 className="mb-2 fw600 color-800">{name} { (status === CLUB_ACTIVE_STATUS) ? <>
            <span className="badge active">Active</span>
          </>: <>
            <span className="badge badgeStatus danger">In-Active</span>
          </> 
        }
      </h6>
      <div className="d-block">
        {
          discounts.map((discountObj: any) => <label className="discountBadge" key = {discountObj?._id}>
            { discountObj?.type == INTEGOLF_DISCOUNT_TYPE_DEFAULT ? "Default" : ( discountObj?.type == INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY ? "3rd Party" : `${discountObj?.days == 0 ? "Current" : discountObj?.days} Day` )  } booking : {discountObj?.discount}%
          </label>
          )
        }
      </div>
    </div>
  </div>
);

interface CoursesDetailProps {}

const CoursesDetail: FC<CoursesDetailProps> = () => {
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const courseId = searchParams.get("courseId");
  const clubId = searchParams.get("clubId");

  const [showDeactivateCourseModal, setShowDeactivateCourseModal] = useState(false);
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false);
  const [activeDataType, SetActiveDataType] = useState(1);

  const club: ClubPayload = useAppSelector(fetchClubData);
  const course: CoursesPayload = useAppSelector(fetchCourseData);
  const reviews: Array<ReviewsPayload> = useAppSelector(fetchReviews);
  const averageRatings: number = useAppSelector(fetchAverageRatings);
  const reviewCount: number = useAppSelector(fetchTotalReviewsCount);
  const discountProfiles: any = useAppSelector(fetchDiscountProfiles);

  const handleCloseModal = (e: any) => {
    setShowDeactivateCourseModal(false);
    setShowDeleteCourseModal(false);
    dispatch(coursesActions.resetCourseDeleteStatus());
  };

  const deactivateCourse = (courseId: string) => {
    const activateDeactivateCoursePayload = { "_id": courseId, "status": CLUB_INACTIVE_STATUS };
    dispatch(coursesActions.updateCourseData(activateDeactivateCoursePayload));
    setShowDeactivateCourseModal(false);
  }

  const deleteCourse = (courseId: string, reason: string, description: string) => {
    const deleteCoursePayload = { "id": courseId, reason, description };
    dispatch(coursesActions.deleteCourseData(deleteCoursePayload)); 
    setShowDeleteCourseModal(false);
    return true;
  }

  const onDeleteSuccess = () => {
    dispatch(coursesActions.resetCourseDeleteStatus());
    navigate("/courses");
  }

  const onCourseDetailEdit = (activeStep: number) => {
    navigate(`/register?courseId=${courseId}&clubId=${clubId}&step=${activeStep}`);
  }

  useEffect(() => {
    const elem: any = document.getElementById("mainBodyWrapper");
    elem.className = "mainBodyWrapper whiteBg";
    return () => {
      elem.className = "mainBodyWrapper";
    };
  });

  useEffect(() => {
    dispatch(coursesActions.fetchCourseData({id: courseId}));
    dispatch(clubsActions.fetchSelectedClubData({id: clubId}));
    dispatch(reviewsActions.reviewList({courseId: courseId}));
    dispatch(discountActions.fetchDiscountProfile(courseId));

    // reset data on unmount
    return () => {
      dispatch(clubsActions.resetSelectedClub());
      dispatch(coursesActions.resetSelectedCourse());
    };

  }, []);
  
  const {
    name,
    description,
    photos = [],
    walkingAllowed = false, 
    metalSpikesAllowed = false, 
    dressCode = false, 
    cartsForRentalAvailable = false, 
    clubsForRentalAvailable = false, 
    caddiesForRentalAvailable = false, 
    drivingRange = false, 
    pitchingArea = false, 
    puttingGreen = false, 
    teachingPro = false ,
    formattedName,
    clubTimings = [],
    tourismPortalClubTimings = [],
  } = (club||{});

  const {
    holes,
    par,
    slope,
    distance,
    status
  } = (course||{});
  
  return (
    <>
      <div className="coursesDetailWrapper">
        <section className="commonHeader pb-4">
          <div className="container">
            <div className="row gutters-10 align-items-center">
              <div className="col-12 mb-3">
                <a className="button button-s3 button-border button-circle button-sm" onClick={() => navigate(-1)}>
                  <em className="fas fa-arrow-left"></em>
                </a>
              </div>
              <div className="col-12">
                <h1 className="mb-2">{name}</h1>
                <p className="fw500">
                  <div className="row">
                    <div className="col-auto">
                      <em className="icon-location mr-2"></em> {formattedName}
                    </div>
                    <div className="col-auto clickable">
                      <em className="fa-regular fa-pen-to-square fa-sm" onClick={() => onCourseDetailEdit(CLUB_LOCATION_MAP_STEP)}></em>
                    </div>
                  </div>
                </p>
                <div className="_rating mb-4">
                  <Rating
                    initialValue={Math.floor(averageRatings||0)}
                    showTooltip
                    onClick={function noRefCheck() {}}
                    size={20}
                    fillColor={"#18C233"}
                    tooltipArray={["1.0", "2.0", "3.0", "4.0", "5.0"]}
                    readonly
                    tooltipDefaultText={"No Reviews Yet"}
                  />
                </div>
                <div className="row gutters-5 pt-3 mb-2">
                  <div className="col">
                    <button
                      type="button"
                      className={`button button-s3 button-border button-sm button-pill buttonStatus px-4 me-2 ${
                        activeDataType == 1 ? "isActive" : ""
                      }`}
                    >
                      Course Details
                    </button>
                    {/* <button
                      type="button"
                      className={`button button-s3 button-border button-sm button-pill buttonStatus px-4 ${
                        activeDataType == 2 ? "isActiveks" : ""
                      }`}
                    >
                      Tee Times
                    </button> */}
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    {/* <button
                      type="button"
                      className="_actionButton d-flex align-items-center"
                      onClick={() => navigate(`/register?courseId=${courseId}&clubId=${clubId}`)}
                    >
                      <em className="icon-pen me-2"></em>{" "}
                      <span>Edit Course</span>
                    </button>
                  <span className="color-s1 mx-2"> | </span>*/}
                    {
                      status === CLUB_ACTIVE_STATUS ? <>
                        <button
                          type="button"
                          className="_actionButton d-flex align-items-center"
                          onClick={() => setShowDeactivateCourseModal(true)}
                        >
                          <em className="fa-regular fa-circle-pause me-2"></em>
                          <span>Deactivate</span>
                        </button>
                      </> : <>
                        <button
                          type="button"
                          className="_actionButton d-flex align-items-center"
                          onClick={() => setShowDeleteCourseModal(true)}
                        >
                          <em className="fa-regular fa-minus-square me-2"></em>
                          <span>Delete Course</span>
                        </button>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="courseDetailSection">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-7 col-xl-8">
                <div className="_detailSection">
                  <div className="row">
                    <div className="col-11">
                      <h3 className="_sectionTitle">Course Description</h3>
                    </div>
                    <div className="col-1 clickable">
                      <em className="fa-regular fa-pen-to-square fa-lg" onClick={() => onCourseDetailEdit(CLUB_DESCRIPTION_STEP)}></em>
                    </div>
                  </div>
                  <p className="mb-4 fw500">
                    {description}
                  </p>
                  <div className="row fw700">
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Holes</label>
                        <h6 className="mb-0 color-800">{(holes||"-")}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Par</label>
                        <h6 className="mb-0 color-800">{(par||"-")}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Slope</label>
                        <h6 className="mb-0 color-800">{(slope||"-")}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Length</label>
                        <h6 className="mb-0 color-800">{(distance||"0")} yards</h6>
                      </div>
                    </div>
                    <div className="col-auto clickable">
                      <em className="fa-regular fa-pen-to-square fa-sm" onClick={() => onCourseDetailEdit(COURSE_BASICS_STEP)}></em>
                    </div>
                  </div>
                </div>
                <div className="_detailSection">
                  <div className="row">
                    <div className="col-11">
                      <h3 className="mb-4 pb-2 _sectionTitle">Things to know</h3>
                    </div>
                    <div className="col-1 clickable">
                      <em className="fa-regular fa-pen-to-square fa-lg" onClick={() => onCourseDetailEdit(CLUB_FACILITIES_STEP)}></em>
                    </div>
                  </div>
                  <div className="row gutters-5">
                    <div className="col">
                      <h6 className="fw700 mb-2">Policies</h6>
                      <ul>
                        <li>Walking allowed: {walkingAllowed ? 'Yes': 'No'}</li>
                        <li>Metal spikes allowed: {metalSpikesAllowed ? 'Yes': 'No'}</li>
                        <li>Dress Code: { dressCode ? 'Yes': 'No'} </li>
                      </ul>
                    </div>
                    <div className="col">
                      <h6 className="fw700 mb-2">Practice/Instruction</h6>
                      <ul>
                        <li>Driving range: {drivingRange ? 'Yes': 'No'}</li>
                        <li>Pitching/chipping area: { pitchingArea ? 'Yes': 'No' }</li>
                        <li>Putting green: {puttingGreen ? 'Yes': 'No'}</li>
                        <li>Teaching pro: {teachingPro ? 'Yes': 'No'}</li>
                      </ul>
                    </div>
                    <div className="col">
                      <h6 className="fw700 mb-2">Rentals/Services</h6>
                      <ul>
                        <li>Carts: {cartsForRentalAvailable ? 'Yes': 'No'}</li>
                        <li>Clubs: {clubsForRentalAvailable ? 'Yes': 'No'}</li>
                        <li>Caddies available: {caddiesForRentalAvailable ? 'Yes': 'No'}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="_detailSection">
                  <div className="row">
                    <div className="col-11">
                      {
                        (clubTimings && clubTimings.length) ? <>
                          <div className="row pl-2">
                            <h5 className="_sectionTitle mb-3">Booking Timings(Discounted Portal)</h5>
                            {clubTimings.map(timing => <>
                              <div className="row align-items-center gutters-10 mb-2">
                                <div className="col-auto">
                                  <label className="daysLabel my-1 capitalize-class me-0">
                                    {timing.day?.substring(0,3)}
                                  </label>
                                </div>
                                <div className="col">
                                  <div className="row gutters-10">
                                    <div className="col-auto">
                                      <h6 className="my-1 fw700">
                                        <span className="fw400">Opening Time:</span>
                                        <span>{convertTime24to12(timing.startHours, timing?.startMinutes)}</span>
                                      </h6>
                                    </div>
                                    <div className="col-auto">
                                      <h6 className="my-1 fw700">
                                        <span className="fw400">Closing Time:</span>
                                        <span>{convertTime24to12(timing.endHours, timing?.endMinutes)}</span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>)}
                          </div>
                        </>: <></>
                      }
                      {
                        (tourismPortalClubTimings && tourismPortalClubTimings.length) ? <>
                          <div className="row pb-4 pl-2">
                            <h5 className="_sectionTitle mb-3">Booking Timings(Tourism Portal)</h5>
                            {tourismPortalClubTimings.map(timing => <>
                              <div className="row align-items-center gutters-10 mb-2">
                                <div className="col-auto">
                                  <label className="daysLabel my-1 capitalize-class me-0">
                                    {timing.day?.substring(0,3)}
                                  </label>
                                </div>
                                <div className="col">
                                  <div className="row gutters-10">
                                    <div className="col-auto">
                                      <h6 className="my-1 fw700">
                                        <span className="fw400">Opening Time:</span>
                                        <span>{convertTime24to12(timing.startHours, timing?.startMinutes)}</span>
                                      </h6>
                                    </div>
                                    <div className="col-auto">
                                      <h6 className="my-1 fw700">
                                        <span className="fw400">Closing Time:</span>
                                        <span>{convertTime24to12(timing.endHours, timing?.endMinutes)}</span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>)}
                          </div>
                        </>: <></>
                      }
                    </div>
                    <div className="col-1">
                      <div className="col-1 clickable">
                        <em className="fa-regular fa-pen-to-square fa-lg" onClick={() => onCourseDetailEdit(COURSE_TIME_AND_DAYS_STEP)}></em>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_detailSection">
                  <div className="row">
                    <div className="col-11">
                    { 
                      discountProfiles.length && <>
                        <div className="_detailSection border-0">
                          <h5 className="_sectionTitle mb-4">Discounts</h5>
                            {
                              discountProfiles.map((discountProfile: any) => <CourseDiscounts 
                                key = {discountProfile?._id}
                                discounts = {discountProfile?.discounts}
                                status = {discountProfile?.status}
                                name = {discountProfile?.name}
                                _id = {discountProfile?._id}
                                type = {discountProfile?.type}
                              />)
                            }
                        </div> 
                      </>
                    }
                    </div>
                    <div className="col-1">
                      <em className="fa-regular fa-pen-to-square fa-lg clickable" onClick={() => onCourseDetailEdit(COURSE_DISCOUNT_STEP)}></em>
                    </div>
                  </div>
                </div>
                <div className="_detailSection">
                  <h3 className="mb-3 _sectionTitle">
                    <em className="fa-solid fa-star me-1"></em> {`${averageRatings}`} · {`${reviewCount} `}
                    reviews
                  </h3>
                  <div className="row">
                    {(reviews||[]).map((review, i) => (
                      <div className="col-12 col-sm-6">
                        <div className="reviewBlock">
                          <div className="row gutters-7 align-items-center">
                            <div className="col-auto">
                              <span className="imgBlock">
                                <img
                                  src={review.user?.photos?.length ? review.user?.photos[0].path :DEFAULT_USER_IMAGE_PATH}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                            </div>
                            <div className="col">
                              <h6 className="fontsize-16 mb-2 fw700">{review?.user?.firstname} {(review?.user?.lastname||"")}</h6>
                              <label className="mb-0">{(new Date(review.createdAt||"")).toDateString()}</label>
                            </div>
                          </div>
                          <p className="mb-0 mt-2">
                            {review?.bookingReview?.review}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-5 col-xl-4">
                <div className="row">
                  <div className="col-11">
                    <div className="coursImagesWrap">
                      {
                        photos.map((e, index) => (
                          <div className="imgBlock" key={index}>
                            <img src={e.path} alt="" className="img-fluid" />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="col-1">
                    <em className="fa-regular fa-pen-to-square fa-sm clickable" onClick={() => onCourseDetailEdit(CLUB_PICTURES_STEP)}></em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <DeactivateModal
        showModal={showDeactivateCourseModal}
        handleClose={() => setShowDeactivateCourseModal(false)}
        onDeactivateClick={()=>deactivateCourse(courseId||"")}
      ></DeactivateModal>
      <DeleteCourseModal
        courseId={courseId||""}
        showModal={showDeleteCourseModal}
        onCloseClick={handleCloseModal}
        onDelete={deleteCourse}
        onDeleteSuccess={onDeleteSuccess}
      ></DeleteCourseModal>
    </>
  );
};

export default CoursesDetail;
