import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import "./discount-request.scss";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { fetchdiscountRequests, discountRequestsActions, fetchProcessDiscountRequestStatus } from "../../reducers/discountRequestSlice";
import { DiscountRequestPayload } from "../../models/discountRequest";
import CommonHeader from "../../shared/common-header/common-header";
import { INTEGOLF_DISCOUNT_REQUEST_STATUS_REQUESTED, SAGA_STATUS_FAILED, SAGA_STATUS_SUCCESS } from "../../utils/constants";

const SUCCESS_MESSAGE = "Discount request processed successfully.";
const FAILURE_MESSAGE = "Error while processing discount request. Please try after sometime.";

interface DiscountRequestProps {}
interface MessageModalProps {
    processed :boolean;
    show: boolean;
    onCloseClick : () => void;
}

const MessageModal: FC<MessageModalProps> = ({
    show = false,
    processed = true,
    onCloseClick
}) => {

    return <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={show}
        backdrop="static"
        keyboard={false}
        centered 
    >
        <Modal.Body>
            <a href={void 0} className="closeButton">
                <em className="fa-solid fa-close" onClick={onCloseClick}></em>
            </a>
            <div className="formWrap">
                {
                    processed ? <div className="successConfirmationModalWrap text-center">
                        <span className="markIcon success">
                        <em className="fa-solid fa-circle-check"></em>
                        </span>
                        <h2 className="mb-3">{SUCCESS_MESSAGE}</h2>
                    </div> : <div className="erroConfirmationModalWrap text-center">
                        <span className="markIcon error">
                        <em className="fa-solid fa-ban"></em>
                        </span>
                        <h2 className="mb-3">{FAILURE_MESSAGE}</h2>
                    </div>
                }
            </div>
        </Modal.Body>
    </Modal>
     
}

const DiscounRequestWidget: FC<DiscountRequestPayload> = (discountRequest) => {

    const { _id, name, status, users = [], createdAt, club, updatedAt, discountRateRequested } = discountRequest;
    const dispatch = useAppDispatch();

    const onRequestProcess = (id: string, approved : boolean) => {
        const payload = { id, approved };
        dispatch(discountRequestsActions.processDiscountRequest(payload));
    }

    return  <tr>
        <td> <b> {name} </b> </td>
        <td> {users[0]?.email} </td>
        <td> {moment(createdAt).format('DD-MM-YYYY')} </td>
        <td> {club.name} </td>
        <td> {`${discountRateRequested}`} %</td>
        <td> {status} </td>
        <td> 
            {
                status === INTEGOLF_DISCOUNT_REQUEST_STATUS_REQUESTED ? <>
                    <button
                        type="button"
                        className="button-sm button-primary button-rounded button-min fw400 fontsize-12"
                        onClick={() => onRequestProcess(_id, true)}
                    >
                        Accept
                    </button>
                    <button
                        type="button"
                        className="button-sm button-danger button-rounded button-min fw400 fontsize-12"
                        onClick={() => onRequestProcess(_id, false)}
                    >
                        Reject
                    </button>
                </> : <>
                    <b>{status}</b> on {moment(updatedAt).format('DD-MM-YYYY hh:mm a')}
                </>
            }
        </td>
    </tr>    
}

const DiscountRequest: FC<DiscountRequestProps> = () => {
  
    const dispatch = useAppDispatch();
    const discountRequests: Array<DiscountRequestPayload> = useAppSelector(fetchdiscountRequests);
    const processDiscountRequestStatus: string = useAppSelector(fetchProcessDiscountRequestStatus);

    const showModal = [SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED].includes(processDiscountRequestStatus);
    const onModalClose = () => {
        dispatch(discountRequestsActions.resetProcessDiscountRequestStatus());
        dispatch(discountRequestsActions.discountRequestList());
    }

    useEffect(() => {
        dispatch(discountRequestsActions.resetProcessDiscountRequestStatus());
        dispatch(discountRequestsActions.discountRequestList())
    }, [])

    return (
        <div className="discountRequestsListWrapper">
            <CommonHeader sectionTitle={"Discount Requests"}/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        { 
                            discountRequests.length > 0 && <div className="card tableCard">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Requests</h4>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-border">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Date</th>
                                                    <th>Club Name</th>
                                                    <th>Rate Requested</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    discountRequests.map(discountRequest => (
                                                        <DiscounRequestWidget 
                                                            key={discountRequest._id}
                                                            _id={discountRequest._id} 
                                                            name = {discountRequest.name}
                                                            users={discountRequest.users}
                                                            status={discountRequest.status}
                                                            createdAt={discountRequest.createdAt}
                                                            updatedAt={discountRequest.updatedAt}
                                                            courseId={discountRequest.courseId}
                                                            discountRateRequested={discountRequest.discountRateRequested}
                                                            club={discountRequest.club}
                                                        />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>    
                </div>

                {discountRequests.length == 0 && (
                    <div className="discountRequestWelcomePage d-flex align-items-center justify-content-center">
                    <div className="text-center">
                        <h2 className="mb-4">No Discount requests present</h2>
                    </div>
                    </div>
                )}
            </div>
            <MessageModal 
                show={showModal} 
                processed={processDiscountRequestStatus === SAGA_STATUS_SUCCESS} 
                onCloseClick={onModalClose}
            />
        </div>
    );
};

export default DiscountRequest;
