import React, { FC, useEffect, useState, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import "rc-slider/assets/index.css";

import "./add-golf-course.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  CLUB_ACTIVE_STATUS,
  CLUB_ACCOUNT_NAME_STEP,
  CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP,
  CLUB_LOCATION_MAP_STEP,
  CLUB_PICTURES_STEP,
  CLUB_DESCRIPTION_STEP,
  COURSE_BASICS_STEP,
  CLUB_FACILITIES_STEP,
  COURSE_TIME_AND_DAYS_STEP,
  CLUB_PREVIEW_STEP,
  COURSE_DISCOUNT_STEP,
  SAGA_STATUS_SUCCESS
} from "../../../../utils/constants";

import { clubsActions, fetchCreateClubCourseStatus, fetchUpdateClubStatus } from "../../../../reducers/clubsSlice";
import { externalClubsActions, fetchexternalClubs } from '../../../../reducers/externalClubsSlice';
import { merchantFilesActions, fetchSelectedMerchantFiles } from "../../../../reducers/merchantFilesSlice";
import { coursesActions, fetchUpdateCourseDataStatus } from "../../../../reducers/coursesSlice";
import { discountActions, fetchDiscountProfiles } from "../../../../reducers/discountsSlice";

import { ClubPayload, ClubTimings } from "../../../../models/clubs";
import { CoursesPayload } from "../../../../models/courses";
import { ExternalClubPayload } from "../../../../models/externalClubs";
import { MerchantFiles } from "../../../../models/merchantFiles";

import Prompt from "../../../../shared/prompt/prompt";

import AddDiscount from "../add-discount/add-discount";
import LinkTeeTimeComponent from "../link-tee-time-component/link-tee-time-component";
import CoursePreview from "../course-preview/course-preview";
import CourseTimings from "../course-timings/course-timings";
import { getUniqueString } from "../../../../utils/common";

const libraries: any = ['places'];

const ALLOWED_MERCHANT_IMAGES = 5;

interface AddGolfCourseProps {
  handleTileChange: (arg: any) => void;
  setStep: (arg: any) => void;
  activeStep: number;
  navigateToPath: (path: string) => void;
  editClubCourseData: boolean;
  club ?: ClubPayload;
  course ?: CoursesPayload;
}

interface ClubName {
  clubDisplayName: string;
}

interface clubLocation {
  clubName: string;
}
interface clubBasicStepsPayload {
  courseHoles: number;
  coursePar: number;
  courseSlope: number;
  courseDistance: number;
}

interface clubDescriptionStepsPayload {
  clubDescription: string;
}

interface clubAmenitiesPayload {
  clubWalkingAround: boolean;
  clubMetalSpikesAllowed: boolean;
  clubDressCodeRequired: boolean;
  clubCartsProvided: boolean;
  clubGolfClubsProvided: boolean;
  clubCaddiesAvailable: boolean;
  clubDrivingRangePresent: boolean;
  clubPitchingAreaPresent: boolean;
  clubPuttingGreen: boolean;
  clubTeachingPro: boolean;
}

const AddGolfCourse: FC<AddGolfCourseProps> = ({
  handleTileChange,
  setStep,
  activeStep,
  navigateToPath,
  editClubCourseData = false,
  club,
  course
}) => {
  
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDoMcuVhHHP8fos2Vp3PdtdZNEaSYHq9kk",
    libraries
  });

  // if edit step is passed then in the path only show the only view 
  const editStep = searchParams.get("step");

  const [showExternalClubModal, toggleExternalClubModal] = useState(false);

  const {
    register: clubDisplayNameRegister,
    handleSubmit: handleClubDisplayNameSubmit,
    formState: { errors: clubDisplayNameError },
    reset: resetClubDisplayName,
    control: clubDisplayNameControl,
  } = useForm<ClubName>();
  const onClubDisplayNameSubmit: SubmitHandler<ClubName> = () => nextStep();

  const [clubDisplayName, setClubDisplayName] = useState("");
  const [formattedClubName, setformattedClubName] = useState("");
  const [clubAddressOne, setClubAddressOne] = useState("");
  const [clubProvince, setClubProvince] = useState("");
  const [clubPostalCode, setClubPostalCode] = useState("");
  const [clubCountry, setClubCountry] = useState("");
  const [clubLocationLat, setClubLocationLat] = useState(0);
  const [clubLocationLong, setclubLocationLong] = useState(0);
  const [clubLocationErr, updateClubLocationErr] = useState<string>("");

  // description view form object and hooks
  const {
    register: descriptionRegister,
    handleSubmit: handleDescriptionSubmit,
    formState: { errors: descriptionError },
    reset: resetDescription,
  } = useForm<clubDescriptionStepsPayload>();
  const onDescriptionSubmit: SubmitHandler<clubDescriptionStepsPayload> = () => nextStep();

  const [clubDescription, setClubDescription] = useState("");

  // basic course view form object and hooks
  const [courseHoles, setcourseHoles] = useState(0);
  const [coursePar, setCoursePar] = useState(0);
  const [courseDistance, setCourseDistance] = useState(0);
  const [courseSlope, setCourseSlope] = useState(0);

  const {
    register: basicRegister,
    handleSubmit: handleCourseBasicSubmit,
    formState: { errors: basicErrors },
    reset: resetBasicSteps,
  } = useForm<clubBasicStepsPayload>();
  const onClubBasicSubmit: SubmitHandler<clubBasicStepsPayload> = () => nextStep();

  // Club amenities form object and hooks
  const [clubWalkingAround, setClubWalkingAround] = useState(false);
  const [clubMetalSpikesAllowed, setClubMetalSpikesAllowed] = useState(false);
  const [clubDressCodeRequired, setClubDressCodeRequired] = useState(false);
  const [clubCartsProvided, setClubCartsProvided] = useState(false);
  const [clubGolfClubsProvided, setClubGolfClubsProvided] = useState(false);
  const [clubDrivingRangePresent, setClubDrivingRangePresent] = useState(false);
  const [clubCaddiesAvailable, setClubCaddiesAvailable] = useState(false);
  const [clubPitchingAreaPresent, setClubPitchingAreaPresent] = useState(false);
  const [clubPuttingGreen, setClubPuttingGreen] = useState(false);
  const [clubTeachingPro, setClubTeachingPro] = useState(false);
  const [requestedDataIsNotFilled, setRequestedDataIsNotFilled] = useState(false);

  const {
    register: amenitiesRegister,
    handleSubmit: handleClubAmenitiesSubmit,
    formState: { errors: amenitiesErrors },
    reset: resetClubAmenities,
  } = useForm<clubAmenitiesPayload>();

  const createClubCourseStatus: string = useAppSelector(fetchCreateClubCourseStatus);
  const updateClubStatus: string = useAppSelector(fetchUpdateClubStatus);
  const updateCourseDataStatus: string = useAppSelector(fetchUpdateCourseDataStatus);

  const externalClubs: Array<ExternalClubPayload> =
    useAppSelector(fetchexternalClubs);
  const merchantImages: Array<MerchantFiles> = useAppSelector(
    fetchSelectedMerchantFiles
  );

  const disableBgColor =
    merchantImages.length === ALLOWED_MERCHANT_IMAGES
      ? "uploadImageWrap--disabled"
      : "";

  const onClubAccountNameSubmit = () => {
    
    if (club && course) {
      
      // update club info
      let dataUpdated = false;
      const { name } = club;
      if ( clubDisplayName && clubDisplayName.trim().length && name !== clubDisplayName ) {
        let updatePayload: any = {
          name: clubDisplayName.trim(),
          ["_id"]: club._id,
          clubEditStep: CLUB_ACCOUNT_NAME_STEP
        };
        dispatch(clubsActions.updateClubData(updatePayload));
        dataUpdated = true;
      }

      // update course info from external club object
      if (course) {
        // update course info
        const { par, distance, slope } = course;
  
        let courseUpdatePayload: any = {
          ...(coursePar && par != coursePar ? { par: coursePar } : {}),
          ...(courseDistance && distance != courseDistance
            ? { distance: courseDistance }
            : {}),
          ...(courseSlope && slope != courseSlope ? { slope: courseSlope } : {}),
        };
  
        if (Object.keys(courseUpdatePayload).length) {
          courseUpdatePayload["_id"] = course._id;
          dispatch(coursesActions.updateCourseData(courseUpdatePayload));
          dataUpdated = true;
        }
      }

      // if no data is updated then immediately move to next step
      if (!dataUpdated) {
        moveToNextStep();
      }
      
    } else {

      // if club object not present then create new club object
      const clubObject: any = {
        clubId : Date.now(),    // create club id
        ...( clubDisplayName ? { name: clubDisplayName }: {}),
        ...( clubAddressOne ? { addressOne: clubAddressOne }: {}),
        ...( clubCountry ? { countryName: clubCountry }: {}),
        ...( clubProvince ? { provinceName: clubProvince }: {}),
      };
      
      const courseObject: any = {
        ...( courseDistance ? { distance: courseDistance }: {}),
        ...( coursePar ? { par: coursePar }: {}),
        ...( courseSlope ? { slope: courseSlope }: {}),
      };

      const addNewClubCoursePayload: any = { club: clubObject, course: courseObject };
      dispatch(clubsActions.createClubAndCourse(addNewClubCoursePayload));
    }

  };

  const onLocationSubmit = () => {

    if (!club) {
      throw new Error("Club info not passed.");
    }

    //if there is a change in
    const {
      location,
      formattedName,
      addressOne,
      postcode,
      provinceName,
      countryName,
    } = club;

    const clubLat = location?.coordinates[1] || 0;
    const clublong = location?.coordinates[0] || 0;

    if ( (clubLocationLat == 0 && clubLocationLong == 0) || formattedClubName.length == 0) {
      
      updateClubLocationErr("Please select a valid location for the course");

    } else {
      
      let updatePayload: any = {
        ...(clublong != clubLocationLong
          ? { coordinateLong: clubLocationLong }
          : {}),
        ...(clubLocationLat != clubLat ? { coordinateLat: clubLocationLat } : {}),
        ...(formattedName != formattedClubName
          ? { formattedName: formattedClubName }
          : {}),
        ...(addressOne != clubAddressOne ? { addressOne: clubAddressOne } : {}),
        ...(provinceName != clubProvince ? { provinceName: clubProvince } : {}),
        ...(postcode != clubPostalCode ? { postcode: clubPostalCode } : {}),
        ...(countryName != clubCountry ? { countryName: clubCountry } : {}),
      };
  
      if (Object.keys(updatePayload).length) {
        updatePayload["_id"] = club._id;
        updatePayload["clubEditStep"] = CLUB_LOCATION_MAP_STEP;
        dispatch(clubsActions.updateClubData(updatePayload));
      } else {  // if there is nothing to update then move to next step
        moveToNextStep();
      }

    }

  };

  const onClubDescriptionSubmit = () => {

    if (!club) {
      throw new Error("Club info not passed.");
    }

    const { description } = club;

    if (clubDescription !== description) {
      let updatePayload: any = {
        _id: club._id,
        description: clubDescription?.trim(),
        clubEditStep: CLUB_DESCRIPTION_STEP
      };
      dispatch(clubsActions.updateClubData(updatePayload));
    } else {  // if there is nothing to update then move to next step
      moveToNextStep();
    }
  };

  const onBasicSubmit = () => {

    if (!course) {
      throw new Error("Course info not passed.");
    }

    const { holes, par, distance, slope } = course;

    let courseUpdatePayload: any = {
      ...(holes != courseHoles ? { holes: courseHoles } : {}),
      ...(par != coursePar ? { par: coursePar } : {}),
      ...(distance != courseDistance ? { distance: courseDistance } : {}),
      ...(slope != courseSlope ? { slope: courseSlope } : {}),
    };

    if (Object.keys(courseUpdatePayload).length) {

      courseUpdatePayload["_id"] = course._id;
      dispatch(coursesActions.updateCourseData(courseUpdatePayload));

      // update club active step 
      if (club && club?._id) {
        const updatePayload: any = {
          _id: club._id,
          clubEditStep: COURSE_BASICS_STEP
        };
        dispatch(clubsActions.updateClubData(updatePayload));
      }

    } else {  // if there is nothing to update then move to next step
      moveToNextStep();
    }
  };

  const onFacilitiesSubmit = () => {

    if (!club) {
      throw new Error("Club info not passed.");
    }

    const {
      walkingAllowed,
      metalSpikesAllowed,
      dressCode,
      cartsForRentalAvailable,
      caddiesForRentalAvailable,
      drivingRange,
      pitchingArea,
      puttingGreen,
      teachingPro,
      clubsForRentalAvailable,
    } = club;

    let updatePayload: any = {
      _id: club._id,
      clubEditStep: CLUB_FACILITIES_STEP,
      ...(walkingAllowed != clubWalkingAround
        ? { walkingAllowed: clubWalkingAround }
        : {}),
      ...(metalSpikesAllowed != clubMetalSpikesAllowed
        ? { metalSpikesAllowed: clubMetalSpikesAllowed }
        : {}),
      ...(dressCode != clubDressCodeRequired
        ? { dressCode: clubDressCodeRequired }
        : {}),
      ...(cartsForRentalAvailable != clubCartsProvided
        ? { cartsForRentalAvailable: clubCartsProvided }
        : {}),
      ...(clubsForRentalAvailable != clubGolfClubsProvided
        ? { clubsForRentalAvailable: clubGolfClubsProvided }
        : {}),
      ...(caddiesForRentalAvailable != clubCaddiesAvailable
        ? { caddiesForRentalAvailable: clubCaddiesAvailable }
        : {}),
      ...(drivingRange != clubDrivingRangePresent
        ? { drivingRange: clubDrivingRangePresent }
        : {}),
      ...(pitchingArea != clubPitchingAreaPresent
        ? { pitchingArea: clubPitchingAreaPresent }
        : {}),
      ...(puttingGreen != clubPuttingGreen
        ? { puttingGreen: clubPuttingGreen }
        : {}),
      ...(teachingPro != clubTeachingPro
        ? { teachingPro: clubTeachingPro }
        : {}),
    };

    dispatch(clubsActions.updateClubData(updatePayload));
  };

  const onImagesSubmit = () => {
    
    if (!club) {
      throw new Error("Club info not passed.");
    }
    
    const { photos = [] } = club;
    const clubPhotos = photos.map(({ _id }) => _id);
    const viewPhotos = merchantImages.map(({ _id }) => _id);

    const areViewPhotosDifferent =
      clubPhotos.length !== viewPhotos.length ||
      viewPhotos.filter((viewId) => !clubPhotos.includes(viewId)).length > 0;
    if (areViewPhotosDifferent) {
      const updatePayload: any = {
        _id: club._id,
        photos: viewPhotos,
        clubEditStep: CLUB_PICTURES_STEP
      };
      dispatch(clubsActions.updateClubData(updatePayload));
    } else { // if there is nothing to update then move to next step
      moveToNextStep();
    }
  };

  const onLocationTextChange = (event: any) => {
    setformattedClubName(event.target.value);
    setClubLocationLat(0);
    setclubLocationLong(0);
    updateClubLocationErr("");
  }

  // steps to follow after selecting place from auto complete
  const autoCompleteOnPlaceSelect = (placeObject: any) => {

    const {
      address_components = [],
      geometry,
      formatted_address,
    } = placeObject;

    // extract address components from 'address_components'

    const lat = geometry.location.lat();
    const lng = geometry.location.lng();
    let addressLineOne = "";

    const streetObject = address_components.find((obj: any) =>
      obj.types.includes("street_number")
    );
    const routeObject = address_components.find((obj: any) =>
      obj.types.includes("route")
    );
    const localityObject = address_components.find((obj: any) =>
      obj.types.includes("locality")
    );
    const stateObject = address_components.find((obj: any) =>
      obj.types.includes("administrative_area_level_1")
    );
    const countryObject = address_components.find((obj: any) =>
      obj.types.includes("country")
    );
    const postalCodeObject = address_components.find((obj: any) =>
      obj.types.includes("postal_code")
    );

    if (streetObject && streetObject["long_name"]) {
      addressLineOne = streetObject["long_name"];
    }

    if (routeObject && routeObject["long_name"]) {
      addressLineOne = `${addressLineOne} ${routeObject["long_name"]}`;
    }

    if (localityObject && localityObject["long_name"]) {
      addressLineOne = `${addressLineOne} ${localityObject["long_name"]}`;
    }

    if (addressLineOne && addressLineOne.trim().length) {
      setClubAddressOne(addressLineOne);
    }

    if (stateObject && stateObject["long_name"]) {
      setClubProvince(stateObject["long_name"]);
    }

    if (countryObject && countryObject["long_name"]) {
      setClubCountry(countryObject["long_name"]);
    }

    if (postalCodeObject && postalCodeObject["long_name"]) {
      setClubPostalCode(postalCodeObject["long_name"]);
    }

    if (formatted_address) {
      setformattedClubName(formatted_address);
    }

    if (lat) {
      setClubLocationLat(lat);
    }

    if (lng) {
      setclubLocationLong(lng);
    }

    updateClubLocationErr("");
  };

  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
  };

  const onTitleChange = (title: string) => {
    setClubDisplayName(title);
    dispatch(externalClubsActions.externalClubsList({ text: title }));
  };

  const onTitleSelect = (externalClubObject: ExternalClubPayload) => {
    const {
      name = "",
      address = "",
      countryName = "",
      provinceName = "",
      par = 0,
      slope = 0,
      length = 0,
    } = externalClubObject;
    setClubAddressOne(address);
    setClubDisplayName(name);
    setClubCountry(countryName);
    setClubProvince(provinceName);
    setCoursePar(par);
    setCourseSlope(slope);
    setCourseDistance(length);
    toggleExternalClubModal(false);
  };

  const onRemoveMerchantImage = (imageId?: string) => {
    const updatedImages = merchantImages.filter(
      (imageObj) => imageObj._id !== imageId
    );
    dispatch(merchantFilesActions.merchantFilesUpdate(updatedImages));
  };

  const onHandleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 5) {
      toast.error("No more than 5 images allowed");
    } else if (
      e.target.files &&
      e.target.files.length > 0 &&
      e.target.files.length <= 5
    ) {
      const formData = new FormData();

      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index];
        formData.append("files", file);
      }

      dispatch(merchantFilesActions.merchantFilesUpload(formData));
    }
  };

  const moveToNextStep = () => {
    
    // if step is passed i.e. edit mode is enabled then on move to next step go to the "courses" screen
    if (!!editStep) {
      navigateToPath("/courses");
    } else {
      // move to next step only when edit step is not passed i.e. course is being created
      const nextStep = (activeStep + 1);
      handleTileChange(nextStep);
      setStep(nextStep);
    }
  };

  const onDiscountCompletion = () => {
    // mark discount step completed
    if (club && club?._id) {
      const updatePayload: any = {
          _id: club?._id,
          clubEditStep: COURSE_DISCOUNT_STEP
      };
      dispatch(clubsActions.updateClubData(updatePayload));
    }
  }

  const onClubTimingsSubmit = (updatePayload: any) => {
    if (club && club._id) {
      updatePayload["_id"] = club._id;
      updatePayload["clubEditStep"]= COURSE_TIME_AND_DAYS_STEP;
      dispatch(clubsActions.updateClubData(updatePayload))
    }
  }

  const nextStep = () => {
    if (activeStep == CLUB_ACCOUNT_NAME_STEP) {
      onClubAccountNameSubmit();
    } else if (activeStep == CLUB_DESCRIPTION_STEP) {
      onClubDescriptionSubmit();
    } else if (activeStep == COURSE_BASICS_STEP) {
      onBasicSubmit();
    } else if (activeStep == CLUB_FACILITIES_STEP) {
      onFacilitiesSubmit();
    } else if (activeStep == CLUB_PICTURES_STEP) {
      onImagesSubmit();
    }
  };

  useEffect(() => {
    
    // club name and club location required to show prompt
    const showPrompt = !!club && !!club._id && (!!!club?.name || !!!club?.location);
    setRequestedDataIsNotFilled(showPrompt);

    if (activeStep == CLUB_ACCOUNT_NAME_STEP) {
      if (club) {
        const {
          name: clubName = "",
          addressOne = "",
          provinceName = "",
          postcode = "",
        } = club;

        setClubDisplayName(clubName);
        setClubAddressOne(addressOne);
        setClubPostalCode(postcode);
        setClubProvince(provinceName);

        resetClubDisplayName({ clubDisplayName: clubName });
        dispatch(externalClubsActions.resetExternalClubs());
      }
    } else if (activeStep == CLUB_LOCATION_MAP_STEP) {
      if (club) {
        const {
          location: clubLocation,
          formattedName = "",
          addressOne = "",
          provinceName = "",
          postcode = "",
          countryName = "",
          name = ""
        } = club;

        setformattedClubName(formattedName);
        setClubLocationLat(
          clubLocation && clubLocation.coordinates
            ? clubLocation.coordinates[1]
            : 0
        );
        setclubLocationLong(
          clubLocation && clubLocation.coordinates
            ? clubLocation.coordinates[0]
            : 0
        );
        setClubAddressOne(addressOne);
        setClubPostalCode(postcode);
        setClubProvince(provinceName);
        setClubCountry(countryName);
      }
    } else if (activeStep == CLUB_DESCRIPTION_STEP) {
      if (club) {
        const { description = "" } = club;
        setClubDescription(description);
        resetDescription({ clubDescription: description }); // reset form data on load
      }
    } else if (activeStep == COURSE_BASICS_STEP) {
      if (course) {
        const { holes = 9, par = 0, distance = 0, slope = 0 } = course;
        setcourseHoles(holes);
        setCoursePar(par);
        setCourseDistance(distance);
        setCourseSlope(slope);
        resetBasicSteps({
          courseDistance: distance,
          courseHoles: holes,
          coursePar: par,
          courseSlope: slope,
        });
      }
    } else if (activeStep == CLUB_FACILITIES_STEP) {
      if (club) {
        const {
          walkingAllowed = false,
          metalSpikesAllowed = false,
          dressCode = false,
          cartsForRentalAvailable = false,
          clubsForRentalAvailable = false,
          caddiesForRentalAvailable = false,
          drivingRange = false,
          pitchingArea = false,
          puttingGreen = false,
          teachingPro = false,
        } = club;
        setClubWalkingAround(walkingAllowed);
        setClubMetalSpikesAllowed(metalSpikesAllowed);
        setClubDressCodeRequired(dressCode);
        setClubCartsProvided(cartsForRentalAvailable);
        setClubGolfClubsProvided(clubsForRentalAvailable);
        setClubDrivingRangePresent(drivingRange);
        setClubCaddiesAvailable(caddiesForRentalAvailable);
        setClubPitchingAreaPresent(pitchingArea);
        setClubPuttingGreen(puttingGreen);
        setClubTeachingPro(teachingPro);
        resetClubAmenities({
          clubWalkingAround: walkingAllowed,
          clubMetalSpikesAllowed: metalSpikesAllowed,
          clubDressCodeRequired: dressCode,
          clubCartsProvided: cartsForRentalAvailable,
          clubGolfClubsProvided: clubsForRentalAvailable,
          clubCaddiesAvailable: caddiesForRentalAvailable,
          clubDrivingRangePresent: drivingRange,
          clubPitchingAreaPresent: pitchingArea,
          clubPuttingGreen: puttingGreen,
          clubTeachingPro: teachingPro,
        });
      }
    } else if (activeStep == CLUB_PICTURES_STEP) {
      // reset selected merchant images
      dispatch(merchantFilesActions.resetSelectedMerchantFile());

      //initialise merchant images
      if (club) {
        const { photos = [] } = club;

        dispatch(merchantFilesActions.merchantFilesUpdate(photos));
      }
    } else if (activeStep == COURSE_DISCOUNT_STEP) {
      dispatch(discountActions.resetAllDiscountStatus())
      if (course) {
        dispatch(discountActions.fetchDiscountProfile(course._id))
      }
    }
  }, [activeStep, club, course]);

  useEffect(() => {
    // when the created club and course is passed and the status is success then only move to next step 
    if (createClubCourseStatus === SAGA_STATUS_SUCCESS && club && course) {
      dispatch(clubsActions.resetCreateClubAndCourseStatus());
      moveToNextStep();
    }
  }, [createClubCourseStatus, club, course])

  useEffect(() => {
    
    // if club data is udated and edit step is passed then show toast on successfully on update
    if (updateClubStatus === SAGA_STATUS_SUCCESS && editStep) {
      toast.info("Club Info Updated Successfully");
    }

    if (
      updateClubStatus === SAGA_STATUS_SUCCESS && 
      [
        CLUB_ACCOUNT_NAME_STEP, 
        CLUB_DESCRIPTION_STEP, 
        CLUB_FACILITIES_STEP, 
        CLUB_PICTURES_STEP, 
        COURSE_TIME_AND_DAYS_STEP,
        CLUB_LOCATION_MAP_STEP,
        COURSE_DISCOUNT_STEP,
        COURSE_BASICS_STEP
      ].includes(activeStep)
    ) {
      dispatch(clubsActions.resetUpdateClubDataStatus());
      moveToNextStep();
    }
  }, [updateClubStatus, activeStep]);

  return (
    <>
      <Prompt 
        show={requestedDataIsNotFilled} 
        message={"Leaving so soon? All the updates will be discarded."}
      />

      <div className="addGolfCourseWrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <div className="formWrap mt-4">
                {activeStep == CLUB_ACCOUNT_NAME_STEP && (
                  <div className="formStepper mt-5 pt-4">
                    <h5 className="mb-4 fw700">Let's give your course a title</h5>
                    <form
                      onSubmit={handleClubDisplayNameSubmit(
                        onClubDisplayNameSubmit
                      )}
                    >
                      <div className="form-floating mt-4 mb-5">
                        <Controller
                          control={clubDisplayNameControl}
                          name="clubDisplayName"
                          render={({ field: { onChange } }) => (
                            <>
                              <input
                                disabled={(club && club.name ? club.name : "").length > 0}
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Add Title"
                                value={clubDisplayName}
                                {...clubDisplayNameRegister("clubDisplayName", {
                                  required: true,
                                  validate: (value) => {
                                    return value.trim() !== "";
                                  },
                                })}
                                onChange={({ target }) => {
                                  onChange(target.value);
                                  onTitleChange(target.value);
                                }}
                                onBlur={(e) => {
                                  e.relatedTarget === null &&
                                    toggleExternalClubModal(
                                      !showExternalClubModal
                                    );
                                }}
                                onFocus={(e) => {
                                  e.relatedTarget === null &&
                                    toggleExternalClubModal(
                                      !showExternalClubModal
                                    );
                                }}
                              />
                              <label htmlFor="title">Add Title</label>
                              <div className="listActionDropdownBlock">
                                <Dropdown.Menu
                                  show={
                                    externalClubs.length > 0 &&
                                    showExternalClubModal
                                  }
                                  className="form-control clubname-dropdown-menu"
                                >
                                  {externalClubs.map((obj) => (
                                    <Dropdown.Item
                                      key={obj._id}
                                      onClick={() => {
                                        onChange(obj.name);
                                        onTitleSelect(obj);
                                      }}
                                    >
                                      {obj.name}{(obj.address ? `, (${obj.address})`: ``)}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </div>
                            </>
                          )}
                        />
                        {(clubDisplayNameError?.clubDisplayName?.type === "required" || clubDisplayNameError?.clubDisplayName?.type === "validate") && (
                            <span className="isInvalidMessage text-danger">
                              Please add club display name before submitting.
                            </span>
                          )}
                      </div>
                      <div className="pt-5">
                        <button
                          className="button button-primary button-rounded button-min-150 fw700"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {activeStep == CLUB_LOCATION_MAP_STEP && (
                  <form>
                    <div className="formStepper">
                      <h3 className="mb-2">Where's your golf course located?</h3>
                      {isLoaded && (
                        <div className="map">
                          <GoogleMap
                            mapContainerStyle={{
                              width: "600px",
                              height: "350px",
                            }}
                            center={{
                              lat: clubLocationLat,
                              lng: clubLocationLong,
                            }}
                            options={mapOptions}
                            zoom={15}
                            // onLoad={data => console.log(data)}
                            // onUnmount={onUnmount}
                            onCenterChanged={() => { }}
                          >
                            <MarkerF
                              position={{
                                lat: clubLocationLat,
                                lng: clubLocationLong,
                              }}
                              icon={{
                                url: "./assets/images/icons/marker.svg",
                              }}
                              zIndex={10}
                              draggable={false}
                            />
                          </GoogleMap>
                          <div className="searchBox">
                            <em className="icon-location"></em>
                              <Autocomplete
                                apiKey={
                                  "AIzaSyDoMcuVhHHP8fos2Vp3PdtdZNEaSYHq9kk"
                                }
                                className="form-control control-xl disable"
                                onPlaceSelected={autoCompleteOnPlaceSelect}
                                defaultValue={formattedClubName}
                                onChange={onLocationTextChange}
                                options={{
                                  types: ["establishment"],
                                  componentRestrictions: {
                                    country: ["CA", "US"],
                                  },
                                }}
                              />
                          </div>
                        </div>
                      )}
                      <div className="text-center _mapNoteText mt-2">
                        Please enter the name of the course and select the appropriate 
                      </div>
                      {
                        (clubLocationErr.length) ? (
                          <span className="isInvalidMessage text-danger">
                            {clubLocationErr}
                          </span>
                        ): <></>
                      }
                      <div className="pt-4">
                        <button
                          className="button button-primary button-rounded button-min-150 fw700"
                          type="button"
                          onClick={onLocationSubmit}
                        >
                          {!!editStep ? 'Save': 'Next'}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {activeStep == CLUB_PICTURES_STEP && (
                  <form className="formStepper" onSubmit={ (event) => {event.preventDefault(); nextStep();}}>
                    <h5 className="mb-4">Upload visuals of Course</h5>
                    <label
                      className={`uploadImageWrap mb-0 d-block ${disableBgColor}`}
                    >
                      <input
                        type="file"
                        disabled={
                          merchantImages.length === ALLOWED_MERCHANT_IMAGES
                        }
                        accept="image/*"
                        onChange={onHandleImageSelect}
                        onClick={(event: any) => { event.target.value = ''}}
                        multiple
                      />
                      <div className="inner" id="dropzone" key={getUniqueString()}>
                        <img
                          src="../assets/images/icons/gallery.svg"
                          alt="gallery"
                        />
                        <h5>Click here to upload course photos</h5>
                        <p>At least 2 and No more than 5 photos required</p>
                        {/* <label className="_link mb-0 mt-5">
                          Upload from your device
                        </label> */}
                      </div>
                    </label>
                    <div className="row gutters-7 my-4">
                      {merchantImages.map((image: MerchantFiles) => (
                        <div className="col-auto" key={image._id}>
                          <div className="uploadedImage">
                            <span>
                              <img
                                src={image.path}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <a
                              href={void 0}
                              onClick={() =>
                                onRemoveMerchantImage(image?._id)
                              }
                            >
                              <em className="fa-solid fa-close"></em>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="pt-4">
                      <button
                        className="button button-primary button-rounded button-min-150 fw700"
                        type="submit"
                        disabled={(merchantImages.length < 2 || merchantImages.length > 5)}
                      >
                        {!!editStep ? 'Save': 'Next'}
                      </button>
                    </div>
                  </form>
                )}
                {activeStep == CLUB_DESCRIPTION_STEP && (
                  <div className="formStepper">
                    <form
                      onSubmit={handleDescriptionSubmit(onDescriptionSubmit)}
                    >
                      <h5 className="mb-4">
                        Share what makes your place special.
                      </h5>
                      <div className="form-group mb-2">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            rows={6}
                            id="shareSpecial"
                            placeholder="Say something"
                            style={{ height: "120px" }}
                            value={(clubDescription||"")}
                            {...descriptionRegister("clubDescription", {
                              required: true,
                              validate: (value) => {
                                return value.trim() !== "";
                              },
                            })}
                            onChange={({ target }) =>
                              setClubDescription(target.value)
                            }
                          ></textarea>
                          <label htmlFor="shareSpecial">Say something</label>
                        </div>
                      </div>
                      {descriptionError.clubDescription?.type && (
                        <span className="isInvalidMessage text-danger">
                          Please add some description before submitting
                        </span>
                      )}
                      <div className="pt-4">
                        <button
                          className="button button-primary button-rounded button-min-150 fw700"
                          type="submit"
                        >
                          {!!editStep ? 'Save': 'Next'}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {activeStep == COURSE_BASICS_STEP && (
                  <div className="formStepper">
                    <form onSubmit={handleCourseBasicSubmit(onClubBasicSubmit)}>
                      <h5 className="mb-4">Add details below</h5>
                      <ul className="courseFormDetail">
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">
                                Holes
                              </label>
                            </div>
                            <div className="col-auto">
                              <label className="ch-radio ch-radio-border my-1 me-3">
                                <input
                                  type="radio"
                                  checked={courseHoles == 9}
                                  onClick={() => setcourseHoles(9)}
                                  value={9}
                                />
                                <span className="fw700">9 holes</span>
                              </label>
                              <label className="ch-radio ch-radio-border my-1">
                                <input
                                  type="radio"
                                  checked={courseHoles == 18}
                                  onClick={() => setcourseHoles(18)}
                                  value={18}
                                />
                                <span className="fw700">18 holes</span>
                              </label>
                            </div>
                            {basicErrors.courseHoles?.type == "required" && (
                              <span className="isInvalidMessage text-danger text-end">
                                Please select a value for course holes
                              </span>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">Par</label>
                            </div>
                            {/* <div className="col-auto"> */}
                              <input
                                type="text"
                                className="form-control control-xl"
                                placeholder="Add here"
                                value={coursePar}
                                {...basicRegister("coursePar", {
                                  required: true,
                                  validate: (value) => Number(value) > 0
                                })}
                                onChange={({ target }) => {
                                  const value = Number(target.value);
                                  if (!isNaN(value) && value > 0) {
                                    setCoursePar(value);
                                  } else {
                                    setCoursePar(0);
                                  }
                                }}
                              />
                              {basicErrors.coursePar?.type == "required" && (
                                <p className="isInvalidMessage text-danger text-end">
                                  Please add a value for Par
                                </p>
                              )}

                             {basicErrors.coursePar?.type === "validate" && (
                                 <p className="isInvalidMessage text-danger text-end">
                                  Par value should be greater than 0
                                 </p>
                               )}
                            </div>
                          {/* </div> */}
                        </li>
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">
                                Slope
                              </label>
                            </div>
                            {/* <div className="col-auto"> */}
                              <input
                                type="text"
                                className="form-control control-xl"
                                placeholder="Add here"
                                value={courseSlope}
                                {...basicRegister("courseSlope", {
                                  required: true,
                                  validate: (value) => Number(value) > 0
                                  
                                })}
                                onChange={({ target }) =>{
                                  const value = Number(target.value);
                                if (!isNaN(value) && value > 0) {
                                  setCourseSlope(value);
                                } else {
                                  setCourseSlope(0);
                                }}
                                }
                              />
                              {basicErrors.courseSlope?.type == "required" && (
                                <p className="isInvalidMessage text-danger text-end">
                                  Please add a value for Slope
                                </p>
                              )}

                             {basicErrors.courseSlope?.type === "validate" && (
                                 <p className="isInvalidMessage text-danger text-end">
                                  Slope value should be greater than 0
                                 </p>
                               )}
                            </div>
                          {/* </div> */}
                        </li>
                        <li>
                          <div className="row gutters-5 align-items-center">
                            <div className="col">
                              <label className="mb-0 control-label">
                                Length{" "}
                                <span className="color-600 fw400">(yards)</span>
                              </label>
                            </div>
                            {/* <div className="col-auto"> */}
                              <input
                                type="text"
                                className="form-control control-xl"
                                placeholder="Add here"
                                value={courseDistance}
                                {...basicRegister("courseDistance", {
                                  required: true,
                                  validate: (value) => Number(value) > 0
                                })}
                                onChange={({ target }) =>{
                                  const value = Number(target.value);
                                if (!isNaN(value) && value > 0) {
                                  setCourseDistance(value);
                                } else {
                                  setCourseDistance(0);
                                }}}
                              />
                              {basicErrors.courseDistance?.type ==
                                "required" && (
                                  <p className="isInvalidMessage text-danger text-end">
                                    Please add a value for Distance
                                  </p>
                                )}

                             {basicErrors.courseDistance?.type ==
                                "validate" && (
                                  <p className="isInvalidMessage text-danger text-end">
                                   Distance should be greater than 0
                                  </p>
                                )}
                            </div>
                          {/* </div> */}
                        </li>
                      </ul>
                      <div className="pt-4">
                        <button
                          type="submit"
                          className="button button-primary button-rounded button-min-150 fw700"
                        >
                          {!!editStep ? 'Save': 'Next'}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {activeStep == CLUB_FACILITIES_STEP && (
                  <div className="formStepper">
                    <form
                      onSubmit={(e) => { e.preventDefault(); nextStep(); }}
                    >
                      <h5 className="mb-4">
                        Add amenities about your golf course.
                      </h5>
                      <ul className="courseFormDetail">
                        <li>
                          <h5 className="mb-3">Policies</h5>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400 fw400">
                                Walking allowed
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubWalkingAround}
                                  onClick={() => setClubWalkingAround(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubWalkingAround}
                                  onClick={() => setClubWalkingAround(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Metal spikes allowed
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubMetalSpikesAllowed}
                                  onClick={() => setClubMetalSpikesAllowed(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubMetalSpikesAllowed}
                                  onClick={() => setClubMetalSpikesAllowed(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Dress Code:Appropriate golf attire
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubDressCodeRequired}
                                  onClick={() => setClubDressCodeRequired(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubDressCodeRequired}
                                  onClick={() => setClubDressCodeRequired(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li>
                          <h5 className="mb-3 mt-2">Rentals/Services</h5>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Carts
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubCartsProvided}
                                  onClick={() => setClubCartsProvided(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubCartsProvided}
                                  onClick={() => setClubCartsProvided(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Clubs
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubGolfClubsProvided}
                                  onClick={() => setClubGolfClubsProvided(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubGolfClubsProvided}
                                  onClick={() => setClubGolfClubsProvided(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Caddies available
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubCaddiesAvailable}
                                  onClick={() => setClubCaddiesAvailable(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubCaddiesAvailable}
                                  onClick={() => setClubCaddiesAvailable(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li className="border-0">
                          <h5 className="mb-3 mt-2">Practice/Instruction</h5>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Driving range
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubDrivingRangePresent}
                                  onClick={() => setClubDrivingRangePresent(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubDrivingRangePresent}
                                  onClick={() => setClubDrivingRangePresent(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Pitching/chipping area
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubPitchingAreaPresent}
                                  onClick={() => setClubPitchingAreaPresent(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubPitchingAreaPresent}
                                  onClick={() => setClubPitchingAreaPresent(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                            {amenitiesErrors.clubPitchingAreaPresent?.type ==
                              "required" && (
                                <span className="isInvalidMessage text-danger">
                                  Please select a value
                                </span>
                              )}
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Putting green
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubPuttingGreen}
                                  onClick={() => setClubPuttingGreen(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubPuttingGreen}
                                  onClick={() => setClubPuttingGreen(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                          <div className="row gutters-5 py-2">
                            <div className="col">
                              <label className="mb-0 control-label fw400">
                                Teaching pro
                              </label>
                            </div>
                            <div className="col-auto d-flex align-items-center">
                              <label className="ch-radio ch-radio-border me-4 fw700">
                                <input
                                  type="radio"
                                  checked={clubTeachingPro}
                                  onClick={() => setClubTeachingPro(true)}
                                />
                                <span>Yes</span>
                              </label>
                              <label className="ch-radio ch-radio-border fw700">
                                <input
                                  type="radio"
                                  checked={!clubTeachingPro}
                                  onClick={() => setClubTeachingPro(false)}
                                />
                                <span>No</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div className="pt-4 mt-3">
                        <button
                          className="button button-primary button-rounded button-min-150 fw700"
                          type="submit"
                        >
                          {!!editStep ? 'Save': 'Next' }
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {activeStep == COURSE_TIME_AND_DAYS_STEP && (
                  <CourseTimings
                    clubObject={club}
                    onSubmit={onClubTimingsSubmit}
                  />
                )}
                {activeStep == COURSE_DISCOUNT_STEP && course && course?._id && (
                  <AddDiscount 
                    courseId={course._id} 
                    onContinue={onDiscountCompletion} 
                    editClubCourseData={editClubCourseData}
                  />
                )}
                {activeStep == CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP && club && course && (
                    <LinkTeeTimeComponent
                      clubObject={club}
                      courseInfo={course}
                      onNextClick={moveToNextStep}
                    />
                  )  
                }
                {activeStep == CLUB_PREVIEW_STEP && club && course && (
                  <CoursePreview
                    clubInfo={club}
                    courseInfo={course}
                    navigateToPath={navigateToPath}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGolfCourse;