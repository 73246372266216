import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import { ReviewsPayload } from '../models/reviews';

export interface reviewsState {
    fetchReviewsStatus: string;
    averageRatings: number;
    reviewsCount: number;
    totalReviewsCount: number;
    reviews: Array<ReviewsPayload>;
}

const initialState: reviewsState = {
    fetchReviewsStatus : "",
    averageRatings: 0,
    totalReviewsCount: 0,
    reviewsCount: 0,
    reviews: []
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    reviewList(state, action: PayloadAction<any>) {
        state.fetchReviewsStatus = "pending";
    },
    reviewListSuccess(state, action: PayloadAction<any>) {
        state.reviews = action.payload.reviews;
        state.totalReviewsCount = (action.payload.ratingsCount || 0);
        state.averageRatings = (action.payload.averageRating || 0);
        state.reviewsCount = Array.isArray(action.payload.reviews)? action.payload.reviews.length: 0;
        state.fetchReviewsStatus = "success";
    },
    reviewListFailed(state, action: PayloadAction<string>) {
        state.fetchReviewsStatus = "failed";
    },
    resetReviews(state) {
        state.fetchReviewsStatus = "";
        state.averageRatings = 0;
        state.totalReviewsCount = 0;
        state.reviewsCount = 0;
        state.reviews = [];
    }
  }
});

// Actions
export const reviewsActions = reviewsSlice.actions;

// Selectors
export const fetchReviews = (state: RootState) => state.reviews.reviews;
export const fetchAverageRatings = (state: RootState) => state.reviews.averageRatings;
export const fetchTotalReviewsCount =  (state: RootState) => state.reviews.totalReviewsCount;

// Reducer
const reviewsReducer = reviewsSlice.reducer;
export default reviewsReducer;
