import React, { FC, useState } from "react";
import "./bookings.scss";
import CommonHeader from "../../../../shared/common-header/common-header";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

interface BookingsProps {}

const Bookings: FC<BookingsProps> = () => {
  const sectionTitle = useState("Bookings ");
  return (
    <section className="bookingsWrapper">
      <CommonHeader sectionTitle={sectionTitle}></CommonHeader>
      <div className="commonHeader pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <nav className="navTabs">
                <NavLink
                  to={"/bookings/upcoming"}
                  className={(nav) =>
                    nav.isActive ? "nav-link active" : "nav-link"
                  }
                >
                  Upcoming
                </NavLink>
                <NavLink
                  to={"/bookings/completed"}
                  className={(nav) =>
                    nav.isActive ? "nav-link active" : "nav-link"
                  }
                >
                  Completed
                </NavLink>
                <NavLink
                  to={"/bookings/cancelled"}
                  className={(nav) =>
                    nav.isActive ? "nav-link active" : "nav-link"
                  }
                >
                  Cancelled
                </NavLink>
                <NavLink
                  to={"/bookings/all"}
                  className={(nav) =>
                    nav.isActive ? "nav-link active" : "nav-link"
                  }
                >
                  All Bookings
                </NavLink>
              </nav>
            </div>
            {/* <div className="col-12 col-md-auto">
              <div className="row">
                <div className="col-auto">
                  <Dropdown className="filterDropdown" align="end">
                    <Dropdown.Toggle className="_actionButton">
                      <em className="icon-filter me-2"></em> Filter
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="_item">
                        <h6 className="mb-3">Holes</h6>
                        <div className="row gutters-5">
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="holes" />
                              <span>9 Holes</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="holes" />
                              <span>18 Holes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <Dropdown.Divider />
                      <div className="_item">
                        <h6 className="mb-3">Players</h6>
                        <div className="row gutters-5">
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>1</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>2</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>3</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>4</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>5</span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="Players" />
                              <span>6</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <Dropdown.Divider />
                      <div className="_item">
                        <h6 className="mb-3">Tee Times</h6>
                        <div className="row gutters-5">
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="teetimes" />
                              <span>Morning Times </span>
                            </label>
                          </div>
                          <div className="col-auto">
                            <label className="ch-radio">
                              <input type="radio" name="teetimes" />
                              <span>Mid-day Times</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-auto">
                  <button type="button" className="_actionButton">
                    <em className="icon-printer me-2"></em> Print
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bookings;
