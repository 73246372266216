import { put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";
import { push } from 'connected-react-router';

import { authActions, currentUser } from '../reducers/authSlice';
import { SignupPayload } from '../models/auth';
import httpClient from "../api/merchantApi/httpClient";
import userHttpClient from "../api/userApi/httpClient";
import merchantHttpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';
import { store } from "../app/store";

function* handleSignupLogin(action: PayloadAction<SignupPayload>) {
  // show loader on start
  yield put(accountStorageActions.showHideSpinner(true));
  let dataCopy = { ...action.payload }
  delete dataCopy.type
  let url = action.payload.type == 'socialSignup' ? `/users/socialSignup` : `/users`;

  const { result, error, headers } = yield call(userHttpClient, {
    data: dataCopy,
    method: "post",
    url
  });
  // show loader on start
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.signupLoginFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(authActions.signupLoginSuccess(result.message))
    if (action.payload.type == 'socialSignup') {
      result.payload.accessToken = headers.accesstoken
      yield put(authActions.socialLoginSuccess(result.payload))
      let updateObj: any = {}

      if(result.payload.hmacToken){
        updateObj['user_hash'] = result.payload.hmacToken
      }
      if (result.payload.email) {
        updateObj['email'] = result.payload.email
      }
      if (result.payload.firstname) {
        updateObj['name'] = result.payload.firstname
      }
      window.Intercom('update', {
        ...updateObj
      });
    }
  }
  return { error, result };
}

function* handleVerifyOtp(action: PayloadAction<any>) {
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(userHttpClient, {
    data: action.payload,
    method: "post",
    url: `/users/verifyOtp`,
  });

  // hide loader on response fetched
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.verifyOtpFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    result.payload.accessToken = headers.accesstoken
    yield put(authActions.verifyOtpSuccess(result.payload))
    let updateObj: any = {}

    if(result.payload.hmacToken){
      updateObj['user_hash'] = result.payload.hmacToken
    }
    if (result.payload.email) {
      updateObj['email'] = result.payload.email
    }
    if (result.payload.phone?.countryCode) {
      updateObj['phone'] = `${result.payload.phone.countryCode}${result.payload.phone.number}`
    }

    window.Intercom('update', {
      ...updateObj
    });
  }

  return { error, result };
}

function* handleUserPortalExists(action: PayloadAction<any>) {
  //yield put(accountStorageActions.showHideSpinner(true));
  let {email, role} = action.payload;
  const { result, error } = yield call(userHttpClient, {
    method: "get",
    url: `/users/userExists?email=${email}&role=${role}`,
  });

  // hide loader on response fetched
  //yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.userPortalExistsFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(authActions.userPortalExistsSuccess({exists: result.payload?.exists, role}))
  }

  return { error, result };
}

function* handleMerchantRegister(action: PayloadAction<any>) {
  //yield put(accountStorageActions.showHideSpinner(true));
  const state = store.getState();
  let token = state?.auth?.currentUser?.accessToken;
  const { result, error, headers } = yield call(userHttpClient, {
    data: action.payload,
    method: "post",
    url: `/users/merchantSignup`,
    headers:{
      accesstoken:token
    }
  });

  // hide loader on response fetched
  //yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.merchantRegisterFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    toast.success("Signup request sent successfully!")
    yield put(authActions.merchantRegisterSuccess(result.payload));

  }

  return { error, result };
}

function* handleResendOtp(action: PayloadAction<any>) {
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(userHttpClient, {
    data: action.payload,
    method: "put",
    url: `/users/resendOtp`,
  });
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.resendOtpFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(authActions.resendOtpSuccess(result.message))
    toast.success(result.message)
  }
  return { error, result };
}

function* handleLogout(action: PayloadAction) {
  const { result, error } = yield call(userHttpClient, {
    data: action.payload,
    method: "put",
    url: `/users/logout`,
  });

  if (error) {
    console.log('Error in logout--', error);
    yield put(authActions.logoutFailed());
  } else {
    yield put(authActions.logoutSuccess())
  }
  return { error, result };
}


function* updateUserProfileData(action: PayloadAction<any>): any {

  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/merchant/profileData`,
  });

  if (error) {
    yield put(authActions.updateProfileDataFailed(error.message)); // Dispatch action
  } else {

    // update current user values with the updated values
    const selectedUser = yield select(currentUser);
    const updatedUser = { ...selectedUser, ...result.response };
    yield put(authActions.updateProfileDataSuccess(updatedUser))

  }
  return { error, result };
}

function* handleChangeEmailVerifyOtp(action: PayloadAction<any>): any {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/merchant/verify`,
  });

  if (error) {
    yield put(authActions.updateUserEmailOtpVerificationFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    // update current user values with the updated values
    const selectedUser = yield select(currentUser);
    /**changeEmail key should not exist in state after success */
    const updatedUser = { ...selectedUser, ...result.response, changeEmail:null };
    yield put(authActions.updateUserEmailOtpVerificationSuccess(updatedUser))
  }
  return { error, result };
}

function* handleUserProfileDelete(): any {
  const { result, error } = yield call(userHttpClient, {
    method: "put",
    url: `/users/deleteAccount`,
    ...( store.getState()?.auth?.currentUser?.accessToken ? {
      headers: {
        accesstoken: store.getState()?.auth?.currentUser?.accessToken
      }
    } : {} )
  });

  if (error) {
    toast.error(error);
  } else {
    toast.success('Profile deleted');
    yield put(authActions.userProfileDeleteSuccess());
  }
  return { error, result };
}


function* handleVerifySession(action: PayloadAction<any>) {

  // show loader on start
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(userHttpClient, {
    data: action.payload,
    method: "post",
    url: `/users/verifySession`
  });

  // hide loader on response fetched
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(authActions.verifySessionFailed(error.message)); // Dispatch action
  } else {
    result.payload.accessToken = headers.accesstoken;
    yield put(authActions.verifySessionSuccess(result.payload))
    let updateObj: any = {}
    if (result.payload.hmacToken) {
      updateObj['user_hash'] = result.payload.hmacToken
    }

    if (result.payload.email) {
      updateObj['email'] = result.payload.email
    }

    if (result.payload.phone?.countryCode) {
      updateObj['phone'] = `${result.payload.phone.countryCode}${result.payload.phone.number}`
    }

    window.Intercom('update', {
      ...updateObj
    });
  }
  return { error, result };
}

function* handleFetchAppConfig(): any {
  
  const { result, error, headers } = yield call(merchantHttpClient, {
    method: "get",
    url: "/config"
  });
  if (error) {
    yield put(authActions.fetchAppConfigfailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(authActions.fetchAppConfigSuccess(result.response))
  }
  return { error, result };
}

export function* authSaga() {
  yield takeLatest(authActions.signupLogin.type, handleSignupLogin);
  yield takeLatest(authActions.verifyOtp.type, handleVerifyOtp);
  yield takeLatest(authActions.resendOtp.type, handleResendOtp);
  yield takeLatest(authActions.logout.type, handleLogout);
  yield takeLatest(authActions.updateProfileData.type, updateUserProfileData)
  yield takeLatest(authActions.updateUserOtpVerification.type, handleChangeEmailVerifyOtp);
  yield takeLatest(authActions.userProfileDelete.type, handleUserProfileDelete);
  yield takeLatest(authActions.verifySession.type, handleVerifySession);
  yield takeLatest(authActions.userPortalExists.type, handleUserPortalExists);
  yield takeLatest(authActions.merchantRegister.type, handleMerchantRegister);
  yield takeLatest(authActions.fetchAppConfig.type, handleFetchAppConfig);
}
