import React, { FC } from "react";
import "./transition.scss";
import CommonHeader from "../../shared/common-header/common-header";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, Outlet } from "react-router-dom";

interface TransitionProps {}

const Transition: FC<TransitionProps> = () => (
  <div className="transitionWrapper">
    <Outlet></Outlet>
  </div>
);

export default Transition;
