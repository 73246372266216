import React, { FC, forwardRef, useState, useImperativeHandle, useEffect, MouseEventHandler, ChangeEvent, ChangeEventHandler } from "react";
import Slider from "rc-slider";

import { 
    COURSE_ADVANCE_BOOKING_MAX_DAYS, 
    COURSE_ADVANCE_BOOKING_MIN_DAYS, 
    DAY_SORTER, 
    COURSE_TEE_HOURS,
    COURSE_TEE_MINUTES, 
} from "../../utils/constants";
import { ClubTimings } from "../../models/clubs";
import { convertTime24to12, getValuesForAllTeeTimes } from "../../utils/common";

import "./date-time-component.scss";

interface IDateTimeComponent {
    info: IDateTimePayload;
    headerText ?: string;
    onHeaderClick ?: MouseEventHandler<HTMLElement>;
    advanceBookingMinDays ?: number;
    advanceBookingMaxDays ?: number;
    advanceBookingDefaultDay ?: number;
    clubTimingsSubmitError ?: string;
}

export interface IDateTimePayload {
    clubTimings ?: Array<ClubTimings>;
    clubAdvanceBookingDuration ?: number;
}

interface IUpdateClub {
    index: number;
    day?: string;
    startHours?: string;
    startMinutes?: string;
    endHours?: string;
    endMinutes?: string;
}

const DATE_TIME_SELECTOR_VALUES = getValuesForAllTeeTimes();

const DateTimeComponent = forwardRef<(IDateTimePayload|undefined), IDateTimeComponent>(({
        info,
        advanceBookingMinDays = COURSE_ADVANCE_BOOKING_MIN_DAYS,
        advanceBookingMaxDays = COURSE_ADVANCE_BOOKING_MAX_DAYS,
        advanceBookingDefaultDay = COURSE_ADVANCE_BOOKING_MIN_DAYS,
        clubTimingsSubmitError,
        headerText = "",
        onHeaderClick = () => console.log('Header Clicked')
    }, 
    ref
) => {

    const [clubTimings, updateClubTimings] = useState<Array<ClubTimings>>(DATE_TIME_SELECTOR_VALUES);
    const [clubAdvanceBookingDuration, setclubAdvanceBookingDuration] = useState<number>(advanceBookingDefaultDay);

    const onAdvanceBookingDaySelect = (day: number) => setclubAdvanceBookingDuration(day);

    const updateClubDuration = (changes: IUpdateClub) => {

        const { index, day, startHours, startMinutes, endHours, endMinutes } = changes;
    
        let updatedClubTimings: Array<ClubTimings> = JSON.parse(JSON.stringify(clubTimings));
        
        if (day) {
            // if the object is already there then on toggle remove the object from the array 
            if (updatedClubTimings[index] && updatedClubTimings[index]?.day) {
                delete updatedClubTimings[index];
            } else {
                updatedClubTimings[index] = { day, startHours: COURSE_TEE_HOURS[0], startMinutes: COURSE_TEE_MINUTES[0] };    // initialize start hours on select
            }
        }
    
        if (startHours) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], startHours };
        }

        if (startMinutes) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], startMinutes };
        }
    
        if (endHours) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], endHours };
        }

        if (endMinutes) {
            updatedClubTimings[index] = { ...updatedClubTimings[index], endMinutes };
        }
        
        updateClubTimings(updatedClubTimings);
    }

    const getDropdownValuesOnDropDownOptionSelect = (callback: (hour: string, minute: string) => void) => (e: ChangeEvent<HTMLSelectElement>) => {
        const [ hours, minutes ] = e.target.value.split(":");
        callback(hours, minutes);
    } 

    useImperativeHandle(ref, () => ({
        clubTimings: clubTimings.filter(val => val && Object.keys(val).length),
        clubAdvanceBookingDuration
    }));

    useEffect(() => {

        const { clubTimings: savedClubTimings, clubAdvanceBookingDuration: savedClubAdvanceBookingDuration } = info;
        
        if (savedClubTimings && savedClubTimings.length) {
            // set club days and timings
            const updatedClubtimings: Array<ClubTimings> = [];
            Object.keys(DAY_SORTER)
                .forEach((day: string, index: number) => {                    
                    const clubtimingObj = savedClubTimings.find(obj => obj.day === day);
                    if (clubtimingObj) {
                        // make timings back wards compatible by checking if the startMinutes and endMinutes are  
                        // present if not then add them 
                        updatedClubtimings[index] = {
                            ...clubtimingObj,
                            ...((!!!clubtimingObj.startMinutes) ? { startMinutes: COURSE_TEE_MINUTES[0] } : {}),
                            ...((!!!clubtimingObj.endMinutes) ? { endMinutes: COURSE_TEE_MINUTES[0] } : {})
                        };
                    }
                })
            updateClubTimings(updatedClubtimings);
        }
  
        if (savedClubAdvanceBookingDuration && clubAdvanceBookingDuration !== savedClubAdvanceBookingDuration) {
            // set advance booking duration value
            setclubAdvanceBookingDuration(savedClubAdvanceBookingDuration);
        }

    }, []);
    
    return <>
        <div className="border-none">
            {
                headerText && <div className="border-none" onClick={onHeaderClick}>
                    <div className="row py-2">
                        <div className="col-11 accordion-header-text">{headerText}</div>
                    </div> 
                </div> 
            }
            <div>
                <div className="mb-2">
                    Days
                </div>
                <div className="setDaysBlock mb-4">
                    {Object.keys(DAY_SORTER).map((key: string) => (
                    <>
                        <div className="row gutters-5 align-items-center mb-3 _dayTimesBlock">
                            <div className="col-12 col-md-3">
                                <div className="d-flex align-items-center">
                                    <label className="mb-0 ch-switch me-3 p-t2">
                                        <input type="checkbox" name={key} value={key} onChange={() => updateClubDuration({ index: DAY_SORTER[key], day: key })} checked={clubTimings[DAY_SORTER[key]]?.day == key} />
                                        <span>&nbsp;</span>
                                    </label>
                                    <h6 className="mb-0 fw700 text-capitalize">{key}</h6>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row gutters-10 align-items-center">
                                    <div className="col-auto">
                                        <select
                                            className="form-select"
                                            disabled={ clubTimings[DAY_SORTER[key]]?.day == undefined}
                                            onChange={getDropdownValuesOnDropDownOptionSelect((hours, minutes) => updateClubDuration({ index: DAY_SORTER[key], startHours: hours, startMinutes: minutes }))}
                                        >
                                        <option disabled hidden selected>
                                            Select Start Time
                                        </option>
                                        {
                                            COURSE_TEE_HOURS
                                                .filter((_, index) => (index < COURSE_TEE_HOURS.length -1))
                                                .map((hourVal, index) => COURSE_TEE_MINUTES.map((minuteVal) => <>
                                                        <option
                                                            value={`${hourVal}:${minuteVal}`}
                                                            key={index}
                                                            selected={(clubTimings[DAY_SORTER[key]]?.startHours == hourVal && clubTimings[DAY_SORTER[key]]?.startMinutes == minuteVal)}
                                                        >
                                                            {convertTime24to12(hourVal, minuteVal)}
                                                        </option>
                                                    </>)
                                                )
                                                .flat()
                                        }
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        <span className="_to">To</span>
                                    </div>
                                    <div className="col-auto">
                                        <select
                                            className="form-select"
                                            disabled={(clubTimings[DAY_SORTER[key]] == undefined || clubTimings[DAY_SORTER[key]].startHours == undefined)}
                                            onChange={getDropdownValuesOnDropDownOptionSelect((hours, minutes) => updateClubDuration({ index: DAY_SORTER[key], endHours: hours, endMinutes: minutes }))}
                                        >
                                        <option disabled hidden selected>
                                            Select Closing Time
                                        </option>
                                        {
                                            COURSE_TEE_HOURS
                                            .map((hourVal, index) => COURSE_TEE_MINUTES.map((minuteVal) => <>
                                                    <option
                                                        disabled={(
                                                            !!clubTimings[DAY_SORTER[key]]?.startHours && 
                                                            !!clubTimings[DAY_SORTER[key]]?.startMinutes &&
                                                            parseInt(`${clubTimings[DAY_SORTER[key]].startHours}${clubTimings[DAY_SORTER[key]].startMinutes}`) >= parseInt(`${hourVal}${minuteVal}`)
                                                        )}
                                                        value={`${hourVal}:${minuteVal}`}
                                                        key={index}
                                                        selected={(clubTimings[DAY_SORTER[key]]?.endHours == hourVal && clubTimings[DAY_SORTER[key]]?.endMinutes == minuteVal)}
                                                    >
                                                        {convertTime24to12(hourVal, minuteVal)}
                                                    </option>
                                                </>)
                                            )
                                            .flat()
                                        }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    ))}
                </div>
                <div className="setDaysBlock mb-2 pt-3">
                    <h6 className="mb-3">
                        Set duration for advance bookings
                    </h6>
                    <div className="row">
                        <div className="col-12">
                            <div className="input-group mb-4 daysInputGroup">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={clubAdvanceBookingDuration}
                                    onChange={(e) => onAdvanceBookingDaySelect(Math.floor(Number(e.target.value)))}
                                />
                                <span className="input-group-text">
                                    {clubAdvanceBookingDuration &&
                                    clubAdvanceBookingDuration > 1
                                    ? "Days"
                                    : "Day"}
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <Slider
                                className="mb-5"
                                min={advanceBookingMinDays}
                                max={advanceBookingMaxDays}
                                railStyle={{
                                    backgroundColor: "#E3F1F0",
                                    height: 4,
                                }}
                                trackStyle={{
                                    backgroundColor: "#153230",
                                    height: 4,
                                }}
                                handleStyle={{
                                    borderColor: "#00D19F",
                                    opacity: 1,
                                    height: 24,
                                    width: 24,
                                    marginLeft: 0,
                                    marginTop: -10,
                                    backgroundColor: "#00D19F",
                                }}
                                marks={{ [advanceBookingMinDays]: `${advanceBookingMinDays} day`, [advanceBookingMaxDays]: `${advanceBookingMaxDays} days` }}
                                onChange={(value: any) => onAdvanceBookingDaySelect(value)}
                                value={clubAdvanceBookingDuration}
                            />
                        </div>
                    </div>
                    <span className="isInvalidMessage text-danger">
                        {clubTimingsSubmitError}
                    </span>
                </div>
            </div>
        </div>
    </>;
});

export default DateTimeComponent;