import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import httpClient from "../../api/merchantApi/httpClient";
import { toast } from "react-toastify";
import { transactionActions } from './transactionSlice';

function* handleFetchTransactions(action: PayloadAction<any>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/bookings/transactions?${action.payload}`,
  });

  if (error) {
    yield put(transactionActions.fetchTransactionsFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(transactionActions.fetchTransactionsSuccess(result))
  }
  return { error, result };
}

function* handleFetchMerchantDashboardStats(action: PayloadAction<any>) {

  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "get",
    url: `/dashboardStats`,
  });

  if (error) {
    yield put(transactionActions.fetchDashboardStatsFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(transactionActions.fetchDashboardStatsSuccess(result.response))
  }
  return { error, result };
}


export function* transactionSaga() {
  yield takeLatest(transactionActions.fetchTransactions.type, handleFetchTransactions);
  yield takeLatest(transactionActions.fetchDashboardStats.type, handleFetchMerchantDashboardStats)
}
