import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { externalClubsActions } from '../reducers/externalClubsSlice';
import { ExternalClubPayload } from '../models/externalClubs';
import httpClient from "../api/merchantApi/httpClient";

function* handleFetchExternalClubs(action: PayloadAction<ExternalClubPayload>) {
    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/clubs/external`,
    });

    if (error) {
        yield put(externalClubsActions.externalClubsListFailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(externalClubsActions.externalClubsListSuccess(result.response))
    }
    return { error, result };
}

export function* externalClubsSaga() {
    yield takeLatest(externalClubsActions.externalClubsList.type, handleFetchExternalClubs);
}
  