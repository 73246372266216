import React, { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface InfoModalProps {
  message ?: string,
  buttonText ?: string,
  showModal: boolean;
  modalImagePath?: string;
  onCloseClick : (arg: any) => void;
  onModalButtonClick: (arg: any) => void;
}

const InfoModal: FC<InfoModalProps> = ({
    message = "Message to be displayed.",
    buttonText = "Okay",
    showModal = false,
    modalImagePath = "",
    onCloseClick,
    onModalButtonClick
}) => {
    return <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
    >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={onCloseClick}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            <div className="text-center">
              {
                modalImagePath && <img
                  src={modalImagePath}
                  className="mb-4 d-inline-block"
                />
              }
              <h3 className="mb-4">
                {message}
              </h3>
              <a
                href={void 0}
                className="text-decoration-underline fw600"
                onClick={onModalButtonClick}
              >
                {buttonText}
              </a>
            </div>
          </div>  
      </Modal.Body>
  </Modal>;
};

export default InfoModal;
