import React, { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../../utils/constants";

import "./delete-course-modal.scss";

const DELETION_REASON_1 = "I made the booking by accident";
const DELETION_REASON_2 = "Other";

interface deleteCoursePayload {
  reason: string;
  description: string;
  termsAccepted: boolean;
}
interface DeleteCourseModalProps {
  courseId: string;
  showModal: boolean;
  onCloseClick : (arg: any) => void;
  onDelete: (courseId: string, reason: string, description: string) => boolean;
  onDeleteSuccess ?: (arg?: any) => void;
  deletionReasons ?: Array<string>;
}

const DeleteCourseModal: FC<DeleteCourseModalProps> = ({
  showModal,
  onCloseClick,
  onDelete,
  courseId,
  onDeleteSuccess,
  deletionReasons = [],
}) => {
  
  const [ deleteReason, setDeleteReason ] = useState<string>("");
  const [ deleteDescription, setDeleteDescription ] = useState<string>("");
  const [ termsAccepted, setTerms ] = useState<boolean>(false);

  const { register: deleteCourseRegister, handleSubmit: handleDeleteCourseSubmit, formState: { errors: deleteCourseError }, reset: resetDeleteCourse, control: deleteCourseControl } = useForm<deleteCoursePayload>();
  const onDeleteCourseSubmit: SubmitHandler<deleteCoursePayload> = _ => deleteCourse();
  
  const onDeleteReasonChange = (e: any) => setDeleteReason(e.target.value);

  const onDescriptionChange = (e: any) => setDeleteDescription(e.target.value);

  const onTermsChange = (e: any) => setTerms(e.target.value);

  const deleteCourse = () => onDelete(courseId, deleteReason, deleteDescription);

  return (
    <>
      <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton">
            <em className="fa-solid fa-close" onClick={onCloseClick}></em>
          </a>
          <div className="formWrap">
            <form onSubmit={handleDeleteCourseSubmit(onDeleteCourseSubmit)}>
              <h4 className="mb-4">Delete Course</h4>
              <div className="mb-4">
                <div className="form-floating">
                  <select className="form-select" id="reason" {...deleteCourseRegister("reason", { required: true })} onClick={onDeleteReasonChange}>
                    <option value="" disabled hidden selected></option>
                    {
                      deletionReasons.length ? deletionReasons.map((reason, index) => <option key={index} value={reason} selected={(deleteReason == reason)}>{reason}</option>) : <option value={DELETION_REASON_1} selected={(deleteReason == DELETION_REASON_1)}>{DELETION_REASON_1}</option>
                    }
                    <option value={DELETION_REASON_2} selected={(deleteReason == DELETION_REASON_2)}>{DELETION_REASON_2}</option>
                  </select>
                  <label htmlFor="reason">Select a Reason</label>
                </div>
                {deleteCourseError.reason?.type == "required" && <span className="isInvalidMessage text-danger">Please select delete reason</span>}
              </div>
              <div className="mb-4">
                <div className="form-floating">
                  <textarea
                    rows={6}
                    className="form-control"
                    id="description"
                    style={{ height: "auto" }}
                    {...deleteCourseRegister("description", { required: true })} onChange={onDescriptionChange}
                  ></textarea>
                  <label htmlFor="description">Description</label>
                </div>
                {deleteCourseError.description?.type == "required" && <span className="isInvalidMessage text-danger">Please add delete description</span>}
              </div>
              <div className="mb-4">
                <div className="row gutters-5">
                  <div className="col-auto">
                    <label className="mb-0 ch-checkbox ch-checkbox-bordered">
                      <input type="checkbox" checked={(termsAccepted||false)} {...deleteCourseRegister("termsAccepted", { required: true })} onClick={onTermsChange}/>
                      <span>&nbsp;</span>
                    </label>
                  </div>
                  <div className="col">
                    <p className="mb-0 fw500">
                      I agree with <a href="https://www.integolf.com/terms" target="_blank">Terms & Conditions</a> and{" "}
                      <a href="https://www.integolf.com/privacy" target="_blank">Privacy Policy</a>.
                    </p>
                  </div>
                </div>
                {deleteCourseError.description?.type == "required" && <span className="isInvalidMessage text-danger">Please accept terms & condtions</span>}
              </div>

              <button
                type="submit"
                className="button button-danger button-rounded button-min-120 fw600 fontsize-16"
              >
                Delete
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteCourseModal;
