import { put, takeLatest, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { coursesActions, fetchCourses, fetchCourseData } from '../reducers/coursesSlice';
import { accountStorageActions } from "../reducers/accountStorageSlice";
import { CoursesPayload } from '../models/courses';
import httpClient from "../api/merchantApi/httpClient";
import dataCollectionHttpClient from "../api/dataCollectionApi/httpClient";
import { CLUB_ACTIVE_STATUS } from '../utils/constants';

function* handleListUserCourses(action: PayloadAction<CoursesPayload>) {
  
  const { result, error, headers } = yield call(httpClient, {
    params: action.payload,
    method: "get",
    url: `/course`,
  });
  
  if (error) {
    yield put(coursesActions.coursesListFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.coursesListSuccess(result.response))
  }
  return { error, result };
}

function* handleFetchCourseData(action: PayloadAction<CoursesPayload>) {
    
    const { result, error, headers } = yield call(httpClient, {
        params: action.payload,
        method: "get",
        url: `/course`,
    });
    
    if (error) {
        yield put(coursesActions.fetchCourseDatafailed(error.message)); // Dispatch action
        toast.error(error);
    } else {
        yield put(coursesActions.selectedCourse(result.response[0]))
    }
    return { error, result };
}

export function* handleCreateNewCourse(action: PayloadAction<CoursesPayload>) {
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/merchant/course`,
  });

  if (error) {
    yield put(coursesActions.addNewCourseFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.addNewCourseSuccess(result.response))
  }
  return { error, result };
}

function* handleUpdateCourseData(action: PayloadAction<CoursesPayload>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/merchant/course`,
  });

  if (error) {
    yield put(coursesActions.updateCourseDataFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    // update club data with de activated course
    const courses: Array<CoursesPayload> = yield select(fetchCourses);
    const updatedCourseIndex = courses.map(obj => obj._id).indexOf(action.payload._id);
    
    if (updatedCourseIndex> -1) {
      const updatedCourseData = JSON.parse(JSON.stringify(courses));
      updatedCourseData[updatedCourseIndex] = result.response;
      if(result?.response?.status === CLUB_ACTIVE_STATUS){
        toast.info('Course is now active.');
      }
      yield put(coursesActions.addCourseUpdatedData(result.response));
      yield put(coursesActions.coursesListSuccess(updatedCourseData));
      yield put(coursesActions.updateCourseDataSuccess(result.response))
    }
    
    //update selected course
    const selectedCourse : CoursesPayload = yield select(fetchCourseData)  ;
    if (selectedCourse && (selectedCourse._id == result.response._id)) {
      yield put(coursesActions.updateCourseDataSuccess(result.response))
    }
  }
  yield put(accountStorageActions.showHideSpinner(false));
  return { error, result };
}

// function used to explicitly publish data to hide the pop up once the course is published in course preview page
function* handlePublishCourseData(action: PayloadAction<CoursesPayload>){
  
  yield put(accountStorageActions.showHideSpinner(true));
  const { error, result } = yield handleUpdateCourseData(action);

  // update course publish flags
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.handleCoursePublishFailure());
  } else {
    yield put(coursesActions.handleCoursePublishSucess());
  }

  return {error, result};
}

function* deleteCourseData(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "delete",
    url: `/course`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.deleteCourseDataFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.deleteCourseDataSuccess(result.response));

    // update course data with removed course
    const courses: Array<CoursesPayload> = yield select(fetchCourses);
    const filteredCourses = courses.filter(course => course._id !== action.payload.id);

    yield put(coursesActions.coursesListSuccess(filteredCourses));

    // if course is currently selected then remove the course
    const selectedCourse: CoursesPayload = yield select(fetchCourseData);
    if (selectedCourse && selectedCourse._id == action.payload.id) {
      yield put(coursesActions.resetSelectedCourse());
    }
  }
  return { error, result };
}

function* handleCourseSyncOnActive(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/syncCourseTeeSheets`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.courseSyncOnActiveFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.courseSyncOnActiveSucess(result.response))
  }
  return { error, result };
}

function* testCourseSync(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(dataCollectionHttpClient, {
    data: action.payload,
    method: "post",
    url: `integolf/fetchTeetimes`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.testCourseTeesheetFetchFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.testCourseTeesheetFetchSuccess(result.response))
  }
  return { error, result };
}

function* teeonOnboarding(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/teeOnBoardingForm`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.teeonOnboardingFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.teeonOnboardingSuccess(result.response))
  }
  return { error, result };
}

function* sendTeeonOnboardingForm(action: PayloadAction<any>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `sendTeeOnBoardingFormEmail`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.sendTeeonOnboardingFormFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.sendTeeonOnboardingFormSuccess(result.response))
  }
  return { error, result };
}

function* fetchTeeonMerchantFormDetails(action: PayloadAction<any>){
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `course/fetchTeeonMerchantFormDetails`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.fetchTeeonOnboardingFormDetailsFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(coursesActions.fetchTeeonOnboardingFormDetailsSuccess(result.response))
  }
  return { error, result };
}

function* fetchChronogolfCoursesRates(action: PayloadAction<any>){
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error } = yield call(dataCollectionHttpClient, {
    method: "get",
    url: `integolf/rates/${action.payload.clubObjectId}`,
  });

  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(coursesActions.fetchChronogolfCoursesRatesFailure(error.message)); // Dispatch action
  } else {
    yield put(coursesActions.fetchChronogolfCoursesRatesSuccess(result))
  }
  return { error, result };
}

export function* coursesSaga() {
  yield takeLatest(coursesActions.coursesList.type, handleListUserCourses);
  yield takeLatest(coursesActions.fetchCourseData.type, handleFetchCourseData);
  yield takeLatest(coursesActions.addNewCourse.type, handleCreateNewCourse);
  yield takeLatest(coursesActions.updateCourseData.type, handleUpdateCourseData);
  yield takeLatest(coursesActions.coursePublish.type, handlePublishCourseData);
  yield takeLatest(coursesActions.deleteCourseData.type, deleteCourseData);
  yield takeLatest(coursesActions.courseSyncOnActive.type, handleCourseSyncOnActive);
  yield takeLatest(coursesActions.testCourseTeesheetFetch.type, testCourseSync);
  yield takeLatest(coursesActions.teeonOnboarding.type, teeonOnboarding);
  yield takeLatest(coursesActions.sendTeeonOnboardingForm.type, sendTeeonOnboardingForm);
  yield takeLatest(coursesActions.fetchTeeonOnboardingFormDetails.type, fetchTeeonMerchantFormDetails);
  yield takeLatest(coursesActions.fetchChronogolfCoursesRates.type, fetchChronogolfCoursesRates);
}
