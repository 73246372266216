import { put, takeLatest, select, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from "react-toastify";

import { clubsActions, fetchClubs, fetchClubData } from '../reducers/clubsSlice';
import { coursesActions, fetchCourseData } from '../reducers/coursesSlice';
import { handleCreateNewCourse } from "./coursesSaga";
import { ClubPayload } from '../models/clubs';
import httpClient from "../api/merchantApi/httpClient";
import { accountStorageActions } from '../reducers/accountStorageSlice';

function* handleListUserClubs(action: PayloadAction<ClubPayload>) {
  
  const { result, error, headers } = yield call(httpClient, {
    params: action.payload,
    method: "get",
    url: `/clubs`,
  });
  
  if (error) {
    yield put(clubsActions.clubsListFailed(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(clubsActions.clubsListSuccess(result.response))
  }
  return { error, result };
}

function* handleFetchSelectedClubData(action: PayloadAction<ClubPayload>) {
  
  const { result, error, headers } = yield call(httpClient, {
    params: action.payload,
    method: "get",
    url: `/clubs`,
  });
  
  if (error) {
    yield put(clubsActions.fetchSelectedClubDataFailed(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(clubsActions.selectedClub(result.response[0]))
  }
  return { error, result };
}

function* handleCreateNewClub(action: PayloadAction<any>) {
  
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "post",
    url: `/merchant/club`,
  });

  if (error) {
    yield put(clubsActions.addNewClubFailure(error.message)); // Dispatch action
  } else {
    yield put(clubsActions.addNewClubSuccess(result.response))
  }
  return { error, result };
}

function* handleUpdateClubData(action: PayloadAction<any>) {
  
  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/merchant/club`,
  });
  
  yield put(accountStorageActions.showHideSpinner(false));
  if (error) {
    yield put(clubsActions.updateClubDataFailure(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(clubsActions.updateClubDataSucess(result.response))
  }
  return { error, result };
}

function* hundleActivateDeactivateClub(action: PayloadAction<ClubPayload>) {

  yield put(accountStorageActions.showHideSpinner(true));
  const { result, error, headers } = yield call(httpClient, {
    data: action.payload,
    method: "put",
    url: `/merchant/club`,
  });

  if (error) {
    yield put(clubsActions.activateDeactivateClubFailure(error.message)); // Dispatch action
    toast.error(error.message);
  } else {
    yield put(clubsActions.activateDeactivateClubSuccess(result.response));

    // update club data with de activated club
    const clubs: Array<ClubPayload> = yield select(fetchClubs);
    const updatedClubIndex = clubs.map(obj => obj._id).indexOf(action.payload._id);
    
    if (updatedClubIndex> -1) {
      const updatedClubData = JSON.parse(JSON.stringify(clubs));
      updatedClubData[updatedClubIndex].status = action.payload.status;
      yield put(clubsActions.clubsListSuccess(updatedClubData));
    }

  }
  yield put(accountStorageActions.showHideSpinner(false));
  return { error, result };
}

function* handleClubCourseCreation(action: PayloadAction<any>) {
  
  yield put(accountStorageActions.showHideSpinner(true));
  // create new club
  const { club, course } = action.payload;
  const { result: clubResult, error } = yield handleCreateNewClub({ payload: club, type: clubsActions.addNewClub.type });

  // create new course
  if (clubResult && clubResult.response) {
    const { response } = clubResult;
    course["parentClubId"]  = response._id;
    
    const { result: courseResult, error } = yield handleCreateNewCourse({ payload: course, type: coursesActions.addNewCourse.type });

    if (error) {
      yield put(clubsActions.createClubAndCourseFailure(error.message));
    } else {
      const result = { club: clubResult.response, course: courseResult.response };
      yield put(clubsActions.createClubAndCourseSuccess(result));
    }

  } else {
    yield put(clubsActions.createClubAndCourseFailure(error.message));
  }
  yield put(accountStorageActions.showHideSpinner(false));
  return { error, result: clubResult };
}

export function* clubsSaga() {
  yield takeLatest(clubsActions.clubsList.type, handleListUserClubs);
  yield takeLatest(clubsActions.fetchSelectedClubData.type, handleFetchSelectedClubData);
  yield takeLatest(clubsActions.addNewClub.type, handleCreateNewClub);
  yield takeLatest(clubsActions.updateClubData.type, handleUpdateClubData);
  yield takeLatest(clubsActions.activateDeactivateClub.type, hundleActivateDeactivateClub);
  yield takeLatest(clubsActions.createClubAndCourse.type, handleClubCourseCreation);
}
