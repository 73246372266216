import React, { FC, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from 'moment';

import "./transition-payout-detail.scss";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { payoutTransactionsActions, fetchMerchantActivePayout, fetchMerchantPayoutTransactions } from "../../../../reducers/payoutTransactionsSlice";
import { Payouts } from "../../../../models/payouts";
import { MerchantBookedTeesheets } from "../../../../models/merchantBookedTeesheets";
import { INTEGOLF_MERCHANT_TRANSACTION_STATUS_SUCCESS, INTEGOLF_MERCHANT_TRANSACTION_STATUS_REFUNDED } from "../../../../utils/constants";

interface TransitionPayoutDetailProps {}

const TransactionElement : FC<MerchantBookedTeesheets> = (transaction: MerchantBookedTeesheets) => {

  const userName = transaction?.firstname && (transaction?.firstname.length || transaction?.lastname?.length) ? `${transaction?.firstname} ${transaction?.lastname}` : '-';

  return <tr key={transaction?._id}>
      <td>
        <h6 className="mb-0 fw700">{userName}</h6>
      </td>
      <td>
        <span className="color-600 fw400">{(transaction?.booking?.bookingId || "-")}</span>
      </td>
      <td>
        <span className="color-600 fw400">{(transaction?.transaction?.transactionId || "-")}</span>
      </td>
      <td>{transaction?.createdAt ? moment(transaction.createdAt).format("dddd, DD MMM YYYY") : '-'}</td>
      <td>
        {
          transaction?.transaction?.status ? 
            ( transaction.transaction.status == INTEGOLF_MERCHANT_TRANSACTION_STATUS_SUCCESS ? 
              <label className="mb-0 badge badgeStatus">Paid</label>
              : ( transaction.transaction.status == INTEGOLF_MERCHANT_TRANSACTION_STATUS_REFUNDED ) ? <label className="mb-0 badge badgeStatus warning">Refunded</label>: <label className="mb-0 badge badgeStatus warning">Failed</label>
            ) 
            : "-" 
        }
        
      </td>
      <td>
        <h6 className="mb-0 fw700">{ transaction?.totalAmount ? '$'+transaction?.totalAmount.toLocaleString() : '$0' }</h6>
      </td>
      <td>
        <span className="color-600 fw500">{ transaction?.integolfFees ? '-$'+transaction?.integolfFees.toLocaleString() : '$0' }</span>
      </td>
      <td>
        <span className="color-600 fw500">{ transaction?.bookingFees ? '-$'+transaction?.bookingFees.toLocaleString() : '$0' }</span>
      </td>
      <td>
        <h6 className="mb-0 fw700">{ transaction?.payouts?.merchantPayoutAmount ? '$'+transaction?.payouts?.merchantPayoutAmount.toLocaleString() : '$0' }</h6>
      </td>
    </tr>;
}

const TransitionPayoutDetail: FC<TransitionPayoutDetailProps> = () => {

  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const activePayout: Payouts = useAppSelector(fetchMerchantActivePayout) || {};
  const payoutTransactions: Array<MerchantBookedTeesheets> = useAppSelector(fetchMerchantPayoutTransactions) || [];
  
  useEffect(() => {
    if (id) {
      dispatch(payoutTransactionsActions.fetchActivePayout({_id: id}));
      dispatch(payoutTransactionsActions.payoutTransactionsList({payoutId: id}));
    }
  }, []);
  
  return (
    <div className="transition-payout-detail">
      <div className="commonHeader transitionWrapper__header pb-4">
        <div className="container">
          <div className="row gutters-10 align-items-center mb-4 pb-2">
            <div className="col-auto">
              <NavLink
                to={"/transaction/payout"}
                className="button button-s3 button-border button-pill button-sm px-4 color-white"
              >
                <em className="fas fa-arrow-left me-2"></em> Back
              </NavLink>
            </div>
            <div className="col-auto">
              <ul className="navBreadCrumb">
                <li>
                  <span>Payout Details</span>
                </li>
              </ul>
            </div>
          </div>
  
          <div className="row gutters-10 align-items-center">
            <div className="col">
              <div className="row gutters-10">
                <div className="col-auto">
                  <div className="_tBlock _tBlock--sm mb-2">
                    <label className="mr-2 mb-4 d-block">Amount Sent</label>
                    <h5 className="mb-0">{ activePayout?.total_amount ? '$'+activePayout.total_amount.toLocaleString() : '$0' }</h5>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="_tBlock _tBlock--sm mb-2">
                    <label className="mr-2 mb-4 d-block">Fees</label>
                    <h5 className="mb-0">{ activePayout?.fees ? '-$'+activePayout.fees.toLocaleString(): '$0' }</h5>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="_tBlock _tBlock--sm mb-2">
                    <label className="mr-2 mb-4 d-block">Booking Fees</label>
                    <h5 className="mb-0">{ activePayout?.booking_fees ? '-$'+activePayout.booking_fees.toLocaleString(): '$0' }</h5>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="_tBlock _tBlock--sm mb-2">
                    <label className="mr-2 mb-4 d-block">Amount Received</label>
                    <h5 className="mb-0">{activePayout?.payout_amount ? '$'+ activePayout.payout_amount.toLocaleString() : '$0'}</h5>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="_tBlock _tBlock--sm mb-2">
                    <label className="mr-2 mb-4 d-block">Paid On</label>
                    <h5 className="mb-0">{activePayout?.upcomingPayout ? moment(activePayout.upcomingPayout).format("dddd, DD MMM YYYY") : '-'}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transitionWrapper__body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-border">
                  <thead>
                    <tr>
                      <th>Paid By</th>
                      <th>Booking ID</th>
                      <th>Transaction ID</th>
                      <th>
                        Date 
                        {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                      </th>
                      <th>
                        Status{" "}
                        {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                      </th>
                      <th>Booking Amount</th>
                      <th>Integolf Fee</th>
                      <th>Booking Fee</th>
                      <th>
                        Amount Received{" "}
                        {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                      </th>
                    </tr>
                  </thead>
                  {
                    payoutTransactions && payoutTransactions.length ? 
                      <tbody>
                        {payoutTransactions.map(transactionObj => <TransactionElement {...transactionObj} />)}
                      </tbody> :
                      <div className='bookingsListWrapper' id='transactionsListWrapper'>
                        <div className="noBookingBlock text-center mt-4 py-5">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/no-booking.png`}
                          alt="no-booking"
                          className="img-fluid mx-auto d-block"
                        />
                        <h5 className="mt-4 pt-3 fontsize-16 fw600">
                          No Payout Transactions to list
                        </h5>
                        </div>
                      </div> 
                  }
                  <tbody>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default TransitionPayoutDetail;
