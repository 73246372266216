import React, { FC, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import './payout-list.scss';
import CommonHeader from '../../../../shared/common-header/common-header';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { payoutsActions, fetchMerchantPayouts, fetchMerchantPaidPayout, fetchMerchantPendingPayout } from '../../../../reducers/payoutsSlice';
import { Payouts } from '../../../../models/payouts';
import { INTEGOLF_MERCHANT_PAYOUT_STATUS_PENDING ,INTEGOLF_MERCHANT_PAYOUT_STATUS_APPROVED, INTEGOLF_MERCHANT_PAYOUT_STATUS_READY } from "../../../../utils/constants";

interface PayoutListProps {}

const PayoutElement : FC<Payouts> = (payoutObject: Payouts) => {

  return <tr key={payoutObject._id}>
    <td><h6 className="mb-0 fw700">{payoutObject?.payoutId}</h6></td>
    <td>{moment(payoutObject.upcomingPayout).format("dddd, DD MMM YYYY")}</td>
    <td>
      {
        payoutObject?.status == INTEGOLF_MERCHANT_PAYOUT_STATUS_PENDING && <label className="mb-0 badge badgeStatus pending">Pending</label>
      }
      {
        payoutObject?.status == INTEGOLF_MERCHANT_PAYOUT_STATUS_READY && <label className="mb-0 badge badgeStatus">Ready</label>
      }
      {
        payoutObject?.status == INTEGOLF_MERCHANT_PAYOUT_STATUS_APPROVED && <label className="mb-0 badge badgeStatus success">Paid</label>
      }
    </td>
    <td>{payoutObject?.bookings}</td>
    <td>
      <h6 className="mb-0 fw700">{ payoutObject?.total_amount ? `$ ${payoutObject.total_amount.toLocaleString()}` : '-' }</h6>
    </td>
    <td> { payoutObject?.fees ? `-$ ${payoutObject.fees.toLocaleString()}` : '-' } </td>
    <td> { payoutObject?.booking_fees ? `-$ ${payoutObject.booking_fees.toLocaleString()}` : '-' } </td>
    <td>
      <h6 className="mb-0 fw700">{ payoutObject?.payout_amount ? `$ ${payoutObject.payout_amount.toLocaleString()}`: '-' }</h6>
    </td>
    {/* <td>****6453</td> */}
    <td>
      <div className="listActionDropdownBlock">
        <Dropdown align="end">
          <Dropdown.Toggle className="toggleButton">
            <em className="icon-vertical-elipse"></em>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <NavLink
              to={`/transaction/history/${payoutObject?._id}`}
              className="dropdown-item"
            >
              View Detail
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </td>
  </tr>;
} 

const PayoutList: FC<PayoutListProps> = () => {

  const dispatch = useAppDispatch();
  const merchantPayouts: Array<Payouts> = useAppSelector(fetchMerchantPayouts) || [];
  const lastPaidPayout: Payouts = useAppSelector(fetchMerchantPaidPayout) || {};
  const pendingPayout: Payouts = useAppSelector(fetchMerchantPendingPayout) || {};
  
  useEffect(() => {
    dispatch(payoutsActions.payoutsList());
    dispatch(payoutsActions.fetchPendingPayout());
    dispatch(payoutsActions.fetchLastPaidPayout());
  }, []);

  return(
  <>
  <div className="transition-history">
    <CommonHeader sectionTitle={"Payouts"} hasExportButton={false}></CommonHeader>
    <div className="commonHeader transitionWrapper__header pt-0 pb-4">
      <div className="container">
        <div className="row gutters-10 align-items-center">
          <div className="col">
            <div className="row gutters-10">
              <div className="col-auto">
                <div className="_tBlock mb-2">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                    <label className="mr-2 mb-2">Balance</label>
                    <label className="_date mb-2">{ pendingPayout?.upcomingPayout ? 'Est. by: '+moment(pendingPayout.upcomingPayout).format("MMM DD, YYYY"): "-" } </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h5 className="mb-0"> { pendingPayout?.payout_amount ? `$ ${pendingPayout?.payout_amount.toLocaleString()}` : "No Balance" }</h5>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="_tBlock mb-2">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                    <label className="mr-2 mb-2">Previous payout</label>
                    <label className="_date mb-2">{ lastPaidPayout?.upcomingPayout ? moment(lastPaidPayout.upcomingPayout).format("MMM DD, YYYY"): "-" }</label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h5 className="mr-2 mb-0">{ lastPaidPayout?.payout_amount ? `$ ${lastPaidPayout?.payout_amount.toLocaleString()}` : "No Payout" }</h5>
                    <label className="badge mb-0">Paid</label>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="_tBlock mb-2">
                  <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
                    <label className="mr-2 mb-2">Next payout</label>
                    <label className="_date mb-2">{ pendingPayout?.upcomingPayout ? moment(pendingPayout.upcomingPayout).format("MMM DD, YYYY"): "-" }</label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <h5 className="mr-2 mb-0">{ pendingPayout?.payout_amount ? `$ ${pendingPayout?.payout_amount.toLocaleString()}` : "No Payout" }</h5>
                    <label className="badge pending mb-0">Pending</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-auto">
            <button
              type="button"
              className="_actionButton dropdown-toggle btn btn-primary mb-2"
            >
              <em className="icon-export me-2"></em> Export
            </button>
          </div> */}
        </div>
      </div>
    </div>
    <div className="transitionWrapper__body">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-border">
                <thead>
                  <tr>
                  <th>Payout ID</th>
                    <th>Date 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Status 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Bookings 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Amount Sent 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Integolf Fees 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Booking Fees 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    <th>Amount Received 
                      {/* <em className="fa-solid fa-sort ms-1 color-700"></em> */}
                    </th>
                    {/* <th>Transferred in</th> */}
                    <th className="w40">&nbsp;</th>
                  </tr>
                </thead>
                {
                    merchantPayouts?.length ?
                    <tbody>
                      {merchantPayouts.map((payout: Payouts) => <PayoutElement {...payout}/>)}
                    </tbody> 
                    : 
                    <div className='bookingsListWrapper' id='payoutListWrapper'>
                      <div className="noBookingBlock text-center mt-4 py-5">
                       <img
                         src={`${process.env.PUBLIC_URL}/assets/images/no-booking.png`}
                         alt="no-booking"
                         className="img-fluid mx-auto d-block"
                       />
                       <h5 className="mt-4 pt-3 fontsize-16 fw600">
                         No payouts to list
                       </h5>
                      </div>
                    </div>
                  }
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></>
)};

export default PayoutList;
