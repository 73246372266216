import React, { FC, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import OtpInput from 'react-otp-input';
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import './update-info-modal.scss';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { authActions, getUserUpdateStatus, getUserVerifyOtpStatus } from "../../../../reducers/authSlice";

interface ChangeEmailPayload {
  email : string;
  emailPayloadKey : string; 
}

interface ChangeEmailOtpVerificationPayload {
  otp : string;
  emailPayloadKey: string
}

interface UpdateInfoModalProps {
  hasModalVisible: boolean;
  handleClose: (arg: any) => void;
  emailPayloadKey: string;
}

const UpdateInfoModal: FC<UpdateInfoModalProps> = ({
  hasModalVisible, 
  handleClose,
  emailPayloadKey
}) => {
  const dispatch = useAppDispatch();
  const userProfileUpdateStatus : string = useAppSelector(getUserUpdateStatus);
  const userVerifyOtpStatus: string = useAppSelector(getUserVerifyOtpStatus);

  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [showConfirmationStep, setShowConfirmationStep] = useState(false);
  const [OTP, setOTP] = useState("");
  const [showTimer, setShowTimer] = useState(true);
  const [time, setTime] = useState(180);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState(time % 60);
  const [ updatedEmail, setUpdatedEmail ] = useState("");

  const {
    register: changeEmailRegister,
    handleSubmit: handChangeEmailSubmit,
    formState: { errors: errorsChangeEmailErrors },
    reset: resetPasswordForm
  } = useForm<ChangeEmailPayload>();
  const changeEmailSubmit: SubmitHandler<ChangeEmailPayload> = () => onSubmit();

  const {
    register: verifyOtpRegister,
    handleSubmit: handVerifyOtpSubmit,
    formState: { errors: errorsVerifyOtpErrors },
    reset: verifyOtpForm,
    control: verifyOtpControl,
  } = useForm<ChangeEmailOtpVerificationPayload>();
  const verifyOtpFormSubmit: SubmitHandler<ChangeEmailOtpVerificationPayload> = () => verifyOTPEvent();

  const closePopup = () => {
    handleClose(!hasModalVisible);
    setShowConfirmationStep(false);
    setShowVerifyOTP(false);
    setUpdatedEmail("");
    setOTP("")
  };

  const verifyOTPEvent = () => {
    const validateOtpPayload = { verificationCode: OTP, verificationType: emailPayloadKey };
    dispatch(authActions.updateUserOtpVerification(validateOtpPayload));
    setUpdatedEmail("");
  };

  // move to next step when there is a successfull update
  useEffect(() => {
    
    if (hasModalVisible && userProfileUpdateStatus == "success" && !showVerifyOTP && !showConfirmationStep) {
      setShowConfirmationStep(false);
      setShowVerifyOTP(true);
      dispatch(authActions.resetUserUpdateStatus());
    } else if (hasModalVisible && userVerifyOtpStatus == "success" && showVerifyOTP && !showConfirmationStep) {
      setShowConfirmationStep(true);
      setShowVerifyOTP(false);
      dispatch(authActions.resetUserVerifyOtpStatus());
    }

  }, [hasModalVisible, userProfileUpdateStatus, userVerifyOtpStatus , showVerifyOTP, showConfirmationStep])


  function padLeadingZeros(num: any, size: any) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
      setMinutes(Math.floor(time / 60));
      setSeconds(time % 60);
    }, 1000);
    if (time < 0) {
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [time, minutes, seconds]);

  const onSubmit = () => {
    const updatePayload = emailPayloadKey == 'secondaryEmail' ? { secondaryEmail: updatedEmail } : { email: updatedEmail };
    dispatch(authActions.updateProfileData(updatePayload));
  }

  const resend = () => {
    /**
     * fix: IN-300
     */
    const updatePayload = emailPayloadKey == 'secondaryEmail' ? { secondaryEmail: updatedEmail } : { email: updatedEmail };
    dispatch(authActions.updateProfileData(updatePayload));
    setTime(180);
    setMinutes(Math.floor(180 / 60));
    setSeconds(180 % 60);
    setShowTimer(true);
  }

  return (
    <>
      <Modal
        className="updateInfoModalWrapper"
        show={hasModalVisible}
        centered={true}
      >
        <Modal.Body>
          <a href={void 0} className="closeButton" onClick={closePopup}>
            <em className="fa-solid fa-close"></em>
          </a>
          <div className="formWrap">
            {!showVerifyOTP && !showConfirmationStep && (
              <form className="formBlock" onSubmit={handChangeEmailSubmit(changeEmailSubmit)}>
                <h4 className="mb-3">Email address</h4>
                <p>
                  Use an address you'll always have access to. We'll call or
                  text you to confirm your email.
                </p>
                <div className="mb-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email Address"
                      {...changeEmailRegister("email", { 
                          required: true, 
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          } 
                      })}
                      value={updatedEmail}
                      onChange={(e) => setUpdatedEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email Address</label>
                  </div>
                  {
                    errorsChangeEmailErrors.email?.type == "required" && (
                          <span className="isInvalidMessage text-danger">
                              Please enter email to be changed.
                          </span>
                      )
                  }
                  {
                    errorsChangeEmailErrors.email?.type == "pattern" && (
                          <span className="isInvalidMessage text-danger">
                              Please enter valid email to be changed.
                          </span>
                      )
                  }
                </div>
                <button
                  type="submit"
                  className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                >
                  Submit
                </button>
              </form>
            )}
            {showVerifyOTP && !showConfirmationStep && (
              <form className="formOtpVerification" onSubmit={handVerifyOtpSubmit(verifyOtpFormSubmit)}>
                <div className="formBlock pb-0">
                  <h4 className="mb-3">Verify Email address</h4>
                  <p>Enter the code we've sent code on your provided email</p>
                  <div className="mb-4">
                    <Controller 
                      control={verifyOtpControl}
                      {...verifyOtpRegister("otp", { required: true, minLength: 4 })} 
                      render={({ field: { onChange } }) => <>
                        <OtpInput
                          value={OTP}
                          shouldAutoFocus={true}
                          numInputs={4}
                          containerStyle={"optInputWrap justify-content-between"}
                          inputStyle={"optInput"}
                          focusStyle={"optInputFocus"}
                          isInputNum={true}
                          placeholder=""
                          onChange={(value: string) => {
                            onChange(value);
                            setOTP(value);
                          }}
                        />
                      </>} 
                    />
                  </div>
                  {
                    errorsVerifyOtpErrors.otp?.type == "required" && (
                      <span className="isInvalidMessage text-danger">
                        Please enter validation otp.
                      </span>
                    )
                  }
                  {
                    errorsVerifyOtpErrors.otp?.type == "minLength" && (
                      <span className="isInvalidMessage text-danger">
                        Enter correct otp value.
                      </span>
                    )
                  }
                  <div className="d-flex justify-content-between align-items-center mb-4 pb-3">
                    {showTimer && <p className="mb-0 fw600">Time remaining {padLeadingZeros(minutes, 2)} : {padLeadingZeros(seconds, 2)} </p>}
                    {!showTimer && <p className="mb-0 fw600">Time remaining 00:00 </p>}
                      <button
                        type="button"
                        className="resendLink fw600 color-gray"
                        onClick={resend}
                        disabled={(minutes+seconds > 0)}
                      >
                        Resend
                      </button>
                  </div>
                  <button
                    type="submit"
                    className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                  >
                    Verify
                  </button>
                </div>
              </form>
            )}
            {!showVerifyOTP && showConfirmationStep && (
              <div className="successConfirmationModalWrap text-center">
                <span className="markIcon success">
                  <em className="fa-solid fa-circle-check"></em>
                </span>
                <h2 className="mb-2">All Done!</h2>
                <p className="color-gray fw500">Email Updated successfully.</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateInfoModal;
