import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";

import { useAppDispatch } from "../../../../app/hooks";
import {
  CLUB_ACTIVE_STATUS,
  CLUB_INACTIVE_STATUS,
  COURSE_SYNC_STATUS_FAIL_NAME,
  COURSE_SYNC_STATUS_INIT_NAME,
  COURSE_SYNC_STATUS_SUCCESS_NAME,
  CLUB_LOCATION_MAP_STEP,
  CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP,
  CLUB_PICTURES_STEP,
  CLUB_DESCRIPTION_STEP,
  COURSE_BASICS_STEP,
  CLUB_FACILITIES_STEP,
  COURSE_TIME_AND_DAYS_STEP,
  COURSE_DISCOUNT_STEP
} from "../../../../utils/constants";
import DeactivateModal from "../../../../shared/deactivate-modal/deactivate-modal";
import DeleteCourseModal from "../../../../shared/delete-course-modal/delete-course-modal";
import { MerchantFiles } from "../../../../models/merchantFiles";
import { COURSE_DELETION_REASON } from "../../../../utils/constants";
import { coursesActions } from "../../../../reducers/coursesSlice";
import InfoModal from "../../../../shared/info-modal/info-model";

interface CourseListItemProps {
  clubId: string;
  courseId: string;
  courseName: string;
  address: string;
  distance: number;
  holes: number;
  par: number;
  slope: number;
  photos: Array<MerchantFiles>;
  status: number;
  syncStatus?: string;
  courseDeleteStatus?: string;
}

const sampleCourseImage = "http://t3.gstatic.com/licensed-image?q=tbn:ANd9GcRlSR21-mqPFGRLpTFSPOs7x35AxefR0rce8FBbefz7clOWun1yFuL7NwXvXGwOBq3-BSzPq-ap7e1HYVrgK5E";
const courseSyncModalMessage = "Do you wish to sync your course tee sheets right now? Please note we sync your active course(s) every day at 12:00 A.M.";

const CourseListItem: FC<CourseListItemProps> = ({ clubId, courseId, courseName, address, distance, holes, par, slope, status, photos = [], syncStatus, courseDeleteStatus }) => {

  const [showDeactivateCourseModal, setshowDeactivateCourseModal] = useState(false);
  const [showDeleteCourseModal, setShowDeleteCourseModal] = useState(false);
  const [showCourseSyncNowModal, setShowCourseSyncNowModal] = useState(false);
  
  const handleCloseModal = (e: any) => {
    setshowDeactivateCourseModal(false);
    setShowDeleteCourseModal(false);
    dispatch(coursesActions.resetCourseDeleteStatus());
  };

  const deactivateCourse = () => {
    const activateDeactivateCoursePayload = { "_id": courseId, "status": CLUB_INACTIVE_STATUS };
    dispatch(coursesActions.updateCourseData(activateDeactivateCoursePayload));
    setshowDeactivateCourseModal(false);
    dispatch(coursesActions.resetUpdateCourseDataStatus())
  }

  const onCourseSyncClick = () => {
    // hide popup
    setShowCourseSyncNowModal(false);
    // call sync API
    const syncCourseData = { courseObjectId: courseId };
    dispatch(coursesActions.courseSyncOnActive(syncCourseData));
  }

  const activateCourse = () => {
    const activateDeactivateCoursePayload = { "_id": courseId, "status": CLUB_ACTIVE_STATUS };
    dispatch(coursesActions.updateCourseData(activateDeactivateCoursePayload));
    dispatch(coursesActions.resetUpdateCourseDataStatus())
  }

  const deleteClub = (courseId: string, reason: string, description: string) => {
    const deleteClubPayload = { "id": courseId, reason, description };
    dispatch(coursesActions.deleteCourseData(deleteClubPayload));
    dispatch(coursesActions.resetCourseDeleteStatus());
    setShowDeleteCourseModal(false);
    return true;
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onEditClick = (clubId: string, courseId: string) => {
    navigate(`/course?courseId=${courseId}&clubId=${clubId}`);
  }

  const onCourseDetailEdit = (clubId: string, courseId: string, activeStep: number) => {
    navigate(`/register?courseId=${courseId}&clubId=${clubId}&step=${activeStep}`);
  }

  const imgSrc = (photos.length > 0) ? photos[0].path : sampleCourseImage;

  return (
    <div key={clubId}>
      <div className="row">
        <div className="col-12">
          <div className="listBlock mb-2">
            <div className="row gutters-10 align-items-center">
              <div className="col-auto cursor-hand" onClick={() => onEditClick(clubId, courseId)}>
                <div className="imgBlock">
                  <img
                    src={imgSrc}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-5 cursor-hand" onClick={() => onEditClick(clubId, courseId)}>
                    <h2>{courseName}</h2>
                    <p className="mb-0">
                      {(address || "")}
                    </p>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="row gutters-5">
                      <div className="col">
                        <label className="mb-3 d-block">Holes</label>
                        <h6 className="mb-0">{(holes || "-")}</h6>
                      </div>
                      <div className="col">
                        <label className="mb-3 d-block">Par</label>
                        <h6 className="mb-0">{(par || "-")}</h6>
                      </div>
                      <div className="col">
                        <label className="mb-3 d-block">Slope</label>
                        <h6 className="mb-0">{(slope || "-")}</h6>
                      </div>
                      <div className="col">
                        <label className="mb-3 d-block">Length</label>
                        <h6 className="mb-0">{distance ? `${distance} yds` : "-"}</h6>
                      </div>
                      <div className="col">
                        <label className="mb-3 d-block">Bookings</label>
                        {(status === CLUB_ACTIVE_STATUS) ? <>
                          <span className="badge active">Active</span>
                        </> : <>
                          <span className="badge secondary">In-Active</span>
                        </>
                        }
                      </div>
                      <div className="col">
                        <label className="mb-3 d-block">Teesheet Sync</label>
                        {(syncStatus === COURSE_SYNC_STATUS_SUCCESS_NAME) ? <>
                          <label className="mb-0 badge success">Success</label>
                        </> : (syncStatus === COURSE_SYNC_STATUS_FAIL_NAME) ? <>
                          <label className="mb-0 badge failed">Failed</label>
                        </> : (syncStatus === COURSE_SYNC_STATUS_INIT_NAME) ? <>
                          <label className="mb-0 badge pending">In-Progress</label>
                        </> : <div style={{ textAlign: 'center' }}>N.A.</div>
                        }
                      </div>
                      {/* <div className="col">
                      <label className="mb-3 d-block">Action</label>
                        <button className="button button-primary button-rounded button-min-120 fw600" onClick={()=>navigate(`/discounts?clubId=${clubId}&courseId=${courseId}`)}>Add discounts</button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="listActionDropdownBlock">
                  <Dropdown align="end">
                    <Dropdown.Toggle className="toggleButton">
                      <em className="icon-vertical-elipse"></em>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollableDropdownBlock">
                    <button
                        className="dropdown-item fw700"
                        onClick={()=>navigate(`/discounts?clubId=${clubId}&courseId=${courseId}`)}
                      >
                        Add Other Discounts
                      </button>
                      <button
                        className="dropdown-item fw700"
                        onClick={() => onEditClick(clubId, courseId)}
                      >
                        View
                      </button>
                      {
                        (status === CLUB_ACTIVE_STATUS) ? <button
                          type="button"
                          className="dropdown-item fw700"
                          onClick={() => setshowDeactivateCourseModal(true)}
                        >
                          Deactivate
                        </button> : <button
                          type="button"
                          className="dropdown-item fw700"
                          onClick={activateCourse}
                        >
                          Publish
                        </button>
                      }
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        disabled={(
                          (status === CLUB_ACTIVE_STATUS  &&
                          [COURSE_SYNC_STATUS_SUCCESS_NAME, COURSE_SYNC_STATUS_INIT_NAME].includes(syncStatus || ''))
                          ||(status === CLUB_INACTIVE_STATUS)
                        )}
                        onClick={() => setShowCourseSyncNowModal(true)}
                      >
                        Sync Course Tee-sheets
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, CLUB_LOCATION_MAP_STEP)}
                      >
                        Edit course location
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, CLUB_PICTURES_STEP)}
                      >
                        Update course images
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, CLUB_DESCRIPTION_STEP)}
                      >
                        Edit course description
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, COURSE_BASICS_STEP)}
                      >
                        Edit course basics
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, CLUB_FACILITIES_STEP)}
                      >
                        Edit course amenities
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, COURSE_TIME_AND_DAYS_STEP)}
                      >
                        Edit course booking timings
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, COURSE_DISCOUNT_STEP)}
                      >
                        Add/Edit course discounts
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700"
                        onClick={() => onCourseDetailEdit(clubId, courseId, CLUB_LINK_TEE_TIME_BOOKING_SYSTEM_STEP)}
                      >
                        Edit/View course onboarding system
                      </button>
                      <button
                        type="button"
                        className="dropdown-item fw700 color-danger"
                        onClick={() => setShowDeleteCourseModal(true)}
                      >
                        Delete
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeactivateModal
        showModal={showDeactivateCourseModal}
        handleClose={handleCloseModal}
        onDeactivateClick={deactivateCourse}
      ></DeactivateModal>
      <DeleteCourseModal
        courseId={courseId}
        showModal={showDeleteCourseModal}
        onCloseClick={handleCloseModal}
        onDelete={deleteClub}
        deletionReasons={[COURSE_DELETION_REASON]}
      ></DeleteCourseModal>
      <InfoModal
        showModal={showCourseSyncNowModal}
        message={courseSyncModalMessage}
        onModalButtonClick={onCourseSyncClick}
        onCloseClick={() => setShowCourseSyncNowModal(false)}
        buttonText="OK"
        modalImagePath="../assets/images/spedometer-low.png"
      />
    </div>
  )
}

export default CourseListItem;