import React, { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import Cropper, { ReactCropperElement } from "react-cropper";
import { toast } from "react-toastify";
import "cropperjs/dist/cropper.css";

import './profile-image-cropper-modal.scss';
import { merchantFilesActions, fetchSelectedMerchantFiles } from "../../../../reducers/merchantFilesSlice";
import { authActions } from '../../../../reducers/authSlice';
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { MerchantFiles } from '../../../../models/merchantFiles';

interface ProfileImageCropperUploadModalProps {
  isModalVisible: boolean;
  handleClose: () => void;
  imageRef?: string;
}

const ProfileImageCropperUploadModal: FC<ProfileImageCropperUploadModalProps> = ({
    isModalVisible, 
    handleClose,
}) => {

    const dispatch = useAppDispatch();
    const merchantImages: Array<MerchantFiles> = useAppSelector(fetchSelectedMerchantFiles);

    const cropperRef = useRef<ReactCropperElement>(null);
    const [ imageSrc, setImageSrc ] = useState("");

    const onHandleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if ( e.target.files && e.target.files.length == 1) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as any);
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            toast.error("Select image to upload");
        }
    }

    const onclose = () => {
        setImageSrc("");
        dispatch(merchantFilesActions.resetmerchantFiles());    // reset merchant files reducer
        handleClose();
    }

    const onSubmit = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blobData) => {
                const formData = new FormData();
                formData.append("files", blobData as Blob);
                dispatch(merchantFilesActions.merchantFilesUpload(formData));
            });
        } else {
            throw new Error("Cropper object not found");
        }
        handleClose();  // close the pop up to show the loader
    }

    // when merchant image is uploaded then update it as merchant image
    useEffect(() => {
        
        const [uploadedImage] = merchantImages;

        // if image is present then update image data
        if (uploadedImage) {
            const fileSrc = uploadedImage.path;
            const uploadImagePayload: any = { profileImage: fileSrc };
            dispatch(authActions.updateProfileData(uploadImagePayload));
            onclose();
        }

    },[merchantImages]);

    return (
        <>
        <Modal
            className="updateInfoModalWrapper"
            show={isModalVisible}
            centered={true}
        >
            <Modal.Body>
                <a href={void 0} className="closeButton" onClick={onclose}>
                    <em className="fa-solid fa-close"></em>
                </a>
                <div className="formWrap">
                    <div className="formBlock">
                        <h4 className="mb-3">Update Profile image</h4>
                        <p>
                            Select image and press 'Submit' to update profile image.
                        </p>
                        {
                            !imageSrc ? <>
                                <label className="uploadImageWrap mb-0 d-block" >
                                    <input type="file" accept="image/*" onChange={onHandleImageSelect}/>
                                    <div className="inner">
                                        <img
                                        src="../assets/images/icons/gallery.svg"
                                        alt="gallery"
                                        />
                                        <h5>Click here to upload your photo</h5>
                                        {/* <label className="_link mb-0 mt-5">
                                            Upload from your device
                                        </label> */}
                                    </div>
                                </label>
                            </> : <>
                            <div className="mb-1">
                                <Cropper
                                    ref={cropperRef}
                                    style={{ height: 400, width: "100%" }}
                                    aspectRatio={1}
                                    preview=".img-preview"
                                    src={imageSrc}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    guides={true}
                                />
                            </div>
                            <button
                                type="button"
                                className="button button-primary button-rounded button-min-150 button-large px-2 fontsize-16 fw700 mb-2"
                                onClick={onSubmit}
                            >
                                Submit
                            </button>                                
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
};

export default ProfileImageCropperUploadModal;
