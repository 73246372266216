import { AVAILABLE_MARKETING_TEMPLATES_IMAGES, AVAILABLE_TEMPLATES } from "../../utils/constants";
import "./marketingmaterial.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { marketingMaterialActions, fetchProcessMarketingMaterialStatus, handleDownloadMarketingRequest, fetchActiveMarketingMaterial } from "../../reducers/marketingMaterialSlice";
import { toast } from "react-toastify";
import ContentLoader from "react-content-loader";
import { useForm } from "react-hook-form";

const MarketingMaterial: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const processedMarketingRequest: any = useAppSelector(
        fetchProcessMarketingMaterialStatus
    );
    const activeMarketingRequest: any = useAppSelector(fetchActiveMarketingMaterial);
    const downloadMarketingRequest: any = useAppSelector(handleDownloadMarketingRequest);

    const imageRefs = useRef<HTMLImageElement[]>([]);

    const confirmPreview = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const newErrors = { title: '', subHeading: '' };

        if (title?.trim()?.length === 0) {
            newErrors.title = 'Title cannot be empty';
        } else if (title?.length > 30) {
            newErrors.title = 'Title must be 30 characters or less';
        }

        if (subHeading?.trim()?.length === 0) {
            newErrors.subHeading = 'Sub Heading cannot be empty';
        } else if (subHeading?.length > 75) {
            newErrors.subHeading = 'Sub Heading must be 75 characters or less';
        }

        setEditFormErrors(newErrors);
        if (!newErrors?.title && !newErrors?.subHeading) {
            const payload = {
                title: title.trim(),
                description: subHeading.trim(),
                templateId: [selectedTemplate]
            };
            setConfirmToProcess(true)
            setEditFormErrors({ title: "", subHeading: "" })
            setSelectedPreview(false);
            const templatesToCall = AVAILABLE_TEMPLATES.filter(template =>
                template !== selectedTemplate
            );
            setTemplatesCalledForResponse([selectedTemplate])
            setTemplatesToCall(templatesToCall); //call to next templates
            setImagePreviewLoading(true);
            dispatch(marketingMaterialActions.processMarketingMaterial(payload));
        }
    };

    const AvailableMarketingTemplates = AVAILABLE_MARKETING_TEMPLATES_IMAGES;
    const [title, setTitle] = useState("");
    const [templatesToCall, setTemplatesToCall] = useState(AVAILABLE_TEMPLATES);
    const [editFormErrors, setEditFormErrors] = useState({ title: '', subHeading: '' });
    const [availableTemplates, setAvailableTemplates] = useState<any>(Array(AVAILABLE_MARKETING_TEMPLATES_IMAGES?.length).fill(0));
    const [timestamp, setTimeStamp] = useState<any>(null);
    const [subHeading, setSubHeading] = useState("");
    const [activeMarketingRequestURL, setActiveMarketingRequestURL] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [confirmToProcess, setConfirmToProcess] = useState(false);
    const [flyerUpdatedResponse, setFlyerUpdatedResponse] = useState<Boolean>(false);
    const [processedTemplate, setProcessedTemplate] = useState("");
    const previewImageRef = useRef<HTMLImageElement | null>(null);
    const [loadingStates, setLoadingStates] = useState<any>({});
    const [templatesInfo, setTemplatesInfo] = useState<any>("");
    const [imagePreviewLoading, setImagePreviewLoading] = useState<any>(true);
    const [selectedImage, setSelectedImage] = useState<any>();
    const [templateLoaded,setTemplateLaoded]=useState<any>("")
    const [templatesCalledForResponse, setTemplatesCalledForResponse] = useState<any>("")
    const [selectedPreview, setSelectedPreview] = useState<Boolean>(false);

    const selectPreview = (preview: any) => {
        const templateId = preview?.templateId ? preview?.templateId : preview?.split(".")?.slice(0, -1)?.join(".");
        const payload = { templateId };
        setImagePreviewLoading(true);
        const index = AVAILABLE_TEMPLATES?.findIndex(template => template === templateId);
        setLoadingStates((prev: any) => ({
            ...prev,
            [index]: true,
        }));
        setEditFormErrors({ title: "", subHeading: "" })
        setSelectedPreview(true)
        clearFlyerChanges();
        dispatch(marketingMaterialActions.fetchActiveMarketingMaterialList(payload));
        setSelectedTemplate(templateId);
    }

    const clearFlyerChanges = () => {
        setTitle("");
        setSubHeading("");
    }

    const editInputChangeHandler = (inputType: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;

        if (inputType === 'title') {
            setTitle(value);
            if (value.trim().length === 0) {
                setEditFormErrors((prev) => ({ ...prev, title: 'Title cannot be empty' }));
            } else if (value.length > 30) {
                setEditFormErrors((prev) => ({ ...prev, title: 'Title must be 30 characters or less' }));
            } else {
                setEditFormErrors((prev) => ({ ...prev, title: '' }));
            }
        } else if (inputType === 'subHeading') {
            setSubHeading(value);
            if (value.trim().length === 0) {
                setEditFormErrors((prev) => ({ ...prev, subHeading: 'Sub Heading cannot be empty' }));
            } else if (value.length > 75) {
                setEditFormErrors((prev) => ({ ...prev, subHeading: 'Sub Heading must be 75 characters or less' }));
            } else {
                setEditFormErrors((prev) => ({ ...prev, subHeading: '' }));
            }
        }
    };

    useEffect(() => {
        const initialLoadingStates: any = {};
        if (templatesInfo && templatesInfo?.length > 0 && Array.isArray(templatesInfo) && confirmToProcess) {
            templatesInfo.forEach((template: any, index: any) => {
                if (selectedTemplate === template?.templateId) {
                    initialLoadingStates[index] = true;
                }
            });
            setLoadingStates(initialLoadingStates);
        }

        if (confirmToProcess === false) {
            const initialLoadingStates: any = {};
            if (templatesInfo && templatesInfo?.length > 0 && Array.isArray(templatesInfo)) {
                templatesInfo.forEach((template: any, index: any) => {
                    setTimeStamp(Date.now())
                    if (selectedTemplate === template?.templateId) {
                        initialLoadingStates[index] = false;         
                    } else{
                        initialLoadingStates[index] = true;
                    }

                    // if(templateLoaded === template?.templateId && templateLoaded?.length > 0 ){
                    //     initialLoadingStates[index] = true;
                    // }

                });
                setLoadingStates(initialLoadingStates);
            }
        }
    }, [confirmToProcess,templatesInfo,templateLoaded]);


    useEffect(() => {
        const initialLoadingStates: any = {};
        if (availableTemplates && availableTemplates?.length > 0 && Array.isArray(availableTemplates)) {
            availableTemplates.forEach((template: any, index: any) => {
                initialLoadingStates[index] = true
            });
            setLoadingStates(initialLoadingStates);
        }
    }, [availableTemplates]);



    const handleImageLoad = (index: number) => {
        if(processedMarketingRequest!=="pending"){
            setLoadingStates((prev: any) => ({
                ...prev,
                [index]: false,
            }));
        }
    };

    const handlePreviewLoad = () => {
        setImagePreviewLoading(false);
    };

    useEffect(() => {
        dispatch(marketingMaterialActions.resetProcessMarketingMaterialStatus());
        dispatch(marketingMaterialActions.resetctiveMarketingMaterialList());
        dispatch(marketingMaterialActions.resetDownloadedMarketingMaterial());
        setSelectedImage(AvailableMarketingTemplates[0]);
        clearFlyerChanges();
        setSelectedTemplate(templatesToCall[0]);
        dispatch(marketingMaterialActions.fetchActiveMarketingMaterialList({ templateId: templatesToCall[0] }));
    }, [])

    useEffect(()=>{
        const loadingStates:any={}
        if(Array.isArray(templatesInfo) && templatesInfo?.length > 0 && selectedTemplate && selectedPreview){
            templatesInfo?.forEach((template:any,index:any)=>{
                if(template?.templateId===selectedTemplate){
                    loadingStates[index]=true;
                }else{
                    loadingStates[index]=false;
                }
            })

            if(loadingStates){
                setLoadingStates(loadingStates);
            }
        }
    },[templatesInfo,selectedPreview,selectedTemplate])

    useEffect(() => {
        if (processedMarketingRequest && processedMarketingRequest?.length > 0 && processedMarketingRequest !== "pending") {
            setFlyerUpdatedResponse(processedMarketingRequest);
            setConfirmToProcess(false);
            let payload = {
                templateId: templatesToCall
            }

            const processedTemplatesMap = Array.isArray(processedMarketingRequest)
                ? new Map(processedMarketingRequest?.map((item: any) => [item?.templateId, item]))
                : new Map();
            const initialLoadingStates: any = {};

            if(processedMarketingRequest?.length===1){
                setTitle(processedMarketingRequest[0]?.title);
                setSubHeading(processedMarketingRequest[0]?.description)
                setTemplateLaoded(selectedTemplate)
            }else{
                setTemplateLaoded("");
            }

            if(Array.isArray(templatesInfo) && templatesInfo?.length>0){
                let loadingStatesToSet:any={}
                const updatedTemplates = templatesInfo.map((template: any, index: any) => {
                    loadingStatesToSet[index] = true;
                    if (processedTemplatesMap.has(template?.templateId)) {
                        return processedTemplatesMap.get(template?.templateId)
                    } 
                    return template;
                });
                setLoadingStates(loadingStatesToSet);
                setTemplatesInfo(updatedTemplates);
            }
            setTimeStamp(Date.now());
            dispatch(marketingMaterialActions.resetProcessMarketingMaterialStatus());
            const loadingStates:any={}
            if (templatesToCall?.length > 0) {
                templatesToCall.map((selectedTemplate,index)=>{
                    const selectedIndex=AVAILABLE_TEMPLATES.findIndex((template: any) => template === selectedTemplate);
                    loadingStates[selectedIndex]=true;
                })
                setTemplatesCalledForResponse(templatesToCall);
                dispatch(marketingMaterialActions.processMarketingMaterial({ templateId: templatesToCall }));
            }
            setLoadingStates(loadingStates)
            setTemplatesToCall([]);
        }
        if (processedMarketingRequest === "failed") {
            dispatch(marketingMaterialActions.resetProcessMarketingMaterialStatus());
            toast.error("Unable to process this Marketing Request");
        }
    }, [processedMarketingRequest]);


    useEffect(() => {
        const download = async () => {
            if (downloadMarketingRequest && downloadMarketingRequest !== "pending") {
                try {
                    // Strip the Base64 prefix
                    const base64String = downloadMarketingRequest.replace(/^data:image\/png;base64,/, '');

                    // Decode Base64 to binary data
                    const binaryString = window.atob(base64String);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);

                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    // Create a Blob from the binary data
                    const blob = new Blob([bytes], { type: 'image/png' });
                    const url = URL.createObjectURL(blob);

                    // Create a link element and trigger the download
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `${selectedTemplate}.png`; // Set a default filename
                    document.body.appendChild(link);
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);
                    dispatch(marketingMaterialActions.resetDownloadedMarketingMaterial());
                } catch (error) {
                    console.error('Error handling the download:', error);
                }
            } else {
                dispatch(marketingMaterialActions.resetDownloadedMarketingMaterial());
            }
        };

        download();
    }, [downloadMarketingRequest, selectedTemplate]);

    useEffect(() => {
        if (activeMarketingRequest && activeMarketingRequest?.length > 0) {
            setFlyerUpdatedResponse(activeMarketingRequest);
            setAvailableTemplates(activeMarketingRequest);
            setTemplatesInfo(activeMarketingRequest);
            setTimeStamp(Date.now());
        }
        if (activeMarketingRequest?.length === 0) {
            dispatch(marketingMaterialActions.resetctiveMarketingMaterialList());
        }
    }, [activeMarketingRequest]);

    useEffect(() => {
        const selectedIndex = Array.isArray(availableTemplates) ? availableTemplates.findIndex((item: any) => item?.templateId === selectedTemplate) : -1;
        if (selectedTemplate && selectedIndex !== -1) {
            setProcessedTemplate(availableTemplates[selectedIndex]?.templateId);
            setSelectedTemplate(availableTemplates[selectedIndex]?.templateId);
            setActiveMarketingRequestURL(availableTemplates[selectedIndex]?.thumbNailURL);
            setTitle(availableTemplates[selectedIndex]?.title);
            setImagePreviewLoading(true);
            setSelectedImage(availableTemplates[selectedIndex]?.thumbNailURL);
            setSubHeading(availableTemplates[selectedIndex]?.description);
        }

        if (selectedIndex !== -1 && imageRefs.current[selectedIndex]) {
            (imageRefs.current[selectedIndex] as HTMLImageElement).scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [activeMarketingRequest, selectedTemplate, availableTemplates]);


    const downloadPreviewFile = () => {
        dispatch(marketingMaterialActions.resetDownloadedMarketingMaterial());
        const payload = { "templateId": selectedTemplate }
        dispatch(marketingMaterialActions.processDownloadedMarketingMaterial(payload));

    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <div className="sidebar mt-4">
                        <h4>Flyers Samples</h4>
                        {Array.isArray(availableTemplates) && availableTemplates?.length > 0 &&
                            availableTemplates?.map((image: any, index: React.Key | any) => (
                                <>
                                    <div className="image-container" key={index}>
                                        {loadingStates[index] && (
                                            <ContentLoader className="mt-4 content-loader"
                                                speed={2}
                                                width={199}
                                                height={284}
                                                viewBox={`0 0 199 284`}
                                                backgroundColor="#e0e0e0"
                                                foregroundColor="#c0c0c0"
                                            >
                                                <rect x="0" y="0" rx="5" ry="5" width="199"
                                                    height="284" />
                                            </ContentLoader>
                                        )}
                                        <img
                                            key={index}
                                            src={`${image?.thumbNailURL}?timestamp=${timestamp}`}
                                            ref={(el) => el && (imageRefs.current[index] = el)}
                                            onClick={() => selectPreview(image)}
                                            alt={`template-${index}`}
                                            onLoad={() => handleImageLoad(index)}
                                            className={selectedTemplate === image?.templateId && loadingStates[index]===false  ? 'selected-image mt-3' : 'mt-3'}
                                            style={loadingStates[index] ? { display: 'none' } : {}}
                                        />
                                    </div>
                                </>
                            ))}
                    </div>
                </div>

                <div className="col-md-9">
                    {selectedImage ? (
                        <div className="row">
                            <div className="col-md-7">
                                <div className="preview">
                                    <div className="preview-block">
                                        {imagePreviewLoading && (
                                            <div className="preview-container">
                                                <ContentLoader
                                                    className="mt-2 preview-loader"
                                                    speed={2}
                                                    width={380}
                                                    height={543}
                                                    viewBox="0 0 380 543"
                                                    backgroundColor="#e0e0e0"
                                                    foregroundColor="#c0c0c0"
                                                >
                                                    <rect
                                                        x="0"
                                                        y="0"
                                                        rx="5"
                                                        ry="5"
                                                        width="380"
                                                        height="543"
                                                    />
                                                </ContentLoader>
                                            </div>
                                        )}
                                        <img
                                            src={`${activeMarketingRequestURL}?timestamp=${timestamp}`}
                                            alt="img-preview"
                                            onLoad={() => handlePreviewLoad()}
                                            style={imagePreviewLoading ? { display: 'none' } : {}}
                                        />

                                        <div className="mt-2">
                                            <button
                                                className="button button-primary button-rounded button-min-130"
                                                disabled={!activeMarketingRequestURL || processedTemplate !== selectedTemplate || imagePreviewLoading}
                                                onClick={() => downloadPreviewFile()}
                                            >
                                                Download
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="preview-content">
                                    <h4>Edit Flyer Content</h4>
                                    <div className="mb-3">
                                        <label htmlFor="title" className="form-label">
                                            Main Title
                                        </label>
                                        <textarea
                                            id="title"
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => editInputChangeHandler('title', e)}
                                        />
                                        {editFormErrors?.title && <div className="text-danger">{editFormErrors?.title}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="subHeading" className="form-label">
                                            Sub Heading Content
                                        </label>
                                        <textarea
                                            id="subHeading"
                                            className="form-control"
                                            value={subHeading}
                                            onChange={(e) => editInputChangeHandler('subHeading', e)}
                                        />
                                        {editFormErrors?.subHeading && <div className="text-danger">{editFormErrors?.subHeading}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <button className="button button-primary button-rounded button-block" onClick={(e) => confirmPreview(e)} disabled={!editFormErrors?.subHeading && !editFormErrors?.title ? false : true}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className="mt-2 ml-5 preview-select-image-text">Select an image to preview</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MarketingMaterial;
