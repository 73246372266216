import React, { FC,  } from 'react';
import Modal from 'react-bootstrap/Modal';

import './iframe-pdf-viewer-modal.scss'

interface IFramePDFViewerModalProps {
  isModalVisible: boolean;
  handleClose : (a: any) => void;
  onSubmit ?: (a: any) => void;
  popUpMessage ?: string;
  popUpHeader ?: string;
  pdfSrcURL: string;
  heightInPixels ?: number;
  widhtInPixels ?: number;
}

const IFramePDFViewerModal: FC<IFramePDFViewerModalProps> = ({
    isModalVisible, 
    handleClose,
    onSubmit,
    popUpMessage,
    popUpHeader,
    pdfSrcURL,
    heightInPixels = 500,
    widhtInPixels = 500
}) => {

    return (
        <>
        <Modal
            className="updateInfoModalWrapper"
            show={isModalVisible}
            dialogClassName="modal-90w"
            centered
        >
            <Modal.Body>
                <a href={void 0} className="closeButton" onClick={handleClose}>
                    <em className="fa-solid fa-close"></em>
                </a>
                <div className="formWrap">
                    <div className="formBlock">
                        { popUpHeader && <h4 className="mb-3">{popUpHeader}</h4> }
                        { popUpMessage && <p>{popUpMessage}</p> }
                        <iframe 
                            src={pdfSrcURL+'#toolbar=0&navpanes=0'} 
                            height={heightInPixels} 
                            width={widhtInPixels}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="button button-primary button-rounded button-min-100 px-2 fontsize-16 fw700 mb-2"
                    onClick={onSubmit}
                >
                    Submit
                </button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default IFramePDFViewerModal;
