import React, { FC, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./courses.scss";
import CommonHeader from "../../../../shared/common-header/common-header";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchCourses, fetchCoursesStatus, fetchCourseDeleteStatus, coursesActions, fetchCourseSyncOnActiveStatus, fetchCoursePublishStatus, fetchUpdateCourseDataStatus, fetchCourseData, getCourseUpdatedData } from "../../../../reducers/coursesSlice";
import { fetchClubs, clubsActions, } from "../../../../reducers/clubsSlice";
import { accountStorageActions } from "../../../../reducers/accountStorageSlice";
import { CoursesPayload } from "../../../../models/courses";
import { ClubPayload } from "../../../../models/clubs";
import CourseListItem from "../course-list-item/course-list-time";
import { CLUB_ACTIVE_STATUS, COURSE_SYNC_STATUS_INIT_NAME, COURSE_SYNC_STATUS_SUCCESS_NAME, SAGA_STATUS_FAILED, SAGA_STATUS_SUCCESS } from "../../../../utils/constants";

interface CoursesProps { }

const Courses: FC<CoursesProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // extract clubs and courses from state
  const clubs: Array<ClubPayload> = useAppSelector(fetchClubs);
  const course: Array<CoursesPayload> = useAppSelector(fetchCourses);
  const coursesListedStatus: string = useAppSelector(fetchCoursesStatus);
  const courseDeleteStatus: string = useAppSelector(fetchCourseDeleteStatus);
  const courseSyncStatus: string = useAppSelector(fetchCourseSyncOnActiveStatus);
  const courseUpdateStatus: string = useAppSelector(fetchUpdateCourseDataStatus);
  const courseUpdatedData: any = useAppSelector(getCourseUpdatedData)

  const [showPopup, SetShowPopup] = useState<boolean>(false);
  const handleCloseWelcomeModal = () => SetShowPopup(false);

  // prepare course data to display
  const courseData: Array<any> = [];
  course.forEach(({
    _id,
    clubObjectId,
    courseId,
    distance,
    holes,
    par,
    position,
    slope,
    status,
    syncStatus
  }) => {

    const clubData = clubs.find(({ _id }) => (_id === clubObjectId));

    // if club is active only then display the course info
    if (clubData) {
      const { addressOne, addressTwo, city, name, location, _id: clubId, photos, countryName, provinceName } = clubData;
      courseData.push({
        _id, clubObjectId, courseId, distance, holes, par, position, addressOne, name, location, addressTwo, city, clubId, photos, slope, countryName, provinceName, status, syncStatus
      });
    }

  })

  useEffect(() => {
    // if course sync is done happen // CourseListItem item
    if (courseSyncStatus === SAGA_STATUS_SUCCESS) {
      toast.info('Course Sync started.');
      dispatch(coursesActions.resetCourseSyncOnActiveStatus());
      dispatch(clubsActions.clubsList())
      dispatch(coursesActions.coursesList({ isDeleted: false }));
    }
  }, [courseSyncStatus]);

  useEffect(() => {
    if (courseUpdateStatus === SAGA_STATUS_FAILED) {
      dispatch(clubsActions.clubsList())
      dispatch(coursesActions.coursesList({ isDeleted: false }));
    }
  }, [courseUpdateStatus])

  useEffect(() => {
    if (courseUpdatedData && Object.keys(courseUpdatedData)?.length > 0 && courseUpdatedData?.status === CLUB_ACTIVE_STATUS && courseUpdateStatus === SAGA_STATUS_SUCCESS) {
      if(![COURSE_SYNC_STATUS_SUCCESS_NAME, COURSE_SYNC_STATUS_INIT_NAME].includes(courseUpdatedData?.syncStatus)){
        const syncCourseData = { courseObjectId: courseUpdatedData?._id };
        dispatch(coursesActions.courseSyncOnActive(syncCourseData));
      }
      dispatch(coursesActions.resetCourseUpdatedData())
      dispatch(clubsActions.clubsList())
      dispatch(coursesActions.coursesList({ isDeleted: false }));
    }
  }, [courseUpdateStatus, courseUpdatedData])

  useEffect(() => {
    if (coursesListedStatus == SAGA_STATUS_SUCCESS) {
      if (courseData.length == 0) {
        SetShowPopup(true);
      }
      dispatch(coursesActions.resetFetchCourseStatus());
    }
  }, [coursesListedStatus, courseData]);

  useEffect(() => {
    dispatch(clubsActions.clubsList())
    dispatch(coursesActions.coursesList({ isDeleted: false }));
    // reset flags to init state when unmounted
    return () => {
      dispatch(coursesActions.resetSelectedCourse());
    };

  }, []);

  return (
    <>
      <div className="coursesWrapper">
        <CommonHeader sectionTitle={"Listings"}></CommonHeader>
        <div className="container py-4 my-2">
          {
            (courseData.length > 0) && <>
              {courseData.map(({
                _id, clubId, distance, holes, par, slope, addressOne, addressTwo, city, name = "-", photos, countryName, provinceName, status, syncStatus
              }) => <CourseListItem
                  clubId={clubId}
                  courseId={_id}
                  courseName={name}
                  address={`${(addressOne || "")} ${(addressTwo ? `${addressTwo},` : "")} ${(city || "")}, ${(provinceName || "")}, ${(countryName || "")}`}
                  distance={distance}
                  holes={holes}
                  par={par}
                  slope={slope}
                  key={_id}
                  photos={photos}
                  status={status}
                  syncStatus={syncStatus}
                  courseDeleteStatus={courseDeleteStatus}
                />)}
            </>
          }
          {
            (courseData.length == 0) && <>
              <div className="d-flex align-items-center justify-content-center">
                <h2>There are no courses to list. Please add a new course</h2>
              </div>
            </>
          }
        </div>
      </div>
      <Modal
        className="welcomePopupModal"
        show={showPopup}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <img
            src="../assets/images/golf-course-placeholder.png"
            alt="golf-course"
            className="img-fluid d-block mx-auto"
          />
          <h2 className="mb-2 mt-3">Welcome to Integolf</h2>
          <p className="mb-4 fw600">Let's list your course on Integolf.</p>
          <button
            type="button"
            className="button button-primary button-rounded fw700 mb-3"
            onClick={() => {
              dispatch(accountStorageActions.showHideCourseAddPopup(false));
              navigate("/register");
              handleCloseWelcomeModal();
            }}
          >
            List My Course
          </button>

          <div className="pt-3">
            <a
              href={void 0}
              className="_laterLink"
              onClick={() => {
                dispatch(accountStorageActions.showHideCourseAddPopup(false));
                handleCloseWelcomeModal();
              }}
            >
              Remind Me Later
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Courses;
