
import { Fragment, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PropTypes from "prop-types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useForm, SubmitHandler } from "react-hook-form";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { authActions, signupLoginStatus, verifyOtpStatus, socialSignupLoginStatus } from "../../../reducers/authSlice";
import { useNavigate, NavLink, useSearchParams } from "react-router-dom";
import VerifyPage from './verify';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { decodeJwt } from "../../../utils/common";

const Register = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const target = searchParams.get("target");

  const [showPhoneForm, setShowPhoneForm] = useState(true);
  const [country, setCountry] = useState<any>("US");
  const signupStatus = useAppSelector(signupLoginStatus);
  const [inputField, setInputField] = useState("");
  const [socialLogin, setSocialLogin] = useState(false);
  const socialSignupStatus = useAppSelector(socialSignupLoginStatus);
  const otpVerificationStatus: string = useAppSelector(verifyOtpStatus);

  type CredentialInput = {
    phoneOrEmail: any
  }

  useEffect(() => {
    // if target is passed then 
    if (target) {
      try {
        decodeJwt(target);
      } catch (err) {
        console.error(err);
        navigate("/LinkExpired");
      }
    }

    dispatch(authActions.reset());
  }, [])


  useEffect(() => {
    if (otpVerificationStatus == 'success' || socialSignupStatus == 'success') {
      navigate("/dashboard")
    }
  }, [otpVerificationStatus])


  const { register, handleSubmit, formState: { errors }, reset } = useForm<CredentialInput>()

  const onSubmit: SubmitHandler<CredentialInput> = data => {

    setInputField(data.phoneOrEmail?.trim());

    let obj: any = {}

    if (showPhoneForm) {
      obj = {
        phone: {
          countryCode: country ? getCountryCallingCode(country) : 1,
          number: data.phoneOrEmail?.trim()
        }
      }
    } else {
      obj = {
        email: data.phoneOrEmail?.trim()
      }
    }
    obj['role'] = 'merchant'
    dispatch(authActions.signupLogin(obj));
  }

  const changeInputFormType: any = (value: boolean) => {
    reset();
    setCountry("US")
    setShowPhoneForm(value)
  }

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      try {
        const data = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { "Authorization": `Bearer ${tokenResponse.access_token}` }
        })

        setSocialLogin(true)
        if (data) {
          let obj = {
            firstname: data.data.given_name,
            lastname: data.data.family_name,
            email: data.data.email,
            profileImage: data.data.picture,
            googleId: data.data.sub,
            role: 'merchant',
            type: 'socialSignup'
          }
          dispatch(authActions.signupLogin(obj))
        }

      } catch (err) {
        console.log('Error--', err)
      }

    }
  });

  return (
    <section className="authWrapper">
      <div className="authMain flex-fill d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9">
              {signupStatus != 'success' && <div className="authWrap">
                <div className="text-center mb-4">
                  <h1>
                    Welcome to Inte
                    <span>
                      g<span>o</span>lf
                    </span>
                  </h1>
                  <h6 className="color-gray">Log in or sign up</h6>
                </div>
                <div className="formSection py-5">
                  <div className="formWrap">
                    <form onSubmit={handleSubmit(onSubmit)}>

                      {showPhoneForm && <Fragment>
                        <div className="mb-4">
                          <div className="form-floating" >
                            <CountrySelect
                              labels={en}
                              value={country}
                              onChange={setCountry}
                            />
                            <label htmlFor="region">Country/Region</label>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="form-floating" >
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              placeholder="Phone Number"
                              {...register("phoneOrEmail", { required: true,validate: (value:any) => {
                                const trimmedValue = value.trim();
                                const numberPattern =  /^[0-9]*$/i;
                                return numberPattern.test(trimmedValue);
                              } })}
                            />
                            {errors.phoneOrEmail?.type == 'required' && <div className="isInvalidMessage text-danger">Please enter phone number</div>}
                            {errors.phoneOrEmail?.type == 'validate' && <div className="isInvalidMessage text-danger">Please enter valid phone number</div>}
                            <label htmlFor="phoneNumber">Phone Number</label>
                          </div>
                        </div>
                      </Fragment>}
                      {!showPhoneForm && <FormTypeEmail register={register} errors={errors}/>}
                      {/* <p>
                        I agree with{" "}
                        <a href="https://www.integolf.com/privacy" target="_blank">Privacy Policy</a>
                         <br /> We'll call
                        or text you to confirm your number.
                      </p> */}
                      <div className="mb-4 pt-2">
                        <button
                          type="submit"
                          className="button button-primary button-block button-rounded button-large fw700 fontsize-16"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="formWrapRight">

                    {/* <FacebookLogin
                      appId="666098185251961" // we created this, remember?
                      autoLoad={false}
                      cookie={false}
                      fields="name,email,picture,first_name,last_name"
                      scope="public_profile"
                      render={(renderProps: any) => (
                        <button
                          type="button"
                          className="button button-block button-border button-large button-rounded"
                          onClick={renderProps.onClick}
                        >
                          <span className="icon">
                            <img
                              src="assets/images/icons/facebook.svg"
                              alt="facebook"
                            />
                          </span>{" "}
                          Continue with Facebook
                        </button>
                      )}
                      callback={responseFacebook}
                    /> */}
                    <button
                      type="button"
                      className="button button-block button-border button-large button-rounded"
                      onClick={() => login()}
                    >
                      <span className="icon">
                        <img
                          src="assets/images/icons/google.svg"
                          alt="facebook"
                        />
                      </span>{" "}
                      Continue with Google
                    </button>
                    {/* <button
                      type="button"
                      className="button button-block button-border button-large button-rounded"
                    >
                      <span className="icon">
                        <img
                          src="assets/images/icons/apple.svg"
                          alt="facebook"
                        />
                      </span>{" "}
                      Continue with Apple
                    </button> */}
                    {showPhoneForm ? (
                      <button
                        type="button"
                        className="button button-block button-border button-large button-rounded"
                        onClick={() => changeInputFormType(false)}
                      >
                        <span className="icon">
                          <img
                            src="assets/images/icons/email.svg"
                            alt="facebook"
                          />
                        </span>{" "}
                        Continue with Email
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="button button-block button-border button-large button-rounded"
                        onClick={() => changeInputFormType(true)}
                      >
                        <span className="icon">
                          <img
                            src="assets/images/icons/phone.svg"
                            alt="facebook"
                          />
                        </span>{" "}
                        Continue with Phone Number
                      </button>
                    )}
                  </div>
                </div>
              </div>}

              {signupStatus == 'success' && !socialLogin && <VerifyPage countryCode={getCountryCallingCode(country)} inputData={inputField} isPhoneInput={showPhoneForm}></VerifyPage>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FormTypeEmail = ({ register, errors }: any) => (
  <Fragment>
    <div className="mb-4">
      <div className="form-floating">
        <input
          type="text"
          className="form-control"
          id="email"
          placeholder="Email Address"
          {...register("phoneOrEmail", { required: true, validate: (value:any) => {
            const trimmedValue = value.trim();
            const emailPattern = /^\S+@\S+$/i;
            return emailPattern.test(trimmedValue);
          } })}
        />
        {errors.phoneOrEmail?.type == "required" && <span className="isInvalidMessage text-danger">Please enter email</span>}
        {errors.phoneOrEmail?.type == "validate" && <span className="isInvalidMessage text-danger">Please enter a valid email</span>}
        <label htmlFor="email">Email Address</label>
      </div>
    </div>
  </Fragment>
);

const CountrySelect = ({ value, onChange, labels, ...rest }: {
  value: any;
  onChange: any;
  labels: any;
}) => (
  <select
    className="form-select"
    id="region"
    {...rest}
    defaultValue={"US"}
    value={value}
    onChange={event => onChange(event.target.value || undefined)}
  >
    {getCountries().map((country) => (
      <option key={country} value={country}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

CountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Register;
