import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/store';
import {ExternalClubPayload} from "../models/externalClubs";

export interface externalClubsState {
  fetchExternalClubsStatus: string;
  totalExternalClubsCount: number,
  externalClubs: Array<ExternalClubPayload>;
}

const initialState: externalClubsState = {
  fetchExternalClubsStatus: "",
  externalClubs: [],
  totalExternalClubsCount: 0
};

const externalClubsSlice = createSlice({
  name: 'externalClubs',
  initialState,
  reducers: {
    externalClubsList(state, action: PayloadAction<any>) {
      state.fetchExternalClubsStatus = "pending";
    },
    externalClubsListSuccess(state, action: PayloadAction<any>) {
      state.externalClubs = action.payload;
      state.totalExternalClubsCount = Array.isArray(action.payload)? action.payload.length: 0;
      state.fetchExternalClubsStatus = "success";
    },
    externalClubsListFailed(state, action: PayloadAction<string>) {
      state.fetchExternalClubsStatus = "failed";
    },
    resetExternalClubs(state) {
      state.externalClubs = [];
      state.totalExternalClubsCount = 0;
      state.fetchExternalClubsStatus = "";
    }
  },
});

// Actions
export const externalClubsActions = externalClubsSlice.actions;

// Selectors
export const fetchExternalClubsStatus = (state: RootState) => state.externalClubs.fetchExternalClubsStatus;
export const fetchexternalClubs = (state: RootState) => state.externalClubs.externalClubs;
export const fetchexternalClubsCount = (state: RootState) => state.externalClubs.totalExternalClubsCount;

// Reducer
const externalClubsReducer = externalClubsSlice.reducer;
export default externalClubsReducer;
