import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { push } from "connected-react-router";
import axiosInstance from "./axiosClient";
import { store } from "../../app/store";

function* httpClient(payload: any) {

  const payloadData = {
    ...payload,
    headers: {
      ...payload.headers,
    },
  };
  
  const state = store.getState();
  
  let token = state?.auth?.currentUser?.tempToken ?  state?.auth?.currentUser?.tempToken :  state?.auth?.currentUser?.accessToken

  if (token) {
    payloadData.headers['x-auth-jwt'] = token
  }
  try {
    const { data: result, error, headers } = yield call(axiosInstance, payloadData);
    return {
      error,
      result,
      headers
    };
  } catch (error: any) {

    let myError = error?.response?.status ? error.response.data : error;

    if (myError?.status) {
      if (myError.status === 401) {
        yield put(push({ pathname: "/" }));
        toast.error("Session Expired. Please login again.");
      } else {
        toast.error(myError.message);
      }
    } else if(error?.response?.status && error.response.status == 422 && error?.response?.data?.response?.message) {
      toast.error(error.response.data.response.message);
    } else {
      toast.error(myError.message);
    }

    return {
      error: myError?.response?.message ? myError?.response : true,
      result: null,
      axiosError: error
    };
  }
}

export default httpClient;
