import { RootState } from './../../app/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { BookingResponseData } from '../../models/booking';

export interface BookingState {
  fetchBookingStatus: string;
  totalBookingCount: number,
  bookings: any;
  fetchBookingDetailStatus: string;
  bookingDetail: any;
  cancelBookingStatus: string
}

const initialState: BookingState = {
  fetchBookingStatus: "",
  bookings: [],
  totalBookingCount: 0,
  fetchBookingDetailStatus: "",
  bookingDetail: {},
  cancelBookingStatus: ""

};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    bookingList(state, action: PayloadAction<any>) {
      state.fetchBookingStatus = "pending";
    },
    bookingListSuccess(state, action: PayloadAction<any>) {
      state.bookings = action.payload.results;
      state.totalBookingCount = action.payload.count;
      state.fetchBookingStatus = "success";
    },
    bookingListFailed(state, action: PayloadAction<string>) {
      state.fetchBookingStatus = "failed";
    },
    bookingDetail(state, action: PayloadAction<any>) {
      state.fetchBookingDetailStatus = "pending";
    },
    bookingDetailSuccess(state, action: PayloadAction<any>) {
      console.log('inside booking detail suucess saga', action.payload)
      state.bookingDetail = action.payload;
      state.fetchBookingDetailStatus = "success";
    },
    bookingDetailFailed(state, action: PayloadAction<string>) {
      state.fetchBookingDetailStatus = "failed";
    },
    cancelBooking(state, action: PayloadAction<any>) {
      state.cancelBookingStatus = "pending";
    },
    cancelBookingSuccess(state, action: PayloadAction<any>) {
      console.log('in cancel booking slice', action.payload)
      const booking = state.bookingDetail
      // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
      booking.status = action.payload.status
      state.cancelBookingStatus = "success";
    },
    cancelBookingFailed(state, action: PayloadAction<string>) {
      state.cancelBookingStatus = "failed";
    },
    resetBookings(state) {
      state.bookings = [];
      state.totalBookingCount = 0;
      state.fetchBookingStatus = "";
    }
  },
});

// Actions
export const bookingActions = bookingSlice.actions;

// Selectors
export const fetchBookingStatus = (state: RootState) => state.booking.fetchBookingStatus;
export const fetchBookings = (state: RootState) => state.booking.bookings;
export const fetchBookingCount = (state: RootState) => state.booking.totalBookingCount;
export const fetchBookingDetail = (state: RootState) => state.booking.bookingDetail;
export const fetchBookingCancelStatus = (state: RootState) => state.booking.cancelBookingStatus;

// Reducer
const bookingReducer = bookingSlice.reducer;
export default bookingReducer;
