import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { toast } from "react-toastify";

import { productsActions } from '../reducers/productSlice';
import httpClient from "../api/merchantApi/httpClient";
import { CLUB_INACTIVE_STATUS } from '../utils/constants';

function* handleAddProduct(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "post",
    url: `/products`,
    data: [action.payload]
  });

  if (error) {
    yield put(productsActions.addProductFailed()); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.addProductSuccess(result.response))
  }
  return { error, result };
}

function* handleListProduct(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "get",
    url: `/products`,
    params: { isApproved: true }
  });

  if (error) {
    yield put(productsActions.fetchProductListFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.fetchProductListSuccess(result.response))
  }
  return { error, result };
}

function* handleDeleteProduct(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "delete",
    url: `/products`,
    data: action.payload
  });

  if (error) {
    yield put(productsActions.deleteProductFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.deleteProductSuccess(result.response))
  }
  return { error, result };
}

function* handleGetProductDetail(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "get",
    url: `/products`,
    params: action.payload
  });

  if (error) {
    yield put(productsActions.fetchProductDetailFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.fetchProductDetailSuccess(result.response))
  }
  return { error, result };
}

function* handleUpdateProduct(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "put",
    url: `/products`,
    data: action.payload
  });

  if (error) {
    yield put(productsActions.updateProductFailed(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.updateProductSuccess(result.response))
  }
  return { error, result };
}

function* handleFetchPendingProducts(action: PayloadAction<any>) {
  const { result, error, headers } = yield call(httpClient, {
    method: "get",
    url: `/products`,
    params: { isApproved: false }
  });

  if (error) {
    yield put(productsActions.fetchPendingProductsFailure(error.message)); // Dispatch action
    toast.error(error);
  } else {
    yield put(productsActions.fetchPendingProductsSuccess(result.response))
  }
  return { error, result };
}

export function* productSaga() {
   yield takeLatest(productsActions.addProduct.type, handleAddProduct);
   yield takeLatest(productsActions.fetchProductList.type, handleListProduct);
   yield takeLatest(productsActions.deleteProduct.type, handleDeleteProduct);
   yield takeLatest(productsActions.fetchProductDetail.type, handleGetProductDetail);
   yield takeLatest(productsActions.updateProduct.type, handleUpdateProduct);
   yield takeLatest(productsActions.fetchPendingProducts.type, handleFetchPendingProducts);
}
  