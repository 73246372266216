import React, { FC, useRef, useState, useEffect, MutableRefObject } from "react";
import { useSearchParams } from "react-router-dom";
import { Accordion, Card } from "react-bootstrap";

import { ClubPayload } from "../../../../models/clubs";
import DateTimeComponent, { IDateTimePayload } from "../../../../shared/date-time-component/date-time-component";
import { 
    COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE, 
    COURSE_ADVANCE_BOOKING_MAX_DAYS, 
    COURSE_ADVANCE_BOOKING_MIN_DAYS 
} from "../../../../utils/constants";
import { getValuesForAllTeeTimes } from "../../../../utils/common";

interface ICourseTimings {
    clubObject ?: ClubPayload
    onSubmit: (payload: any) => void;
}

const DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL = 4;
const TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL = 30;

const DISCOUNTED_PORTAL_EVENT_KEY = "0";
const TOUR_PORTAL_EVENT_KEY = "1";

const CourseTimings: FC<ICourseTimings> = ({
    clubObject,
    onSubmit,
}) => {

    const [ searchParams ] = useSearchParams();
    const [clubTimingsTourPortalSubmitError, setClubTimingsTourPortalSubmitError] = useState<string>("");
    const [clubTimingsDiscountedPortalSubmitError, setClubTimingsDiscountedPortalSubmitError] = useState<string>("");
    const [ activeKey, setActiveKey ] = useState<string>(DISCOUNTED_PORTAL_EVENT_KEY);

    // refrences used to get info from child elements on submit
    const tourPortalRef: MutableRefObject<IDateTimePayload|undefined> = useRef();
    const discountedPortalRef: MutableRefObject<IDateTimePayload|undefined> = useRef();
    
    // if edit step is passed then in the path only show the only view 
    const editStep = searchParams.get("step");

    const getValidateDateTimePayload = (advanceBookingDurationMin: number, advanceBookingDurationMax: number) => (payload: IDateTimePayload) => {

        const { clubTimings = [], clubAdvanceBookingDuration = 0 } = payload;
        
        const clubTimingPayload = clubTimings.filter(val => !!val);
        
        if (!clubTimingPayload.length) {
            throw new Error('Please select the days and the timings when the club is open.');
        }
    
        if (clubAdvanceBookingDuration < advanceBookingDurationMin || clubAdvanceBookingDuration > advanceBookingDurationMax) {
            throw new Error('Please select valid club advance booking duration');
        }
    
        let message = '';
        clubTimingPayload.forEach(timingObject => {
            if (!timingObject.startHours || !timingObject.endHours) {
                message = `Please provide correct start and end hours for selected day(s).`;
            } else if (parseInt(`${timingObject.startHours}${timingObject.startMinutes}`) >= parseInt(`${timingObject.endHours}${timingObject.endMinutes}`)) {
                message = `Please select valid start and end hours for selected day(s).`;
            }
        });

        if (message.length) {
            throw new Error(message);
        }
    
    }

    const validateDiscountedPortalDateTimePayload = (payload: IDateTimePayload): ({error?: string, payload?: any}) => {

        if (!clubObject) {
          throw new Error("Club info not passed.");
        }

        try{
            
            const validateDateTimePayload = getValidateDateTimePayload(COURSE_ADVANCE_BOOKING_MIN_DAYS, DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL); 
            validateDateTimePayload(payload);

            const { clubTimings = [], clubAdvanceBookingDuration = 0 } = payload;
            const updatePayload: any = {
                clubTimings,
                advanceBookingDuration: clubAdvanceBookingDuration
            };
              
            return { payload: updatePayload };
        } catch(err: any) {
            return { error: err?.message };
        }
            
    };

    const validateTourismPortalDateTimePayload = (payload: IDateTimePayload): ({error?: string, payload?: any}) => {

        if (!clubObject) {
          throw new Error("Club info not passed.");
        }

        try{

            const validateDateTimePayload = getValidateDateTimePayload(COURSE_ADVANCE_BOOKING_MIN_DAYS, COURSE_ADVANCE_BOOKING_MAX_DAYS);
            validateDateTimePayload(payload);

            const { clubTimings = [], clubAdvanceBookingDuration = 0 } = payload;
            const updatePayload: any = {
                tourismPortalClubTimings: clubTimings,
                tourismPortalAdvanceBookingDuration: clubAdvanceBookingDuration
            };
              
            return { payload: updatePayload };
        } catch(err: any) {
            return { error: err?.message };
        }
            
    };

    const onDateTimeSubmit = () => {
        
        let tourismPortalPayload = {};
        let discountedPortalPayload = {};
        
        if (tourPortalRef.current) {
            const { clubTimings, clubAdvanceBookingDuration } = tourPortalRef.current;
            tourismPortalPayload = {
                clubTimings, 
                clubAdvanceBookingDuration
            };
        }

        if (discountedPortalRef.current) {
            const { clubTimings, clubAdvanceBookingDuration } = discountedPortalRef.current;
            discountedPortalPayload = {
                clubTimings, 
                clubAdvanceBookingDuration
            };
        }

        const { error: discountPortalErrors = '', payload: discountPortalTimingChanges } = validateDiscountedPortalDateTimePayload(discountedPortalPayload);
        const { error: tourPortalErrors = '', payload: tourPortalTimingChanges } = validateTourismPortalDateTimePayload(tourismPortalPayload);

        if (tourPortalErrors.length || discountPortalErrors.length) {
            
            if (tourPortalErrors?.length) {
                setClubTimingsTourPortalSubmitError(tourPortalErrors);
            } else {
                setClubTimingsTourPortalSubmitError("");
            }

            if (discountPortalErrors?.length) {
                setClubTimingsDiscountedPortalSubmitError(discountPortalErrors);    
            } else {
                setClubTimingsDiscountedPortalSubmitError("");
            }

            // if there is an error related to discounted portal but the tour portal is open then open the discounted portal  
            if (discountPortalErrors.length && !!!tourPortalErrors.length && activeKey !== DISCOUNTED_PORTAL_EVENT_KEY) {
                setActiveKey(DISCOUNTED_PORTAL_EVENT_KEY);
            } else if (tourPortalErrors.length && !!!discountPortalErrors.length && activeKey !== TOUR_PORTAL_EVENT_KEY) {
                // if there is an error related to discounted portal but the tour portal is open then open the discounted portal
                setActiveKey(TOUR_PORTAL_EVENT_KEY);
            }

        } else {
            
            setClubTimingsDiscountedPortalSubmitError("");
            setClubTimingsTourPortalSubmitError("");
            
            onSubmit({
                ...tourPortalTimingChanges,
                ...discountPortalTimingChanges
            });

        }

    }

    const discountedPortalInfo = {
        clubTimings: (clubObject?.clubTimings||getValuesForAllTeeTimes()),
        clubAdvanceBookingDuration: (clubObject?.advanceBookingDuration||COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE)
    };

    const tourismPortalInfo = { 
        clubTimings: (clubObject?.tourismPortalClubTimings||getValuesForAllTeeTimes()), 
        clubAdvanceBookingDuration: (clubObject?.tourismPortalAdvanceBookingDuration||COURSE_ADVANCE_BOOKING_DAYS_DEFAULT_VALUE)  
    };

    return (clubObject?._id) ? <>
        <form
            className="formStepper"
            onSubmit={(e) => { e.preventDefault(); onDateTimeSubmit(); }}
        >   
            <h5 className="mb-4">Add time and days for tee times</h5>
            <Accordion activeKey={activeKey} defaultActiveKey={DISCOUNTED_PORTAL_EVENT_KEY}>
                <Card className="border-none">
                    <Card.Header className="border-none cursor-hand" onClick={() => setActiveKey(DISCOUNTED_PORTAL_EVENT_KEY)}>
                        <div className="row py-2">
                            <div className="col-11 accordion-header-text">
                                Discounted Portal
                            </div>
                            <div className="col-1">
                                <em className="fa-solid fa-chevron-down fa-lg"></em>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={DISCOUNTED_PORTAL_EVENT_KEY} className="p-4">
                        <DateTimeComponent 
                            info={discountedPortalInfo}
                            clubTimingsSubmitError={clubTimingsDiscountedPortalSubmitError}
                            ref={discountedPortalRef}
                            advanceBookingMaxDays={DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL}
                            advanceBookingDefaultDay={DISCOUNT_PORTAL_ADVANCE_BOOKING_MAX_VAL}
                        />
                    </Accordion.Collapse>
                    <Card.Body></Card.Body>
                </Card>
                <Card className="border-none">
                    <Card.Header className="border-none cursor-hand" onClick={() => setActiveKey(TOUR_PORTAL_EVENT_KEY)}>
                        <div className="row py-2">
                            <div className="col-11 accordion-header-text">
                                Tourism Portal
                            </div>
                            <div className="col-1">
                                <em className="fa-solid fa-chevron-down fa-lg"></em>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey={TOUR_PORTAL_EVENT_KEY}>
                        <Card.Body>
                            <DateTimeComponent 
                                info={tourismPortalInfo}
                                clubTimingsSubmitError={clubTimingsTourPortalSubmitError}
                                ref={tourPortalRef}
                                advanceBookingDefaultDay={TOURISM_PORTAL_ADVANCE_BOOKING_DEFAULT_VAL}
                            />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="pt-4">
                <button
                    className="button button-primary button-rounded button-min-150 fw700"
                    type="submit"
                >
                    {!!editStep ? 'Save': 'Continue'}
                </button>
            </div>
        </form>
    </>: <></>;
};

export default CourseTimings;
