import React, { FC, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import ImageCropperUploadModal from "../../../../shared/image-cropper-modal/image-cropper-modal";
import IFramePDFViewerModal from "../../../../shared/iframe-pdf-viewer-modal/iframe-pdf-viewer-modal";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { merchantFilesActions, fetchMerchantFilesUploadStatus, fetchSelectedMerchantFiles } from "../../../../reducers/merchantFilesSlice";
import { COUNTRY_CODE_PHONE, SAGA_STATUS_INIT, SAGA_STATUS_SUCCESS, LINK_TEE_TIME_SYSTEM_STEP_SECOND } from "../../../../utils/constants";
import { MerchantFiles } from "../../../../models/merchantFiles";
import { coursesActions, fetchSendTeeonOnboardingFormStatus, fetchTeeonOnboardingResult, fetchTeeonOnboardingStatus, fetchTeeonOnboardingFormStatus, fetchTeeonOnboardingFormDetails } from "../../../../reducers/coursesSlice";
import { CoursesPayload } from "../../../../models/courses";
import { ClubPayload } from "../../../../models/clubs";
import { TeeonOnboardingFormDetails } from "../../../../models/teeonOnboardingFormDetails";
import { extractPhoneAndCode } from "../../../../utils/common";

type linkTeeTimeTeeonFormElements = {
  golfCourseName: string;
  golfCourseTechnicalContact: string;
  golfContactPhoneCountryCode: string;
  golfContactPhoneNumber: string;
  golfContactEmail: string;
  signedByName: string;
  signFileName: string;
  signFilePath: string;
  signFileType: string;
}

interface LinkTeeTimeTeeonFormComponentProps {
  clubObject: ClubPayload;
  courseInfo: CoursesPayload;
  onFormCompletion: Function
}

const LinkTeeTimeTeeonFormComponent: FC<LinkTeeTimeTeeonFormComponentProps> = ({
    clubObject,
    courseInfo,
    onFormCompletion
}) => {

  const dispatch = useAppDispatch();
  const fileUploadStatus: string = useAppSelector(fetchMerchantFilesUploadStatus);
  const filesUploadList: Array<MerchantFiles> = useAppSelector(fetchSelectedMerchantFiles);
  const teeonOnboardingStatus: string = useAppSelector(fetchTeeonOnboardingStatus);
  const teeonOnboardingResult: any = useAppSelector(fetchTeeonOnboardingResult);
  const sendTeeonOnboardingFormStatus: string = useAppSelector(fetchSendTeeonOnboardingFormStatus);
  const teeonOnboardingFormStatus: string = useAppSelector(fetchTeeonOnboardingFormStatus);
  const teeonOnboardingFormDetails: Array<TeeonOnboardingFormDetails> = useAppSelector(fetchTeeonOnboardingFormDetails);

  const { _id: clubId, name: golfCourseName = '' } = clubObject;
  const { _id: courseId, teeonMerchantFormRequestId, teeSheetSystemStep, courseCode } = courseInfo;
  
  // disable form fields if the current active 'teeSheetSystemStep' passes i.e. next to/greater than LINK_TEE_TIME_SYSTEM_STEP_SECOND 
  const disableFormFields = !!teeSheetSystemStep && teeSheetSystemStep > LINK_TEE_TIME_SYSTEM_STEP_SECOND;

  const {
    register: addTeeonFormElementsRegister,
    handleSubmit: handleSubmitTeeOnApplicationForm,
    formState: { errors: addTeeonFormElementsErrors },
    reset,
    getValues: getForValues,
    setValue,
    clearErrors
  } = useForm<linkTeeTimeTeeonFormElements>();

  const [ showSignUploadModal, setshowSignUploadModal ] = useState(false);
  const [ teeonOnboardingRequestId, setTeeonOnboardingRequestId ] = useState("");
  const [ teeonOnBoardingGeneratedPdfUrl, setTeeonOnBoardingGeneratedPdfUrl ] = useState("");

  const onSubmitTeeOnApplicationForm = (formData: linkTeeTimeTeeonFormElements) => {
    const { signFileName, golfContactPhoneCountryCode, golfContactPhoneNumber, ...payloadData } = formData;
    const teeonFormSubmitPayload: any = { 
      clubId, 
      courseId, 
      golfContactPhoneNumber: `${golfContactPhoneCountryCode}${golfContactPhoneNumber}`,
      ...payloadData 
    };
    dispatch(coursesActions.teeonOnboarding(teeonFormSubmitPayload));
  }
  
  const onSignImageCropSubmit = (formData: FormData) => dispatch(merchantFilesActions.merchantFilesUpload(formData));

  const onClosePDFModal = () => {
    setTeeonOnboardingRequestId("");
    setTeeonOnBoardingGeneratedPdfUrl("")
  };

  const onSubmitPDFModal = () => {
    const sendTeeonOnboardingFormPayload: any = { requestId: teeonOnboardingRequestId};
    dispatch(coursesActions.sendTeeonOnboardingForm(sendTeeonOnboardingFormPayload));
  }

  useEffect(() => {
    
    // reset if empty files are already there
    if (fileUploadStatus == SAGA_STATUS_INIT && filesUploadList.length) {
      dispatch(merchantFilesActions.resetSelectedMerchantFile());
    }

    if (fileUploadStatus == SAGA_STATUS_SUCCESS){

      const [ signatureObject ] = filesUploadList;
      
      setValue('signFilePath', signatureObject?.path || '');  // set sign file path
      setValue('signFileType', signatureObject?.mimeType?.split("/").pop() || '');  // set file sign type
      setValue('signFileName', signatureObject?.fileName ||'');
      clearErrors('signFilePath');

      dispatch(merchantFilesActions.resetSelectedMerchantFile());
      setshowSignUploadModal(false);
    }

  }, [fileUploadStatus, filesUploadList]);

  // once course is updated 
  useEffect(() => {
    if (teeonOnboardingStatus === SAGA_STATUS_SUCCESS) {
      const { _id, url } = teeonOnboardingResult;
      // once teeon onboarding pdf is generated then set relavant value
      if (_id) {
        setTeeonOnboardingRequestId(_id);
      }
      if (url) {
        setTeeonOnBoardingGeneratedPdfUrl(url);
      } 

      // reset onboarding result
      dispatch(coursesActions.resetTeeonOnboardingStatus())
    }
  }, [teeonOnboardingStatus]);

  useEffect(() => {
    // if teeon onboarding form status is successful then update and call on onFormCompletion function
    if (sendTeeonOnboardingFormStatus == SAGA_STATUS_SUCCESS) {
      // close the modal
      onClosePDFModal();
      // call the parent function to be called on form completion
      onFormCompletion();
      // reset the send teeon onboarding form status
      dispatch(coursesActions.resetSendTeeonOnboardingFormStatus());
    }
  }, [sendTeeonOnboardingFormStatus]);

  useEffect(()=>{
    // set values if these are present
    if (teeonOnboardingFormStatus == SAGA_STATUS_SUCCESS && teeonOnboardingFormDetails.length) {
      const [ teeonOnboardingFormDetail ] = teeonOnboardingFormDetails;
      const { signFilePath = '', signFileType = '', signedByName = '', golfCourseName = '', golfCourseTechnicalContact = '', golfContactPhoneNumber = '', golfContactEmail = '' } = teeonOnboardingFormDetail;
      setValue('signFilePath', signFilePath);
      setValue('signFileType', signFileType);
      setValue('signFileName', signFilePath.split('/').pop()||'');
      setValue('signedByName', signedByName);
      setValue('golfCourseName', golfCourseName);
      setValue('golfCourseTechnicalContact', golfCourseTechnicalContact);
      setValue('golfContactEmail', golfContactEmail);

      if (golfContactPhoneNumber) {
        const [ countryCode, phoneNumber ] = extractPhoneAndCode(golfContactPhoneNumber);
        setValue('golfContactPhoneCountryCode', countryCode);
        setValue('golfContactPhoneNumber', phoneNumber);
      }

    }
  }, [teeonOnboardingFormStatus])

  // set golf course name in the form using the prop passed
  useEffect(() => {
    setValue('golfCourseName', golfCourseName);

    // if teeonMerchantFormRequestId is present then load data teeonMerchantForm details on component load
    if (teeonMerchantFormRequestId) {
      const requestPayload = { id: teeonMerchantFormRequestId };
      dispatch(coursesActions.fetchTeeonOnboardingFormDetails(requestPayload))
    }

    // on component unmount reset the teeon onboarding form details
    return () => {
      dispatch(coursesActions.resetFetchTeeonOnboardingFormStatus())
    }
  }, []);

    return <>
        <h4>Enable TeeOn Access</h4>
        <p>
            Please fill the following details and click submit. A system generated application will be sent with these details to TeeOn for remote access auhorization of the teesheets. After 3-5 business days, your course will be assigned a course code and your Integolf can sync up course teesheets seamlessly.
        </p>
        <div className="row mt-4">
            <form onSubmit={handleSubmitTeeOnApplicationForm(onSubmitTeeOnApplicationForm)}>
                <div className="mb-4 row">
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="golfCourseName"
                        placeholder="Golf Course Name"
                        disabled={disableFormFields}
                        {...addTeeonFormElementsRegister("golfCourseName", { required: true })}
                      />
                      <label htmlFor="golfCourseName">Golf Course Name</label>
                      {addTeeonFormElementsErrors.golfCourseName?.type == 'required' && <div className="isInvalidMessage text-danger">Golf Course Name is required.</div>}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="golfCourseTechnicalContact"
                        placeholder="Course Technical Contact Name"
                        disabled={disableFormFields}
                        {...addTeeonFormElementsRegister("golfCourseTechnicalContact", { required: true })}
                      />
                      <label htmlFor="golfCourseTechnicalContact">Course Technical Contact Name</label>
                      {addTeeonFormElementsErrors.golfCourseTechnicalContact?.type == 'required' && <div className="isInvalidMessage text-danger">Golf contact name is required.</div>}
                    </div>
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-4 pr-0">
                          <select 
                            className="form-control form-select"
                            id="golfContactPhoneCountryCode"
                            disabled={disableFormFields}
                            {...addTeeonFormElementsRegister("golfContactPhoneCountryCode", { required: true})}
                          >
                            <option value="">Country Code</option>
                            { COUNTRY_CODE_PHONE.map(({ name, dial_code, code }) => (
                              <option key={name} value={dial_code}>
                                {code} ({dial_code})
                              </option>
                            ))}
                          </select>
                      </div>
                      <div className="col-8">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="golfContactPhoneNumber"
                            placeholder="Course Technical Phone"
                            disabled={disableFormFields}
                            {...addTeeonFormElementsRegister("golfContactPhoneNumber", { required: true, pattern: /^[0-9]*$/i })}
                          />
                          <label htmlFor="golfContactPhoneNumber">Course Technical Phone</label>
                        </div>
                      </div>
                    </div>
                    {addTeeonFormElementsErrors.golfContactPhoneNumber?.type == 'required' && <div className="isInvalidMessage text-danger">Golf contact phone number is required.</div>}
                    {addTeeonFormElementsErrors.golfContactPhoneNumber?.type == 'pattern' && <div className="isInvalidMessage text-danger">Golf contact phone number should be valid.</div>}
                    {addTeeonFormElementsErrors.golfContactPhoneCountryCode?.type == 'required' && <div className="isInvalidMessage text-danger">Golf contact phone number country code is required.</div>}
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="golfContactEmail"
                        placeholder="Golf Contact Email"
                        disabled={disableFormFields}
                        {...addTeeonFormElementsRegister("golfContactEmail", { required: true, pattern: /^\S+@\S+$/i})}
                      />
                      <label htmlFor="golfContactEmail">Golf Contact Email</label>
                      {addTeeonFormElementsErrors.golfContactEmail?.type == 'required' && <div className="isInvalidMessage text-danger">Golf contact email is required.</div>}
                      {addTeeonFormElementsErrors.golfContactEmail?.type == 'pattern' && <div className="isInvalidMessage text-danger">Golf contact email should be valid.</div>}
                    </div>
                  </div>
                </div>
                <div className="mb-4 row">
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="signedByName"
                        placeholder="Signed By Name"
                        disabled={disableFormFields}
                        {...addTeeonFormElementsRegister("signedByName", { required: true })}
                      />
                      <label htmlFor="signedByName">Signed By Name</label>
                      {addTeeonFormElementsErrors.signedByName?.type == 'required' && <div className="isInvalidMessage text-danger">Golf contact signed by name is required.</div>}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        type="button"
                        className="form-control clickable"
                        id="signFileName"
                        placeholder="Click and Select your Sign as an image"
                        disabled={disableFormFields}
                        onClick={() => setshowSignUploadModal(true)}
                        {...addTeeonFormElementsRegister("signFileName", { required: true })}
                      />
                      <label htmlFor="signFileName">Click and Select your Sign as an image</label>
                      {addTeeonFormElementsErrors.signFileName?.type == 'required' && <div className="isInvalidMessage text-danger">Golf signed file is required.</div>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {
                    disableFormFields ? <h3>
                      {
                        !!courseCode ? 'Your form application is approved': 'Form submitted'
                      }
                    </h3> : <div className="col-3">
                      <button
                        type="submit"
                        className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                        disabled={disableFormFields}
                      >
                        Submit
                      </button>
                    </div>
                  }
                </div>
            </form>
        </div>
        <ImageCropperUploadModal 
          isModalVisible={showSignUploadModal}
          onSubmit={onSignImageCropSubmit}
          handleClose={() => setshowSignUploadModal(false)}
          onError={(errString:string) => toast.error(errString)}
          popUpMessage="Select an image of your signature and crop the selected portion"
          popUpHeader="Select signature image"
          minCropBoxHeight={10}
          minCropBoxWidth={30}
          aspectRatio={3} 
        />
        <IFramePDFViewerModal
          isModalVisible={!!teeonOnboardingRequestId}
          handleClose={onClosePDFModal}
          pdfSrcURL={teeonOnBoardingGeneratedPdfUrl}
          onSubmit={onSubmitPDFModal}
          popUpHeader="Review application"
          popUpMessage="This is a system generated pdf which will be emailed to Teeon for teesheet access. Please review and approve."
        />
    </>;
}

export default LinkTeeTimeTeeonFormComponent;