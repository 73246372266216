import React, { FC, useRef, useState, useEffect } from "react";
import "./course-preview.scss";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import { EffectFade, Thumbs, FreeMode } from "swiper";

import { convertTime24to12 } from "../../../../utils/common";
import { ClubPayload } from "../../../../models/clubs";
import { CoursesPayload } from "../../../../models/courses";
import { CLUB_ACTIVE_STATUS, INTEGOLF_DISCOUNT_TYPE_DEFAULT, INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY, SAGA_STATUS_SUCCESS } from "../../../../utils/constants";
import { coursesActions, fetchCoursePublishStatus } from "../../../../reducers/coursesSlice";
import { discountActions, fetchDiscountProfiles } from "../../../../reducers/discountsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

const successPopUpMessage = "Your course is now published. You can start syncing your tee sheets directly from your course listings once your TTC application is approved.";
interface CoursePreviewProps {
  clubInfo: ClubPayload;
  courseInfo: CoursesPayload;
  navigateToPath: (path: string) => void;
}

interface CourseDiscountsProps {
  discounts: Array<any>;
  status: number;
  name: string;
  type: string;
  _id: string;
}

interface SuccessModalProps {
  showModal: boolean;
  message: string;
  onModalClose: Function
}

const CourseDiscounts: FC<CourseDiscountsProps> = ({
  discounts = [],
  status = CLUB_ACTIVE_STATUS,
  name = '',
  _id = '',
}) => (
  <div className="row mt-4">
    <div className="col-12">
      <h6 className="mb-2 fw600 color-800">{name} { (status === CLUB_ACTIVE_STATUS) ? <>
            <span className="badge active">Active</span>
          </>: <>
            <span className="badge badgeStatus danger">In-Active</span>
          </> 
        }
      </h6>
      <div className="d-block">
        {
          discounts.map((discountObj: any) => <label className="discountBadge" key = {discountObj?._id}>
            { discountObj?.type == INTEGOLF_DISCOUNT_TYPE_DEFAULT ? "Default" : (discountObj?.type == INTEGOLF_DISCOUNT_TYPE_THIRD_PARTY ? "3rd Party" : `${discountObj?.days == 0 ? "Current" : discountObj?.days} Day`) } booking : {discountObj?.discount}%
          </label>
          )
        }
      </div>
    </div>
  </div>
)

const SuccessModal: FC<SuccessModalProps> = ({
  showModal = false,
  message = 'Add message here',
  onModalClose = () => console.log('Close Clicked')
}) => (
  <>
    <Modal
      className="loginModal"
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <a href={void 0} className="closeButton float-end">
          <em
            className="fa-solid fa-close"
            onClick={() => {
              onModalClose();
            }}
          ></em>
        </a>

        <div className="successConfirmationModalWrap text-center">
          <span className="markIcon success">
            <em className="fa-solid fa-circle-check"></em>
          </span>
          <h2 className="mb-3">Sit back and relax!</h2>
          <p className="color-grey fw500 px-xl-5 mb-4">
            {message} 
          </p>

          <a
            href={void 0}
            className="text-decoration-underline fw600"
            onClick={() => {
              onModalClose();
            }}
          >
            My Listings
          </a>
        </div>
      </Modal.Body>
    </Modal>
  </>
);


const CoursePreview: FC<CoursePreviewProps> = ({
  clubInfo,
  courseInfo,
  navigateToPath
}) => {
  const {
    name,
    formattedName = "",
    addressOne,
    addressTwo,
    provinceName,
    countryName,
    description,
    photos,
    walkingAllowed = false,
    metalSpikesAllowed = false,
    dressCode = false,
    cartsForRentalAvailable = false,
    clubsForRentalAvailable = false,
    caddiesForRentalAvailable = false,
    drivingRange = false,
    pitchingArea = false,
    puttingGreen = false,
    teachingPro = false,
    clubTimings = [],
    tourismPortalClubTimings = [],
  } = clubInfo;
  const { holes = 0, slope = 0, par = 0, distance = 0, _id: courseId } = courseInfo;

  const sliderImages = (photos || []).map((photo) => ({
    imagePath: photo.path,
  }));

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swipermainRef = useRef() as any;
  const slideTo = (index: number) => {
    if (swipermainRef) {
      swipermainRef.current.swiper.slideTo(index);
    }
  };
  const courseLocation  = formattedName.length ? formattedName : [addressOne, addressTwo, provinceName,countryName].filter(elem => elem && elem.trim().length).join(", ") ;
  
  const dispatch = useAppDispatch();
  const coursePublishStatus: string = useAppSelector(fetchCoursePublishStatus);
  const courseDiscountProfiles: any = useAppSelector(fetchDiscountProfiles);

  const onPublishClick = () => {
    const updatePayload = { _id: courseId, status: CLUB_ACTIVE_STATUS };
    dispatch(coursesActions.coursePublish(updatePayload));
  };

  const onSucessModalClose = () => {
    dispatch(coursesActions.resetCoursePublishStatus());
    dispatch(coursesActions.resetUpdateCourseDataStatus());
    navigateToPath("/courses");
  }

  useEffect(() => {
    // when course is published then initiate the tee sheet sync job in backgroud
    if (coursePublishStatus === SAGA_STATUS_SUCCESS) {
      const syncCourseData = { courseObjectId : courseId };
      dispatch(coursesActions.courseSyncOnActive(syncCourseData));  
    }
  },[coursePublishStatus])

  useEffect(() => {
    dispatch(discountActions.fetchDiscountProfile(courseId));
  },[])

  return (
    <>
      <div className="coursePreviewWrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="courseContainer">
                <div className="coverImageWrap mb-4">
                  <Swiper
                    className="mySwiper"
                    effect={"fade"}
                    slidesPerView={1}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, EffectFade, Thumbs]}
                    ref={swipermainRef}
                  >
                    {sliderImages.map((e: any, j: number) => (
                      <SwiperSlide>
                        <div className="coverImage">
                          <img src={e.imagePath} alt="" className="img-fluid" />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <Swiper
                    spaceBetween={10}
                    slidesPerView={"auto"}
                    freeMode={true}
                    watchSlidesProgress
                    modules={[FreeMode, Thumbs]}
                    className="imageThumbsSlider"
                    onSwiper={(swiper: any) => setThumbsSwiper}
                  >
                    {sliderImages.map((e: any, j: number) => (
                      <SwiperSlide>
                        <span onClick={() => slideTo(j)}>
                          <img src={e.imagePath} alt="" className="img-fluid" />
                        </span>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>

                <h2 className="mb-2 cTitle">{name}</h2>
                <div className="_loc d-flex align-items-center mb-4">
                  <em className="icon-locaiton"></em> {courseLocation}
                </div>

                <div className="_detailSection">
                  <h5 className="_sectionTitle mb-3">Course Description</h5>
                  <p className="color-700 fw500">{description}</p>

                  <div className="row fw700">
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Holes</label>
                        <h6 className="mb-0 color-800">{holes}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Par</label>
                        <h6 className="mb-0 color-800">{par}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Slope</label>
                        <h6 className="mb-0 color-800">{slope}</h6>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="_metaInfo">
                        <label className="d-block color-700 mb-2">Length</label>
                        <h6 className="mb-0 color-800">{distance} yards</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_detailSection">
                  <h3 className="mb-4 pb-2 _sectionTitle">Things to know</h3>
                  <div className="row gutters-5">
                    <div className="col">
                      <h6 className="fw700 mb-2">Policies</h6>
                      <ul>
                        <li>
                          Walking allowed: {walkingAllowed ? "Yes" : "No"}
                        </li>
                        <li>
                          Metal spikes allowed:{" "}
                          {metalSpikesAllowed ? "Yes" : "No"}
                        </li>
                        <li>
                          Dress Code:{" "}
                          {dressCode ? "Yes" : "No"}
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <h6 className="fw700 mb-2">Practice/Instruction</h6>
                      <ul>
                        <li>Driving range: {drivingRange ? "Yes" : "No"}</li>
                        <li>
                          Pitching/chipping area: {pitchingArea ? "Yes" : "No"}
                        </li>
                        <li>Putting green: {puttingGreen ? "Yes" : "No"}</li>
                        <li>Teaching pro: {teachingPro ? "Yes" : "No"}</li>
                      </ul>
                    </div>
                    <div className="col">
                      <h6 className="fw700 mb-2">Rentals/Services</h6>
                      <ul>
                        <li>Carts: {cartsForRentalAvailable ? "Yes" : "No"}</li>
                        <li>Clubs: {clubsForRentalAvailable ? "Yes" : "No"}</li>
                        <li>
                          Caddies available:{" "}
                          {caddiesForRentalAvailable ? "Yes" : "No"}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="_detailSection ">
                  {
                    (clubTimings && clubTimings.length) ? <>
                      <div className="row pb-4 pl-2">
                        <h5 className="_sectionTitle mb-3">Booking Timings(Discounted Portal)</h5>
                        {clubTimings.map(timing => <>
                          <div className="row align-items-center gutters-10 mb-2">
                            <div className="col-auto">
                              <label className="daysLabel my-1 capitalize-class me-0">
                                {timing.day?.substring(0,3)}
                              </label>
                            </div>
                            <div className="col">
                              <div className="row gutters-10">
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Opening Time:</span>
                                    <span>{convertTime24to12(timing.startHours, timing?.startMinutes)}</span>
                                  </h6>
                                </div>
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Closing Time:</span>
                                    <span>{convertTime24to12(timing.endHours, timing?.endMinutes)}</span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>)}
                      </div>
                    </>: <></>
                  }
                  {
                    (tourismPortalClubTimings && tourismPortalClubTimings.length) ? <>
                      <div className="row pb-2 pl-2">
                        <h5 className="_sectionTitle mb-3">Booking Timings(Tourism Portal)</h5>
                        {tourismPortalClubTimings.map(timing => <>
                          <div className="row align-items-center gutters-10 mb-2">
                            <div className="col-auto">
                              <label className="daysLabel my-1 capitalize-class me-0">
                                {timing.day?.substring(0,3)}
                              </label>
                            </div>
                            <div className="col">
                              <div className="row gutters-10">
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Opening Time:</span>
                                    <span>{convertTime24to12(timing.startHours, timing?.startMinutes)}</span>
                                  </h6>
                                </div>
                                <div className="col-auto">
                                  <h6 className="my-1 fw700">
                                    <span className="fw400">Closing Time:</span>
                                    <span>{convertTime24to12(timing.endHours, timing?.endMinutes)}</span>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>)}
                      </div>
                    </>: <></>
                  }
                </div>
                { 
                  courseDiscountProfiles.length && <>
                  <div className="_detailSection border-0">
                    <h5 className="_sectionTitle mb-4">Discounts</h5>
                      {
                        courseDiscountProfiles.map((discountProfile: any) => <CourseDiscounts 
                          key = {discountProfile?._id}
                          discounts = {discountProfile?.discounts}
                          status = {discountProfile?.status}
                          name = {discountProfile?.name}
                          _id = {discountProfile?._id}
                          type = {discountProfile?.type}
                        />)
                      }
                  </div> 
                </>
                }
                <div className="_detailSection">
                  <div className="row align-items-left gutters-10 mb-2">
                    <div className="col-auto">
                      <button
                        type="button"
                        className="button button-primary button-rounded button-min-180 fw700"
                        onClick={() => onPublishClick()}
                      >
                        Publish Course
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal 
        showModal={(coursePublishStatus == SAGA_STATUS_SUCCESS)} 
        message={successPopUpMessage} 
        onModalClose={onSucessModalClose}
      />
    </>
  );
};

export default CoursePreview;