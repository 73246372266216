import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";
import { RootState } from '../app/store';
import { IChronogolfCourseRates } from '../models/clubs';

export interface CoursesState {
  fetchCoursesStatus: string;
  fetchCoursesDataStatus: string;
  addNewCourseStatus: string;
  updateCourseDataStatus: string;
  courseUpdatedData:any,
  deleteCourseDataStatus: string;
  courseSyncOnActiveStatus: string;
  coursePublishStatus: string;
  totalCoursesCount: number,
  courses: Array<any>;
  selectedCourse: any;
  testCourseTeesheetFetchStatus: string;
  teeonOnboardingStatus: string;
  teeonOnboardingResult: any;
  sendTeeonOnboardingFormStatus: string;
  fetchTeeonOnboardingFormStatus: string;
  teeonOnboardingFormDetails: Array<any>;
  fetchChronogolfCoursesRatesStatus: string;
  chronogolfCoursesRates: Array<IChronogolfCourseRates>;
}

const initialState: CoursesState = {
  fetchCoursesStatus: SAGA_STATUS_INIT,
  fetchCoursesDataStatus: SAGA_STATUS_INIT,
  addNewCourseStatus: SAGA_STATUS_INIT,
  updateCourseDataStatus: SAGA_STATUS_INIT,
  deleteCourseDataStatus: SAGA_STATUS_INIT,
  courseSyncOnActiveStatus: SAGA_STATUS_INIT,
  coursePublishStatus: SAGA_STATUS_INIT,
  testCourseTeesheetFetchStatus: SAGA_STATUS_INIT,
  courseUpdatedData:SAGA_STATUS_INIT,
  courses: [],
  totalCoursesCount: 0,
  selectedCourse: null,
  teeonOnboardingStatus: SAGA_STATUS_INIT,
  teeonOnboardingResult: null,
  sendTeeonOnboardingFormStatus: SAGA_STATUS_INIT,
  fetchTeeonOnboardingFormStatus: SAGA_STATUS_INIT,
  teeonOnboardingFormDetails: [],
  fetchChronogolfCoursesRatesStatus: SAGA_STATUS_INIT,
  chronogolfCoursesRates: []
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    coursesList(state, action: PayloadAction<any>) {
      state.fetchCoursesStatus = SAGA_STATUS_PENDING;
    },
    coursesListSuccess(state, action: PayloadAction<any>) {
      state.courses = action.payload;
      state.totalCoursesCount = Array.isArray(action.payload)? action.payload.length: 0;
      state.fetchCoursesStatus = SAGA_STATUS_SUCCESS;
    },
    coursesListFailed(state, action: PayloadAction<string>) {
      state.fetchCoursesStatus = SAGA_STATUS_FAILED;
    },
    fetchCourseData(state, action: PayloadAction<any>) {
      state.fetchCoursesDataStatus = SAGA_STATUS_PENDING;
    },
    fetchCourseDatafailed(state, action: PayloadAction<string>) {
      state.fetchCoursesDataStatus = SAGA_STATUS_FAILED;
    },
    selectedCourse(state, action: PayloadAction<any>) {
      state.selectedCourse = action.payload;
      state.fetchCoursesDataStatus = SAGA_STATUS_SUCCESS;
    },
    resetSelectedCourse(state) {
      state.selectedCourse = null;
      state.fetchCoursesDataStatus = SAGA_STATUS_INIT;
      state.addNewCourseStatus = SAGA_STATUS_INIT;
      state.coursePublishStatus = SAGA_STATUS_INIT;
      state.courseSyncOnActiveStatus = SAGA_STATUS_INIT;
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_INIT;
    },
    resetCourses(state) {
      state.courses = [];
      state.totalCoursesCount = 0;
      state.fetchCoursesStatus = SAGA_STATUS_INIT;
      state.selectedCourse = null;
      state.fetchCoursesDataStatus = SAGA_STATUS_INIT;
      state.addNewCourseStatus = SAGA_STATUS_INIT;
      state.updateCourseDataStatus = SAGA_STATUS_INIT;
      state.courseSyncOnActiveStatus = SAGA_STATUS_INIT;
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_INIT;
    },
    addNewCourse(state, action: PayloadAction<any>) {
      state.addNewCourseStatus = SAGA_STATUS_PENDING;      
    },
    addNewCourseFailure(state, action: PayloadAction<any>) {
      state.addNewCourseStatus = SAGA_STATUS_FAILED;
    },
    addNewCourseSuccess(state, action: PayloadAction<any>) {
      state.addNewCourseStatus = SAGA_STATUS_SUCCESS;
      state.selectedCourse = action.payload;
    },
    updateCourseData(state, action: PayloadAction<any>) {
      state.updateCourseDataStatus = SAGA_STATUS_PENDING;
    },
    updateCourseDataSuccess(state, action: PayloadAction<any>) {
      state.updateCourseDataStatus = SAGA_STATUS_SUCCESS;
      state.selectedCourse = action.payload;
    },
    updateCourseDataFailure(state, action: PayloadAction<any>) {
      state.updateCourseDataStatus = SAGA_STATUS_FAILED;
    },
    resetUpdateCourseDataStatus(state) {
      // state.courseUpdatedData={}
      state.updateCourseDataStatus = SAGA_STATUS_INIT;
    },
    deleteCourseData(state, action: PayloadAction<any>) {
      state.deleteCourseDataStatus = SAGA_STATUS_PENDING;
    },
    deleteCourseDataSuccess(state, action: PayloadAction<any>) {
      state.deleteCourseDataStatus = SAGA_STATUS_SUCCESS;
    },
    deleteCourseDataFailure(state, action: PayloadAction<any>) {
      state.deleteCourseDataStatus = SAGA_STATUS_FAILED;
    },
    resetCourseDeleteStatus(state) {
      state.deleteCourseDataStatus = SAGA_STATUS_INIT;
    },
    resetFetchCourseStatus(state) {
      state.fetchCoursesStatus = SAGA_STATUS_INIT;
    },
    courseSyncOnActive(state, action: PayloadAction<any>) {
      state.courseSyncOnActiveStatus = SAGA_STATUS_PENDING;
    },
    courseSyncOnActiveFailure(state, action: PayloadAction<string>) {
      state.courseSyncOnActiveStatus = SAGA_STATUS_FAILED;
    },
    courseSyncOnActiveSucess(state, action: PayloadAction<string>) {
      state.courseSyncOnActiveStatus = SAGA_STATUS_SUCCESS;
    },
    resetCourseSyncOnActiveStatus(state) {
      state.courseSyncOnActiveStatus = SAGA_STATUS_INIT;
    },
    coursePublish(state, action: PayloadAction<any>) {
      state.coursePublishStatus = SAGA_STATUS_PENDING;
    },
    handleCoursePublishFailure(state) {
      state.coursePublishStatus = SAGA_STATUS_FAILED;
    },
    handleCoursePublishSucess(state) {
      state.coursePublishStatus = SAGA_STATUS_SUCCESS;
    },
    resetCoursePublishStatus(state) {
      state.coursePublishStatus = SAGA_STATUS_INIT;
    },
    testCourseTeesheetFetch(state, action: PayloadAction<any>) {
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_PENDING;
    },
    testCourseTeesheetFetchSuccess(state, action: PayloadAction<any>) {
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_SUCCESS;
    },
    testCourseTeesheetFetchFailure(state, action: PayloadAction<any>) {
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_FAILED;
    },
    resetTestCourseTeesheetFetchStatus(state) {
      state.testCourseTeesheetFetchStatus = SAGA_STATUS_INIT;
    },
    teeonOnboarding(state, action: PayloadAction<any>) {
      state.teeonOnboardingStatus = SAGA_STATUS_PENDING;
    },
    teeonOnboardingSuccess(state, action: PayloadAction<any>) {
      state.teeonOnboardingStatus = SAGA_STATUS_SUCCESS;
      state.teeonOnboardingResult = action.payload;
    },
    teeonOnboardingFailure(state, action: PayloadAction<any>) {
      state.teeonOnboardingStatus = SAGA_STATUS_FAILED;
    },
    resetTeeonOnboardingStatus(state) {
      state.teeonOnboardingStatus = SAGA_STATUS_INIT;
      state.teeonOnboardingResult = null;
    },
    sendTeeonOnboardingForm(state, action: PayloadAction<any>) {
      state.sendTeeonOnboardingFormStatus = SAGA_STATUS_PENDING;
    },
    sendTeeonOnboardingFormSuccess(state, action: PayloadAction<any>) {
      state.sendTeeonOnboardingFormStatus = SAGA_STATUS_SUCCESS;
    },
    sendTeeonOnboardingFormFailure(state, action: PayloadAction<any>) {
      state.sendTeeonOnboardingFormStatus = SAGA_STATUS_FAILED;
    },
    resetSendTeeonOnboardingFormStatus(state) {
      state.sendTeeonOnboardingFormStatus = SAGA_STATUS_INIT;
    },
    fetchTeeonOnboardingFormDetails(state, action: PayloadAction<any>) {
      state.fetchTeeonOnboardingFormStatus = SAGA_STATUS_PENDING;
    },
    fetchTeeonOnboardingFormDetailsSuccess(state, action: PayloadAction<any>) {
      state.fetchTeeonOnboardingFormStatus = SAGA_STATUS_SUCCESS;
      state.teeonOnboardingFormDetails = action.payload?.result;
    },
    fetchTeeonOnboardingFormDetailsFailure(state, action: PayloadAction<any>) {
      state.fetchTeeonOnboardingFormStatus = SAGA_STATUS_FAILED;
    },
    resetFetchTeeonOnboardingFormStatus(state) {
      state.fetchTeeonOnboardingFormStatus = SAGA_STATUS_INIT;
      state.teeonOnboardingFormDetails = [];
    },
    fetchChronogolfCoursesRates(state, action: PayloadAction<any>) {
      state.fetchChronogolfCoursesRatesStatus = SAGA_STATUS_PENDING;
    },
    fetchChronogolfCoursesRatesSuccess(state, action: PayloadAction<any>) {
      state.fetchChronogolfCoursesRatesStatus = SAGA_STATUS_SUCCESS;
      state.chronogolfCoursesRates = action.payload?.results;
    },
    fetchChronogolfCoursesRatesFailure(state, action: PayloadAction<any>) {
      state.fetchChronogolfCoursesRatesStatus = SAGA_STATUS_FAILED;
    },
    resetFetchChronogolfCoursesRates(state) {
      state.fetchChronogolfCoursesRatesStatus = SAGA_STATUS_INIT;
      state.chronogolfCoursesRates = [];
    },
    addCourseUpdatedData(state,action:PayloadAction<any>){
      state.courseUpdatedData=action?.payload
    },
    resetCourseUpdatedData(state){
      state.courseUpdatedData=null
    }

  },
});

// Actions
export const coursesActions = coursesSlice.actions;

// Selectors
export const fetchCoursesStatus = (state: RootState) => state.courses.fetchCoursesStatus;
export const fetchCourses = (state: RootState) => state.courses.courses;
export const fetchCoursesCount = (state: RootState) => state.courses.totalCoursesCount;
export const fetchCourseData = (state: RootState) => state.courses.selectedCourse;
export const fetchCourseSyncOnActiveStatus = (state: RootState) => state.courses.courseSyncOnActiveStatus;
export const fetchUpdateCourseDataStatus = (state: RootState) =>state.courses.updateCourseDataStatus;
export const fetchCourseDeleteStatus = (state: RootState) =>state.courses.deleteCourseDataStatus;
export const fetchCoursePublishStatus = (state: RootState) =>state.courses.coursePublishStatus;
export const fetchTestCourseTeesheetFetchStatus = (state: RootState) =>state.courses.testCourseTeesheetFetchStatus;
export const fetchTeeonOnboardingStatus = (state:RootState) => state.courses.teeonOnboardingStatus;
export const fetchTeeonOnboardingResult = (state: RootState) => state.courses.teeonOnboardingResult;
export const fetchSendTeeonOnboardingFormStatus = (state: RootState) => state.courses.sendTeeonOnboardingFormStatus;
export const fetchTeeonOnboardingFormDetails = (state: RootState) => state.courses.teeonOnboardingFormDetails;
export const fetchTeeonOnboardingFormStatus = (state: RootState) => state.courses.fetchTeeonOnboardingFormStatus;
export const fetchChronogolfCoursesRates = (state: RootState) => state.courses.chronogolfCoursesRates;
export const getFetchChronogolfCoursesRatesStatus = (state: RootState) => state.courses.fetchChronogolfCoursesRatesStatus;
export const getCourseUpdatedData = (state:RootState) => state.courses.courseUpdatedData
// Reducer
const coursesReducer = coursesSlice.reducer;
export default coursesReducer;
