import React, { FC, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import {
  COURSE_TEE_HOURS,
  COURSE_TEE_MINUTES,
  TEESHEET_SOLD_COUNT_FOR_INSTANT_DISCOUNT,
} from "../../../utils/constants";
import {
  convertTime24to12,
  disableNumWheelBehaviour,
} from "../../../utils/common";

interface ModalProps {
  showModal: boolean;
  onCloseClick: any;
  selectedDiscount: any;
  onAddInstantDiscount: any;
  modalType?: string;
  deleteDiscount: any;
  currentClubDateTime:any;
}

interface FormValues {
  discounttype: string;
  start_time?: string;
  end_time?: string;
  no_of_count?: number;
  discount: number;
}

const DiscountModel: FC<ModalProps> = ({
  showModal = false,
  onCloseClick,
  selectedDiscount,
  onAddInstantDiscount,
  modalType,
  deleteDiscount,
  currentClubDateTime
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  useEffect(() => {
    if (selectedDiscount && Object.keys(selectedDiscount).length != 0) {
      reset({
        discounttype:
          selectedDiscount?.teesheetSold && selectedDiscount?.teesheetSold > 0
            ? "discount_by_count"
            : "discount_by_time",
        start_time: selectedDiscount?.startTime
          ? selectedDiscount?.startTime
          : "",
        end_time: selectedDiscount?.endTime ? selectedDiscount?.endTime : "",
        no_of_count: selectedDiscount?.teesheetSold
          ? selectedDiscount?.teesheetSold
          : 0,
        discount: selectedDiscount?.discount ? selectedDiscount?.discount : 0,
      });

      if (selectedDiscount.startTime) {
        let splitTime = selectedDiscount.startTime.split(":");
        setStartTimeHours(splitTime[0]);
        setStartTimeMinutes(splitTime[1]);
      }

      if (selectedDiscount.endTime) {
        let splitTime = selectedDiscount.endTime.split(":");
        setStartTimeHours(splitTime[0]);
        setStartTimeMinutes(splitTime[1]);
      }
    } else {
      reset({
        discounttype: "discount_by_count",
        start_time: "",
        end_time: "",
        no_of_count: 0,
        discount: 0,
      });
      setStartTimeHours("");
      setStartTimeMinutes("");
      setEndTimeHours("");
      setEndTimeMinutes("");
    }
  }, [selectedDiscount]);

  const selectedOption = watch("discounttype");
  const [startTimeHours, setStartTimeHours] = useState("");
  const [startTimeMinutes, setStartTimeMinutes] = useState("");

  const [endTimeHours, setEndTimeHours] = useState("");
  const [endTimeMinutes, setEndTimeMinutes] = useState("");

  const now = new Date();
  const currentHour = currentClubDateTime.hour || now.getHours();
  const currentMinute = currentClubDateTime.minute || now.getMinutes();


  const validate = (data: FormValues) => {
    const errors: Record<string, string> = {};

    if (!data.discounttype) {
      errors.discounttype = "Discount type is required";
    }

    if (data.discounttype === "discount_by_time") {
      if (!data.start_time) {
        errors.start_time = "Start time is required";
      }
      if (!data.end_time) {
        errors.end_time = "End time is required";
      }
      if (data.start_time && data.end_time) {
        const startMinutes = timeToMinutes(data.start_time);
        const endMinutes = timeToMinutes(data.end_time);
        if (startMinutes > endMinutes) {
          errors.start_time = "Start time cannot be greater than End Time";
        }

        if (startMinutes == endMinutes) {
          errors.start_time = "Start and End Time cannot be same";
        }
      }
    } else if (data.discounttype === "discount_by_count") {
      if (data.no_of_count === undefined || data.no_of_count <= 0) {
        errors.no_of_count =
          "Teesheet sold count is required and cannot be 0";
      }

      if (
        data.no_of_count &&
        data.no_of_count > TEESHEET_SOLD_COUNT_FOR_INSTANT_DISCOUNT
      ) {
        errors.no_of_count = `Teesheet sold count cannot be more than ${TEESHEET_SOLD_COUNT_FOR_INSTANT_DISCOUNT}`;
      }
    }

    if (data.discount <= 0) {
      errors.discount = "Discount is required and cannot be 0";
    }

    if (data.discount >= 100) {
      errors.discount = "Discount cannot be more than 99%";
    }

    // Set errors if any
    for (const key in errors) {
      setError(key as keyof FormValues, { message: errors[key] });
    }

    // Clear errors if validation passes
    if (Object.keys(errors).length === 0) {
      clearErrors();
      return true;
    }

    return false;
  };

  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const onSubmit = (data: FormValues) => {
    if (validate(data)) {
      let obj: any = {
        discount: Number(data.discount),
      };

      if (data.discounttype == "discount_by_time") {
        obj["startTime"] = data.start_time;
        obj["endTime"] = data.end_time;
      } else {
        obj["teesheetSold"] = Number(data.no_of_count);
      }

      if (selectedDiscount && Object.keys(selectedDiscount).length != 0) {
        obj["discountProfileId"] = selectedDiscount?.discountProfileId?._id;
        obj["discountId"] = selectedDiscount._id;
      }
      onAddInstantDiscount(obj);
    }
  };

  return (
    <Modal
      className={`discountModalWrapper modalLayoutWrapper `}
      show={showModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <a
          href={void 0}
          className="closeButton"
          onClick={() => {
            reset();
            onCloseClick();
          }}
        >
          <em className="fa-solid fa-close"></em>
        </a>
        <div className="formWrap">
          {/* Add Discount level modal  */}

          {(!modalType || modalType != "delete") && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h4 className="mb-4">Add Instant Discount</h4>

              {/* 
old checkbox.....
          <div className="mb-4 w-75 d-flex justify-content-between align-items-center ">
            <div className="w-50">
              <label className="w-100 d-flex  justify-content-between align-items-center ">
                <Controller

                  name="discounttype"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="radio"
                        id="option1"
                        value={'discount_by_time'}
                        checked={field.value == "discount_by_time"}
                      />
                      <span>Discount by time</span>
                    </>
                  )}
                />
              </label>
            </div>
            <div>
              <label>
                <Controller
                  name="discounttype"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="radio"
                        id="option2"
                        value={'discount_by_count'}
                        checked={field.value == "discount_by_count"}
                      />
                      <span>Discount by count</span>
                    </>
                  )}
                />

              </label>
            </div>
            {errors.discounttype && <p className="text-danger">{errors.discounttype.message}</p>}
          </div> */}

              {/* --------------newbutton--------------- */}
              <div className="mb-4 d-flex align-items-center custom-discount-wrapper modalBtnText">
                <div className="flex-grow-1">
                  <Controller
                    name="discounttype"
                    control={control}
                    render={({ field }) => (
                      <button
                        type="button"
                        className={`custom-discount-button ${
                          field.value === "discount_by_time"
                            ? "btn-primary active"
                            : "btn-outline-primary"
                        } fw-semibold px-3 py-2`}
                        onClick={() => field.onChange("discount_by_time")}
                      >
                        Discount by time
                      </button>
                    )}
                  />
                </div>
                <div className="flex-grow-1">
                  <Controller
                    name="discounttype"
                    control={control}
                    render={({ field }) => (
                      <button
                        type="button"
                        className={`custom-discount-button ${
                          field.value === "discount_by_count"
                            ? "btn-primary active"
                            : "btn-outline-primary"
                        } fw-semibold px-4 py-2`}
                        onClick={() => field.onChange("discount_by_count")}
                      >
                        Discount by count
                      </button>
                    )}
                  />
                </div>
              </div>

              {/* --------------newbutton--------------- */}

              {selectedOption == "discount_by_time" && (
                <>
                  <div className="mb-4">
                    <div className="form-floating">
                      <Controller
                        name="start_time"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className="form-control"
                            id="start_time"
                          >
                            <option value="" disabled>
                              Select Option
                            </option>
                            {COURSE_TEE_HOURS
                          .filter(hourVal => Number(hourVal) > currentHour || (Number(hourVal) == currentHour && COURSE_TEE_MINUTES.some(minuteVal => Number(minuteVal) > currentMinute)))
                          .flatMap(hourVal =>
                            COURSE_TEE_MINUTES
                              .filter(minuteVal => Number(hourVal) > currentHour || (Number(hourVal) == currentHour && Number(minuteVal) > currentMinute))
                              .map(minuteVal => (
                                <option
                                  value={`${hourVal}:${minuteVal}`}
                                  key={`${hourVal}:${minuteVal}`}
                                  defaultValue={`${startTimeHours}:${startTimeMinutes}`} // Adjust as needed
                                >
                                  {convertTime24to12(hourVal, minuteVal)}
                                </option>
                              ))
                          )}
                          </select>
                        )}
                      />
                      <label htmlFor="start_time">Start Time</label>
                    </div>
                    {errors.start_time && (
                      <p className="text-danger">{errors.start_time.message}</p>
                    )}
                  </div>

                  <div className="mb-4">
                    <div className="form-floating">
                      <Controller
                        name="end_time"
                        control={control}
                        render={({ field }) => (
                          <select
                            {...field}
                            className="form-control"
                            id="end_time"
                          >
                            <option value="" disabled>
                              Select Option
                            </option>
                            {COURSE_TEE_HOURS
                          .filter(hourVal => Number(hourVal) > currentHour || (Number(hourVal) == currentHour && COURSE_TEE_MINUTES.some(minuteVal => Number(minuteVal) > currentMinute)))
                          .flatMap(hourVal =>
                            COURSE_TEE_MINUTES
                              .filter(minuteVal => Number(hourVal) > currentHour || (Number(hourVal) == currentHour && Number(minuteVal) > currentMinute))
                              .map(minuteVal => (
                                <option
                                  value={`${hourVal}:${minuteVal}`}
                                  key={`${hourVal}:${minuteVal}`}
                                  defaultValue={`${startTimeHours}:${startTimeMinutes}`} // Adjust as needed
                                >
                                  {convertTime24to12(hourVal, minuteVal)}
                                </option>
                              ))
                          )}
                          </select>
                        )}
                      />
                      <label htmlFor="end_time">End Time</label>
                    </div>
                    {errors.end_time && (
                      <p className="text-danger">{errors.end_time.message}</p>
                    )}
                  </div>
                </>
              )}

              {selectedOption == "discount_by_count" && (
                <div className="mb-4">
                  <div className="form-floating">
                    <Controller
                      name="no_of_count"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          className="form-control"
                          id="no_of_count"
                          placeholder="Number of Count"
                          {...field}
                          onChange={(e) => {
                            let value = Number(e.target.value);
                            if (value > 0) {
                              e.target.value = Math.trunc(value).toFixed(0);
                            } else {
                              e.target.value = "";
                            }
                            field.onChange(e);
                          }}
                          onWheel={disableNumWheelBehaviour}
                        />
                      )}
                    />
                    <label htmlFor="no_of_count">Number of Count</label>
                  </div>
                  {errors.no_of_count && (
                    <p className="text-danger">{errors.no_of_count.message}</p>
                  )}
                </div>
              )}

              <div className="mb-4">
                <div className="form-floating">
                  <Controller
                    name="discount"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="number"
                        step={1}
                        className="form-control"
                        id="discount"
                        placeholder="Discount Offered"
                        {...field}
                        onChange={(e) => {
                          let value = Number(e.target.value);
                          if (value > 0) {
                            e.target.value = Math.trunc(value).toFixed(0);
                          } else {
                            e.target.value = "";
                          }
                          field.onChange(e);
                        }}
                        onWheel={disableNumWheelBehaviour}
                      />
                    )}
                  />
                  <label htmlFor="discount">Discount Offered</label>
                </div>
                {errors.discount && (
                  <p className="text-danger">{errors.discount.message}</p>
                )}
              </div>

              <button
                type="submit"
                className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
              >
                {selectedDiscount && Object.keys(selectedDiscount).length != 0
                  ? "Update"
                  : "Save"}
              </button>
            </form>
          )}

          {/* Delete Discount profile modal  */}
          {modalType && modalType == "delete" && (
            <div className="text-center px-md-5">
              <img
                src="./assets/images/info-circle.png"
                alt="deactivate"
                className="mb-4 d-inline-block"
              />
              <h3 className="mb-4">
                Are you sure, you want to delete this discount?
              </h3>
              <a
                href={void 0}
                className="button button-primary button-rounded button-min-120 fw600 fontsize-16"
                onClick={() => {
                  deleteDiscount({
                    discountId: selectedDiscount?._id,
                    discountProfileId: selectedDiscount?.discountProfileId?._id,
                  });
                  reset({});
                }}
              >
                Yes, Delete
              </a>
            </div>
          )}

          {/* {levelType == "success" && (
          <div className="successConfirmationModalWrap text-center">
            <span className="markIcon success">
              <em className="fa-solid fa-circle-check"></em>
            </span>
            <h2 className="mb-3">Successfully updated.</h2>
          </div>
        )} */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DiscountModel;
