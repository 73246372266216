import React, { FC , useEffect, useState} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import './bookings-cancelled.scss';
import { NavLink } from 'react-router-dom';
import BookingList from '../../components/bookingList';
import { bookingActions, fetchBookings, fetchBookingCount } from '../../bookingSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { useAppSelector } from '../../../../app/hooks';


const BookingsCancelled= () => {
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(0)
 
  useEffect(() => {
    dispatch(bookingActions.bookingList(`?skip=${skip}&limit=10&type=cancelled`))
  }, [skip])
  const bookings = useAppSelector(fetchBookings);
  const totalCount = useAppSelector(fetchBookingCount);
  return (
    <>
      {/* <div className="bookingDataBlock mb-2">
        <div className="row gutters-10">
          <div className="col-auto">
            <span className="userImage">
              <img
                src="../../assets/images/default-user.png"
                alt="user"
                className="img-fluid"
              />
            </span>
          </div>
          <div className="col">
            <div className="row">
              <div className="col-12 col-lg-5">
                <h2 className="mb-2">James Hamon</h2>
                <p className="color-700 mb-3 fw500">
                  613-555-0192{" "}
                  <span className="color-400">&nbsp; | &nbsp;</span>{" "}
                  james_hamon@gmail.com
                </p>
                <p className="color-600 mb-2 fw600">
                  Booked on:{" "}
                  <span className="color-800">11:10 AM, Mon, Oct 31, 2022</span>
                </p>
                <p className="color-600 mb-2 fw600">
                  Total Payment:{" "}
                  <span className="color-800 fw700">$150.98</span>
                </p>
              </div>
              <div className="col-12 col-lg-7 colSeprator">
                <h3 className="mb-2">Lincoln Park Golf Course</h3>
                <p className="color-700 mb-3 fw600">
                  <em className="icon-location me-2"></em> 26W151 Butterfield
                  Rd, Wheaton,Orlando, FL
                </p>
                <div className="row gutters-7">
                  <div className="col-12 col-sm _item">
                    <label className="color-600 mb-2">Tee Time</label>
                    <h5 className="color-800">11:10 AM, Mon, Oct 31, 2022</h5>
                  </div>
                  <div className="col-12 col-sm _item">
                    <label className="color-600 mb-2">Number of Golfers</label>
                    <h5 className="color-800">2 players, 18 Holes</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto align-self-center">
            <div className="d-flex align-items-center actions">
              <button
                type="button"
                className="button button-secondary button-border button-rounded button-sm px-3 fw600"
              >
                Initiate Refund
              </button>
              <div className="listActionDropdownBlock ms-3">
                <Dropdown align="end">
                  <Dropdown.Toggle className="toggleButton">
                    <em className="icon-vertical-elipse"></em>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <NavLink to={"/booking-detail"} className="dropdown-item">
                      View Detail
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bookingDataBlock mb-2">
        <div className="row gutters-10">
          <div className="col-auto">
            <span className="userImage">
              <img
                src="../../assets/images/default-user.png"
                alt="user"
                className="img-fluid"
              />
            </span>
          </div>
          <div className="col">
            <div className="row">
              <div className="col-12 col-lg-5">
                <h2 className="mb-2">James Hamon</h2>
                <p className="color-700 mb-3 fw500">
                  613-555-0192{" "}
                  <span className="color-400">&nbsp; | &nbsp;</span>{" "}
                  james_hamon@gmail.com
                </p>
                <p className="color-600 mb-2 fw600">
                  Booked on:{" "}
                  <span className="color-800">11:10 AM, Mon, Oct 31, 2022</span>
                </p>
                <p className="color-600 mb-2 fw600">
                  Total Payment:{" "}
                  <span className="color-800 fw700">$150.98</span>
                </p>
              </div>
              <div className="col-12 col-lg-7 colSeprator">
                <h3 className="mb-2">Lincoln Park Golf Course</h3>
                <p className="color-700 mb-3 fw600">
                  <em className="icon-location me-2"></em> 26W151 Butterfield
                  Rd, Wheaton,Orlando, FL
                </p>
                <div className="row gutters-7">
                  <div className="col-12 col-sm _item">
                    <label className="color-600 mb-2">Tee Time</label>
                    <h5 className="color-800">11:10 AM, Mon, Oct 31, 2022</h5>
                  </div>
                  <div className="col-12 col-sm _item">
                    <label className="color-600 mb-2">Number of Golfers</label>
                    <h5 className="color-800">2 players, 18 Holes</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto align-self-center">
            <div className="d-flex align-items-center actions">
              <label className="_label active fontsize-14 fw700 my-2">Amount Refunded</label>
              <div className="listActionDropdownBlock ms-3">
                <Dropdown align="end">
                  <Dropdown.Toggle className="toggleButton">
                    <em className="icon-vertical-elipse"></em>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <NavLink to={"/booking-detail"} className="dropdown-item">
                      View Detail
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <BookingList bookings={bookings} totalCount={totalCount} setSkip={setSkip} type={'cancelled'}></BookingList>
    </>
  );
};

export default BookingsCancelled;
