import OtpInput from "react-otp-input";
import { useState } from "react";
// import Modal from "react-bootstrap/Modal";
import { FunctionComponent, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { authActions } from "../../../reducers/authSlice";

interface VerificationProps {
  countryCode: any,
  inputData: string,
  isPhoneInput: boolean
}

const VerifyPage: FunctionComponent<VerificationProps> = (props) => {

  const [OTP, setOTP] = useState("");
  // const [modalShow, setShowModal] = useState(false);
  // const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);
  const [showTimer, setShowTimer] = useState(true);
  const dispatch = useAppDispatch()
  const [showRequiredError, setShowRequiredError] = useState(false);

  const [time, setTime] = useState(180);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState(time % 60);

  function padLeadingZeros(num: any, size: any) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
      setMinutes(Math.floor(time / 60));
      setSeconds(time % 60);
    }, 1000);
    if (time < 0) {
      setShowTimer(false);
    }

    return () => clearInterval(interval);
  }, [time, minutes, seconds]);


  const sendOtp = (otp: number) => {
    let obj: any = {}
    if (props.isPhoneInput) {
      obj = {
        otp: otp,
        phone: {
          countryCode: props.countryCode,
          number: props.inputData
        }

      }
    } else {
      obj = {
        otp: otp,
        email: props.inputData
      }
    }
    obj['role'] = 'merchant'
    dispatch(authActions.verifyOtp(obj))
  }

  const resendOtp = () => {
    let obj: any = {}
    if (props.isPhoneInput) {
      obj = {
        phone: {
          countryCode: props.countryCode,
          number: props.inputData
        }
      }
    } else {
      obj = {
        email: props.inputData
      }
    }
    obj['role'] = 'merchant'
    dispatch(authActions.resendOtp(obj))
  }

  const onSubmit = (otp: any) => {
    if (otp) {
      setShowRequiredError(false)
      sendOtp(otp)
    } else {
      setShowRequiredError(true)
      return;
    }
  }

  const resend = () => {
    resendOtp()
    setTime(180)
    setMinutes(Math.floor(180 / 60))
    setSeconds(180 % 60)
    setShowTimer(true)
    setOTP("")
  }

  const onAddOtp = (otp: string) => {
    setOTP(otp)
    if (otp && otp.length == 6) {
      onSubmit(otp)
    }
  }

  const onBackClick = () => {
    dispatch(authActions.resetSignUpLoginStatus());
  }

  return (
    <>
      <div className="authWrap">
        <a className="button button-s3 button-border button-circle button-sm  me-3" onClick={onBackClick}>
          <em className="fas fa-arrow-left"></em>
        </a>
        <div className="text-center">
          <h1>Confirm your {props.isPhoneInput ? "number" : "email"}</h1>
          <h6 className="color-gray">
            Enter the code we've sent via {props.isPhoneInput ? "SMS" : "email"} to {props.isPhoneInput ? `${props.countryCode}${props.inputData}`: props.inputData}
          </h6>
        </div>
        <div className="formSection formSectionVerify">
          <div className="formWrap pb-0">
            <form>
              <div className="mb-4">
                {/* <div className="form-group-verify">
                  <label className="mb-0">Enter OTP</label> */}
                  <OtpInput
                    value={OTP}
                    shouldAutoFocus={true}
                    numInputs={6}
                    containerStyle={"optInputWrap"}
                    inputStyle={"optInput"}
                    focusStyle={"optInputFocus"}
                    isInputNum={true}
                    placeholder=""
                    onChange={(OTP: any) => onAddOtp(OTP)}
                  />
                  {/* {errors.code?.type == 'required' && span className="otpInvalid">Invalid!</span>}  */}
                {/* </div> */}
                {showRequiredError && <div className="isInvalidMessage text-danger">Please enter code</div>}
              </div>
              <div className="d-flex justify-content-between align-items-center mb-4">
                {showTimer && <p className="mb-0 fw600">Time remaining {padLeadingZeros(minutes, 2)} : {padLeadingZeros(seconds, 2)} </p>}
                {!showTimer && <p className="mb-0 fw600">Time remaining 00:00 </p>}
                <button
                  type="button"
                  onClick={() => resend()}
                  className="resendLink fw600 color-gray">
                  Resend
                </button>
              </div>
              {/* <div className="mb-4 pt-2">
                <button
                  type="button"
                  className="button button-primary button-block button-rounded button-large fw700 fontsize-16"
                  onClick={() => onSubmit()}
                >
                  Confirm
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};


export default VerifyPage;
