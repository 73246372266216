import React, { FC, useState } from "react";
import "./deactivate-modal.scss";
import Modal from "react-bootstrap/Modal";

interface DeactivateModalProps {
  showModal: boolean;
  handleClose: (arg: any) => void;
  onDeactivateClick: () => void;
}

const DeactivateModal: FC<DeactivateModalProps> = ({
  showModal,
  handleClose,
  onDeactivateClick
}) => {
  const [step, setStep] = useState(1);
  const next = (step: number) => {
    setStep(step);
  };
  const close = () => {
    handleClose(!showModal);
    setTimeout(() => {
      setStep(1);
    }, 300);
  };
  return (
    <>
      <Modal
        className="cancelBookingModal modalLayoutWrapper"
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <a href={void 0} className="closeButton">
            <em className="fa-solid fa-close" onClick={() => close()}></em>
          </a>
          <div className="formWrap">
            {step == 1 && (
              <div className="text-center">
                <img
                  src="./assets/images/spedometer-low.png"
                  alt="deactivate"
                  className="mb-4 d-inline-block"
                />
                <h3 className="mb-4">
                  Are you sure, you want to deactivate this course?
                </h3>
                <p className="mb-4 fontsize-16">
                  By deactivating, this course will no longer to available for
                  future bookings.
                </p>
                <a
                  href={void 0}
                  className="text-decoration-underline fw600 color-danger"
                  onClick={onDeactivateClick}
                >
                  Deactivate
                </a>
              </div>
            )}

            {step == 2 && (
              <div className="successConfirmationModalWrap text-center">
                <span className="markIcon success">
                  <em className="fa-solid fa-circle-check"></em>
                </span>
                <h2 className="mb-3">Successfully deactivated.</h2>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeactivateModal;
