import React, { FC, useEffect , useState} from "react";
import "./bookings-upcoming.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import BookingList from "../../components/bookingList";
import { useAppDispatch } from "../../../../app/hooks";
import { useAppSelector } from "../../../../app/hooks";
import { bookingActions, fetchBookings, fetchBookingCount } from "../../bookingSlice";


const BookingsUpcoming = () => {
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    dispatch(bookingActions.bookingList(`?skip=${skip}&limit=10&type=upcoming`))
  }, [skip])

  const bookings = useAppSelector(fetchBookings);
  const totalCount = useAppSelector(fetchBookingCount);
 
  return (
    <BookingList bookings={bookings} totalCount={totalCount} setSkip={setSkip} type={'upcoming'}></BookingList>

  );
};

export default BookingsUpcoming;
