import { RootState } from '../app/store';
import { SAGA_STATUS_INIT, SAGA_STATUS_PENDING, SAGA_STATUS_SUCCESS, SAGA_STATUS_FAILED } from "../utils/constants";

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ClubsState {
  fetchSyncClubStatus: string;
  fetchClubDataStatus: string;
  addNewClubStatus: string;
  updateClubDataStatus: string;
  activeDeactivateClubStatus: string;
  syncedClubs: any;
  totalSyncedClubsCount: number;
  fetchClubsStatus: string;
  totalClubsCount: number,
  clubs: any;
  clubData: any;
  createClubCourseStatus: string;
}

const initialState: ClubsState = {
  fetchClubsStatus: SAGA_STATUS_INIT,
  fetchSyncClubStatus: SAGA_STATUS_INIT,
  fetchClubDataStatus: SAGA_STATUS_INIT,
  addNewClubStatus: SAGA_STATUS_INIT,
  updateClubDataStatus: SAGA_STATUS_INIT,
  activeDeactivateClubStatus: SAGA_STATUS_INIT,
  createClubCourseStatus: SAGA_STATUS_INIT,
  clubs: [],
  syncedClubs: [],
  totalClubsCount: 0,
  totalSyncedClubsCount: 0,
  clubData: null
};

const clubsSlice = createSlice({
  name: 'clubs',
  initialState,
  reducers: {
    clubsList(state) {
      state.fetchClubsStatus = SAGA_STATUS_PENDING;
    },
    clubsListSuccess(state, action: PayloadAction<any>) {
      state.clubs = action.payload;
      state.totalClubsCount = Array.isArray(action.payload)? action.payload.length: 0;
      state.fetchClubsStatus = SAGA_STATUS_SUCCESS;
    },
    clubsListFailed(state, action: PayloadAction<string>) {
      state.fetchClubsStatus = SAGA_STATUS_FAILED;
    },
    syncedClubsList(state) {
      state.fetchSyncClubStatus = SAGA_STATUS_PENDING;
    },
    syncedClubsListSuccess(state, action: PayloadAction<any>) {
      state.syncedClubs = action.payload;
      state.totalSyncedClubsCount = Array.isArray(action.payload)? action.payload.length: 0;
      state.fetchSyncClubStatus = SAGA_STATUS_SUCCESS;
    },
    syncedClubsListFailed(state, action: PayloadAction<string>) {
      state.fetchSyncClubStatus = SAGA_STATUS_FAILED;
    },
    resetSyncedClubs(state) {
      state.syncedClubs = [];
      state.totalSyncedClubsCount = 0;
      state.fetchSyncClubStatus = SAGA_STATUS_INIT;
    },
    fetchSelectedClubData(state, action: PayloadAction<any>) {
      state.fetchClubDataStatus = SAGA_STATUS_PENDING;
    },
    fetchSelectedClubDataFailed(state, action: PayloadAction<string>) {
      state.fetchClubDataStatus = SAGA_STATUS_FAILED;
    },
    selectedClub(state, action: PayloadAction<any>) {
      state.clubData = action.payload;
      state.fetchClubDataStatus = SAGA_STATUS_SUCCESS;
    },
    resetSelectedClub(state) {
      state.clubData = null;
      state.fetchClubDataStatus = SAGA_STATUS_INIT;
      state.addNewClubStatus = SAGA_STATUS_INIT;
      state.updateClubDataStatus = SAGA_STATUS_INIT;
      state.createClubCourseStatus = SAGA_STATUS_INIT;
    },
    addNewClub(state, action: PayloadAction<any>) {
      state.addNewClubStatus = SAGA_STATUS_PENDING;
    },
    addNewClubSuccess(state, action: PayloadAction<any>) {
      state.addNewClubStatus = SAGA_STATUS_SUCCESS;
      state.clubData = action.payload;
    },
    addNewClubFailure(state, action: PayloadAction<string>) {
      state.addNewClubStatus = SAGA_STATUS_FAILED;
    },
    activateDeactivateClub(state, action: PayloadAction<any>) {
      state.activeDeactivateClubStatus = SAGA_STATUS_PENDING;
    },
    activateDeactivateClubSuccess(state, action: PayloadAction<any>) {
      state.activeDeactivateClubStatus = SAGA_STATUS_SUCCESS;
    },
    activateDeactivateClubFailure(state, action: PayloadAction<string>) {
      state.activeDeactivateClubStatus = SAGA_STATUS_FAILED;
    },
    updateClubData(state, action: PayloadAction<any>) {
      state.updateClubDataStatus = SAGA_STATUS_PENDING;
    },
    updateClubDataSucess(state, action: PayloadAction<string>) {
      state.updateClubDataStatus = SAGA_STATUS_SUCCESS;
      state.clubData = action.payload;
    },
    updateClubDataFailure(state, action: PayloadAction<string>) {
      state.updateClubDataStatus = SAGA_STATUS_FAILED;
    },
    resetUpdateClubDataStatus(state) {
      state.updateClubDataStatus = SAGA_STATUS_INIT;
    },
    createClubAndCourse(state, action: PayloadAction<any>) {
      state.createClubCourseStatus = SAGA_STATUS_PENDING;
    },
    createClubAndCourseSuccess(state, action: PayloadAction<any>) {
      state.createClubCourseStatus = SAGA_STATUS_SUCCESS;
    },
    createClubAndCourseFailure(state, action: PayloadAction<string>) {
      state.createClubCourseStatus = SAGA_STATUS_FAILED;
    },
    resetCreateClubAndCourseStatus(state) {
      state.createClubCourseStatus = SAGA_STATUS_INIT;
    },
    resetClubs(state) {
      state.fetchClubsStatus = SAGA_STATUS_INIT;
      state.fetchSyncClubStatus = SAGA_STATUS_INIT;
      state.fetchClubDataStatus = SAGA_STATUS_INIT;
      state.addNewClubStatus = SAGA_STATUS_INIT;
      state.updateClubDataStatus = SAGA_STATUS_INIT;
      state.activeDeactivateClubStatus = SAGA_STATUS_INIT;
      state.createClubCourseStatus = SAGA_STATUS_INIT;
      state.clubs = [];
      state.syncedClubs = [];
      state.totalClubsCount = 0;
      state.totalSyncedClubsCount = 0;
      state.clubData = null;
    },
  },
});

// Actions
export const clubsActions = clubsSlice.actions;

// Selectors
export const fetchClubsStatus = (state: RootState) => state.clubs.fetchClubsStatus;
export const fetchClubs = (state: RootState) => state.clubs.clubs;
export const fetchClubsCount = (state: RootState) => state.clubs.totalClubsCount;
export const fetchSyncedClubsListStatus = (state: RootState) => state.clubs.fetchSyncClubStatus;
export const fetchSyncedClubs = (state: RootState) => state.clubs.syncedClubs;
export const fetchSyncedClubsCount = (state: RootState) => state.clubs.totalSyncedClubsCount;
export const fetchClubData = (state: RootState) => state.clubs.clubData;
export const fetchClubDataStatus = (state: RootState) => state.clubs.fetchClubDataStatus;
export const fetchUpdateClubStatus = (state: RootState) => state.clubs.updateClubDataStatus;
export const fetchCreateClubCourseStatus = (state: RootState) => state.clubs.createClubCourseStatus;

// Reducer
const clubsReducer = clubsSlice.reducer;
export default clubsReducer;
